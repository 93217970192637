import {React,useState,useEffect} from "react";
import "./collectionss.css";
import Button from "@material-ui/core/Button";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Common/Header/Navbar";
import ShopNavbar2 from "../WebShop/Common/ShopNavbar2";
import FooterShop from "../WebShop/Common/FooterShop";
import {useDispatch} from "react-redux";
import Axios from "../../Services/Axios";
import toast from "../../utils/toast";
import {LoadingState} from "../../actions/loaderActions";

const Collectionss = ({ history }) => {
    const user = localStorage.getItem("user");
    const [subscriptions, setSubscriptions] = useState([]);

    if(!user || user === "") {
        history.push("/login");
    }


    let dispatch = useDispatch();

    useEffect(() => {

        const sendingData = {
            user_id: user,
            active: 1
        };

        Axios.get("/api/user/subscriptions/all?user_id="+user+'&active=1', sendingData)
            .then((res) => {
                if(res.data.length > 0) {
                    setSubscriptions(res.data);
                }
                dispatch(LoadingState(false));
            })
            .catch((err) => {
                toast.error("There is some error while getting subscriptions");
                // dispatch(LoadingState(false));
            });
    },[]);

  return (
    <div>
      <ShopNavbar2 history={history}/>
        <div style={{marginTop:130}}>
      <h1 className="text-center my-5">Collections</h1>
      <p className="text-center my-3">Next pickup will occur on</p>

      <div className="d-flex justify-content-center align-items-center">
        <span className="circle___css">
          <h3 style={{textAlign:'center'}}>
            On <br/>{subscriptions && subscriptions[0] && subscriptions[0].next_pickedup_date}
          </h3>
        </span>
      </div>
      <div className="container my-5 wrapper">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12" style={{margin:'auto'}}>
            <h2>Instructions: {subscriptions && subscriptions[0] && subscriptions[0].instructions_for_driver}</h2>
            <h4 className="my-2 hide" style={{display:'none'}}>Choose pick-up day</h4>
            <form action="" class="hide"  style={{display:'none'}}>
              <input
                type="date"
                id="birthday"
                name="birthday"
                className="pickup-css"
              />
            </form>
            <h4 className="mt-3 hide"  style={{display:'none'}}>Change day for next pick-up</h4>
            <form action="" class="hide"  style={{display:'none'}}>
              <input
                type="date"
                id="birthday"
                name="birthday"
                className="pickup-css"
              />
            </form>
            <br />
            <div className="d-flex justify-content-center"  style={{display:'none'}}>
              <Button variant="contained" color="primary"  style={{display:'none'}}>
                Spara
              </Button>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12"  style={{display:'none'}}>
            <h1>Add-on</h1>
            <h6>Try out of our add-on services</h6>
            <h6>ASAP Pick-up (149 kr per 60 liter)</h6>
            <form action="">
              <input
                type="date"
                id="birthday"
                name="birthday"
                className="pickup-css"
              />
            </form>
            <br />
            <h5>Götgatan 34</h5>
            <hr className="w-75 " />
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4 col-md-4 col-12">
                <h6>266 888</h6>
              </div>
              <div className="col-lg-8 col-md-8 col-12">
                <h6>Stockholm</h6>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <Button variant="contained" color="primary">
                Book
              </Button>
            </div>
          </div>
        </div>
      </div>
        </div>
        <FooterShop />
    </div>
  );
};

export default Collectionss;
