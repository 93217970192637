import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Icon from "@material-ui/core/Icon";

import { useDispatch, useSelector } from "react-redux";
import { AddPayment } from "../../actions/paymentActions";

import toast from '../../utils/toast';
import { getAllPackages } from '../../actions/packageActions';
import { getPackageBags } from '../../actions/packageBagActions';


const PaymentForm = () => {
    const user = localStorage.getItem("user");
    const { packages } = useSelector((state) => state.packageReducer);
    const { package_bags } = useSelector((state) => state.packageBagsReducer);

    const [name, setName] = useState("");
    const [cardNo, setCardNo] = useState("");
    const [expMonth, setExpMonth] = useState("");
    const [expYear, setExpYear] = useState("");
    const [cvc, setCvc] = useState("");
    const [amount, setAmount] = useState("");
    const [prodid, setProdid] = useState("");
    let id = ""

    const dispatch = useDispatch();

    

    useEffect(() => {
        console.log("Package VALUE");
        if (Array.isArray(packages) && packages.length > 0) {
            packages.map(data => {
                if (data.pkg_name === 'Relax') {
                    id = data.pkg_id;
                    return setProdid(data.pkg_id);
                }
            })
        } else {
            dispatch(getAllPackages());
        }
    }, [dispatch, packages]);

    useEffect(() => {
        const sendingData = {
            package_id: id
        };
        console.log("Payment data", sendingData);
        dispatch(getPackageBags(sendingData));
    }, []);

    const onClickListener = async (e) => {
        e.preventDefault();

        if (user === null) {
            toast.error("You are not logged in");
        }
        else {
            const sendingData = {
                name: name,
                product_id: prodid,
                cardNo: cardNo,
                expMonth: expMonth,
                expYear: expYear,
                cvc: cvc,
                amount: amount,
                user_id: user,
                type: 'package'
            };
            console.log("Payment data", sendingData);
            dispatch(AddPayment(sendingData));
        }

        // if (!stripe || !elements) {
        //     return;
        // }

        // const card = elements.getElement(CardElement);
        // const result = await stripe.createToken(card);

        // if (result.error) {
        //     console.log(result.error.message);
        // } else {
        //     stripeTokenHandler(result.token);
        // }


    };

    // function stripeTokenHandler(token) {
    //     setToken(token);

    //     const sendingData = {
    //         name: name,
    //         identifier: 'relax',
    //         // cardNo: cardNo,
    //         // expMonth: expMonth,
    //         // expYear: expYear,
    //         // cvc: cvc,
    //         // amount: amount,
    //         token: token.id,
    //         user_id: user
    //     };
    //     console.log("Payment data", sendingData);
    //     dispatch(AddPayment(sendingData));
    // }

    const style = {
        marginTop: '20px'
    }


    return (
        <form class="form" role="form" autocomplete="off" onSubmit={onClickListener}>
            <div class="form-group">
                <label for="cc_name">Card Holder's Name</label>
                <input
                    type="text"
                    class="form-control"
                    id="cc_name"
                    pattern="\w+ \w+.*"
                    title="First and last name"
                    required="required"
                    onChange={e => setName(e.target.value)}
                />
            </div>
            <div class="form-group">
                <label>Card Number</label>
                <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    maxlength="20"
                    pattern="\d{16}"
                    title="Credit card number"
                    required=""
                    onChange={e => setCardNo(e.target.value)}
                />
            </div>
            <div class="form-group row">
                <label class="col-md-12 ">Card Exp. Date</label>
                <div class="col-md-4 mt-2">
                    <select class="form-control" name="cc_exp_mo" size="0" onChange={e => setExpMonth(e.target.value)}>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </select>
                </div>
                <div class="col-md-4 mt-2">
                    <select class="form-control" name="cc_exp_yr" size="0" onChange={e => setExpYear(e.target.value)}>
                        <option>2018</option>
                        <option>2019</option>
                        <option>2020</option>
                        <option>2021</option>
                        <option>2022</option>
                    </select>
                </div>
                <div class="col-md-4 mt-2">
                    <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        maxlength="3"
                        pattern="\d{3}"
                        title="Three digits at back of your card"
                        required=""
                        placeholder="CVC"
                        onChange={e => setCvc(e.target.value)}
                    />
                </div>
            </div>
            <div class="row">
                <label class="col-md-12">Amount</label>
            </div>
            <div class="form-inline">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                    </div>
                    <input
                        type="text"
                        class="form-control text-right"
                        id="exampleInputAmount"
                        placeholder="39"
                        onChange={e => setAmount(e.target.value)}
                    />
                    <div class="input-group-append">
                        <span class="input-group-text">.00</span>
                    </div>
                </div>
            </div>
            <hr />
            <div class="form-group row">
                <div class="col-md-6 mt-2">
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                    >
                        cancel
                </Button>
                </div>
                <div class="col-md-6 mt-2">
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Icon>send</Icon>}
                        onClick={onClickListener}
                    >
                        Submit
                </Button>
                </div>
            </div>
        </form>
    )
}

export default PaymentForm;