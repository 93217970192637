import React from "react";
import "./BgImg.css";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Common/Header/Navbar";
import ShopNavbar2 from "../WebShop/Common/ShopNavbar2";
import FooterShop from "../WebShop/Common/FooterShop";

const DoYouKnow = () => {
  return (
    <div>
        <ShopNavbar2 />
      <div className="container doYouKNow" style={{paddingTop: 130}}>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12 mt-4">
            <div className="hero-image00"></div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 doYouKNowHead">
            <h1 className="home_page_heading">Your Contribution to the environment is </h1>
            <ul>
              <li style={{textAlign:'left'}}>12 more trees</li>
              <li style={{textAlign:'left'}}>10 kg less CO2</li>
              <li style={{textAlign:'left'}}>30 kg less plastic in the ocean</li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 mt-4 thirdSec">
            <div className="hero-image00"></div>
          </div>
        </div>
      </div>

      <div className="text mt-5 bg-white doyouknowText mb-5">
        <h1 className="text-center mt-3 px-3 home_page_heading">Do you know that?</h1>
        <p className="mt-5 text-center mx-5">
          Världen är 9 procent cirkulär. Det betyder att nio procent av de
          råvaror som mineraler, metaller och biomassa som används inom
          världsekonomin återvinns varje år.
        </p>
        <p className="mt-5 text-center mx-5">
          För en hållbar värld och för att vi ska kunna nå klimatmålen behöver
          mer återvinnas. Avfallet är ojämnt fördelat över världen. Europa och
          Centralasien har till exempel mer än dubbelt så mycket avfall per
          person som Afrika söder om Sahara.
        </p>
        <p className="mt-5 text-center mx-5">
          I Europa och Centralasien produceras cirka 390 kg sopor per person,
          medan Afrika söder om Sahara produceras 170 kg sopor per person.
          Mängden sopor i världen förväntas nästan fördubblas till år 2050. Den
          största ökningen kommer att ske i växande ekonomier. Tack för att du
          bidrar till en hållbar framtid.
        </p>
      </div>
        <FooterShop />
    </div>
  );
};

export default DoYouKnow;
