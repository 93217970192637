import {React,useState,useEffect} from "react";
import { NavLink } from 'react-router-dom';
import FooterShop from '../WebShop/Common/FooterShop';
import ShopNavbar2 from '../WebShop/Common/ShopNavbar2';
import './FaqDetailPage.css';
import Axios from "../../Services/Axios";
import {LoadingState} from "../../actions/loaderActions";
import { useDispatch } from "react-redux";

const FaqDetailPage = (props) => {
    const { id } = props.match.params;
    const [faqDetails, setFaqDetails] = useState([]);
    const [acc, setAcc] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {

        Axios.get("/api/faq/"+id)

             .then((res) => {
                 setFaqDetails(res.data.faqs);

                 if(res.data.data.length > 0) {
                    // setFaqDetails(res)

                }

                dispatch(LoadingState(false));
            })
            .catch((err) => {

                // dispatch(LoadingState(false));
            });

    },[]);
    return(
        <div className="faqPage">
            <ShopNavbar2 />
            <div className="banner">
                <h1>FAQ</h1>
            </div>
            <div className="container">
                <div className="contentWrap">
                    <div className="breadcrums">
                        <NavLink to="/faq">FAQ</NavLink><span className="slash"></span>
                    </div>
                    {faqDetails && faqDetails.map((data, id) => (
                        <div className="accordionWrap">
                            <h2 style={{cursor:'pointer'}} onClick={() => setAcc({...acc, [data.id]:!acc[data.id]})}>
                                {data.name}
                                {acc[data.id]===true &&
                                    <svg className="svg-inline--fa fa-minus fa-w-14" ariaHidden="true" viewBox="0 0 448 512" ><path fill="currentColor" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                                }
                                {!acc[data.id] &&
                                    <svg className="svg-inline--fa fa-plus fa-w-14" ariaHidden="true" viewBox="0 0 448 512" ><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                                }
                            </h2>
                            {acc[data.id]===true &&
                                <div className="content">
                                    {data.description}
                                </div>
                            }
                        </div>
                    ))}
                    {/* {faqDetails && faqDetails.map((faqDetail) => (
                        <div className="detailBox">
                            <div>
                                <h4>{faqDetail.name}</h4>
                                <p>{faqDetail.description}</p>
                            </div>
                        </div>
                    ))} */}
                </div>
            </div>
            <FooterShop />
        </div>
    )
}

export default FaqDetailPage;