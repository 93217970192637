import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updatePackage} from "../../../../actions/packageActions";
import Loader from "react-loader-spinner";

const Edit_Packages = ({history}) => {
    const data = history.location.state.id;

    const [name, setname] = useState(data.pkg_name);
    const [status, setstatus] = useState(data.pkg_active);
    const [noOfDays, setNoOfDays] = useState(data.no_of_days);
    const [frequency, setFrequency] = useState(data.frequency);
    const [isFormSubmit, setIsFormSubmit] = useState(0);

    const dispatch = useDispatch();
    const {loading} = useSelector((state) => state.loaderReducer);
    const [packageDescription, setPackageDescription] = useState(data.pkg_description);

    // history.location.state.id

    const onClickListener = () => {
        setIsFormSubmit(1)
        if(noOfDays!='' &&  name!='' && frequency!='' && packageDescription!='' ) {
            const data2 = {
                pkg_name: name,
                pkg_active: status,
                pkg_id: data.pkg_id,
                frequency: frequency,
                packageDescription: packageDescription,
                noOfDays: noOfDays,
            };
            console.log("SEND DATA", data2, data.pkg_id);
            dispatch(updatePackage(data2, data.pkg_id,history));
        }

    };
    return (
        <div className="p-2 mt-2  mx-auto">
            <div className="container card p-5 mobile__cs">
                <h2 className="text-center my-1">Edit Packages </h2>
                <div className="row mt-4">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                        <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                package name
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setname(e.target.value)}
                                value={name}
                            />
                        </div>
                        <div className="input-group mb-3">
                            {/*<span className="input-group-text" id="inputGroup-sizing-default">*/}
                            {/*Package Price*/}
                            {/*</span>*/}
                            {/*<input*/}
                            {/*type="text"*/}
                            {/*className="form-control"*/}
                            {/*aria-label="Sizing example input"*/}
                            {/*aria-describedby="inputGroup-sizing-default"*/}
                            {/*onChange={(e) => setPrice(e.target.value)}/>*/}
                            {/*</div>*/}
                            {/*<div className="input-group mb-3">*/}
                            <span className="input-group-text" id="inputGroup-sizing-default">
              No of Days
              </span>
                            <input
                                type="Number"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setNoOfDays(e.target.value)}
                                value={noOfDays}/>


                        </div>
                        {isFormSubmit == 1 && noOfDays == "" && <label className="row mx-auto" style={{color: "red"}}
                        >This field is required</label>}
                        <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Frequency
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setFrequency(e.target.value)}

                            value={frequency}
                            />
                        </div>
                        {isFormSubmit == 1 && frequency == "" && <label className="row mx-auto" style={{color: "red"}}
                        >This field is required</label>}
                        <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Package Description
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setPackageDescription(e.target.value)}
                            value={packageDescription}
                            />
                        </div>
                        {isFormSubmit == 1 && packageDescription == "" && <label className="row mx-auto" style={{color: "red"}}
                        >This field is required</label>}
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setstatus(e.target.value)}
                            value={status}
                        >
                            <option disabled selected>Status type</option>
                            <option value="1">active</option>
                            <option value="0">not active</option>
                        </select>
                        <br/>
                    </div>
                </div>
                <div className=" mx-auto">
                    <button
                        type="button"
                        class="btn btn-primary "
                        onClick={onClickListener}
                    >
                        Edit package
                        <Loader
                            type="Puff"
                            color="#FFF"
                            height={20}
                            width={15}
                            style={{marginLeft: 5}}
                            visible={loading}
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Edit_Packages;
