import { CLEAR_BAGS, GET_ALL_BAGS, GET_ALL_PACKAGES } from "./DispatchActions";
import Axios from "../Services/Axios";
import { LoadingState } from "./loaderActions";
import toast from "../utils/toast";

export const getAllBags = () => (dispatch) => {
  //   dispatch(LoadingState(true));

  Axios.get("/api/recycling-bags/all")
    .then((res) => {
      console.log("getAllBags :", res.data);
      dispatch({
        type: GET_ALL_BAGS,
        payload: res.data.data,
      });

      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      console.log("Register ERR:", data);
      //   dispatch(LoadingState(false));
    });
};

export const AddBags = (data) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("AddBags ACTION DATA", data);
  Axios.post("/api/recycling-bag/add", data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllBags());
      // if (res.status === 200) {
      toast.success("Recycle Bag Added successfully.");
      console.log("DATA API HIT :", res.data);
      // let { id, name, avatar } = jwtDecode(res.data.token);
      // authConfig(res.data.token, res.data.user, history);
      // dispatch({
      //   type: GET_PROFILE,
      //   payload: res.data,
      // });
      // }
    })
    .catch((err) => {
      // const data = err.response.data.error;
      // var res = data.split("validation.custom.messages.");
      // res = res[1];
      // console.log("Login ERR:", res);
      // // var pk = "invalid_login";

      // console.log("Messgas", Message.validation.custom.messages[res]);
      // const Error = Message.validation.custom.messages[res];
      toast.error("There is Some Error While Adding Recycle Bag");
      dispatch(LoadingState(false));
    });
};

export const updateBag = (data, id,history) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("updateBag ACTION DATA", data, id);
  Axios.post("/api/recycling-bag/edit/" + id, data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllBags());
        history.push("/admin/bag-list");

        toast.success("Recycle Bag Updated Successfully.");

      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Updating Recycle Bag");
      dispatch(LoadingState(false));
    });
};

export const removeBag = (id) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("removeBag ACTION DATA", id);
  Axios.get("/api/recycling-bag/delete/" + id)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllBags());
      toast.success("Recycle Bag Removed Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Removing Recycle Bag");
      dispatch(LoadingState(false));
    });
};

export const clearBags = () => (dispatch) => {
  console.log("ACTION clearBags ");
  dispatch({
    type: CLEAR_BAGS,
  });
};