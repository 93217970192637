import { GET_ALL_BAGS, CLEAR_BAGS } from "../actions/DispatchActions";

// START_LOADING
const initialState = {
  bags: null,
};

export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_ALL_BAGS:
      return { ...state, bags: actions.payload };
    case CLEAR_BAGS:
      return { bags: null };
    default:
      return state;
  }
}
