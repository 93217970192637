import React from "react";
import Button from "@material-ui/core/Button";
import "./choseplan.css";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Common/Header/Navbar";

const ChosePlan = ({ history }) => {
  return (
    <div>
      <Navbar />
      <h1 className="text-center my-5 plaan___css">
        80 LITERS <span className="span__border_left">|</span>120 LITERS
      </h1>
      <div className="container mb-5">
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 h4 planText">
            With larger space comes more opportunities for recycling. hand over
            the sorting to us. collect your waste with your own or our included
            bags. We will come and pick it up at your doorstep.
          </div>
          <div className="col-2"></div>
        </div>
      </div>
      <div className="container ">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-3 col-md-6 col-sm-12 relaxAlign">
            <div class="card relax3"></div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div class="card single3"></div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 relaxAlign">
            <div class="card standard3"></div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div class="card single3"></div>
          </div>
        </div>
        <br />
        <br />
      </div>
      <div className="d-flex justify-content-center mb-5">
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/chose-location")}
        >
          chose your plan
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default ChosePlan;
