import {
    GET_ALL_FAQ,
} from "../actions/DispatchActions";

// START_LOADING
const initialState = {
    allFaq: null,
};

export default function (state = initialState, actions) {
    switch (actions.type) {
        case GET_ALL_FAQ:
            return { ...state, allFaq: actions.payload };
        default:
            return state;
    }
}
