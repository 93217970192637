import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useDispatch, useSelector } from "react-redux";
import { addStoreProductCategory } from "../../../../actions/productAction";
import {
    clearCategories,
    getAllBulkRubbishCategories,
} from "../../../../actions/categoryAction";

const Add_Store_Category = () => {
    const dispatch = useDispatch();
    const { category } = useSelector((state) => state.categoryReducer);

    const initialState = "";
    const [size, setsize] = useState(initialState);

    const [name, setname] = useState(initialState);
    const [image, setimage] = useState(initialState);
    const [price, setprice] = useState(initialState);
    const [productPrice, setProductPrice] = useState(initialState);
    const [status, setstatus] = useState(1);
    const [categoryProduct, setcategory] = useState(initialState);
    const [productDescription, setproductDescription] = useState(initialState);
console.log(status)
    const onClickListener = () => {
        const formData = new FormData();
        formData.append("name", name);
        // formData.append("brp_desc", productDescription);
        // formData.append("brp_points", price);
        // formData.append("brp_price", productPrice);
        // formData.append("brp_img[]", image);
        // formData.append("brp_size", size);
        formData.append("status", status);
        // formData.append("brp_brc_id", categoryProduct);

        console.log("Add Product Data", formData);
        dispatch(addStoreProductCategory(formData));
    };

    useEffect(() => {
        if (Array.isArray(category) && category.length > 0) {
        } else {
            dispatch(getAllBulkRubbishCategories());
        }
    }, [dispatch, category]);

    useEffect(() => {
        return () => {
            dispatch(clearCategories());
            console.log("clearPackage");
        };
    }, [dispatch]);

    return (
        <div className="container p-2">
            <div className="container card p-5 add-products-container">
                <h2 className="text-center mb-5">Add Store category</h2>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 mx-auto mt-2">
                        <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Category Name
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setname(e.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Status
              </span>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setstatus(e.target.value)}
                            >
                                 <option value="0">Not Active</option>
                                <option value="1" selected>Active</option>
                            </select>
                        </div>
                        {/*<div className="mb-3 mt-2">*/}
                            {/*<input*/}
                                {/*className="form-control"*/}
                                {/*type="file"*/}
                                {/*id="formFile"*/}
                                {/*onChange={(e) => setimage(e.target.files[0])}*/}
                            {/*/>*/}
                        {/*</div>*/}

                    </div>

                </div>
                <div className=" mx-auto mt-3">
                    <button
                        type="button"
                        class="btn btn-primary "
                        onClick={onClickListener}
                    >
                        Add Store Category
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Add_Store_Category;
