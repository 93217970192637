import {
  CLEAR_PRODUCT_TYPE,
  CLEAR_ZIPCODE,
  GET_ALL_PRODUCT_TYPE,
  GET_ALL_ZIPCODE,
} from "../actions/DispatchActions";

// START_LOADING
const initialState = {
  productType: null,

};

export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_ALL_PRODUCT_TYPE:
      return { ...state, productType: actions.payload };
    case CLEAR_PRODUCT_TYPE:
      return { productType: null };

    default:
      return state;
  }
}
