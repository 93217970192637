import React from "react";
import { Parent_Color } from "../../Common/Color";
import "./bag.css";
import Button from "@material-ui/core/Button";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Common/Header/Navbar";

const RecycleBag = ({ history }) => {
  return (
    <div>
      <Navbar />
      <h1 className="text-center mt-5 mb-2">Recycle Bag</h1>
      {/* <h2 className="text-center my-3">How often do you want a collection?</h2> */}
      <div className="container">
        <div className="row my-5 p-0 text-center d-flex justify-content-center">
          <h4>Choose based on what fits best in your home.</h4>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-3 col-md-3 col-6">
            <div class="relax2"></div>
          </div>
          <div className="col-lg-3 col-md-3 col-6">
            <div class="single2"></div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-3 col-md-3 col-12 first  d-flex justify-content-center align-items-center">
            <h1>SIGNLE</h1>
          </div>
          <div className="col-lg-3 col-md-3 col-12 d-flex justify-content-center align-items-center second_css">
            <h1>STANDARD</h1>
          </div>
          <div className="col-lg-3 col-md-3 col-12 d-flex justify-content-center align-items-center third_css">
            <h1>RELAX</h1>
          </div>
        </div> */}
        <h5 className="text-center mt-5 px-5">
          Use of the bags are included.
          <br />
          The 80 liter bag can be compared to the sum of 3 of the largest size
          in the grocery stores. <br />
          All bags come with a lid as a closure <br />
          B32xH42xD14 cm{" "}
        </h5>

        <div className="d-flex justify-content-center my-5">
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/plan")}
          >
            chose your plan
          </Button>
        </div>

        {/* <h1 className="text-center my-4"></h1>
        <h1 className="text-center my-4">How does it work?</h1> */}
      </div>
      <Footer />
    </div>
  );
};

export default RecycleBag;
