import { CLEAR_ZIPCODE, GET_ALL_ZIPCODE } from "../actions/DispatchActions";

// START_LOADING
const initialState = {
  zipCode: null,
};

export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_ALL_ZIPCODE:
      return { ...state, zipCode: actions.payload };
    case CLEAR_ZIPCODE:
      return { zipCode: null };

    default:
      return state;
  }
}
