import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../../../actions/productAction";
import {
  clearProductType,
  getAllProductType,
} from "../../../../actions/productTypeAction";
import {
  clearCategories,
  getAllCategories,
} from "../../../../actions/categoryAction";

const Addproducts = () => {
  const dispatch = useDispatch();
  const initialState = "";
  const [numm, setNumm] = useState(0);
  const [name, setname] = useState(initialState);
  const [image, setimage] = useState(initialState);
  const [price, setprice] = useState(initialState);
  const [size, setsize] = useState(initialState);
  const [status, setstatus] = useState(initialState);
  const [description, setdescription] = useState(initialState);
  const [categoryProduct, setcategory] = useState(initialState);
  const [type, settype] = useState(initialState);
  const [point, setPoint] = useState('');
  const [color, setcolor] = useState(initialState);
  const { productType } = useSelector((state) => state.productTypeReducer);
  const { category } = useSelector((state) => state.categoryReducer);
    const [isFormSubmit, setIsFormSubmit] = useState(0);

  const incNumm = () => {
    setNumm(numm + 1);
  };
  const decNumm = () => {
    if (numm > 0) {
      setNumm(numm - 1);
    } else {
      alert("Sorry ! Zero Limit Reached");
      setNumm(0);
    }
  };

  const onClickListener = () => {

      if(name!='' && numm!='' && price!='' && image!='' && size!='' && status!='' && description!='' && type!='' && color!=''&& categoryProduct!='') {
          const formData = new FormData();
          formData.append("p_name", name);
          formData.append("p_qty", numm);
          formData.append("p_price", price);
          formData.append("p_img[]", image);
          formData.append("p_size", size);
          formData.append("p_active", status);
          // formData.append("type", point);
          formData.append("p_description", description);
          formData.append("p_pc_id", categoryProduct);
          formData.append("p_pt_id", type);

          formData.append("p_color", color);
          dispatch(addProduct(formData));

      }


    // console.log("Add Product Data", formData);
  };

  useEffect(() => {
    console.log("productType VALUE", productType);
    if (Array.isArray(productType) && productType.length > 0) {
    } else {
      dispatch(getAllProductType());
    }

    if (Array.isArray(category) && category.length > 0) {
    } else {
      dispatch(getAllCategories());
    }
  }, [dispatch, productType, category]);

  useEffect(() => {
    return () => {
      dispatch(clearProductType());
      dispatch(clearCategories());
      console.log("clearPackage");
    };
  }, [dispatch]);

  return (
    <div className="container p-2">
      <div className="container card p-4  add-products-container">
        <h2 className="text-center my-4">Add Products</h2>
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
            <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product name
              </span>
              <input
                type="text"
                className="form-control col-md-4"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
              />

            </div>
              {isFormSubmit == 1 && name=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
            <br />
            <div className="">
              <input
                className="form-control"
                type="file"
                id="formFile"
                onChange={(e) => setimage(e.target.files[0])}
              />

            </div>
              {isFormSubmit == 1 && image=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
            <br />
            <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product Price
              </span>
              <input
                type="number"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setprice(e.target.value)}
              />

            </div>
              {isFormSubmit == 1 && price=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
            <select
              className="form-select mt-4"
              aria-label="Default select example"
              onChange={(e) => setsize(e.target.value)}
            >
              <option selected>Size</option>
              <option value="small">Small</option>
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="extra-small">Extra Small</option>
              <option value="extra-large">Extra Large</option>
              {/* <option value="3">six</option> */}
            </select>
              {isFormSubmit == 1 && size=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
            <br />

            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setstatus(e.target.value)}
            >
              <option value=""  disabled selected>Status</option>
              <option value="1">active</option>
              <option value="0">not active</option>
            </select>
            <br />

            <lebel>Quantity : </lebel>
            <ButtonGroup
              size="small"
              color="primary"
              aria-label="large outlined primary button group"
            >
              <Button onClick={decNumm}>
                <RemoveIcon />
              </Button>
              <Button>
                <h5>{numm}</h5>
              </Button>
              <Button onClick={incNumm}>
                <AddIcon />
              </Button>
            </ButtonGroup>
            <br />
            <br />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setcategory(e.target.value)}
            >
              <option disabled selected>Product Catagory</option>
              {Array.isArray(category) && category.length > 0
                ? category.map((data, id) => (
                    <option key={id} value={data.pc_id}>
                      {data.pc_name}
                    </option>
                  ))
                : null}

              {/* <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option> */}
            </select>
              {isFormSubmit == 1 && category=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}

              <select
                  className=" mt-3  form-select"
                  aria-label="Default select example"
                  onChange={(e) => settype(e.target.value)}
              >
                  <option value="cash" selected>Cash</option>
                  <option value="point">Points</option>
              </select>

            <br />

            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setcolor(e.target.value)}
            >
              <option  disabled selected>Product Color</option>
              <option value="green">Green</option>
              <option value="yellow">Yellow</option>
              <option value="red">Red</option>
              <option value="pink">Pink</option>
              <option value="black">Black</option>
              <option value="white">White</option>
            </select>
            <br />

            {/* <select
              className="form-select"
              aria-label="Default select example"
              // onChange={(e) => setstatus(e.target.value)}
            >
              <option selected>Status</option>
              <option value="1">active</option>
              <option value="0">not active</option>
            </select>
            <br /> */}

            <div className="mb-3 ">
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Type product Brand here"
                />
              </div>
            </div>

            <div className="mb-3">
              <label for="exampleFormControlTextarea1" className="form-label">
                Short description
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                onChange={(e) => setdescription(e.target.value)}
              ></textarea>
            </div>
              {isFormSubmit == 1 && description=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
          </div>
        </div>
        <div className=" mx-auto">
          <button
            type="button"
            class="btn btn-primary "
            onClick={()=>{
                onClickListener();

                setIsFormSubmit(1);
            }}>

            Add Products
          </button>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Addproducts;
