import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useDispatch, useSelector } from "react-redux";
import {addProduct, getAllProduct} from "../../../../actions/productAction";
import {
    clearProductType,
    getAllProductType,
} from "../../../../actions/productTypeAction";
import {
    clearCategories,
    getAllStoreProductCategories,
} from "../../../../actions/categoryAction";
import storeCategoryReducer from "../../../../reducers/storeCategoryReducer";
import toast from "../../../../utils/toast";
import {LoadingState} from "../../../../actions/loaderActions";
import Axios from "../../../../Services/Axios";

const Add_Store_Product = () => {
    const dispatch = useDispatch();
    const initialState = "";
    const [numm, setNumm] = useState(0);
    const [name, setname] = useState(initialState);
    const [image, setimage] = useState(initialState);
    const [price, setprice] = useState(initialState);
    const [size, setsize] = useState(initialState);
    const [status, setstatus] = useState(1);
    const [categoryProduct, setcategory] = useState(initialState);
    const [description, setDescription] = useState(initialState);
    const [points, setPoints] = useState('cash');
    const [type, settype] = useState(initialState);
    const [color, setcolor] = useState(initialState);
    const { productType } = useSelector((state) => state.productTypeReducer);
    const { storeCategories } = useSelector((state) => state.storeCategoryReducer);

    const incNumm = () => {
        setNumm(numm + 1);
    };
    const decNumm = () => {
        if (numm > 0) {
            setNumm(numm - 1);
        } else {
            alert("Sorry ! Zero Limit Reached");
            setNumm(0);
        }
    };

    const onClickListener = () => {


        const formData = new FormData();
        formData.append("product_name", name);
        formData.append("qty", numm);
        formData.append("price", price);
        formData.append("img[]", image);
        formData.append("status", status);
        formData.append("category_id", categoryProduct);
        formData.append("points", points);
        formData.append("description", description);
        formData.append("type", type);


        console.log("Add Product Data", formData);
        // dispatch(addProduct(formData));
        Axios.post("/api/store-product/add", formData)
            .then((res) => {
                dispatch(LoadingState(false));
                // dispatch(getAllProduct());
                if (res.status === 200) {
                    toast.success(res.data.message);
                    console.log("DATA API HIT :", res.data);
                }
            })
            .catch((err) => {
                toast.error("There is Some Error While Adding Product");
                dispatch(LoadingState(false));
            });
    };

    useEffect(() => {
        console.log("productType VALUE", productType);
        if (Array.isArray(productType) && productType.length > 0) {
        } else {
            dispatch(getAllProductType());
        }

        if (Array.isArray(storeCategories) && storeCategories.length > 0) {
        } else {
            dispatch(getAllStoreProductCategories());
        }
    }, [dispatch, productType, storeCategories]);

    useEffect(() => {
        return () => {
            dispatch(clearProductType());
            dispatch(clearCategories());
            console.log("clearPackage");
        };
    }, [dispatch]);

    return (
        <div className="container p-2">
            <div className="container card p-4  add-products-container">
                <h2 className="text-center my-4">Add Products</h2>
                <div className="row ">
                    <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product name
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setname(e.target.value)}
                            />
                        </div>
                        <br />
                        <div className="">
                            <input
                                className="form-control"
                                type="file"
                                id="formFile"
                                onChange={(e) => setimage(e.target.files[0])}
                            />
                        </div>
                        <br />
                        <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product Price
              </span>
                            <input
                                type="number"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setprice(e.target.value)}
                            />
                        </div>
                        <select
                            className="form-select mt-4"
                            aria-label="Default select example"
                            onChange={(e) => setsize(e.target.value)}
                        >
                            <option selected>Size</option>
                            <option value="small">Small</option>
                            <option value="large">Large</option>
                            <option value="medium">Medium</option>
                            <option value="extra-small">Extra Small</option>
                            <option value="extra-large">Extra Large</option>
                            {/* <option value="3">six</option> */}
                        </select>
                        <br />

                        <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setstatus(e.target.value)}
                        >
                             <option value="1" selected>active</option>
                            <option value="0">not active</option>
                        </select>
                        <br />

                        <lebel>Quantity : </lebel>
                        <ButtonGroup
                            size="small"
                            color="primary"
                            aria-label="large outlined primary button group"
                        >
                            <Button onClick={decNumm}>
                                <RemoveIcon />
                            </Button>
                            <Button>
                                <h5>{numm}</h5>
                            </Button>
                            <Button onClick={incNumm}>
                                <AddIcon />
                            </Button>
                        </ButtonGroup>
                        <br />
                        <br />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setcategory(e.target.value)}
                        >
                            {/*<option selected>Product Catagory</option>*/}
                            {Array.isArray(storeCategories) && storeCategories.length > 0
                                ? storeCategories.map((data, id) => (
                                    <option selected key={id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))
                                : null}

                            {/* <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option> */}
                        </select>
                        <br />

                        <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => settype(e.target.value)}
                        >
                             <option value="cash" selected>Cash</option>
                            <option value="point">Points</option>
                        </select>
                        <br />

                        {/* <select
              className="form-select"
              aria-label="Default select example"
              // onChange={(e) => setstatus(e.target.value)}
            >
              <option selected>Status</option>
              <option value="1">active</option>
              <option value="0">not active</option>
            </select>
            <br /> */}

                        <div className="mb-3 ">
                            <div className="">
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Type product points here"
                                    onChange={(e) => setPoints(e.target.value)}

                                />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label for="exampleFormControlTextarea1" className="form-label">
                                Short description
                            </label>
                            <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto">
                    <button
                        type="button"
                        class="btn btn-primary "
                        onClick={onClickListener}
                    >
                        Add Products
                    </button>
                </div>
                <br />
            </div>
        </div>
    );
};

export default Add_Store_Product;
