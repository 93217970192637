import {
  CLEAR_CATEGORIES,
  CLEAR_ZIPCODE,
  GET_ALL_CATEGORIES,
  GET_ALL_ZIPCODE,
} from "../actions/DispatchActions";

// START_LOADING
const initialState = {
  category: null,
};

export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_ALL_CATEGORIES:
      return { ...state, category: actions.payload };
    case CLEAR_CATEGORIES:
      return { category: null };
    default:
      return state;
  }
}
