import React, {useState, useEffect} from "react";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {LoadingState} from "../../../../actions/loaderActions";
import {useDispatch, useSelector} from "react-redux";
import toast from "../../../../utils/toast";

const Edit_user_list = () => {
    let history = useHistory();
    const dispatch = useDispatch();

    const data = history.location.state;
    console.log(data)
    const id = data.data.id;
    const [role, setRole] = useState(data.data.user_role_id);
    const [user, setUser] = useState(data.data.user_name);
    const [phone, setPhone] = useState(data.data.phone);
    const [address, setAddress] = useState(data.data.address);
    const [active, setActive] = useState(data.data.is_active);
     // user_name: data.data.user_name,
    // user_role_id: data.data.user_role_id,
    // is_active: data.data.is_active,

    // });

    // const { user_name, user_role_id, is_active } = user;
    // const onInputChange = e => {
    //
    //   setUser({ ...user, [e.target.name]: e.target.value });
    // };

    // useEffect(() => {
    //   loadUser();
    // }, []);

    const onSubmit = async e => {
        const sendingData = {
            user_role_id: role,
            user_name: user,
            is_active: active,
            address: address,
            phone: phone,
            type: 'updateUserProfile',
        };
        e.preventDefault();

        await axios.post("https://loopabackend.p2gmanagement.xyz/api/user/updateuser/" + id, sendingData)

            .then((res) => {
                dispatch(LoadingState(false));
                toast.success(res.data.message);

                history.push("/admin/user-list");

            })
            .catch((err) => {
                toast.error("There is Some Error While Update");
                dispatch(LoadingState(false));
            });

    };

    // const loadUser = async () => {
    //     const result = await axios.get("https://loopabackend.p2gmanagement.xyz/api/users/all");
    //     setUser(result.data.data);
    //     console.log(result.data.data);
    //   };

    return (
        <div className="p-2 mt-2  mx-auto">

            <div className="container card p-5 mobile__cs">
                <h2 className="text-center my-1">Edit user list</h2>
                <form>
                    <div className="row mt-4">
                        <div className="col-lg-12 col-md-12 col-12 mx-auto">
                            <div className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">
                                user  name
                               </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-default"
                                    value={user}
                                    onChange={(e) => setUser(e.target.value)}
                                />
                            </div>
                            <div className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">
                                Phone
                               </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-default"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                            <div className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">
                                Address
                               </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-default"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>


                            <div className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">
                                role
              </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-default"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}

                                />
                            </div>

                            <select
                                className="form-select mt-4"
                                aria-label="Default select example"
                                value={active}
                                onChange={(e) => setActive(e.target.value)}

                            >
                                <option selected disabled>status type</option>
                                <option value="1" selected>active</option>
                                <option value="2">not active</option>

                            </select>

                            <br/>


                        </div>

                    </div>
                </form>
                <div className=" mx-auto">
                    <button type="button" class="btn btn-primary " onClick={onSubmit}>Edit user list

                    </button>
                </div>
            </div>
        </div>
    );
};

export default Edit_user_list;
