import React from "react";
import "./Collection.css";
import Button from "@material-ui/core/Button";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Common/Header/Navbar";

const Collection = ({ history }) => {
  return (
    <div>
      <Navbar />
      <h1 className="text-center my-4">Collection</h1>
      <div className="container wrapper mb-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 mx-auto">
            <form>
              <div class="input-data">
                <input type="text" required />
                <div class="underline"></div>
                <label>ADRESS</label>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 my-4">
                  <div class="input-data">
                    <input type="text" required />
                    <div class="underline"></div>
                    <label>ZIP</label>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 my-4">
                  <div class="input-data">
                    <input type="text" required />
                    <div class="underline"></div>
                    <label>DISTRICT</label>
                  </div>
                </div>
              </div>

              <div class="input-data my-4">
                <input type="text" required />
                <div class="underline"></div>
                <label>CELL PHONE</label>
              </div>
              <div class="input-data my-4">
                <input type="text" required />
                <div class="underline"></div>
                <label>POST CODE</label>
              </div>
              <div class="input-data my-4">
                <input type="text" required />
                <div class="underline"></div>
                <label>INSTRUCTION TO DRIVER</label>
              </div>

              <h6 className=" my-2">Choose pick-up day</h6>
              <div className="col-lg-5">
                <div className="input-data">
                  <input
                    type="date"
                    name="pick"
                    id="pick"
                    className="pic____css"
                    placeholder="Friday"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mx-auto">
            <h4 className="text-center my-4">Picked up outside your door </h4>
            <h6 className="text-center">
              Place the bags outside the door during the collection day.
              <br /> You contribute with increased awareness of the importance
              <br /> You will also receive a message about the estimated
              <br /> time for collection.
            </h6>
            <h3 className="text-center my-2 tctc">
              Environmentally friendly and <br />
              sustainable all the way
            </h3>
            <p className="text-center my-2">Carbon neutral to your doorstep</p>
            <br />
            <div className=" d-flex justify-content-center ">
              <div className="col-lg-4 col-md-6 col-8">
                <Button
                  variant="contained"
                  color="primary"
                  className="payment__btn"
                  onClick={() => history.push("/collections")}
                >
                  GO TO PAYMENT
                </Button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Collection;
