import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import { NavLink } from "react-router-dom";
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {history} from "../../../../../WebShop/Common/ShopNavbar2";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function UserActions() {
  const [anchorEl, setAnchorEl] = React.useState(null);
    const userName = localStorage.getItem("user_name");

    const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
    const redirectToEdit = () => {
        handleClose()
        history.push("/");
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('user_name');
        localStorage.removeItem('role');
        localStorage.removeItem('role_id');
    };

  const classes = useStyles();

  return (
    <div className={classes.root} >
      <div aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick} className="d-flex main-useraction">
        <Avatar alt="Remy Sharp"  src="https://icons.iconarchive.com/icons/custom-icon-design/silky-line-user/256/user-icon.png" />
          {/*<i className="fa fa-user" style={{height:""}}></i>*/}
        <span className=" avatar_name">{userName} </span>
        <span className="arrowcs">  <ArrowDropDownIcon className="mt-2" /></span>
      </div>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}

      >
        <NavLink to="user-profile">
          <MenuItem onClick={handleClose}><PersonIcon className="mr-2 text-primary p-1" />  User profile</MenuItem>
        </NavLink>

        <NavLink to="edit-user-profile">
          <MenuItem onClick={handleClose}><EditIcon className="mr-2 text-primary p-1" /> Edit Profile</MenuItem>
        </NavLink>

          <NavLink to="password-change">
              <MenuItem onClick={handleClose}><EditIcon className="mr-2 text-primary p-1" /> Change password</MenuItem>
          </NavLink>

        {/*<NavLink to="transaction-history">*/}
          {/*<MenuItem onClick={handleClose}><MonetizationOnOutlinedIcon className="mr-2 text-primary p-1" />Transactions</MenuItem>*/}
        {/*</NavLink>*/}

        {/*<NavLink to="/file-manager-list">*/}
          {/*<MenuItem onClick={handleClose}><FileCopyOutlinedIcon className="mr-2 text-primary p-1" />Files</MenuItem>*/}
        {/*</NavLink>*/}

        <NavLink to="/">
          <MenuItem onClick={redirectToEdit}><ExitToAppOutlinedIcon className="mr-2 text-danger p-1" />log out</MenuItem>
        </NavLink>
      </Menu>
    </div>
  );
}
