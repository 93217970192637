import {React,useState,useEffect} from "react";
import "./UserSubscriptions.css";
import Button from "@material-ui/core/Button";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Common/Header/Navbar";
import PaymentForm from "../MemberShip2/SingleForm";
import ShopNavbar2 from "../WebShop/Common/ShopNavbar2";
import Axios from "../../Services/Axios";
import toast from "../../utils/toast";
import {LoadingState} from "../../actions/loaderActions";
import {useDispatch} from "react-redux";
import FooterShop from "../WebShop/Common/FooterShop";
const UserSubscriptions = ({ history }) => {

    const user = localStorage.getItem("user");

    if(!user || user === "") {
        history.push("/login");
    }

    let dispatch = useDispatch();
    const [price, setPrice] = useState(0);
    const [currency, setCurency] = useState('$');
    const [subscriptions, setSubscriptions] = useState([]);
    const [bagsObj, setBagsObj] = useState([]);
    const [pkgsObj, setPkgsObj] = useState([]);

    useEffect(() => {

        const sendingData = {
            user_id: user
        };

        Axios.get("/api/user/subscriptions/all?user_id="+user, sendingData)
            .then((res) => {
                console.log('r',res.data);
                if(res.data.length > 0) {
                    setSubscriptions(res.data);
                }

                dispatch(LoadingState(false));
            })
            .catch((err) => {
                toast.error("There is some error while getting subscriptions");
                // dispatch(LoadingState(false));
            });

    },[]);

    return (
    <div>
        <ShopNavbar2 history={history}/>
        <div style={{marginTop:130}}>
        <h1 className="text-center my-4" >Subscriptions</h1>

        <div className="container wrapper mb-5">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 mx-auto">
                <table className="table table-bordered">
                    <tr><th>Package Name</th><th>Bag Name</th><th>Status</th><th>Actions</th></tr>
                    { subscriptions && subscriptions.map((data) => (
                        <tr>
                            <td>{data.pkgs.pkg_name}</td>
                            <td>{data.bags.bag_name}</td>
                            <td>{data.status}</td>
                            <td>
                                <button onClick={()=>{
                                    history.push('/membership/details/'+data.id)
                                }}>View Detail</button>
                            </td>
                        </tr>
                    ))}
                </table>
              </div>
            </div>
        </div>
        </div>
    <FooterShop />
    </div>
  );
};

export default UserSubscriptions;
