import React, { useEffect, useState } from "react";
import "./ShopCard.css";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import {useDispatch, useSelector} from "react-redux";
import FooterShop from "../Common/FooterShop";
import  Autocomplete  from '@material-ui/lab/Autocomplete';

import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import {NOT_FOUND_IMAGE, BULKRUBBISH_PRODUCT_IMAGE_URL} from "../../../Services/DataApi";
import PaymentFormSingle from "../../MemberShip2/SingleForm";
import PaymentForm from "../../MemberShip2/SingleForm";
import "../Cart/Cart.css";
import {AddPayment, AddStorePayment} from "../../../actions/paymentActions";
import toast from "../../../utils/toast";
import ShopNavbar2 from "../Common/ShopNavbar2";
import {getRubbishProductByID} from "../../../actions/productAction";
import TextField from '@material-ui/core/TextField';

function BulkiRubbishCheckOut(props) {
    const { id } = props.match.params;

    const history = useHistory();
    const [openPayment, setOpenPayment] = useState(false);
    const [openDetailForm, setOpenDetailForm] = useState(false);
    const [formPaymentData, setFormPaymentData] = useState([]);
    const dispatch = useDispatch();
    const [isFormSubmit, setIsFormSubmit] = useState(0);
    const [zipCode, setZipCode] = useState(null);
    const [timeOptions, setTimeOptions] = useState([]);
    const [pickUpAddress, setPickUpAddress] = useState('');
    const [productDetails, setproductDetails] = useState("");

    const [pickUpZip, setPickUpZip] = useState('');
    const [pickUpDistrict, setPickUpDistrict] = useState('');
    const [collectionPhone, setCollectionPhone] = useState('');
    const [collectionPostCode, setCollectionPostCode] = useState('');
    const [collectionInstructions, setCollectionInstructions] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [pickupTimeFrom, setPickupTimeFrom] = useState('');
    const [pickupTimeTo, setPickupTimeTo] = useState('');
    const [pickupType, setPickupType] = useState('outside_door');
     const [data, setData] = useState([]);
    const  user= localStorage.getItem("user");
console.log(data)
    const totalPrice = () => {
        var totalPrice=0;
        var totalPoints=0;

        if(data){
            for (let i=0; i < data.length; i++){
                totalPoints += data[i].type =="point" ?(data[i].point * data[i].count):0;
                totalPrice += data[i].type !="point" ?(data[i].price * data[i].count):0;
            }

        }
        return {totalPoints:totalPoints,totalPrice:totalPrice};
    };
    const addRemoveFromCart = (id) => {
        let cart = [];
        let newcart = [];

        if (localStorage.getItem('StoreBulkyRubbishProduct')) {
            cart = JSON.parse(localStorage.getItem('StoreBulkyRubbishProduct'));

            for(let i = 0;i < cart.length; i++) {
                if(cart[i].id != id) {
                    newcart.push(cart[i]);
                }
            }
        }

        setData(newcart);
        localStorage.setItem('StoreBulkyRubbishProduct', JSON.stringify(newcart))
    };


    const mySubmitHandler = (event) => {
        event.preventDefault();

        if(pickUpAddress!='' && pickUpZip!='' && pickUpDistrict!='' && collectionPhone!='' && collectionPostCode!='' && collectionInstructions!='') {

            let formValues = {
                pickUpAddress: pickUpAddress,
                pickUpZip: pickUpZip,
                pickUpDistrict: pickUpDistrict,
                collectionPhone: collectionPhone,
                email: email,
                userName: userName,
                postCode: collectionPostCode,
                instructions: collectionInstructions,
                pickupDate: pickupDate,
                pickupTimeFrom: pickupTimeFrom,
                pickupTimeTo: pickupTimeTo,
                product_id: productDetails.brp_id,
                pickUpType: pickupType
            };
            setFormPaymentData(formValues)
            const user = localStorage.getItem("user");

            if(!user || user === "") {
                window.localStorage.setItem('path','/cart/rubbish')
                history.push("/login");
            }else{
                setOpenPayment(true)
                // setOpenDetailForm(true)

            }

        }
    };

    useEffect(() => {
        getRubbishProductByID(id).then((res) => {

            setproductDetails(res.data.data);
            setZipCode(res.data.zipCode);
         });

        let options = [];
        let index = ':00';
        let indexD = 0;
        for(let i = 0;i <= 48;i++) {
            options.push(indexD + index);
            if(index == ':00') {
                index = ':30';
            } else {
                indexD++;
                index = ':00';
            }
        }
        setTimeOptions(options);
        const StoreProductCart= localStorage.getItem('StoreBulkyRubbishProduct');
        setData( JSON.parse(StoreProductCart));
        setFormPaymentData({items:JSON.parse(StoreProductCart),user_Id:user})


    }, []);

    const toCart = (e) => {
        setIsFormSubmit(1);
        mySubmitHandler(e);
        // history.push("/shop/cart");
    };
    const onClickListener = async (e) => {
        e.preventDefault();

        if (user === null) {
            toast.error("You are not logged in");
        } else {
            const sendingData = {
                name:  data.name,
                product_id: data.id,
                // cardNo: cardNo,
                // expMonth: expMonth,
                // expYear: expYear,
                // cvc: cvc,
                amount: data.point,
                webStoreType: 'webStore',
                user_id: user,
                type: 'point',
                items: data
            };

            console.log("Payment data", sendingData);
            dispatch(AddStorePayment(sendingData,history));
        }
    }


    return (
        <div>
            <ShopNavbar2 />
            {data && data.map((data) => (
                <div className="CheckOutCard1">
                    <img
                        src={
                            data.image
                                ? BULKRUBBISH_PRODUCT_IMAGE_URL + data.image
                                : NOT_FOUND_IMAGE
                        }
                        alt=""
                        className="checkout-card-img"
                        style={{maxWidth:"400px"}}
                        // style={{maxHeight:"300px"}}

                    />
                    <div className="checkout-card-NamePrice">
                        <h5>{data.name}</h5>

                        {data.type =="cash" &&
                        <h5>{data.price * data.count} sek</h5>
                        }
                        {data.type =="point" &&
                        <h5>{data.point * data.count}</h5>
                        }
                        <Button
                            variant="outlined"
                            color="#fff"
                            onClick={() => addRemoveFromCart(data.id)}
                            style={{ borderColor: "orangered" }}
                        >
                            REMOVE
                        </Button>
                    </div>
                </div>
            ))}
            <>
                {totalPrice().totalPrice != null  && totalPrice().totalPrice != 0 &&
                <div className="bg-light p-3 mt-2 d-flex justify-content-around">
                    <div className="h6">
                        Total price checkout for the product:
                    </div>
                    <div className="h6 ">
                        <strong>{totalPrice().totalPrice} sek</strong>
                    </div>
                </div>
                }
                {totalPrice().totalPoints != null && totalPrice().totalPoints != 0 &&
                <div className="bg-light p-3 mt-2 d-flex justify-content-around">
                    <div className="h6">
                        Total point checkout for the product:
                    </div>
                    <div className="h6 ">
                        <strong>{totalPrice().totalPoints}</strong>
                    </div>
                </div>
                }
            </>
             <div style={{marginTop: 50}} className="container wrapper mb-5">
                <h1 className="text-center my-4">Rubbish Product</h1>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                        <form>

                            <div className="input-data my-4">
                                <input type="text" required onChange={(e) => {
                                    setUserName(e.target.value);
                                }}/>
                                <div className="underline"></div>
                                <label>Username</label>
                            </div>
                            {isFormSubmit == 1 && userName == ""
                            && <label className="row mx-auto"   >This field is required</label>}
                            <div className="input-data my-4">
                                <input type="text" required onChange={(e) => {
                                    setEmail(e.target.value);
                                }}/>
                                <div className="underline"></div>
                                <label>Email</label>
                            </div>
                            {isFormSubmit == 1 && email == ""
                            && <label className="row mx-auto">This field is required</label>}
                            <div className="input-data">
                                <input type="text" required onChange={(e) => {
                                    setPickUpAddress(e.target.value);
                                }}/>

                                <div className="underline"></div>
                                <label>ADDRESS</label>
                            </div>

                            {isFormSubmit == 1 && pickUpAddress == ""
                            && <label className="row mx-auto" style={{color:"red"}}>This field is required</label>}
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12 my-4" style={{paddingLeft: 0}}>

                                    <Autocomplete
                                        options={zipCode}
                                        label
                                        getOptionLabel={(option) => option.zip_code}
                                        style={{ width: 600 }}
                                        clearOnEscape
                                        onChange={(event, newValue) => {
                                            setPickUpZip(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Zip Code" variant="outlined" />}
                                    />
                                    {/*<div className="input-data">*/}
                                    {/*<select required className="form-control" style={{*/}
                                    {/*paddingLeft: 3,*/}
                                    {/*borderTop: 0,*/}
                                    {/*borderLeft: 0,*/}
                                    {/*borderRight: 0,*/}
                                    {/*borderBottom: '1.5px solid grey',*/}
                                    {/*marginTop: 5*/}
                                    {/*}} onChange={(e) => {*/}
                                    {/*setPickUpZip(e.target.value);*/}
                                    {/*}}>*/}
                                    {/*<option value="">SELECT ZIP CODE</option>*/}
                                    {/*{zipCode && zipCode.map((data)=>(*/}
                                    {/*<option value={data.zip_id}>{data.zip_code}</option>*/}
                                    {/*))}*/}
                                    {/*</select>*/}
                                    {/*</div>*/}


                                    {isFormSubmit == 1 && pickUpZip == "" &&
                                    <label className="row mx-auto" style={{color:"red"}}>This field is required</label>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-12 my-4" style={{paddingRight: 0}}>
                                    <div className="input-data">
                                        <input type="text" required onChange={(e) => {
                                            setPickUpDistrict(e.target.value);
                                        }}/>
                                        <div className="underline"></div>
                                        <label>DISTRICT</label>
                                    </div>
                                    {isFormSubmit == 1 && pickUpDistrict == "" &&
                                    <label className="row mx-auto" style={{color:"red"}}>This field is required</label>}
                                </div>
                            </div>

                            <div className="input-data my-4">
                                <input type="number" required onChange={(e) => {
                                    setCollectionPhone(e.target.value);
                                }}/>
                                <div className="underline"></div>
                                <label>CELL PHONE</label>
                            </div>


                            {isFormSubmit == 1 && collectionPhone == "" &&
                            <label className="row mx-auto" style={{color:"red"}}>This field is required</label>}
                            <div className="input-data my-4">
                                <input type="text" required onChange={(e) => {
                                    setCollectionPostCode(e.target.value);
                                }}/>
                                <div className="underline"></div>
                                <label>POST CODE</label>
                            </div>
                            {isFormSubmit == 1 && collectionPostCode == "" &&
                            <label className="row mx-auto" style={{color:"red"}}>This field is required</label>}
                            <div className="input-data my-4">
                                <input type="text" required onChange={(e) => {
                                    setCollectionInstructions(e.target.value);
                                }}/>
                                <div className="underline"></div>
                                <label>INSTRUCTION TO DRIVER</label>
                            </div>

                            {isFormSubmit == 1 && collectionInstructions == "" &&
                            <label className="row mx-auto" style={{color:"red"}}>This field is required</label>}
                            <h6 className="my-2">Choose Pick-Up Day</h6>
                            <div className="col-lg-4 col-sm-4">
                                <div className="input-data">
                                    <input type="date" name="pick" id="pick" onChange={(e) => {
                                        setPickupDate(e.target.value);
                                    }} className="pic____css"/>
                                </div>
                            </div>

                            <div className="col-lg-4  col-sm-4">
                                <div className="input-data">
                                    <select className="form-control" onChange={(e) => {
                                        setPickupTimeFrom(e.target.value);
                                    }}>
                                        <option>Select Time From</option>
                                        {timeOptions && timeOptions.map((data) => (
                                            <option value={data}>{data}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-4">
                                <div className="input-data">
                                    <select className="form-control" onChange={(e) => {
                                        setPickupTimeTo(e.target.value);
                                    }}>
                                        <option>Select Time To</option>
                                        {timeOptions && timeOptions.map((data) => (
                                            <option value={data}>{data}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="d-flex justify-content-left">
                        <div className="col-lg-4 col-md-6 col-8">
                            <Button type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="payment__btn"
                                    onClick={(e) => {
                                        toCart(e)
                                    }}>
                                GO TO PAYMENT
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {totalPrice().totalPrice != null && openPayment == true && totalPrice().totalPrice != 0 && (totalPrice().totalPoints == 0 || totalPrice().totalPoints == null || user )  &&
            <div className="container wrapper mb-5" style={{marginTop:"20px"}}>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-6 mx-auto">
                        <PaymentForm price={totalPrice().totalPrice}  setOpenPayment={setOpenPayment} setOpenDetailForm={setOpenDetailForm} data={formPaymentData}  type={'isBulkRubbishOrder'} currency={'sek'}   history={history}/>

                        {/*<PaymentForm price={totalPrice().totalPrice}  setOpenPayment={setOpenPayment} setOpenDetailForm={setOpenDetailForm} data={formPaymentData} type={'isBulkRubbishOrder'}currency={'sek'}   history={history}/>*/}

                    </div>
                </div>
            </div>
            }

            {totalPrice().totalPoints != null && totalPrice().totalPoints != 0 && !user ?
                <h6 className=" my-2 bg-light p-4">
                    <CreditCardIcon className="check__out__css" /> Herdu in rabbatak ?{" "}
                    <a href="javascript:void(0)" className="text-primary"
                       onClick={()=>{
                           window.localStorage.setItem('path','/shop/cart')
                           history.push('/login')
                       }}
                    >
                        Click of logga in?{" "}
                    </a>
                </h6>:
                <>
                    {totalPrice().totalPrice == 0 && totalPrice().totalPoints != 0 &&
                    <div style={{textAlign:'center',marginTop:'20px'}}>
                        <button onClick={onClickListener} className="btn btn-primary " style={{text:'center',fontSize:'20px'}}>
                            Submit</button>
                    </div>
                    }
                </>
            }
            <FooterShop />

        </div>



    );
}

export default BulkiRubbishCheckOut;
