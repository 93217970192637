import React from "react";
import "./BulkRubbishMain/Page.css";
import { Parent_Color, Parent_ShopCard_Btn_Color } from "../../Common/Color";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Sofa from "../../assets/sofa.png";
import {
  BULKRUBBISH_PRODUCT_IMAGE_URL,
  NOT_FOUND_IMAGE,
  PRODUCT_IMAGE_URL,
} from "../../Services/DataApi";
function Card3(props) {
  const history = useHistory();

  // const arrayImage = props.image.split(",");
  // console.log("Array Image", arrayImage);
  return (
    <div className="row px-1 py-1 Card_Shop2 mb-3">
      <div className="col-lg-6 text-center">
          <img
              src={
                  props.image
                      ? BULKRUBBISH_PRODUCT_IMAGE_URL + props.image
                      : NOT_FOUND_IMAGE
              }
              alt=""
              className="img-fluid img-responsive"
              style={{maxWidth:"100%"}}

          />

      </div>

      <div className="col-lg-6 text-center">
          <div className="h5 my-2">{props.price} Sek</div>
          <div className="bulk_ProductName mt-2">{props.name}</div>

          <div className="h6 my-2 text-uppercase">{props.size}</div>

          {/*<Button*/}
          {/*variant="contained"*/}
          {/*color="#000"*/}
          {/*style={{ backgroundColor: Parent_ShopCard_Btn_Color }}*/}
          {/*onClick={() => history.push("/shop/cart")}*/}
        {/*>*/}
          {/*Add to Cart*/}
        {/*</Button> */}
        {/*<div*/}
          {/*className="btn btn-lg w-auto px-3 py-2 mt-2"*/}
          {/*style={{ backgroundColor: Parent_Color }}*/}
        {/*>*/}
          {/*LAGG TILL*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default Card3;
