import { GET_ALL_PACKAGES_ADMIN } from "../actions/DispatchActions";

// START_LOADING
const initialState = {
    packages: null,
};

export default function (state = initialState, actions) {
    switch (actions.type) {
        case GET_ALL_PACKAGES_ADMIN:
            return { packages: actions.payload };
        default:
            return state;
    }
}
