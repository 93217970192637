import React, {useState, useEffect} from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {set} from "react-ga";
import Loader from "react-loader-spinner";


import {useDispatch, useSelector} from "react-redux";
import {AddPackage} from "../../../../actions/packageActions";
import {getAllBags} from "../../../../actions/recyclebagActions";

const Add_Packages =({history}) => {
    const [price, setPrice] = useState(0);
    const [name, setname] = useState("");
    const [noOfDays, setNoOfDays] = useState("");
    const [frequency, setFrequency] = useState("");
    const [packageDescription, setPackageDescription] = useState("");
    const [bag, setBag] = useState([]);
    const [collection, setCollection] = useState([{
        bag_id: '',
        price: '',
        rendererPackageName: '',
        noOfDays: '',
        frequency: '',
        packageDescription: ''
    }]);
    const [status, setstatus] = useState("0");
    const dispatch = useDispatch();
    const {loading} = useSelector((state) => state.loaderReducer);
    const {bags} = useSelector((state) => state.recycleReducer);

    const handleInputChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...collection];
        list[index][name] = value;
        setCollection(list);
    }
    const handleRemoveEvent = index => {
        const list = [...collection];
        list.splice(index, 1);
        setCollection(list);
    }

    const handleAddField = () => {
        setCollection([...collection, {bag_id: '', price: '', noOfDays: '', frequency: '', packageDescription: ''}])
    }


    const addField = collection.map((x, i) => (
        <React.Fragment>
            {/*<select*/}
                {/*className="form-select"*/}
                {/*aria-label="Default select example"*/}
                {/*onChange={(e) => setBagStatus(e.target.value)}*/}
            {/*>*/}
                {/*<option selected>Bags</option>*/}
                {/*{bag.map(data => (*/}
                    {/*<option value={data.bag_id}>{data.bag_name}</option>*/}
                {/*))}*/}
            {/*</select>*/}
            <div className="input-group mb-3 mt-3">
                <span class="input-group-text">$</span>
                <input type="text" class="form-control" name="price" aria-label="Amount (to the nearest dollar)"
                       onChange={(e) => handleInputChange(e, i)}/>
                <span class="input-group-text">.00</span>
            </div>
            {collection.length !== 1 &&
            <button type="button" className="btn btn-danger mb-5 mr-5" onClick={() => handleRemoveEvent(i)}>
                Remove
            </button>}
            {collection.length - 1 === i &&
            <button type="button" className="btn btn-success mb-5 ml-5" onClick={handleAddField}
                    style={{marginLeft: '20px'}}>
                Add Bag
            </button>}

        </React.Fragment>
    ));


    useEffect(() => {
        console.log("bags VALUE");
        if (Array.isArray(bags) && bags.length > 0) {
            // bags.map(data => setBag([...bag, data]))
            setBag(bags);
            console.log(bag)
        } else {
            dispatch(getAllBags());
        }
    }, [dispatch, bags]);

    const onClickListener = () => {

        const data = {
            pkg_name: name,
            pkg_active: status,
            frequency: frequency,
            packageDescription: packageDescription,
             noOfDays: noOfDays,
        };
        // return console.log(data)
        dispatch(AddPackage(data,history));
    };

    return (
        <div className="p-2 mt-2  mx-auto">
            <div className="container card p-5 mobile__cs">
                <h2 className="text-center my-1">Add Packages</h2>
                <div className="row mt-4">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                        <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Package name
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setname(e.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
              {/*<span className="input-group-text" id="inputGroup-sizing-default">*/}
                {/*Package Price*/}
              {/*</span>*/}
                            {/*<input*/}
                                {/*type="text"*/}
                                {/*className="form-control"*/}
                                {/*aria-label="Sizing example input"*/}
                                {/*aria-describedby="inputGroup-sizing-default"*/}
                                {/*onChange={(e) => setPrice(e.target.value)}/>*/}
                        {/*</div>*/}
                        {/*<div className="input-group mb-3">*/}
              <span className="input-group-text" id="inputGroup-sizing-default">
              No of Days
              </span>
                            <input
                                type="Number"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setNoOfDays(e.target.value)}/>
                        </div>
                        <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Frequency
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setFrequency(e.target.value)}/>
                        </div>
                        <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Package Description
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setPackageDescription(e.target.value)}/>
                        </div>
                        {/* <br />
            <lebel>Quantity : </lebel>
            <ButtonGroup
              size="small"
              color="primary"
              aria-label="large outlined primary button group"
            >
              <Button onClick={decNumm}>
                {" "}
                <RemoveIcon />
              </Button>
              <Button>
                <h5>{numm}</h5>
              </Button>
              <Button onClick={incNumm}>
                <AddIcon />
              </Button>
            </ButtonGroup> */}

                        {/* <br /> */}
                        {/* <div className="mb-3 mt-3">
              <label for="exampleFormControlTextarea1" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div> */}

                        <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setstatus(e.target.value)}
                        >
                            <option selected>Status</option>
                            <option value="1">active</option>
                            <option value="0">not active</option>
                        </select>
                        <br/>
                        {/*{addField}*/}

                        {/*<div className="mx-auto">
              <button type="button" className="btn btn-primary mb-3" onClick={addMoreBag}>Add Bag</button>
            </div>

            {addField}

            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setBagStatus(e.target.value)}
            >
              <option selected>Bags</option>
              {bag.map(data => (
                <option value={data.bag_id} onChange={(e) => setIdInput(e.target.value)}>{data.bag_name}</option>
              ))}
            </select>
            <div className="input-group mb-3 mt-3">
              <span class="input-group-text">$</span>
              <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)" onChange={(e) => setPriceInput(e.target.value)} />
              <span class="input-group-text">.00</span>
            </div>

            {newField}
            {/*console.log("Number of new fields", newField)*/}
                        <br/>
                    </div>
                </div>
                <div className=" mx-auto">
                    <button
                        type="button"
                        class="btn btn-primary "
                        onClick={onClickListener}
                    >
                        Add package
                        <Loader
                            type="Puff"
                            color="#FFF"
                            height={20}
                            width={15}
                            style={{marginLeft: 5}}
                            visible={loading}
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Add_Packages;
