import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../../actions/authAction";
import toast from "../../../utils/toast";
import Loader from "react-loader-spinner";
const Register = ({ history }) => {
  const initialState = "";
  const [email, setemail] = useState(initialState);
  const [password, setpassword] = useState(initialState);
  const [firstName, setfirstName] = useState(initialState);
  const [lastName, setlastName] = useState(initialState);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loaderReducer);

  const onClickListener = (e) => {
    e.preventDefault();
    if (email != "" && password != "" && firstName != "" && lastName != "") {
      console.log("Data:", email, password);
      const Data = {
        email: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        user_name: firstName,
      };
      dispatch(register(Data));
    } else {
      toast.error("Please Fill Out the Required Fields.Thanks!");
    }
  };
  return (
    <div>
      <h1 className="text-center my-4">Register</h1>
      <div className="container">
        <div className="col-lg-7 col-md-7 col-12 mx-auto">
          <form onSubmit={(e) => onClickListener(e)}>
            <div className="wrapper">
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary">
                  Continue with Facebook <i class="fab fa-facebook"></i>{" "}
                </button>
              </div>
              <br />
              <p className="text-center my-3">Register with your email</p>
              <div className="input-data">
                <input
                  type="text"
                  required
                  id="firstname"
                  onChange={(e) => setfirstName(e.target.value)}
                />
                <div className="underline"></div>
                <label>FIRST NAME</label>
              </div>
              <br />
              <div className="input-data">
                <input
                  type="text"
                  required
                  id="lastname"
                  onChange={(e) => setlastName(e.target.value)}
                />
                <div className="underline"></div>
                <label>LAST NAME</label>
              </div>
              <br />
              <div className="input-data">
                <input
                  type="mail"
                  required
                  id="email"
                  onChange={(e) => setemail(e.target.value)}
                />
                <div className="underline"></div>
                <label>MAIL</label>
              </div>
              <br />
              <div className="input-data">
                <input
                  type="password"
                  required
                  id="password"
                  onChange={(e) => setpassword(e.target.value)}
                />
                <div className="underline"></div>
                <label>PASSWORD</label>
              </div>
              <br />
              <p className="text-center my-2">
                By continuing the registation you agree to our terms of
                <br /> use and our personal data policy
              </p>
              <div className="d-flex justify-content-center mt-2">
                Already Have a Acount?
                <Link className="changePasswordText" to="/login">
                  Login Here
                </Link>
              </div>
              <div className="d-flex justify-content-center my-3">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onClickListener}
                  disabled={loading}
                >
                  continue
                  <Loader
                    type="Puff"
                    color="#FFF"
                    height={20}
                    width={15}
                    style={{ marginLeft: 5 }}
                    visible={loading}
                  />
                </Button>
              </div>
            </div>
            <br />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
