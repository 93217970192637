import React from "react";
import "./shopNav.css";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import { Parent_ShopNavbar_Color } from "../../../Common/Color";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useHistory,
} from "react-router-dom";
const ShopNavbar = () => {
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-light "
        style={{ backgroundColor: Parent_ShopNavbar_Color }}
      >
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <FormatAlignJustifyIcon />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link first___nav"
                  aria-current="page"
                  to="/shop/main"
                >
                  Kallstring
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link first___nav" to="/bulk-rubbish">
                  Bulk.Rubbish
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link first___nav" to="/shop/shop">
                  Webshop
                </Link>
              </li>
              <li className="nav-item dropdown  navLinkMarginLeft">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Vanliga
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown ">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Pricer
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item ">
                <a className="nav-link" href="#">
                  Business/BRF
                </a>
              </li>
              <li className="nav-item navLinkMarginLeft2">
                <Link className="nav-link first___nav" to="/login">
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="d-flex justify-content-center pt-1 bg-light">
        <header>
          <nav className="navCategory">
            <ul className="menu">
              <li>
                <a href="#">Accessories</a>
              </li>
              <li>
                <a href="#">bam</a>
              </li>
              <li>
                <a href="#">Design</a>
              </li>
              <li>
                <a href="#">hobby</a>
              </li>
              <li>
                <a href="#">sport</a>
              </li>
              <li>
                <a href="#">media</a>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    </div>
  );
};

export default ShopNavbar;
