import React, { useState,useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";

import { getAllRubbishProduct } from '../../../../actions/productAction';
import Axios from "../../../../Services/Axios";
import {GET_ALL_PRODUCT} from "../../../../actions/DispatchActions";


function Transaction_List({ history }) {
    const user = localStorage.getItem("user");

    // const { allRubbishOrders } = useSelector((state) => state.rubbishOrderReducer);
    const [row, setrow] = useState([]);
    const dispatch = useDispatch();
    const [rubbishOrders, setRubbishOrder] = useState(null);
    const [ispopulated, setIsPopulated] = useState(0);

    const makeRow = () => {
         var data =
            rubbishOrders.length > 0 ? rubbishOrders.map((data, id) => ({
                    order_id: data.id,
                    cell_phone: data.cell_phone,
                     user_id: data.user.name,

                    instructions_for_driver: data.instructions_for_driver,
                    price: data.price + ' sek',
                    pickup_address: data.pickup_address,
                    pickup_date_from: data.pickup_date_from,
                    pickup_date_to: data.pickup_date_to,
                    pickup_district: data.pickup_district,
                    post_code: data.post_code,
                    status: data.status,
                    // amount: data.amount,
                    //
                    // status: data.status === "1" ? "Active" : "Not Active",
                    // action: (
                    //     <div>
                    //         <Edit color="action" onClick={() => redirectToEdit(data)} />
                    //         <Delete color="error" onClick={() => removeData(data.zip_id)} />
                    //     </div>
                    // ),
                }))
                : null;
        setrow(data);
        console.log("NEW DATA SHAPE:", data);

    };
    useEffect(() => {
        Axios.get("/api/user/rubbishSubscriptions/all?user_id=0")
            .then((res) => {
                if(res.data.data.length > 0 && ispopulated == 0) {
                    setRubbishOrder(res.data.data);
                    setIsPopulated(1)
                }
                //   dispatch(LoadingState(false));
            })
            .catch((err) => {
                const data = err.response.data.error;
                console.log("GET_API_PRODUCT ERR:", data);
                //   dispatch(LoadingState(false));
            });

        if (Array.isArray(rubbishOrders) && rubbishOrders.length > 0) {
            makeRow();
            // setRubbishOrder(allRubbishOrders);
        } else {
            // dispatch(getAllRubbishProduct(user));
        }
    }, [dispatch,rubbishOrders]);

      const columns = [
        {
            label: "Order id",
            field: "order_id",
            width: 150,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Name",
            },
        },
        {
            label: "Phone",
            field: "cell_phone",
            width: 200,
        },
        {
            label: "User id",
            field: "user_id",
            width: 200,
        }, {
            label: "instructions for driver",
            field: "instructions_for_driver",
            sort: "disabled",
            width: 100,
        }, {
            label: "Price",
            field: "price",
            sort: "disabled",
            width: 100,
        }, {
             label: "pickup District ",
             field: "pickup_district",
             sort: "disabled",
             width: 100,
         }, {
             label: "Post Code ",
             field: "post_code",
             sort: "disabled",
             width: 100,
         },{
            label: "Pickup Address",
            field: "pickup_address",
            sort: "disabled",
            width: 100,
        }, {
            label: "Pickup Date From",
            field: "pickup_date_from",
            sort: "disabled",
            width: 100,
        }, {
            label: "Pickup Date To",
            field: "pickup_date_to",
            sort: "disabled",
            width: 100,
        }, {
            label: "Status",
            field: "status",
            sort: "disabled",
            width: 100,
        },
    ];




    return (
        <div>
            <div className="card m-2">
                <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
                    Rubbissh order
                </div>
                <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={{
                        columns,
                        rows: Array.isArray(row) && row.length > 0 ? row : [],
                    }}
                    style={{ fontSize: 15 }}
                    searchLabel="Seacrh here......"
                    searchTop
                    searchBottom={false}
                    responsive
                    barReverse
                />
            </div>


        </div>
    );
}

export default Transaction_List;
