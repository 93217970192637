import {React,useState,useEffect} from "react";
import { NavLink } from 'react-router-dom';
import FooterShop from '../WebShop/Common/FooterShop';
import ShopNavbar2 from '../WebShop/Common/ShopNavbar2';
import './AboutPage.css';
import Axios from "../../Services/Axios";
import {LoadingState} from "../../actions/loaderActions";
import toast from "../../utils/toast";
import { useDispatch } from "react-redux";
import styles from "../WebShop/Css/HowItWorks.module.css";
import Rectangle from "../../assets/Rectangle.png";
import ContentImg from "../../assets/homelast1.png";


const AboutPage = () => {

    const [faqCat, setFaqCat] = useState([]);
    const [acc, setAcc] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {


        Axios.get("/api/faq/categories")
            .then((res) => {
                if(res.data.data.length > 0) {
                    setFaqCat(res.data.data)
                    }

                dispatch(LoadingState(false));
            })
            .catch((err) => {

                // dispatch(LoadingState(false));
            });

    },[]);

    useEffect(() => {
        console.log(acc)
    },[acc])
    
    return(
        <div className="faqPage">
            <ShopNavbar2 />
            <div className="banner">
                <h1>Om oss</h1>
            </div>
            <div className="container py-5">
                <div className="row">
                    <div className="col-sm-6">
                        <div style={{textAlign:"left"}}>
                            <div className={styles.heading1+' home_page_heading'} style={{textAlign:"left"}}>Det ska vara enkett och<br />sjavklart att leva miljovanlight</div>
                            <div class="d-flex justify-content-start my-3">
                                <img
                                    className={styles.RectangleImageSmall+' home_rectangle_img'}
                                    src={Rectangle}
                                    style={{width:100}}
                                    alt=""
                                />
                            </div>
                            <div className={styles.paraGraphSmall+' home_section_para mt-0'} style={{textAlign:"left"}}>
                                Välj hur ofta vi ska hämta och vilken storlek på återvinningspåse
                                som passar bäst i ditt hem.Välj hur ofta vi ska hämta och vilken storlek på återvinningspåse
                                som passar bäst i ditt hem.Välj hur ofta vi ska hämta och vilken storlek på.
                            </div>
                            <div className={styles.paraGraphSmall+' home_section_para mt-4'} style={{textAlign:"left"}}>
                                Välj hur ofta vi ska hämta och vilken storlek på återvinningspåse
                                som passar bäst i ditt hem.Välj.
                            </div>
                            <div className={styles.paraGraphSmall+' home_section_para mt-4'} style={{textAlign:"left"}}>
                                Välj hur ofta vi ska hämta och vilken storlek på återvinningspåse
                                som passar bäst i ditt hem.Välj hur ofta vi ska hämta och vilken storlek på återvinningspåse
                                som passar bäst i ditt hem.Välj hur ofta vi ska hämta och vilken storlek på.
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-center align-items-center">
                        <img
                            src={ContentImg}
                            style={{width:320}}
                            alt=""
                        />
                    </div>
                </div>

                <div className="row mt-5 pt-4" id="process">
                    <div className={styles.heading1+' home_page_heading'}>Processen for atervining</div>
                    <div class="d-flex justify-content-center my-3">
                        <img
                            className={styles.RectangleImageSmall+' home_rectangle_img'}
                            src={Rectangle}
                            style={{width:100}}
                            alt=""
                        />
                    </div>
                    <div className={styles.paraGraphSmall+' home_section_para mt-0 col-sm-5 m-auto'}>
                        Välj hur ofta vi ska hämta och vilken storlek på återvinningspåse
                        som passar bäst i ditt hem.Välj hur ofta vi ska hämta och vilken storlek.
                    </div>
                    <div className="col-sm-12 mt-5">
                        <div className="row d-flex justify-content-around">
                            <div className="col-sm-5 mb-5">
                                <h3 style={{textAlign:"center",fontWeight:'bold'}}>1.</h3>
                                <div className={styles.paraGraphSmall+' home_section_para mt-0'}>
                                    Välj hur ofta vi ska hämta och vilken storlek på återvinningspåse
                                    som passar bäst i ditt hem.Välj hur ofta vi ska hämta och vilken storlek.
                                </div>
                            </div>
                            <div className="col-sm-5 mb-5">
                                <h3 style={{textAlign:"center",fontWeight:'bold'}}>2.</h3>
                                <div className={styles.paraGraphSmall+' home_section_para mt-0'}>
                                    Välj hur ofta vi ska hämta och vilken storlek på återvinningspåse
                                    som passar bäst i ditt hem.Välj hur ofta vi ska hämta och vilken storlek.
                                </div>
                            </div>
                            <div className="col-sm-5 mb-5">
                                <h3 style={{textAlign:"center",fontWeight:'bold'}}>3.</h3>
                                <div className={styles.paraGraphSmall+' home_section_para mt-0'}>
                                    Välj hur ofta vi ska hämta och vilken storlek på återvinningspåse
                                    som passar bäst i ditt hem.Välj hur ofta vi ska hämta och vilken storlek.
                                </div>
                            </div>
                            <div className="col-sm-5 mb-5">
                                <h3 style={{textAlign:"center",fontWeight:'bold'}}>4.</h3>
                                <div className={styles.paraGraphSmall+' home_section_para mt-0'}>
                                    Välj hur ofta vi ska hämta och vilken storlek på återvinningspåse
                                    som passar bäst i ditt hem.Välj hur ofta vi ska hämta och vilken storlek.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterShop />
        </div>
    )
}

export default AboutPage;