import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useDispatch, useSelector } from "react-redux";
import {addProduct, getAllProduct} from "../../../../actions/productAction";
import {
    clearProductType,
    getAllProductType,
} from "../../../../actions/productTypeAction";
import {
    clearCategories,
    getAllStoreProductCategories,
} from "../../../../actions/categoryAction";
import storeCategoryReducer from "../../../../reducers/storeCategoryReducer";
import toast from "../../../../utils/toast";
import {LoadingState} from "../../../../actions/loaderActions";
import Axios from "../../../../Services/Axios";

const Add_Terms = () => {
    const dispatch = useDispatch();
    const initialState = "";

    const [categoryId, setcategory] = useState(initialState);
    const [name, setName] = useState(initialState);
    const [description, setDescription] = useState(initialState);
    const [type, setType] = useState('terms');
    const [faqCat, setFaqCat] = useState(initialState);

    const onClickListener = () => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("type", type);

        Axios.post("/api/add-term", formData)
            .then((res) => {
                dispatch(LoadingState(false));
                // dispatch(getAllProduct());
                if (res.status === 200) {
                    toast.success(res.data.message);
                    console.log("DATA API HIT :", res.data);
                }
            })
            .catch((err) => {
                toast.error("There is Some Error While Adding Product");
                dispatch(LoadingState(false));
            });
    };

    return (
        <div className="container p-2" style={{marginTop: 70}}>
            <div className="container card p-4  add-products-container" >
                <h2 className="text-center my-4">Add Terms</h2>
                <div className="row ">

                    <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                    <div className="mb-3">
                            <label className="form-label">
                                Type
                            </label>
                            <select
                                className="form-control"
                                onChange={(e) => setType(e.target.value)}>
                                <option value="terms">Terms and Conditions</option>
                                <option value="policy">Privacy Policy</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label for="exampleFormControlTextarea1" className="form-label">
                                Terms Title
                            </label>
                            <input
                                className="form-control"
                                onChange={(e) => setName(e.target.value)}/>
                        </div>


                        <div className="mb-3">
                            <label for="exampleFormControlTextarea1" className="form-label">
                                Terms description
                            </label>
                            <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                        </div>


                    </div>
                </div>
                <div className=" mx-auto">
                    <button
                        type="button"
                        class="btn btn-primary "
                        onClick={onClickListener}
                    >
                        Add Term
                    </button>
                </div>
                <br />
            </div>
        </div>
    );
};

export default Add_Terms;
