import React from "react";
import Button from "@material-ui/core/Button";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Common/Header/Navbar";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import ShopNavbar2 from "../../Modules/WebShop/Common/ShopNavbar2";
import FooterShop from "../../Modules/WebShop/Common/FooterShop";
const InviteFriendz = () => {
  return (
    <div>
        <ShopNavbar2 />
        <div style={{marginTop:130}}>
      <h1 className="text-center my-4">Friends and Neighbours</h1>
      <p className="text-center my-3">
        Invite a friends or neighbour to free downloads through
        <br />
        your unique link for each member your recruit , you will
        <br />
        also recieve free pick-up that will at future check-in-time
      </p>
      <div className="d-flex justify-content-center">
        <Button variant="contained" color="primary">
          <FileCopyIcon /> Copy link
        </Button>
      </div>
      <h1 className="text-center my-5">INVITE WITH SMS OR EMAIL</h1>
      <div className="container mb-5">
        <div className="col-lg-4 col-md-4 col-12 mx-auto">
          <form>
            <div class="wrapper">
              <div class="input-data">
                <input type="number" required />
                <div class="underline"></div>
                <label>Cell phone</label>
              </div>
              <br />
              <div class="input-data">
                <input type="email" required />
                <div class="underline"></div>
                <label>Email</label>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button variant="contained" color="primary">
                <ShareIcon /> Share
            </Button>
            </div>
          </form>
        </div>

      </div>
        </div>
        <FooterShop />
    </div>
  );
};

export default InviteFriendz;
