import React, { useEffect, useState } from "react";
import style from "../Css/Header.module.css";
import ss from "../../../assets/ss.png";
import axios from "axios";
import line from "../../../assets/line.png";
import FormatAlignJustifyIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';
import createBrowserHistory from "history/createBrowserHistory"

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  NavLink,
  useHistory,
} from "react-router-dom";
export const history = createBrowserHistory({
    forceRefresh: true
})
const redirectToEdit = () => {
    history.push("/login");
};
const ShopNavbar2 = () => {
    const history = useHistory();
    const [scrollState, setScrollState] = useState("top");
    const [navbarClicked, setNavbarClicked] = useState(0);
    let listener = null;
    
    useEffect(() => {
        listener = document.addEventListener("scroll", e => {
            var scrolled = document.scrollingElement.scrollTop;
            if (scrolled >= 30) {
                if (scrollState === "top") {
                    setScrollState("end");
                }
            } else {
                if (scrollState === "end") {
                    setScrollState("top");
                }
            }
        });

        return () => {document.removeEventListener("scroll", listener)}

    }, [scrollState]);

   const [oldState,newState]=useState(<><li className={style.logga__in}>
    <Link to="/login">
      <span className={style.shop_log_button}>Logga in</span>
    </Link>
  </li>
  <li className={style.logga__in}>
    <Link to="/register">
      <span className={style.shop_log_button}>Registrera</span>
    </Link>
  </li> </>);

    const [logoLink, setLogoLink] = useState('/');

    // useEffect(()=>{
    //     let currUrl = window.location.href.split('/');
    //     if(typeof currUrl[3] != "undefined" && currUrl[3] == "") {
    //         // setLogoLink('/membership');
    //     }
    // },[]);

    useEffect(()=>{
    console.log(localStorage.getItem('role_id'));
    console.log("hello");
    if(localStorage.getItem('role_id'))
    {
      newState(<li className="nav-item dropdown  navbarProfile" style={{ marginTop: '25px' }}>
      <a className="nav-link centerText dropdown-toggle"
         to="/myaccount"
         id="navbarDropdown"
         role="button"
         data-bs-toggle="dropdown"
         aria-expanded="false">
            <i className="far fa-user"></i> MY PROFILE
      </a>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
        <li>
          <Link className="dropdown-item" to="/my-account">
            <i className="far fa-user"></i> Account
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/user/store-product/">
              <i className="fas fa-shopping-cart"></i> Webstore Orders
          </Link>
        </li>
        <li>
            <Link className="dropdown-item" to="/user/subscriptions">
            <i class="fas fa-shopping-cart"></i> Subscriptions
            </Link>
        </li>
        <li>
            <Link className="dropdown-item" to="/collections">
                <i class="fas fa-recycle"></i> Collections
            </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/do-you-know">
            <i class="fas fa-people-carry"></i> Do You Know
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/user/rubbishSubscriptions">
            <i class="fas fa-user"></i> My Orders
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" onClick={()=>{
                redirectToEdit();
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('user_name');
                localStorage.removeItem('role');
                localStorage.removeItem('role_id');
                localStorage.removeItem('StoreProduct');
                localStorage.removeItem('StoreBulkyRubbishProduct');
                localStorage.removeItem('email');
                localStorage.removeItem('StoreBulkyRubbishProductID');
                localStorage.removeItem('StoreProductID');
                localStorage.removeItem('pannel');
             }}>
            <i class="fas fa-sign-out-alt"></i> LOGOUT
          </Link>
        </li>
      </ul>
    </li>)
    }


  },[]);

  return (
    <div className={ scrollState !="top"?"container_navbar sticky-top navbarShadow p-0":"container_navbar sticky-top navbarShadow p-0 fontColor"}  style={{position:"fixed",width:"100%",color:'white' }}>
      <nav className="navbar navbar-expand-lg navbar-light p-0" style={{maxHeight: 85}}>
        <div className="container-fluid" style={{'backgroundColor':scrollState !="top"?"white":"" ,maxHeight:85}}>
          <Link className="navbar-brand" to={logoLink}>
            <img src={ss} alt="" className={style.shop_nav_bar_logo} style={{maxHeight:66}} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{boxShadow:'none'}}
          >
            <span className="navbar-toggler-icon" onClick={()=>{if(navbarClicked == 0){setNavbarClicked(1);}else{setNavbarClicked(0);}}}>
                {navbarClicked === 0 && <FormatAlignJustifyIcon style={{margin:-3}}/>}
                {navbarClicked === 1 && <CloseIcon/>}
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item shop__nav__2">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  activeClassName={'activeLinkStyle'}
                  exact
                  to="/"
                >
                  <span style={{ color:'#bdbdbd'}}>Källsortering</span>
                </NavLink>
              </li>
              <li className="nav-item" style={{maxHeight:"80px !important"}}>
                <a className="nav-link active" aria-current="page" href="#">
                  <img src={line} alt="" className={style.line__web_style} />
                </a>
              </li>
              <li className="nav-item shop__nav__2">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to="/new-shop/bulk-rubbish"
                  activeClassName={'activeLinkStyle'}
                >
                  <span style={{ color:'#bdbdbd'}}> Grovsoper</span>
                </NavLink>
              </li>
                <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">
                        <img src={line} alt="" className={style.line__web_style}  />
                    </a>
                </li>
                {/*<li className="nav-item shop__nav__2">*/}
                    {/*<Link*/}
                        {/*className="nav-link"*/}
                        {/*aria-current="page"*/}
                        {/*to="/store/shop"*/}
                    {/*>*/}
                        {/*<span style={{ color: "#bdbdbd" }}> Store</span>*/}
                    {/*</Link>*/}
                {/*</li>*/}
              {/*<li className="nav-item">*/}
                {/*<a className="nav-link active" aria-current="page" href="#">*/}

                  {/*<img src={line} alt="" className={style.line__web_style} />*/}
                {/*</a>*/}
              {/*</li>*/}
              <li className="nav-item shop__nav__2">
                <NavLink className="nav-link" to="/new-shop/shop" activeClassName={'activeLinkStyle'}>
                  <span style={{ color:'#bdbdbd'}}> Webshop</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">

                  <img src={line} alt="" className={style.line__web_style} />
                </a>
              </li>

              <li className="nav-item dropdown shop__nav__2">
                   <NavLink className="nav-link" to="/faq" activeClassName={'activeLinkStyle'}>
                    <span style={{ color:'#bdbdbd'}}>Vanliga frågor</span>
                   </NavLink>

                {/*<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
  </ul>*/}
              </li>
              <li className="nav-item dropdown shop__nav__2">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to="/membership"
                  activeClassName="activeLinkStyle"
                  onClick={()=>{
                      localStorage.removeItem('subscription')
                      history.push('/membership')}}
                   // onClick={
                   //    history.push("/membership")
                   //    // localStorage.removeItem('subscription')
                   //    }
                >

                  <span style={{ color:'#bdbdbd'}}>Priser</span>
                </NavLink>
                {/*<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
  </ul>*/}
              </li>
              <li className="nav-item shop__nav__2" style={{marginRight: "30px"}}>
                <NavLink className="nav-link" to="/new-shop/business" activeClassName={'activeLinkStyle'}>

                  <span style={{ color:'#bdbdbd'}}>Företag/BRF</span>
                </NavLink>
              </li>


              {/* <li className={style.logga__in}>
                <Link to="/login">
                  <span className={style.shop_log_button}>Logga in</span>
                </Link>
              </li>
              <li className={style.logga__in}>
                <Link to="/register">
                  <span className={style.shop_log_button}>Registrera</span>
                </Link>
              </li> */}
              {oldState}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default ShopNavbar2;
