import React from "react";
import "./zip.css";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Common/Header/Navbar";

const ChoseLocation = () => {
  return (
    <div>
      <Navbar />
      <div className="hero-image1020">
        <div className="hero-text1020">
          <h1>
            Do we pick-up
            <br />
            At your place
          </h1>
          <br />
          <div className="main___location">
            <div className="form-group has-search">
              <span className="fa fa-search "></span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Zip Code"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChoseLocation;
