import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {useDispatch, useSelector} from "react-redux";
import {addProduct, getAllProduct} from "../../../../actions/productAction";
import {
    clearProductType,
    getAllProductType,
} from "../../../../actions/productTypeAction";
import {
    clearCategories,
    getAllStoreProductCategories,
} from "../../../../actions/categoryAction";
import storeCategoryReducer from "../../../../reducers/storeCategoryReducer";
import toast from "../../../../utils/toast";
import {LoadingState} from "../../../../actions/loaderActions";
import Axios from "../../../../Services/Axios";

const Add_Faq_Cat = () => {
    const dispatch = useDispatch();
    const initialState = "";
     const [name, setname] = useState(initialState);



    const onClickListener = () => {


        const formData = new FormData();
        formData.append("category_name", name);



         Axios.post("/api/create/faqCat", formData)
            .then((res) => {
                dispatch(LoadingState(false));
                 if (res.status === 200) {
                    toast.success(res.data.message);
                 }
            })
            .catch((err) => {
                  toast.error("Use another categories name");
                dispatch(LoadingState(false));
            });
    };



    return (
        <div className="container p-2"  style={{marginTop: 70}}>
            <div className="container card p-4  add-products-container">
                <h2 className="text-center my-4">Add Faq Category</h2>
                <div className="row ">
                    <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
               Category name
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setname(e.target.value)}
                            />
                        </div>
                        <br/>


                    </div>

                </div>
                <div className=" mx-auto">
                    <button
                        type="button"
                        class="btn btn-primary "
                        onClick={onClickListener}
                    >
                        Submit
                    </button>
                </div>
                <br/>
            </div>
        </div>
    );
};

export default Add_Faq_Cat;
