import React, { useEffect,useState } from "react";

import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch,
    useHistory,
} from "react-router-dom";
import { Parent_Strip_Color } from "../../../Common/Color";
import Card3 from "../../BulkyRubbish/Card3";
import FooterShop from "../Common/FooterShop";
import ShopNavbar2 from "../Common/ShopNavbar2";
import styles from "../Css/BulkRubbish.module.css";
import BookCard from "./part_/BookCard";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCategories,
  getAllActiveCategories,
  getAllBulkRubbishCategories,
} from "../../../actions/categoryAction";
import {
  clearProduct,
  getAllActiveProduct,
  getAllProductByCategoryID,
  getAllBulkRubbishProduct,
  getAllBulkProductByCategoryID,
} from "../../../actions/productAction";

function BulkRubbish() {
  const { product } = useSelector((state) => state.productReducer);
  const { category } = useSelector((state) => state.categoryReducer);
    const [data, setData] = useState(0);
    const [catName, setcatName] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {


    if (Array.isArray(category) && category.length > 0) {
    } else if(data == 0) {
        setData(1)
      dispatch(getAllBulkRubbishCategories());
        dispatch(getAllBulkRubbishProduct());

    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearProduct());
      dispatch(clearCategories());
      console.log("clearProduct/ClearCategories");
    };
  }, [dispatch]);

  const getProductsByID = (id) => {
      dispatch(getAllBulkProductByCategoryID(id));
  };

  return (
    <div className={styles.main}>
      <ShopNavbar2 />
      <div className={styles.bulkRubbishImageOverText+' grove_shop'}>
        <div>
          <h1 className="bulk_page_heading" style={{color:'#fff'}}>grovsopor - det som inte får plats i soptunnan</h1>
          <h4 className="bulk_page_desc" style={{color:'#fff'}}>
            Beställ hämtning av grovsopor till fasta priser. Det kan vara en
            säng, soffa, kyl, gammal elektronik, dammsugare, pulka eller annat.
            Vi försöker hitta en ny ägare till det som går. Annars kör vi det
            till tippen för vidare återvinning.
          </h4>
        </div>
      </div>

      <div
        className={styles.stripeSearch}
        style={{ backgroundColor: Parent_Strip_Color }}
      >
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            {/* <input type="search" name="" id="" className={styles.searchInput} /> */}
            <div className="form-group has-search d-flex justify-content-center mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="Skriv in ditt postnummer"
              />
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 mt-3">
            <h5>
              Ingen upphämtningskostnad vid order på minst 400 kr. Alltid gratis
              upphämtning av källsortering.
            </h5>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-4 col-md-4">
          <div className={styles.sidebar}>
            <h5>Välj kategori</h5>
            <hr />
            <ul className="bulk_page_list">
              {/* <li className="my-3">
                <a href="">Möbler</a>
              </li>
              <li>
                <a href="">Elektronik</a>
              </li>

              <li>
                <a href="">Vitvaror</a>
              </li>
              <li>
                <a href="">Byggavfall</a>
              </li>
              <li>
                <a href="">Övrigt</a>
              </li> */}
              {Array.isArray(category) && category.length > 0 ? (
                category.map((cat, id) => (
                    <>
                        {cat.brc_active == 1 &&
                  <li key={id}
                      onClick={() => {
                          getProductsByID(cat.brc_id);
                          setcatName(cat.brc_name)
                      }}>
                    {cat ? cat.brc_name : "Category Name"}
                  </li>
                        }
                    </>
                ))
              ) : (
                <p>No kategori Found</p>
              )}
            </ul>

            {/*<h5 className="mt-5">Alternativ</h5>*/}
            {/*<hr />*/}
              {/*{Array.isArray(product) && product.length > 0 ? (*/}
                  {/*product.slice(0, 1).map((data, id) => (*/}
                      {/*<div className="col-lg-6 div col-md-6 col-sm-12">*/}
                          {/*<Link to={"/rubbish/detail/"+data.brp_id}>*/}

                              {/*<BookCard name={data.brp_name}*/}
                                        {/*id={data.brp_brc_id}*/}
                                        {/*size={data.brp_size}*/}
                                        {/*image={data.brp_img}*/}
                                        {/*brp_desc={data.brp_desc}*/}
                                        {/*price={data.price}/>*/}
                          {/*</Link>*/}
                      {/*</div>*/}
                  {/*))*/}
              {/*) : (*/}
                  {/*<p>No Product Found</p>*/}
              {/*)}*/}

            {/*<BookCard />*/}
          </div>
        </div>
        <div className="col-lg-5 col-md-6 col-sm-12">
          <div className={styles.bulkProducts}>
            <h1 className="mb-5">{catName?catName:''}</h1>
          </div>
          <div className="row">
              {/*{Array.isArray(product) && product.length > 0 ? (*/}
                  {/*product.slice(0, 1).map((data, id) => (*/}
                      {/*<div className="col-lg-6 div col-md-6 col-sm-12">*/}
                          {/*<Link to={"/rubbish/detail/"+data.brp_id}>*/}

                              {/*<BookCard name={data.brp_name}*/}
                                        {/*id={data.brp_brc_id}*/}
                                        {/*size={data.brp_size}*/}
                                        {/*image={data.brp_img}*/}
                                        {/*brp_desc={data.brp_desc}*/}
                                        {/*price={data.price}/>*/}
                          {/*</Link>*/}
                      {/*</div>*/}
                  {/*))*/}
              {/*) : (*/}
                  {/*<p>No Product Found</p>*/}
              {/*)}*/}

            {Array.isArray(product) && product.length > 0 ? (
              product.map((data, id) => (
                <div className="col-lg-6 div col-md-6 col-sm-12">
                    <Link to={"/rubbish/detail/"+data.brp_id}>

                        <BookCard name={data.brp_name}
                                  id={data.brp_brc_id}
                                  size={data.brp_size}
                                  image={data.brp_img}
                                  brp_desc={data.brp_desc}
                                  price={data.price}/>
                    {/*<Card3*/}
                    {/*name={data.brp_name}*/}
                    {/*id={data.brp_brc_id}*/}
                    {/*size={data.brp_size}*/}
                    {/*image={data.brp_img}*/}
                    {/*price={data.price}*/}
                  {/*/>*/}
                    </Link>
                </div>
              ))
            ) : (
              <p>No Product Found</p>
            )}
            {/* <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>
            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>

            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>

            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>

            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div> */}
          </div>
        </div>
      </div>

      <FooterShop />
    </div>
  );
}

export default BulkRubbish;
