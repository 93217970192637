import {
  GET_ALL_PACKAGES,
  CLEAR_PACKAGES,
  GET_ALL_CATEGORIES,
  CLEAR_CATEGORIES,
  GET_ALL_PRODUCT_TYPE,
  CLEAR_PRODUCT_TYPE,
} from "./DispatchActions";
import Axios from "../Services/Axios";
import { LoadingState } from "./loaderActions";
import toast from "../utils/toast";

export const getAllProductType = () => (dispatch) => {
  //   dispatch(LoadingState(true));

  Axios.get("api/product-types/all")
    .then((res) => {
      console.log("getAllProductType :", res.data);
      dispatch({
        type: GET_ALL_PRODUCT_TYPE,
        payload: res.data.data,
      });

      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      console.log("Register ERR:", data);
      //   dispatch(LoadingState(false));
    });
};

export const addProductType = (data) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("addProductType ACTION DATA", data);
  Axios.post("/api/product-type/add", data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllProductType());
      // if (res.status === 200) {
      toast.success("Product Type Added successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Adding Product Type");
      dispatch(LoadingState(false));
    });
};

export const updateProductType = (data, id ,history) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("updateProductType ACTION DATA", data);
  Axios.post("/api/product-type/edit/" + id, data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllProductType());

        history.push("/admin/product-type-list");

        toast.success("Product Type Updated Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Updating Product Type");
      dispatch(LoadingState(false));
    });
};

export const removeProductType = (id) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("removeProductType ACTION DATA", id);
  Axios.get("/api/product-type/delete/" + id)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllProductType());
      toast.success("Product Type Removed Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Removing Product Type");
      dispatch(LoadingState(false));
    });
};

export const clearProductType = () => (dispatch) => {
  console.log("ACTION clearProductType ");
  dispatch({
    type: CLEAR_PRODUCT_TYPE,
  });
};
