// import React, { useState } from "react";
// import TableList2 from "../../components/TableListing/TableList2";
// import TableList3 from "../../components/TableListing/TableList3";
// import TableListing from "../../components/TableListing/TableListing";
// import Edit from "@material-ui/icons/Edit";
// import Delete from "@material-ui/icons/Delete";
// import Modal from "react-awesome-modal";

// function Order_List({ history }) {
//   const [visible, setvisible] = useState(false);
//   const closeModal = () => {
//     setvisible(false);
//   };
//   const openModal = () => {
//     setvisible(true);
//   };
//   const redirectToEdit = () => {
//     history.push("/edit-order-list");
//   };

//   const rows = [
//     {
//       name: "Waok",
//       order_date: "2010/03/17",
//       address: "street no 6 london Uk",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },
//     {
//       name: "Dummny",
//       order_date: "2010/03/17",
//       address: "street no 6 london Uk",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },

//     {
//       name: "piterson",
//       order_date: "2010/03/17",
//       address: "street no 6 london Uk",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },

//     {
//       name: "Collingwood",
//       order_date: "2010/03/17",
//       address: "street no 6 london Ukr",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },

//     {
//       name: "Jonshon",
//       order_date: "2010/03/17",
//       address: "street no 6 london Uk",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },

//     {
//       name: "Bag 6",
//       order_date: "2010/03/17",
//       address: "street no 6 london Uk",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },
//     {
//       name: "Bag 1",
//       order_date: "2010/03/17",
//       address: "street no 6 london Uk",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },
//     {
//       name: "Bag 2",
//       order_date: "2010/03/17",
//       address: "street no 6 london Uk",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },

//     {
//       name: "Bag 3",
//       order_date: "2010/03/17",
//       address: "street no 6 london Uk",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },

//     {
//       name: "Bag 4",
//       order_date: "2010/03/17",
//       address: "street no 6 london Ukr",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },

//     {
//       name: "Bag 5",
//       order_date: "2010/03/17",
//       address: "street no 6 london Uk",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },

//     {
//       name: "Bag 6",
//       order_date: "2010/03/17",
//       address: "street no 6 london Uk",
//       number: "924678908",
//       action: (
//         <div>
//           <Edit color="action" onClick={redirectToEdit} />
//           <Delete color="error" onClick={openModal} />
//         </div>
//       ),
//     },
//   ];
//   const columns = [
//     {
//       label: "Customer Name",
//       field: "name",
//       width: 150,
//       attributes: {
//         "aria-controls": "DataTable",
//         "aria-label": "Name",
//       },
//     },
//     {
//       label: "Address",
//       field: "address",
//       width: 200,
//     },
//     {
//       label: "Phone number",
//       field: "number",
//       width: 200,
//     },
//     {
//       label: "Order date",
//       field: "order_date",
//       sort: "disabled",
//       width: 150,
//     },
//     {
//       label: "Action",
//       field: "action",
//       sort: "disabled",
//       width: 100,
//     },
//   ];
//   return (
//     <div>
//       <TableList2 heading="Order" columns={columns} rows={rows} />
//       {/* <TableList3 /> */}
//       <Modal
//         visible={visible}
//         effect="fadeInRight"
//         onClickAway={() => closeModal()}
//         className="modalPop"
//       >
//         <div className="modalPop1">
//           {/* <h1>Title</h1> */}
//           <div className="text-danger">
//             Are you Sure? You want to delete this Record.
//           </div>
//           <div className="d-flex justify-content-around">
//             <div
//               className="btn btn-primary btn-sm"
//               onClick={() => closeModal()}
//             >
//               Cancel
//             </div>
//             <div className="btn btn-danger btn-sm" onClick={() => closeModal()}>
//               Delete
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default Order_List;
// <==============orderList====>

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Modal from "react-awesome-modal";

const Order_List = ({ history }) => {
  const [users, setUser] = useState([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const redirectToEdit = () => {
    history.push("/admin/edit-order-list");
  };

  const loadUsers = async () => {
    const result = await axios.get("https://jsonplaceholder.typicode.com/users");
    setUser(result.data);

  };


  return (
    <div className="container">
      <div className="py-4">
        <h1 className="my-2 text-center">Users List</h1>

        <table className="table border shadow bg-white">
          <thead className="thead-white">
            <tr>

              <th scope="col">Customer Name</th>
              <th scope="col">Address</th>
              <th scope="col">Phone no</th>
              <th scope="col">Order date</th>
              <th scope="col">Active</th>

            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr>

                <td>{user.name}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>
                  <div className="d-flex" style={{ margin: '8px' }}>
                    <div className="" onClick={redirectToEdit}><Edit style={{ color: '#757575' }} /></div>
                    <Link
                      className=" ml-2"

                    >
                      <Delete style={{ color: '#f44336', cursor: 'pointer' }} />
                    </Link>

                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Order_List