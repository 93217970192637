import React, { useEffect } from "react";

import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch,
    useHistory,
} from "react-router-dom";
import { Parent_Strip_Color } from "../../../Common/Color";
import Card3 from "../../BulkyRubbish/Card3";
import FooterShop from "../Common/FooterShop";
import ShopNavbar2 from "../Common/ShopNavbar2";
import styles from "../Css/BulkRubbish.module.css";
// import BookCard from "../BulkRubbish/part_/BookCard";
import { useDispatch, useSelector } from "react-redux";
import {
    clearCategories,
    getAllActiveCategories,
    getAllBulkRubbishCategories, getAllStoreProductCategories,
} from "../../../actions/categoryAction";
import {
    clearProduct,
    getAllStoreProduct,
    getAllStoreProductByCategoryID, getStoreAllProduct,
} from "../../../actions/productAction";
import Card2 from "../../BulkyRubbish/Card2";

function Store() {
    const { allStoreProduct } = useSelector((state) => state.allProductsReducer);
    const { storeCategories } = useSelector((state) => state.storeCategoryReducer);
     const dispatch = useDispatch();

    useEffect(() => {
        console.log("bags VALUE");
        if (Array.isArray(allStoreProduct) && allStoreProduct.length > 0) {
        } else {
            dispatch(getAllStoreProduct());
        }

        if (Array.isArray(storeCategories) && storeCategories.length > 0) {
        } else {
            dispatch(getAllStoreProductCategories());
        }
    }, [dispatch, allStoreProduct, storeCategories]);

    useEffect(() => {
        return () => {
            dispatch(clearProduct());
            dispatch(clearCategories());
            dispatch(getStoreAllProduct());

            console.log("clearProduct/ClearCategories");
        };
    }, [dispatch]);

    const getProductsByID = (id) => {
        console.log('sasasas',id)
        dispatch(getAllStoreProductByCategoryID(id));
    };


    return (
        <div className={styles.main}>
            <ShopNavbar2 />
            <div className={styles.bulkRubbishImageOverText+' web_store_banner'}>
                {/*<div>*/}
                    {/*<h1>grovsopor - det som inte får plats i soptunnan</h1>*/}
                    {/*<h4>*/}
                        {/*Beställ hämtning av grovsopor till fasta priser. Det kan vara en*/}
                        {/*säng, soffa, kyl, gammal elektronik, dammsugare, pulka eller annat.*/}
                        {/*Vi försöker hitta en ny ägare till det som går. Annars kör vi det*/}
                        {/*till tippen för vidare återvinning.*/}
                    {/*</h4>*/}
                {/*</div>*/}
            </div>

            {/*<div*/}
                {/*className={styles.stripeSearch}*/}
                {/*style={{ backgroundColor: Parent_Strip_Color }}*/}
            {/*>*/}
                {/*<div className="row">*/}
                    {/*<div className="col-lg-4 col-md-4 col-sm-12">*/}
                        {/*/!* <input type="search" name="" id="" className={styles.searchInput} /> *!/*/}
                        {/*<div className="form-group has-search d-flex justify-content-center mt-3">*/}
                            {/*<input*/}
                                {/*type="text"*/}
                                {/*className="form-control"*/}
                                {/*placeholder="Skriv in ditt postnummer"*/}
                            {/*/>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-8 col-md-8 col-sm-12 mt-3">*/}
                        {/*<h5>*/}
                            {/*Ingen upphämtningskostnad vid order på minst 400 kr. Alltid gratis*/}
                            {/*upphämtning av källsortering.*/}
                        {/*</h5>*/}
                    {/*</div>*/}
                {/*</div>*/}
            {/*</div>*/}

            <div className="row mt-5">
                <div className="col-lg-4 col-md-4">
                    <div className={styles.sidebar}>
                        <h5>Välj kategori</h5>
                        <hr />
                        <ul>
                            {Array.isArray(storeCategories) && storeCategories.length > 0 ? (
                                storeCategories.map((cat, id) => (
                                    <li key={id} onClick={() => getProductsByID(cat.id)}>
                                        {cat ? cat.name : "Category Name"}
                                    </li>
                                ))
                            ) : (
                                <p>No kategori Found</p>
                            )}
                        </ul>

                        {/*<h5 className="mt-5">Alternativ</h5>*/}
                        <hr />
                        {/*<BookCard />*/}
                        {/*<BookCard />*/}
                    </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                    <div className={styles.bulkProducts}>
                        <h1 className="mb-5">Store</h1>
                    </div>
                    <div className="row">
                        {Array.isArray(allStoreProduct) && allStoreProduct.length > 0 ? (
                            allStoreProduct.map((data, id) => (
                                <div className="col-lg-6 div col-md-6 col-sm-12">
                                    {/*<Link to={"/rubbish/detail/"+data.id}>*/}

                                        <Card2
                                            name={data.product_name}
                                            id={data.id}
                                            price={data.price}
                                            currency={data.currency}
                                            image={data.img}
                                            points={data.point}
                                            type={data.type}
                                            description={data.description}
                                            store='true'
                                        />
                                    {/*</Link>*/}
                                </div>
                            ))
                        ) : (
                            <p>No Product Found</p>
                        )}
                        {/* <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>
            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>

            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>

            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>

            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div> */}
                    </div>
                </div>
            </div>

            <FooterShop />
        </div>
    );
}

export default Store;
