import React, { useState,useEffect } from "react";
import "./member.css";
import Tabss from "./Tabss";
import PaymentForm from './StandardForm';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from "@material-ui/core/Button";
import ShopNavbar2 from "../../Modules/WebShop/Common/ShopNavbar2";
import FooterShop from "../../Modules/WebShop/Common/FooterShop";

import { Elements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {useDispatch} from "react-redux";
import Axios from "../../Services/Axios";
import toast from "../../utils/toast";
import {LoadingState} from "../../actions/loaderActions";
import Collection from "../Collection/Collection";
import ButtonGroup from "@material-ui/core/ButtonGroup/index";
import {AddPayment, AddStorePayment} from "../../actions/paymentActions";

const MemberShip2 = ({ history }) => {
  // const { payments } = useSelector((state) => state.recycleReducer);
  // const stripePromise = loadStripe('pk_test_51IXfK6HVjYUffmJdp2R3nYu2MouPuYZe3xHB1aDuBQ46v5JrrsSmySqrlaqLBD4QvTQ2qQNIzKf9gm3HjHRShPnw00ngvBIPat');
    let id = window.location.href.split("/").pop();
    let dispatch = useDispatch();
    const user = localStorage.getItem("user");
    const [packageDetails, setPackageDetails] = useState(null);
    const [packageBagSelectedId, setPackageBagSelectedId] = useState(0);
    const [recurring, setRecurring] = useState(0);
    const [userBagQuery, setUserBagQuery] = useState('');
    const [packageBagSelected, setPackageBagSelected] = useState(null);
    const [state, setState] = React.useState({
        gilad: recurring == 1 ? true:false,

    });
    console.log('recurring',recurring)

    const handleChange = (event) => {
         console.log( ' event.target.checked',event.target.checked )
         setState({ ...state, [event.target.name]: event.target.checked });

        let sendingData = {
            user_id: user,
            isRecurring: event.target.checked == true ? 1 : 0
        };

        setRecurring(sendingData.isRecurring);
        setUserBagQuery(0)
        Axios.post("/api/user/recurring-payment",sendingData)
            .then((res) => {
                toast.success("Recurring successful updated!");
                dispatch(LoadingState(false));
            })
            .catch((err) => {
                // toast.error("There is Some Error While Adding Package 1");
                // dispatch(LoadingState(false));
            });

    };
    // console.log('state',packageDetails && packageDetails.recurring)

    if(!user || user === "") {
        history.push("/login");
    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const onClickListener  = (value) => {
        let sendingData = {
                user_id: user,
                isRecurring: value
            };
         setRecurring(value);
         setUserBagQuery(0)
              Axios.post("/api/user/recurring-payment",sendingData)
                 .then((res) => {
                     toast.success("Recurring successful updated!");
                     dispatch(LoadingState(false));
                 })
                 .catch((err) => {
                     // toast.error("There is Some Error While Adding Package 1");
                     // dispatch(LoadingState(false));
                 });


    };



    function updateOrderStatus(status) {
        const sendingData = {
            status: status,
            subscription_id: id
        };

        Axios.post("/api/packages/bags", sendingData)
        .then((res) => {
            // console.log('bhhhghg1',res.data.data);
            if (res.data.data.bag.length > 0) {
                setPackageDetails(res.data.data);
                if (res.data.data.bag.length > 0) {
                    setPackageBagSelectedId(res.data.data.bag[0].bag_id);
                    setPackageBagSelected(res.data.data.bag[0]);
                }
            }
            dispatch(LoadingState(false));
        })
        .catch((err) => {
            // toast.error("There is Some Error While Adding Package 1");
            // dispatch(LoadingState(false));
        });
    }

    useEffect(() => {
        if(id) {
            const sendingData = {
                subscription_id: id
            };

            Axios.post("/api/packages/bags", sendingData)
                .then((res) => {
                    // console.log('bhhhghg1',res.data.data);
                    if (res.data.data.bag.length > 0) {
                        setPackageDetails(res.data.data);
                        setRecurring(res.data.data.recurring);
                         if (res.data.data.bag.length > 0) {
                            setPackageBagSelectedId(res.data.data.bag[0].bag_id);
                            setPackageBagSelected(res.data.data.bag[0]);
                        }
                    }
                    dispatch(LoadingState(false));
                })
                .catch((err) => {
                    // toast.error("There is Some Error While Adding Package 1");
                    // dispatch(LoadingState(false));
                });
        }
     }, [id]);


// <FormControlLabel
//     control={<Switch checked={recurring== 1?true:false}  color="primary" onChange={handleChange} name="gilad" />}
//     label="Recurring payment"
//         />
    return (
    <div>
      <ShopNavbar2 />
      <div className="container">
        <div className="row gx-5">
          <div className="col-lg-6 col-md-6 col-12 mx-auto mt-3 first_div_a d-flex justify-content-center align-items-center flex-column">
            <h1 className="text-center my-5 h1____css">{packageDetails && packageDetails.package.pkg_name}</h1>
            <h3 className="text-center my-4">
                Collection every {packageDetails && packageDetails.package.frequency}
            </h3>

          </div>
    {packageDetails && (packageDetails.orderStatus == 'end' ) &&
        <div className="col-lg-6 col-md-6 col-12 mx-auto mt-3 first_div1 d-flex justify-content-center align-items-center flex-column">

            <Button variant="contained" color="primary">
                No Active Subscription
            </Button>
    <br /><br />
            <Button variant="contained" color="primary" onClick={()=>{
                localStorage.setItem('subscription', 'subscriptionChange');
            history.push('/membership')}}>
                Subscribe
            </Button>
            <br /><br />

             {/*{recurring == 0 &&*/}
            {/*< Button variant="contained" color="primary" onClick={() => onClickListener(1)}>*/}
                {/*Recurring payment enable*/}
            {/*</Button>*/}
            {/*}*/}
            {/*{recurring == 1 &&*/}
            {/*< Button variant="contained" color="primary" onClick={() => onClickListener(0)}>*/}
                {/*Recurring payment disable*/}
            {/*</Button>*/}
            {/*}*/}


        </div>
    }
            {packageDetails && (packageDetails.orderStatus == 'pause' || packageDetails.orderStatus == 'pending') &&
          <div className="col-lg-6 col-md-6 col-12 mx-auto mt-3 first_div1 d-flex marginTop justify-content-center align-items-center flex-column">

            <Button variant="contained" color="primary" onClick={()=>{history.push('/membership')}}>
              change
            </Button>
            <br />
                <div className="xyz">
                    {packageDetails.orderStatus == 'pause' ?
                      <Button variant="contained" color="primary"
                        onClick={()=>{
                            if(window.confirm('Are you sure you want to resume this subscription?')){
                                updateOrderStatus('pending');
                            }
                        }}>
                        Resume
                      </Button>:
                      <Button variant="contained" color="primary"
                        onClick={()=>{
                            if( window.confirm('Are you sure you want to pause this subscription?')){
                                updateOrderStatus('pause');
                            }
                        }}>
                        Pause
                      </Button>}
                      <Button
                        variant="contained"
                        color="primary"
                        className="secons____btnnn"
                            onClick={()=>{
                            if( window.confirm('Are you sure you want to end this subscription?')){
                                updateOrderStatus('end');
                            }
                        }}>
                        end
                      </Button>

                    {/*{recurring == 0 &&*/}
                    {/*< Button variant="contained" color="primary" onClick={() => onClickListener(1)}>*/}
                        {/*Recurring payment enable*/}
                    {/*</Button>*/}
                    {/*}*/}
                    {/*{recurring == 1 &&*/}
                    {/*< Button variant="contained" color="primary" onClick={() => onClickListener(0)}>*/}
                        {/*Recurring payment disable*/}
                    {/*</Button>*/}
                    {/*}*/}

                </div>
              <div>

              </div>
          </div>}

        </div>

      </div>
      <h1 className="text-center my-3">Recycling bag</h1>
        <div className="col-4 mx-auto" style={{maxWidth:120,margin:'auto'}}>
            <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
            <div>
            <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                {packageDetails && packageDetails.bag && packageDetails.bag.map((data, id) => (

                    <Button color="primary" onClick = {()=>{
                        setPackageBagSelectedId(data.bag_id);
                        setPackageBagSelected(data);
                        }}
                        variant={(data.bag_id == packageBagSelectedId) ?"contained":""}>
                        {data.bag_name}
                    </Button>
            ))}
            </ButtonGroup>
            </div>
            </div>
        </div>
      <p className="text-center my-2" style={{maxWidth:400,margin:'auto'}}>

        {packageBagSelected && packageBagSelected.bag_description}
      </p>

      <div class="container mb-5">
        <h1 className="text-center my-5">Payment history</h1>
        <div className="col-12 mx-auto">
          <table className="table table-condensed">
            <thead className="bg-dark text-white text-center">
              <tr>
                <th>ID</th>
                <th>DATE</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>

                {packageDetails && packageDetails.transactions && packageDetails.transactions.map((data)=>(
                    <tr className="text-center">
                        <td>{data.id}</td>
                        <td>{data.created_at}</td>
                        <td>{data.amount}</td>
                    </tr>
                ))}

            </tbody>
          </table>
        </div>
        <h1 class="text-center my-4">
          Get a free pick-up for every recruited member!
        </h1>
        <div class="col-11 mx-auto">
          <div class="col-lg-4 col-md-6 col-11 mx-auto">
            <div class="d-flex justify-content-center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/invite")}
              >
                Invite Friends and neighbors
              </Button>
            </div>
          </div>
        </div>
      </div>
      <FooterShop />
    </div>
  );
};

export default MemberShip2;
