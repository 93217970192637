import React, { useState } from "react";
import "./add_catagory.css";
import Loader from "react-loader-spinner";

import { useDispatch, useSelector } from "react-redux";
import {
  AddBulkRubbishCategory,
  AddCategory,
} from "../../../../actions/categoryAction";

const Add_bulkCategory = () => {
  const [name, setname] = useState("");
  const [status, setstatus] = useState("0");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loaderReducer);
    const [isFormSubmit, setIsFormSubmit] = useState(0);

  const onClickListener = () => {
      setIsFormSubmit(1)
      if(name!='' && status!='') {

          const data = {
              brc_name: name,
              brc_active: status,
              brc_desc: "Description",
          };
          dispatch(AddBulkRubbishCategory(data));
      }
  };
  return (
    <div className=" mt-3">
      <div className="container card p-5 mobile__cs">
        <h2 className="text-center my-3">Add Bulky Rubbish Catagory</h2>
        <div className="row mt-4">
          <div className="col-lg-12 col-md-12 col-12 mx-auto">
            <div className="input-group mb-3  ">
              <span
                className="input-group-text bg-primary text-white"
                id="inputGroup-sizing-default"
              >
                Category Name
              </span>
              <input
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
              />
            </div>
              {isFormSubmit == 1 && name=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setstatus(e.target.value)}
            >
              <option disabled  selected>Status</option>
              <option value="1">active</option>
              <option value="0">not active</option>
            </select>
            <br />
          </div>
        </div>
        <div className=" mx-auto mt-3">
          <button
            type="button"
            class="btn btn-primary "
            onClick={onClickListener}
          >
            Add Category
            <Loader
              type="Puff"
              color="#FFF"
              height={20}
              width={15}
              style={{ marginLeft: 5 }}
              visible={loading}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Add_bulkCategory;
