import React, { useState ,useEffect} from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Navbar from "../../../Common/Header/Navbar";
import Footer from "../../../Common/Footer/Footer";
import axios from "axios/index";
import toast from "../../../utils/toast";
import { useDispatch, useSelector } from "react-redux";

import {LoadingState} from "../../../actions/loaderActions";
import Axios from "../../../Services/Axios";
import {register} from "../../../actions/authAction";
import {getAllUserss} from "../../../actions/AllUsersList";
import ShopNavbar2 from "../../WebShop/Common/ShopNavbar2";
import FooterShop from "../../WebShop/Common/FooterShop";

const MyAccount =  ({ history }) => {
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState('');
  const [lastName,setlastName] = useState('');
  const [phoneNumber,setPhoneNumber] = useState('');
  const [address,setAddress] = useState('');
  const [Email,setEmail] = useState('');
  const [userData,setUserData] = useState('');
    const user = localStorage.getItem("user");

    const onClickListener = (e) => {
        e.preventDefault();
             const sendingData = {
                lname: lastName,
                fname: firstName,
                phone: phoneNumber,
                 address: address,
                type: 'updateProfile',

            };
             axios.post("https://loopabackend.p2gmanagement.xyz/api/user/updateuser/" +user ,sendingData)
                .then((res) => {
                    dispatch(LoadingState(false));
                    // dispatch(getAllUserss());
                    toast.success("Users Updated Successfully.");
                    console.log("DATA API HIT :", res.data);
                })
                .catch((err) => {
                    toast.error("Already Updated!");
                    dispatch(LoadingState(false));
                });
            // history.push("");

    }

     useEffect(() => {
        Axios.get("/api/user/" +user)
            .then((res) => {
                setUserData(res.data.data);
                setFirstName(res.data.data.first_name);
                setlastName(res.data.data.last_name);
                setEmail(res.data.data.email);
                setAddress(res.data.data.address);
                setPhoneNumber(res.data.data.phone);

                //   dispatch(LoadingState(false));
            })
            .catch((err) => {
                const data = err.response.data.error;
                console.log("Payment ERR:", data);
                //   dispatch(LoadingState(false));
            });




    },[]);console.log(userData)


    return (
    <div>
        <ShopNavbar2 />
        <div style={{marginTop:130}}>
      <h1 className="text-center my-5">My Profile</h1>
      <div className="container wrapper mb-5">
        <form onSubmit={(e) => onClickListener(e)}>
          <div className="row">

            <div className="col-lg-6 col-md-6 col-12 mx-auto">

              <div className="input-data">
                <input type="text" required name="fname" id="fname" value={firstName}
                       onChange={(e) => {
                           setFirstName(e.target.value);
                       }}/>

                <div className="underline"></div>
                <label>First Name</label>
              </div>

                <div className="input-data my-4">

                    <input type="email" required name="email" id="email"  value={Email} disabled />
                    <div className="underline"></div>
                 </div>



            </div>
            <div className="col-lg-6 col-md-6 col-12 mx-auto">
              <div className="input-data">
                  <input type="text" required name="lname" id="lname"  value={lastName} onChange={(e) => {
                      setlastName(e.target.value);
                  }}/>
                  <div className="underline"></div>
                  <label>Last Name</label>
               </div>
              <div className="input-data my-4">
                <input type="number" required name="pnumber" id="pnumber" onChange={(e) => {
                           setPhoneNumber(e.target.value);
                }} value={phoneNumber}/>
                <div className="underline"></div>
                <label>Phone</label>
              </div>
            </div>
              {/*<div className="input-data my-4" style={{width:"100%"}} >*/}
                   {/**/}
                  {/*<label style={{left:"20px"}}>Address</label>*/}
              {/*</div>*/}
              <div className="input-data my-4">

                  <input type="text" required name="address" id="address"  onChange={(e) => {
                      setAddress(e.target.value);
                  }}
                            value={address}/>
                  <div className="underline"></div>
                  <label style={{left:"10px"}}>Address</label>

              </div>

          </div>
        </form>
        <div className="d-flex justify-content-center mt-2">
          <Link className="changePasswordText" to="/change-password">
            Want to Change your Password?
          </Link>
        </div>
        <br />
        <div className="d-flex justify-content-center">
            <Button theme="accent"  onClick={onClickListener}>
            Update Profile
        </Button>
        </div>

      </div>
        </div>
    <FooterShop />
    </div>
  );
};

export default MyAccount;
