import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";
// routes Edit pages
import Edit_Product from "./views/Edit_Pages/Edit_Product";
import Edit_bag from "./views/Edit_Pages/Edit_bag";
import Edit_ZipCode from "./views/Edit_Pages/Edit_ZipCode";
import Edit_order_list from "./views/Edit_Pages/Edit_order_list";
import Edit_Packages from "./views/Edit_Pages/Edit_Packages";
import Edit_Catagory from "./views/Edit_Pages/Edit_Catagory";
import Edit_user_list from "./views/Edit_Pages/Edit_user_list";
import Edit_Transaction from "./views/Edit_Pages/Edit_Transaction";
// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import Transaction from "./views/Transaction";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Add_Product from "./views/Add_Product/Page";
import Add_Terms from "./views/Add_Terms/Add_Terms";
import Terms_list from "./views/Add_Terms/Terms_List";
import Add_BulkRubbish from "./views/Add_BulkRubbish/Add_BulkRubbish";
import Add_Store_Category from "./views/Store/Add_Store_Category";
import add_store_product from "./views/Store/Add_Store_Product";
import Add_Packages from "./views/Add_Packages/Add_Packages";
import Add_Category from "./views/Add_Category/Add_Category";
import Add_Bag from "./views/Add_Bag/Add_Bag";
import Add_ZipCode from "./views/Add_ZipCode/Add_ZipCode";
import Bag_List from "./views/Listing/Bag_List";
import Product_List from "./views/Listing/Product_List";
import Category_List from "./views/Listing/Category_List";
import Packages_List from "./views/Listing/Packages_List";
import Zip_List from "./views/Listing/Zip_List";
import Order_List from "./views/Listing/Order_List";
import User_List from "./views/Listing/User_List";
import Transaction_List from "./views/Listing/Transaction_List";
import Edit_ProductType from "./views/Edit_Pages/Edit_ProductType";
import Add_ProductType from "./views/Add_ProductType/Add_ProductType";
import ProductType_List from "./views/Listing/ProductType_List";
import BulkRubbish_List from "./views/Listing/BulkRubbish_List";
import Edit_BulkRubbish from "./views/Edit_Pages/Edit_BulkRubbish";
import Add_bulkCategory from "./views/Add_Category/Add_bulkCategory";
import Add_Faq_Cat from "./views/Add_faq/Add_Faq_Cat";
import BulkCategory_List from "./views/Listing/BulkCategory_List";
import Rubbish_Order from "./views/Listing/Rubbish_Order";
import WebStoreOrder from "./views/Listing/WebStoreOrders";
import CollectionOrder from "./views/Listing/Collection_Order";
import Edit_BulkCategory from "./views/Edit_Pages/Edit_BulkCategory";
import RubbishSubscription from "../UserSubscriptions/RubbishSubscription";
import AdminUserProfileEdit from "./views/AdminUserProfileEdit";
import AdminChangePassword from "./views/adminChangePassword";
import UserRequest from "./views/Listing/UserRequest";
import UserInfo from "./views/Listing/userInfo";
import Faq_Cat_List from "./views/Add_faq/Faq_Cat_List";
import Add_Faq from "./views/Add_faq/Add_Faq";
import Faq_list from "./views/Listing/Faq_list";

export default [
  {
    path: "/admin",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/admin/login" />,
  },
  {
    path: "/admin/dashboard-overview",
    layout: DefaultLayout,
    component: BlogOverview,
  },
    {
        path: "/admin/add_store_category",
        layout: DefaultLayout,
        component: Add_Store_Category,
    },
    {
        path: "/admin/add_store_product",
        layout: DefaultLayout,
        component: add_store_product,
    },
  {
    path: "/admin/add-product",
    layout: DefaultLayout,
    component: Add_Product,
  },
  {
    path: "/admin/add-terms",
    layout: DefaultLayout,
    component: Add_Terms,
  },
  {
    path: "/admin/terms/list",
    layout: DefaultLayout,
    component: Terms_list,
  },
  {
    path: "/admin/add-bulk-rubbish",
    layout: DefaultLayout,
    component: Add_BulkRubbish,
  },
  {
    path: "/admin/add-packages",
    layout: DefaultLayout,
    component: Add_Packages,
  },
  {
    path: "/admin/add-category",
    layout: DefaultLayout,
    component: Add_Category,
  },
  {
    path: "/admin/add-bulk-rubbish-category",
    layout: DefaultLayout,
    component: Add_bulkCategory,
  },
  {
    path: "/admin/add-bags",
    layout: DefaultLayout,
    component: Add_Bag,
  },
  {
    path: "/admin/add-zip-code",
    layout: DefaultLayout,
    component: Add_ZipCode,
  },
  {
    path: "/admin/add-product-type",
    layout: DefaultLayout,
    component: Add_ProductType,
  },
  {
    path: "/admin/product-type-list",
    layout: DefaultLayout,
    component: ProductType_List,
  },
  {
    path: "/admin/bag-list",
    layout: DefaultLayout,
    component: Bag_List,
  },
  {
    path: "/admin/product-list",
    layout: DefaultLayout,
    component: Product_List,
  },
  {
    path: "/admin/bulk-rubbish-list",
    layout: DefaultLayout,
    component: BulkRubbish_List,
  },
  {
    path: "/admin/category-list",
    layout: DefaultLayout,
    component: Category_List,
  },
    {
    path: "/admin/rubbish-order",
    layout: DefaultLayout,
    component: Rubbish_Order,
    },
  {
    path: "/admin/bulk-category-list",
    layout: DefaultLayout,
    component: BulkCategory_List,
  },
  {
    path: "/admin/packages-list",
    layout: DefaultLayout,
    component: Packages_List,
  },
  {
    path: "/admin/zip-list",
    layout: DefaultLayout,
    component: Zip_List,
  },
  {
    path: "/admin/order-list",
    layout: DefaultLayout,
    component: Order_List,
  },
  {
    path: "/admin/user-profile",
    layout: DefaultLayout,
    component: UserProfileLite,
  },
  {
    path: "/admin/user-list",
    layout: DefaultLayout,
    component: User_List,
  },
    {
        path: "/admin/user-request",
        layout: DefaultLayout,
        component: UserRequest,
    },
  {
    path: "/admin/transaction-list",
    layout: DefaultLayout,
    component: Transaction_List,
  },{
    path: "/admin/collection-order",
    layout: DefaultLayout,
    component: CollectionOrder,
  },
  {
    path: "/admin/edit-product",
    layout: DefaultLayout,
    component: Edit_Product,
  },
  {
    path: "/admin/web-store-order",
    layout: DefaultLayout,
    component: WebStoreOrder,
  },
  {
    path: "/admin/edit-bag",
    layout: DefaultLayout,
    component: Edit_bag,
  },
  {
    path: "/admin/edit-catagory",
    layout: DefaultLayout,
    component: Edit_Catagory,
  },
  {
    path: "/admin/edit-packages",
    layout: DefaultLayout,
    component: Edit_Packages,
  },
  {
    path: "/admin/edit-zipcode",
    layout: DefaultLayout,
    component: Edit_ZipCode,
  },
  {
    path: "/admin/edit-order-list",
    layout: DefaultLayout,
    component: Edit_order_list,
  },
  {
    path: "/admin/edit-user-list",
    layout: DefaultLayout,
    component: Edit_user_list,
  },
  {
    path: "/admin/edit-transaction",
    layout: DefaultLayout,
    component: Edit_Transaction,
  },
  {
    path: "/admin/edit-product-type",
    layout: DefaultLayout,
    component: Edit_ProductType,
  },
  {
    path: "/admin/edit-bulk-rubbish",
    layout: DefaultLayout,
    component: Edit_BulkRubbish,
  },
  {
    path: "/admin/edit-bulk-rubbish-category",
    layout: DefaultLayout,
    component: Edit_BulkCategory,
  },
  {
    path: "/admin/edit-user-profile",
    layout: DefaultLayout,
    component: AdminUserProfileEdit
  },
  {
    path: "/admin/password-change",
    layout: DefaultLayout,
    component: AdminChangePassword
  },{
    path: "/admin/user-contact",
    layout: DefaultLayout,
    component: UserInfo
  },{
    path: "/admin/add-faq-category",
    layout: DefaultLayout,
    component: Add_Faq_Cat
  },{
    path: "/admin/faq-category",
    layout: DefaultLayout,
    component: Faq_Cat_List
  },{
    path: "/admin/add-faq",
    layout: DefaultLayout,
    component: Add_Faq
  },{
    path: "/admin/faq-list",
    layout: DefaultLayout,
    component: Faq_list
  },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: ComponentsOverview
  // },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  // {
  //   path: "/blog-posts",
  //   layout: DefaultLayout,
  //   component: BlogPosts
  // }
];
