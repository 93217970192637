import {GET_ALL_COLLECTIONS, GET_ALL_PAYMENTS, GET_ALL_TRANSACTIONS} from "./DispatchActions";
import Axios from "../Services/Axios";
import { LoadingState } from "./loaderActions";
import toast from "../utils/toast";

export const getAllPayments = () => (dispatch) => {
    //   dispatch(LoadingState(true));
  
    Axios.get("/api/payments/all")
      .then((res) => {
        console.log("getAllPayments :", res.data);
        dispatch({
          type: GET_ALL_PAYMENTS,
          payload: res.data.data,
        });

        //   dispatch(LoadingState(false));
      })
      .catch((err) => {
        const data = err.response.data.error;
        console.log("Payment ERR:", data);
        //   dispatch(LoadingState(false));
      });
  };

export const getAllTransactions = () => (dispatch) => {
    //   dispatch(LoadingState(true));

    Axios.get("/api/transaction/all")
      .then((res) => {
        console.log("getAllTransactions :", res.data);
        dispatch({
          type: GET_ALL_TRANSACTIONS,
          payload: res.data.data,
        });

        //   dispatch(LoadingState(false));
      })
      .catch((err) => {
        // const data = err.response.data.error;
        // console.log("Payment ERR:", data);
        //   dispatch(LoadingState(false));
      });
  };

export const getAllCollection = () => (dispatch) => {
    //   dispatch(LoadingState(true));

    Axios.get("/api/collections/all")
      .then((res) => {
        console.log("getAllCollection :", res.data);
        dispatch({
          type: GET_ALL_COLLECTIONS,
          payload: res.data.data,
        });

        //   dispatch(LoadingState(false));
      })
      .catch((err) => {
        const data = err.response.data.error;
        console.log("Payment ERR:", data);
        //   dispatch(LoadingState(false));
      });
  };
  
  export const AddPayment = (data,history) => (dispatch) => {
    dispatch(LoadingState(true));
    console.log("AddPayments ACTION DATA", data);
    Axios.post("/api/user/payment-store", data)
      .then((res) => {
          // console.log(res.data.type)
   localStorage.removeItem('subscription');

          dispatch(LoadingState(false));
        // dispatch(getAllPayments());
        // if (res.status === 200) {
          if(res.data.type =='error'){
              console.log(res.data.message)
              toast.error(res.data.message);
          }else if(data.type == 'isBulkRubbishOrder' &&  res.data.type !='error'){
              toast.success("Payment successful!");
              history.push('/user/rubbishSubscriptions');
              localStorage.removeItem('StoreBulkyRubbishProduct');
          }else {
              toast.success("Payment successful!");
              history.push('/user/subscriptions');
          }
          // else{
          //     history.push('/user/subscriptions');
          //
          // }



         // console.log("DATA API HIT :", res.data);
        // let { id, name, avatar } = jwtDecode(res.data.token);
        // authConfig(res.data.token, res.data.user, history);
        // dispatch({
        //   type: GET_PROFILE,
        //   payload: res.data,
        // });
        // }
      })
      .catch((err) => {
        // const data = err.response.data.error;
        // var res = data.split("validation.custom.messages.");
        // res = res[1];
        // console.log("Login ERR:", res);
        // // var pk = "invalid_login";
  
        // console.log("Messgas", Message.validation.custom.messages[res]);
        // const Error = Message.validation.custom.messages[res];
          const data = err.response.data.message;
          console.log(data)

          toast.error(data);
          dispatch(LoadingState(false));
      });
  };

export const AddStorePayment = (data,history) => (dispatch) => {
    dispatch(LoadingState(true));
    console.log("AddPayments ACTION DATA", data);
    Axios.post("/api/user/payment-web-store", data)
        .then((res) => {
            dispatch(LoadingState(false));
            // dispatch(getAllPayments());
            if(data.type == 'point' && res.data.code != 500) {
                history.push('/user/store-product/');
                localStorage.removeItem('StoreProduct');
            }
        if(res.data.code== 500) {
            toast.error(res.data.message);
        } else if(data.type !='point'){
            history.push('/user/thank-you');
            localStorage.removeItem('StoreProduct');
        }
                // history.push('/user/rubbishSubscriptions');
             // toast.success("Payment successful!");
            // console.log("DATA API HIT :", res.data);
            // let { id, name, avatar } = jwtDecode(res.data.token);
            // authConfig(res.data.token, res.data.user, history);
            // dispatch({
            //   type: GET_PROFILE,
            //   payload: res.data,
            // });
            // }
        })
        .catch((err) => {
            // const data = err.response.data.error;
            // var res = data.split("validation.custom.messages.");
            // res = res[1];
            // console.log("Login ERR:", res);
            // // var pk = "invalid_login";
            const data = err.response.data.message;

              toast.error(data);
            dispatch(LoadingState(false));
        });
};