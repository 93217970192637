import {GET_ALL_COLLECTIONS, GET_ALL_TRANSACTIONS} from "../actions/DispatchActions";

// START_LOADING
const initialState = {
    allCollection: null,
};

export default function (state = initialState, actions) {
    switch (actions.type) {
        case GET_ALL_COLLECTIONS:
            return { ...state, allCollection: actions.payload };
        default:
            return state;
    }
}
