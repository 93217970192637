import { CLEAR_PACKAGES, GET_ALL_PACKAGES } from "../actions/DispatchActions";

// START_LOADING
const initialState = {
  packages: null,
};

export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_ALL_PACKAGES:
      return { packages: actions.payload };
    case CLEAR_PACKAGES:
      return { packages: null };
    default:
      return state;
  }
}
