import React, { useState } from "react";
import ShopCategoryCard from "../../ShopCard/ShopCategoryCard";
import InfiniteCarousel from "react-leaf-carousel";

function HorizontalScroller({ history }) {
  return (
    <InfiniteCarousel
      breakpoints={[
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ]}
      dots={false}
      showSides={true}
      sidesOpacity={1}
      sideSize={1}
      slidesToScroll={4}
      slidesToShow={4}
      scrollOnDevice={true}
      arrows={true}
    >
      <ShopCategoryCard
        title="Shoew2"
        price="$10"
        img="https://djc49bfkedftq.cloudfront.net/fit-in/248x4096/webApp/market/newLanding/categories/august2020/tile_overdelar.jpg"
      />
      <ShopCategoryCard
        title="Shoew3"
        price="$10"
        img="https://djc49bfkedftq.cloudfront.net/fit-in/248x4096/webApp/market/newLanding/categories/august2020/tile_overdelar.jpg"
      />
      <ShopCategoryCard
        title="Shoew4"
        price="$10"
        img="https://djc49bfkedftq.cloudfront.net/fit-in/248x4096/webApp/market/newLanding/categories/august2020/tile_overdelar.jpg"
      />
      <ShopCategoryCard
        title="Shoew5"
        price="$10"
        img="https://djc49bfkedftq.cloudfront.net/fit-in/248x4096/webApp/market/newLanding/categories/august2020/tile_overdelar.jpg"
      />
      <ShopCategoryCard
        title="Shoew6"
        price="$10"
        img="https://djc49bfkedftq.cloudfront.net/fit-in/248x4096/webApp/market/newLanding/categories/august2020/tile_overdelar.jpg"
      />
      <ShopCategoryCard
        title="Shoew7"
        price="$10"
        img="https://djc49bfkedftq.cloudfront.net/fit-in/248x4096/webApp/market/newLanding/categories/august2020/tile_overdelar.jpg"
      />
      <ShopCategoryCard
        title="Shoew8"
        price="$10"
        img="https://djc49bfkedftq.cloudfront.net/fit-in/248x4096/webApp/market/newLanding/categories/august2020/tile_overdelar.jpg"
      />
      <ShopCategoryCard
        title="Shoew9"
        price="$10"
        img="https://djc49bfkedftq.cloudfront.net/fit-in/248x4096/webApp/market/newLanding/categories/august2020/tile_overdelar.jpg"
      />
      <ShopCategoryCard
        title="Shoew10"
        price="$10"
        img="https://djc49bfkedftq.cloudfront.net/fit-in/248x4096/webApp/market/newLanding/categories/august2020/tile_overdelar.jpg"
      />
    </InfiniteCarousel>
  );
}

export default HorizontalScroller;
