import React, { useEffect, useState } from "react";
import "./ShopCard.css";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import {useDispatch, useSelector} from "react-redux";

import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import {NOT_FOUND_IMAGE, PRODUCT_IMAGE_URL} from "../../../Services/DataApi";
import PaymentFormSingle from "../../MemberShip2/SingleForm";
import PaymentForm from "../../MemberShip2/SingleForm";
import "../Cart/Cart.css";
import {AddPayment, AddStorePayment} from "../../../actions/paymentActions";
import toast from "../../../utils/toast";
import Axios from "../../../Services/Axios";

function CheckOutCard1() {
  const history = useHistory();
    const [openPayment, setOpenPayment] = useState(false);
    const [openDetailForm, setOpenDetailForm] = useState(false);
    const [formPaymentData, setFormPaymentData] = useState([]);
    const [customerCurrPoint, setCustomerCurrPoint] = useState(0);
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const  user= localStorage.getItem("user");

    const totalPrice = () => {
        var totalPrice=0;
        var totalPoints=0;

        if(data){
            for (let i=0; i < data.length; i++){
                totalPoints += data[i].type =="point" ?(data[i].point * data[i].count):0;
                totalPrice += data[i].type !="point" ?(data[i].price * data[i].count):0;
            }

        }
        return {totalPoints:totalPoints,totalPrice:totalPrice};
    };
    const addRemoveFromCart = (id) => {
        let cart = [];
        let newcart = [];

        if (localStorage.getItem('StoreProduct')) {
            cart = JSON.parse(localStorage.getItem('StoreProduct'));

            for(let i = 0;i < cart.length; i++) {
                if(cart[i].id != id) {
                    newcart.push(cart[i]);
                }
            }
        }

        setData(newcart);
        localStorage.setItem('StoreProduct', JSON.stringify(newcart))
    };
    useEffect(() => {

        const StoreProductCart= localStorage.getItem('StoreProduct');
        setData( JSON.parse(StoreProductCart));
        setFormPaymentData({items:JSON.parse(StoreProductCart),user_Id:user})

        Axios.get("/api/users/details/"+user)
            .then((res) => {
                setCustomerCurrPoint(res.data.data.customer_points);
            })
            .catch((err) => {
                const data = err.response.data.error;
                console.log("GET_API_PRODUCT ERR:", data);
                //   dispatch(LoadingState(false));
            });

    }, []);


    const onClickListener = async (e) => {
        e.preventDefault();

        if (user === null) {
            toast.error("You are not logged in");
        } else {
            const sendingData = {
                name:  data.name,
                product_id: data.id,
                // cardNo: cardNo,
                // expMonth: expMonth,
                // expYear: expYear,
                // cvc: cvc,
                amount: data.point,
                webStoreType: 'webStore',
                user_id: user,
                type: 'point',
                items: data
            };

            console.log("Payment data", sendingData);
            dispatch(AddStorePayment(sendingData,history));
        }
    }
  return (
    <div>
        {data && data.map((data) => (
      <div className="CheckOutCard1">
          <img
              src={
                  data.image
                      ? PRODUCT_IMAGE_URL + data.image
                      : NOT_FOUND_IMAGE
              }
              alt=""
              className="checkout-card-img"
              style={{maxWidth:"400px"}}
              style={{maxHeight:"300px"}}

          />
        <div className="checkout-card-NamePrice">
          <h5>{data.name}</h5>

            {data.type =="cash" &&
            <h5>{data.price * data.count} sek</h5>
            }
            {data.type =="point" &&
            <h5>{data.point * data.count}</h5>
            }
          <Button
            variant="outlined"
            color="#fff"
            onClick={() => addRemoveFromCart(data.id)}
            style={{ borderColor: "orangered" }}
          >
            REMOVE
          </Button>
        </div>
      </div>
        ))}
        <>
            {totalPrice().totalPrice != null  && totalPrice().totalPrice != 0 &&
            <div className="bg-light p-3 mt-2 d-flex justify-content-around">
                <div className="h6">
                    Total price checkout for the product:
                </div>
                <div className="h6 ">
                    <strong>{totalPrice().totalPrice} sek</strong>
                </div>
            </div>
            }
            {totalPrice().totalPoints != null && totalPrice().totalPoints != 0 &&
            <div className="bg-light p-3 mt-2 d-flex justify-content-around">
                <div className="h6">
                    Total point checkout for the product:
                </div>
                <div className="h6 ">
                    <strong>{totalPrice().totalPoints}</strong>
                </div>
            </div>
            }
            {totalPrice().totalPoints != null && totalPrice().totalPoints != 0 &&
            <div className="bg-light p-3 mt-2 d-flex justify-content-around">
                <div className="h6">
                Your Total Points :
                </div>
            <div className="h6 ">
                <strong>{customerCurrPoint}</strong>
                </div>
            </div>
            }
         </>
        {totalPrice().totalPrice != null  && totalPrice().totalPrice != 0 && (totalPrice().totalPoints == 0 || totalPrice().totalPoints == null || user )  &&
        <div className="container wrapper mb-5" style={{marginTop:"20px"}}>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-6 mx-auto">
                    <PaymentForm price={totalPrice().totalPrice}  setOpenPayment={setOpenPayment} setOpenDetailForm={setOpenDetailForm} data={formPaymentData}  type={'webStore'} currency={'sek'}   history={history}/>

                </div>
            </div>
        </div>
        }

        {totalPrice().totalPoints != null && totalPrice().totalPoints != 0 && !user ?
            <h6 className=" my-2 bg-light p-4">
                <CreditCardIcon className="check__out__css" /> Herdu in rabbatak ?{" "}
                <a href="javascript:void(0)" className="text-primary"
                onClick={()=>{
                    window.localStorage.setItem('path','/shop/cart')
                    history.push('/login')
                }}
                >
                    Click of logga in?{" "}
                </a>
            </h6>:
            <>
            {totalPrice().totalPrice == 0 && totalPrice().totalPoints != 0 &&
            <div style={{textAlign:'center',marginTop:'20px'}}>
                <button onClick={onClickListener} className="btn btn-primary " style={{text:'center',fontSize:'20px'}}>
                    Redeem</button>
                </div>
                  }
        </>
        }

    </div>


  );
}

export default CheckOutCard1;
