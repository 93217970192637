import {
  GET_ALL_PACKAGES,
  CLEAR_PACKAGES,
  GET_ALL_ZIPCODE, GET_ALL_FAQ,
  CLEAR_ZIPCODE,
} from "./DispatchActions";
import Axios from "../Services/Axios";
import { LoadingState } from "./loaderActions";
import toast from "../utils/toast";

export const getAllZipCode = () => (dispatch) => {
  //   dispatch(LoadingState(true));

  Axios.get("/api/zip-code/all")
    .then((res) => {
      console.log("getAllZipCode :", res.data);
      dispatch({
        type: GET_ALL_ZIPCODE,
        payload: res.data.data,
      });

      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      console.log("Register ERR:", data);
      //   dispatch(LoadingState(false));
    });
};
export const getAllFaqList = () => (dispatch) => {
  //   dispatch(LoadingState(true));

    Axios.get("/api/faq-list")
        .then((res) => {

            dispatch({
                type: GET_ALL_FAQ,
                payload: res.data.data,
            });
        })
        .catch((err) => {
         });
};

export const removeFaqById = (id) => (dispatch) => {
    dispatch(LoadingState(true));
     Axios.get("/api/delete/faq/" + id)
        .then((res) => {
            dispatch(LoadingState(false));
            dispatch(getAllFaqList());
            toast.success("Faq Removed Successfully.");
            console.log("DATA API HIT :", res.data);
        })
        .catch((err) => {
            toast.error("There is Some Error While Removing faq");
            dispatch(LoadingState(false));
        });
};

export const removeTermById = (id) => (dispatch) => {
    dispatch(LoadingState(true));
     Axios.get("/api/delete/term/" + id)
        .then((res) => {
            dispatch(LoadingState(false));
            dispatch(getAllFaqList());
            toast.success("Term Removed Successfully.");
            // console.log("DATA API HIT :", res.data);
        })
        .catch((err) => {
            toast.error("There is Some Error While Removing term");
            dispatch(LoadingState(false));
        });
};

export const AddZipCode = (data) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("Package ACTION DATA", data);
  Axios.post("/api/zip-code/add", data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllZipCode());
      if (res.status === 200) {
        toast.success("Zip Code Added successfully.");
        console.log("DATA API HIT :", res.data);
      }
    })
    .catch((err) => {
      // const data = err.response.data.error;
      // var res = data.split("validation.custom.messages.");
      // res = res[1];
      // console.log("Login ERR:", res);
      // // var pk = "invalid_login";

      // console.log("Messgas", Message.validation.custom.messages[res]);
      // const Error = Message.validation.custom.messages[res];
      toast.error("There is Some Error While Adding Zip Code");
      dispatch(LoadingState(false));
    });
};

export const updateZipCode = (data, id) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("ZipCode ACTION DATA", data);
  Axios.post("/api/zip-code/edit/" + id, data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllZipCode());
      toast.success("Zip Code Updated Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Updating Zip Code");
      dispatch(LoadingState(false));
      console.log("DATA API HIT :", err.response.data);
    });
};

export const removeZipCode = (id) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("ZipCode ACTION DATA", id);
  Axios.get("/api/zip-code/delete/" + id)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllZipCode());
      toast.success("Zip Code Removed Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Removing Zip Code");
      dispatch(LoadingState(false));
    });
};

export const clearZipCode = () => (dispatch) => {
  console.log("ACTION clearZipCode ");
  dispatch({
    type: CLEAR_ZIPCODE,
  });
};
