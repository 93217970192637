import React from "react";
import FooterShop from "../Common/FooterShop";
import ShopNavbar2 from "../Common/ShopNavbar2";
import styles from "../Css/Business.module.css";
import ShopNavbar from "../HeaderShop/ShopNavbar";
import UpdateIcon from "@material-ui/icons/Update";
import { Parent_Color } from "../../../Common/Color";
function Business() {
  return (
    <div className={styles.main+' fortge_page'}>
      <ShopNavbar2 />

      <h1 style={{marginTop:0,paddingTop:130}}>FÖRETAG</h1>

      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 p-5 text-center">
          <div className="d-flex flex-row justify-content-center">
            <h3>Flexibla lösningar</h3>
            <UpdateIcon style={{ color: "#000", fontSize: "2rem" }} />
          </div>
          <h5 className={styles.h5_busnies}>
            Vi erbjuder många alternativ för hantering av sopor och avfall till
            företag och organisationer. Vårt erbjudande innehåller både
            traditionella och innovativa lösningar. Kontakta oss för att
            skräddarsy vår hantering efter era behov. Inget uppdrag är för litet
            eller stort.
          </h5>

          <div className="d-flex flex-row justify-content-center mt-5">
            <h3>Rabattavtal för anställda</h3>
            <UpdateIcon style={{ color: "#000", fontSize: "2rem" }} />
          </div>
          <h5 className={styles.h5_busnies}>
            Vill ni visa uppskattning till era anställda och erbjuda en
            attraktiv personalförmån? Många hushåll i Stockholm saknar möjlighet
            till källsortering i sin fastighet. Vi hämtar och sorterar
            anställdas vardagliga återvinning. Kontakta oss om ni vill veta mer.
          </h5>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <form className={styles.formBusiness} action="">
            <div className="form-group has-search d-flex justify-content-center">
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                color="#a4a4a4"
              />
            </div>
            <div className="form-group has-search d-flex justify-content-center">
              <input
                type="text"
                className="form-control"
                placeholder="Telefone"
                color="#a4a4a4"
              />
            </div>

            <div className="form-group has-search d-flex justify-content-center">
              <input
                type="text"
                className="form-control"
                placeholder="E-Post"
                color="#a4a4a4"
              />
            </div>

            <div className="form-group has-search d-flex justify-content-center">
              <textarea
                type="text"
                className="form-control"
                placeholder="Name"
                color="#a4a4a4"
                rows={4}
              />
            </div>
            <div
              className="btn btn-lg w-100"
              style={{ backgroundColor: Parent_Color }}
            >
              Skicka
            </div>
          </form>
        </div>
      </div>

      <div className={styles.SectionTwo} id="foreningar">
        <h1>Föreningar</h1>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <form className={styles.formBusiness} action="">
              <div className="form-group has-search d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  color="#a4a4a4"
                />
              </div>
              <div className="form-group has-search d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Telefone"
                  color="#a4a4a4"
                />
              </div>

              <div className="form-group has-search d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="E-Post"
                  color="#a4a4a4"
                />
              </div>

              <div className="form-group has-search d-flex justify-content-center">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  color="#a4a4a4"
                  rows={4}
                />
              </div>
              <div
                className="btn btn-lg w-100"
                style={{ backgroundColor: Parent_Color }}
              >
                Skicka
              </div>
            </form>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 py-2 px-5 text-center padding_left_right">
            <div className={styles.bgWhiteEffect}>
              <div className="d-flex flex-row justify-content-center mt-2">
                <h3>Vi har något för alla</h3>
                <UpdateIcon style={{ color: "#000", fontSize: "2rem" }} />
              </div>
              <h6>
                Vi har lösningar för alla fastigheter. Det gäller oavsett om ni
                har ett befintligt soprum för källsortering eller ej. Det ska
                vara enkelt och skönt att återvinna. Vårt erbjudande innehåller
                både traditionella och innovativa lösningar. Kontakta gärna oss
                för en gratis second opinion av eran nuvarande hantering.
              </h6>
            </div>
            <div className="mt-2">
              <div className={styles.bgWhiteEffect}>
                <div className="d-flex flex-row justify-content-center mt-3">
                  <h3>Rabattavtal till föreningar</h3>
                  <UpdateIcon style={{ color: "#000", fontSize: "2rem" }} />
                </div>
                <h6>
                  Är ni flera miljömedvetna grannar i eran förening och är
                  intresserade av en skön hantering av källsortering? Vi erbjuder
                  en mängdrabbatt om ni är minst 10 st i samma fastighet som
                  önskar att loopa med hämtning vid dörren.
              </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterShop />
    </div>
  );
}

export default Business;
