import React, { useEffect, useState } from "react";
import "../ProductDetail/ProductDetail.css";
import { Parent_Strip_Color } from "../../../Common/Color";
import ShopNavbar from "../HeaderShop/ShopNavbar";
import Footer from "../../../Common/Footer/Footer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

import { useHistory,Redirect } from "react-router-dom";
import PaymentForm from "../../MemberShip2/SingleForm";
import ShopNavbar2 from "../Common/ShopNavbar2";
import FooterShop from "../Common/FooterShop";
 import { getRubbishProductByID } from "../../../actions/productAction";
import { NOT_FOUND_IMAGE, PRODUCT_IMAGE_URL ,BULKRUBBISH_PRODUCT_IMAGE_URL} from "../../../Services/DataApi";
import PaymentFormSingle from "../../MemberShip2/SingleForm";
import  Autocomplete  from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CartDetails from "../../CartDrawer/CartDetails";
import RubbishCardDetail from "../../CartDrawer/RubbishCardDetail";

const drawerWidth = 330;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    title: {
        flexGrow: 1,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: "flex",

        padding: theme.spacing(2, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
}));

function RubbishProductDetail(props) {
    const history = useHistory();
    const user = localStorage.getItem("user");

    // if(!user || user === "") {
    //     history.push("/login");
    // }

    const classes = useStyles();
    const { id } = props.match.params;
    const [productDetails, setproductDetails] = useState("");
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [data, setData] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [isFormSubmit, setIsFormSubmit] = useState(0);
    const [pickUpAddress, setPickUpAddress] = useState('');
    const [pickUpZip, setPickUpZip] = useState('');
    const [pickUpDistrict, setPickUpDistrict] = useState('');
    const [collectionPhone, setCollectionPhone] = useState('');
    const [collectionPostCode, setCollectionPostCode] = useState('');
    const [collectionInstructions, setCollectionInstructions] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [pickupTimeFrom, setPickupTimeFrom] = useState('');
    const [pickupTimeTo, setPickupTimeTo] = useState('');
    const [pickupType, setPickupType] = useState('outside_door');
    const [timeOptions, setTimeOptions] = useState([]);
    const [openPayment, setOpenPayment] = useState(false);
    const [formPaymentData, setFormPaymentData] = useState([]);
    const [openDetailForm, setOpenDetailForm] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [cartItems, setCartItems] = React.useState([]);

    const handleDrawerOpen = () => {
        setOpenDetailForm(false);
        localStorage.setItem("StoreBulkyRubbishProductID", productDetails.brp_id);
        addToCart(productDetails.brp_id,productDetails.brp_name,productDetails.brp_points,productDetails.price,productDetails.brp_img)
        setOpenDrawer(true);
    };
    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };
    const addToCart = (id,name,point,price,image) => {
        // if (localStorage.getItem('StoreProduct')) {
        //     cart = JSON.parse(localStorage.getItem('StoreProduct'));
        // }
        // cart[id] = (cart[id] || 0) + 1;
        // localStorage.setItem('StoreProduct', JSON.stringify(cart));
        let cart = [];
        let itemToAdd = {
            id,
            count: 1,
            name:name,
            price:price,
            point:point,
            type:'cash',
            image:image,

        };
        if (localStorage.getItem('StoreBulkyRubbishProduct')) {
            cart = JSON.parse(localStorage.getItem('StoreBulkyRubbishProduct'));
            let item = cart.find(el => el.id === id);
            if (!item) {
                cart.push(itemToAdd);
            } else {
                ++ item.count;
            }
        } else {
            cart = [itemToAdd];
        }
        setCartItems(cart)
        localStorage.setItem('StoreBulkyRubbishProduct', JSON.stringify(cart))
        console.log(localStorage.getItem('StoreBulkyRubbishProduct'))
    };
console.log(pickUpZip)
    const toCart = (e) => {
        setIsFormSubmit(1);
        mySubmitHandler(e);
         // history.push("/shop/cart");
    };

    const mySubmitHandler = (event) => {
        event.preventDefault();

        if(pickUpAddress!='' && pickUpZip!='' && pickUpDistrict!='' && collectionPhone!='' && collectionPostCode!='' && collectionInstructions!='') {

            let formValues = {
                pickUpAddress: pickUpAddress,
                pickUpZip: pickUpZip,
                pickUpDistrict: pickUpDistrict,
                collectionPhone: collectionPhone,
                email: email,
                userName: userName,
                postCode: collectionPostCode,
                instructions: collectionInstructions,
                pickupDate: pickupDate,
                pickupTimeFrom: pickupTimeFrom,
                pickupTimeTo: pickupTimeTo,
                product_id: productDetails.brp_id,
                pickUpType: pickupType
            };
            setFormPaymentData(formValues)
            const user = localStorage.getItem("user");

            if(!user || user === "") {
                history.push("/login");
            }else{
                setOpenPayment(true)
                // setOpenDetailForm(true)

            }

         }
    };

    useEffect(() => {
        getRubbishProductByID(id).then((res) => {

            setproductDetails(res.data.data);
           setZipCode(res.data.zipCode);
            setData(productDetails);
        });

        let options = [];
        let index = ':00';
        let indexD = 0;
        for(let i = 0;i <= 48;i++) {
            options.push(indexD + index);
            if(index == ':00') {
                index = ':30';
            } else {
                indexD++;
                index = ':00';
            }
        }
        setTimeOptions(options);
    }, [getRubbishProductByID]);

    return (
        <div className=" bg-white">
            <ShopNavbar2 />
            {open == false &&

            <div className="container">
                <div className="Section_ProductDetail my-2" style={{paddingTop:140}}>
                    <div className="row">
                        <div className="col-lg-6 d-flex justify-content-center align-content-center ">
                            <img
                                src={
                                    productDetails.brp_img
                                        ? BULKRUBBISH_PRODUCT_IMAGE_URL + productDetails.brp_img
                                        : NOT_FOUND_IMAGE
                                }
                                alt=""
                                className="img-fluid product_detail_img"
                                style={{maxWidth:"400px"}}
                            />
                        </div>
                        <div className="col-lg-6 p-5">

                            <div className="h1 home_page_heading">{productDetails?.brp_name}</div>
                            {/*<div class   Name="h6 textGrey mt-3">by Philip Hog Stackholm</div>*/}
                            <div className="h4  mt-3 home_section_para">
                                {productDetails?.price} {productDetails.currency}
                            </div>
                            <div className="h5  mt-3 home_section_para">SIZE </div>
                            <div className="row" style={{marginLeft:"-10px"}}>
                                <div className="col-sm-4 col-6">
                                    <div className="h5 m-0 border text-center py-2"> {productDetails?.brp_size}</div>
                                </div>

                                    <div className="col-sm-4 col-6">
                                        <Button
                                            className="add_cart_btn"
                                            variant="contained"
                                            color="#000"
                                            onClick={handleDrawerOpen}
                                            style={{
                                                backgroundColor: "#000",
                                                color: "#fff",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            ADD TO CART
                                        </Button>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {openDetailForm == true &&
             <div style={{marginTop: 50}} className="container wrapper mb-5">
                <h1 className="text-center my-4">Rubbish Product</h1>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                        <form>

                            <div className="input-data my-4">
                                <input type="text" required onChange={(e) => {
                                    setUserName(e.target.value);
                                }}/>
                                <div className="underline"></div>
                                <label>Username</label>
                            </div>
                            {isFormSubmit == 1 && userName == ""
                            && <label className="row mx-auto">This field is required</label>}
                            <div className="input-data my-4">
                                <input type="text" required onChange={(e) => {
                                    setEmail(e.target.value);
                                }}/>
                                <div className="underline"></div>
                                <label>Email</label>
                            </div>
                            {isFormSubmit == 1 && email == ""
                            && <label className="row mx-auto">This field is required</label>}
                            <div className="input-data">
                                <input type="text" required onChange={(e) => {
                                    setPickUpAddress(e.target.value);
                                }}/>

                                <div className="underline"></div>
                                <label>ADDRESS</label>
                            </div>
                            {isFormSubmit == 1 && pickUpAddress == ""
                            && <label className="row mx-auto">This field is required</label>}
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12 my-4" style={{paddingLeft: 0}}>

                                    <Autocomplete
                                        options={zipCode}
                                        label
                                        getOptionLabel={(option) => option.zip_code}
                                        style={{ width: 600 }}
                                        clearOnEscape
                                        onChange={(event, newValue) => {
                                            setPickUpZip(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Zip Code" variant="outlined" />}
                                    />
                                    {/*<div className="input-data">*/}
                                        {/*<select required className="form-control" style={{*/}
                                            {/*paddingLeft: 3,*/}
                                            {/*borderTop: 0,*/}
                                            {/*borderLeft: 0,*/}
                                            {/*borderRight: 0,*/}
                                            {/*borderBottom: '1.5px solid grey',*/}
                                            {/*marginTop: 5*/}
                                        {/*}} onChange={(e) => {*/}
                                            {/*setPickUpZip(e.target.value);*/}
                                        {/*}}>*/}
                                            {/*<option value="">SELECT ZIP CODE</option>*/}
                                            {/*{zipCode && zipCode.map((data)=>(*/}
                                            {/*<option value={data.zip_id}>{data.zip_code}</option>*/}
                                            {/*))}*/}
                                        {/*</select>*/}
                                    {/*</div>*/}


                                    {isFormSubmit == 1 && pickUpZip == "" &&
                                    <label className="row mx-auto">This field is required</label>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-12 my-4" style={{paddingRight: 0}}>
                                    <div className="input-data">
                                        <input type="text" required onChange={(e) => {
                                            setPickUpDistrict(e.target.value);
                                        }}/>
                                        <div className="underline"></div>
                                        <label>DISTRICT</label>
                                    </div>
                                    {isFormSubmit == 1 && pickUpDistrict == "" &&
                                    <label className="row mx-auto">This field is required</label>}
                                </div>
                            </div>

                            <div className="input-data my-4">
                                <input type="number" required onChange={(e) => {
                                    setCollectionPhone(e.target.value);
                                }}/>
                                <div className="underline"></div>
                                <label>CELL PHONE</label>
                            </div>


                            {isFormSubmit == 1 && collectionPhone == "" &&
                            <label className="row mx-auto">This field is required</label>}
                            <div className="input-data my-4">
                                <input type="text" required onChange={(e) => {
                                    setCollectionPostCode(e.target.value);
                                }}/>
                                <div className="underline"></div>
                                <label>POST CODE</label>
                            </div>
                            {isFormSubmit == 1 && collectionPostCode == "" &&
                            <label className="row mx-auto">This field is required</label>}
                            <div className="input-data my-4">
                                <input type="text" required onChange={(e) => {
                                    setCollectionInstructions(e.target.value);
                                }}/>
                                <div className="underline"></div>
                                <label>INSTRUCTION TO DRIVER</label>
                            </div>

                            {isFormSubmit == 1 && collectionInstructions == "" &&
                            <label className="row mx-auto">This field is required</label>}
                            <h6 className="my-2">Choose Pick-Up Day</h6>
                            <div className="col-lg-4 col-sm-4">
                                <div className="input-data">
                                    <input type="date" name="pick" id="pick" onChange={(e) => {
                                        setPickupDate(e.target.value);
                                    }} className="pic____css"/>
                                </div>
                            </div>

                            <div className="col-lg-4  col-sm-4">
                                <div className="input-data">
                                    <select className="form-control" onChange={(e) => {
                                        setPickupTimeFrom(e.target.value);
                                    }}>
                                        <option>Select Time From</option>
                                        {timeOptions && timeOptions.map((data) => (
                                            <option value={data}>{data}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-4">
                                <div className="input-data">
                                    <select className="form-control" onChange={(e) => {
                                        setPickupTimeTo(e.target.value);
                                    }}>
                                        <option>Select Time To</option>
                                        {timeOptions && timeOptions.map((data) => (
                                            <option value={data}>{data}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="d-flex justify-content-left">
                        <div className="col-lg-4 col-md-6 col-8">
                            <Button type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="payment__btn"
                                    onClick={(e) => {
                                       toCart(e)
                                    }}>
                                GO TO PAYMENT
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            }

            {openPayment && openPayment == true  &&
            <div className="container wrapper mb-5">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-6 mx-auto">
                        <PaymentForm price={productDetails?.brp_points}  setOpenPayment={setOpenPayment} setOpenDetailForm={setOpenDetailForm} data={formPaymentData}  type={'isBulkRubbishOrder'} currency={productDetails.currency}   history={history}/>

                    </div>
                </div>
            </div>
            }
            <FooterShop />
            <Drawer
                className="bg-light"
                variant="persistent"
                anchor="right"
                open={openDrawer}

                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className="bg-light">
                    <CloseIcon
                        onClick={handleDrawerClose}
                        className="close__btn__css mt-2 "
                    />
                </div>

                <RubbishCardDetail cartItems={cartItems} setOpenDrawer={setOpenDrawer} setOpenDetailForm={setOpenDetailForm}/>
            </Drawer>
        </div>
    );
}

export default RubbishProductDetail;
