import {React,useState,useEffect} from "react";
import FooterShop from "../WebShop/Common/FooterShop";
import ShopNavbar2 from "../WebShop/Common/ShopNavbar2";
import styles from "../WebShop/Css/Business.module.css";
import UpdateIcon from "@material-ui/icons/Update";
import { Parent_Color } from "../../Common/Color";
import './Contact.css';
import { NavLink } from "react-router-dom";
import Axios from "../../Services/Axios";

import {updatePackage} from "../../actions/packageActions";
import Loader from "react-loader-spinner";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios/index";
import {LoadingState} from "../../actions/loaderActions";
import toast from "../../utils/toast";

function ContactPage({history}) {
    const [name, setname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [description, setdescription] = useState('');
    const {loading} = useSelector((state) => state.loaderReducer);
    const [isFormSubmit, setIsFormSubmit] = useState(0);
    const dispatch = useDispatch();

    const [faqCat, setFaqCat] = useState([]);

    const onClickListener = () => {
      console.log('sdadas')
        setIsFormSubmit(1)
        if(name!='' &&  email!='' && phone!='' && description!='' ) {
            const data2 = {
                name: name,
                email: email,
                phone:phone,
                description: description,

            };
            // console.log("SEND DATA", data2, data.pkg_id);
            Axios.post("/api/user/info",data2)
                .then((res) => {
                    toast.success("Thank you");
                    history.push('/');

                    dispatch(LoadingState(false));
                })
                .catch((err) => {
                });
        }

    };

    useEffect(() => {
        Axios.get("/api/faq/categories")
            .then((res) => {
                if(res.data.data.length > 0) {
                    setFaqCat(res.data.data)
                }

                dispatch(LoadingState(false));
            })
            .catch((err) => {

                // dispatch(LoadingState(false));
            });

    },[]);
// <p>{faqCatItem.description}</p>
    return (
    <div className={styles.main+' fortge_page contactPage'}>
      <ShopNavbar2 />

      <div className="banner">
          <h1 style={{fontWeight:400}}>CONTACT</h1>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 p-5 text-center">
            <div>
              <h3 style={{textAlign:'left'}}>Flexibla lösningar</h3>
            </div>
            <h5 className={styles.h5_busnies} style={{padding:0}}>
              Vi erbjuder många alternativ för hantering av sopor och avfall till
              företag och organisationer. Vårt erbjudande innehåller både
              traditionella och innovativa lösningar. Kontakta oss för att
              skräddarsy vår hantering efter era behov. Inget uppdrag är för litet
              eller stort.
            </h5>
            <h5 className={styles.h5_busnies} style={{padding:0}}>
              Vill ni visa uppskattning till era anställda och erbjuda en
              attraktiv personalförmån? 
            </h5>
            <div className="btn btn-lg w-100" style={{backgroundColor: "#f6568c", color:'#fff'}}>Skicka</div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <form className={styles.formBusiness} action="">
              <div className="form-group has-search d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  color="#a4a4a4"
                  onChange={(e) => setname(e.target.value)}

                />
              </div>
                {isFormSubmit == 1 && name == "" && <label className="row mx-auto" style={{color: "red"}}
                >This field is required</label>}
              <div className="form-group has-search d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Telefone"
                  color="#a4a4a4"
                  onChange={(e) => setPhone(e.target.value)}

                />
              </div>
                {isFormSubmit == 1 && phone == "" && <label className="row mx-auto" style={{color: "red"}}
                >This field is required</label>}

              <div className="form-group has-search d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="E-Post"
                  color="#a4a4a4"
                  onChange={(e) => setEmail(e.target.value)}

                />
              </div>
                {isFormSubmit == 1 && email == "" && <label className="row mx-auto" style={{color: "red"}}
                >This field is required</label>}

              <div className="form-group has-search d-flex justify-content-center">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Description"
                  color="#a4a4a4"
                  rows={4}
                  onChange={(e) => setdescription(e.target.value)}

                />
              </div>
                {isFormSubmit == 1 && description == "" && <label className="row mx-auto" style={{color: "red"}}
                >This field is required</label>}
                  <button
                      type="button"
                      className="btn btn-lg w-100 btn-primary "
                      onClick={onClickListener}
                      style={{ backgroundColor: '#f6568c', borderColor:'#f6568c',color:'#fff' }}
                  >
                      Skicka

                    <Loader
                          type="Puff"
                          color="#FFF"
                          height={20}
                          width={15}
                          style={{marginLeft: 5}}
                          visible={loading}
                      />
                  </button>
            </form>
          </div>
        </div>
      </div>
      <div className="faqListWrap">
        <div className="container">
        <div className="contentWrap">
          <h2 className="listHeading">Vanliga frågor och svar</h2>
          <ul className="list-unstyled">

              {faqCat && faqCat.map((faqCatItem) => (
                  <li>
                      <NavLink to={"/faq/detail/"+faqCatItem.id}>
                          <div className="iconWrap">
                              <span className="icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" ariaHidden="true" className="v-icon__svg" style={{fontSize:36,height:36,width:36}}><path d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z"></path></svg>
                              </span>
                          </div>
                          <div>
                              <h3>{faqCatItem.category_name}</h3>

                          </div>
                      </NavLink>
                  </li>
              ))}
          </ul>
        </div>
        </div>
      </div>

      <FooterShop />
    </div>
  );
}

export default ContactPage;
