import React, { useState } from "react";
import "./member.css";
import Tabss from "./Tabss";
import PaymentForm from './form';

import Button from "@material-ui/core/Button";
import ShopNavbar2 from "../../Modules/WebShop/Common/ShopNavbar2";
import FooterShop from "../../Modules/WebShop/Common/FooterShop";



const MemberShipRelax = ({ history }) => {

    // const { payments } = useSelector((state) => state.recycleReducer);

    // const stripePromise = loadStripe('pk_test_51IXfK6HVjYUffmJdp2R3nYu2MouPuYZe3xHB1aDuBQ46v5JrrsSmySqrlaqLBD4QvTQ2qQNIzKf9gm3HjHRShPnw00ngvBIPat');


    return (
        <div>
            <ShopNavbar2 />
            <div className="container">
                <div className="row gx-5">
                    <div className="col-lg-6 col-md-6 col-12 mx-auto mt-3 first_div_a d-flex justify-content-center align-items-center flex-column">
                        <h1 className="text-center my-5 h1____css">Relax</h1>
                        <h3 className="text-center my-4">
                            Collextion every second week,
              <br /> 89 kr per pick-up.{" "}
                        </h3>
                        <Button variant="contained" color="primary">
                            read more
            </Button>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mx-auto mt-3 first_div1 d-flex justify-content-center align-items-center flex-column">
                        <Button variant="contained" color="primary">
                            change
            </Button>
                        <br />
                        <div className="xyz">
                            <Button variant="contained" color="primary">
                                pause
              </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className="secons____btnnn"
                            >
                                end
              </Button>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className="text-center my-3">Recycling bag</h1>
            <div className="col-4 mx-auto">
                <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <Tabss className="tab__css" />
                </div>
            </div>
            <p className="text-center my-2">
                Here is a text about what it means to have a bag that is 80 liters or
                120 liters.
        <br /> Type comparison measure so customer can get a sense of
        <br /> what the different sizes mean. When you change, you get the new
        size at <br />
        the next pick-up!
      </p>
            <h1 className="text-center my-4">Payment option</h1>

            <div class="content py-2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <span class="anchor" id="formPayment"></span>
                            <hr class="my-5" />

                            <div class="card card-outline-secondary">
                                <div class="card-body">
                                    <h3 class="text-center">Credit Card Payment</h3>
                                    <hr />
                                    <div class="alert alert-info p-2 pb-3">
                                        <a
                                            class="close font-weight-normal initialism"
                                            data-dismiss="alert"
                                            href="#"
                                        >
                                            <samp>×</samp>
                                        </a>
                    CVC code is required.
                  </div>
                                    <PaymentForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container mb-5">
                <h1 className="text-center my-5">Payment history</h1>
                <div className="col-12 mx-auto">
                    <table className="table table-condensed">
                        <thead className="bg-dark text-white text-center">
                            <tr>
                                <th>DATE</th>
                                <th>DESCRIPTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-center">
                                <td>15 aug 2020</td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-primary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Standard
                    </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <a className="dropdown-item" href="#">
                                                Action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Another action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Something else here
                      </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td>5 aug 2019</td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-primary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            pick-up
                    </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <a className="dropdown-item" href="#">
                                                Action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Another action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Something else here
                      </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td>18 july 2020</td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-primary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Bulke-y
                    </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <a className="dropdown-item" href="#">
                                                Action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Another action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Something else here
                      </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h1 class="text-center my-4">
                    Get a free pick-up for every recruited member!
        </h1>
                <div class="col-11 mx-auto">
                    <div class="col-lg-4 col-md-6 col-11 mx-auto">
                        <div class="d-flex justify-content-center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => history.push("/invite")}
                            >
                                Invite Friends and neighbors
              </Button>
                        </div>
                    </div>
                </div>
            </div>
            <FooterShop />
        </div>
    );
};

export default MemberShipRelax;
