import React from "react";
import styles from "../../Css/TwoSide.module.css";
import Grosoper from "../../../../assets/images/grosoper.png";
import WebShop from "../../../../assets/images/shop.png";
import Rectangle from "../../../../assets/Rectangle.png";
import { Link } from 'react-router-dom';

function TwoSide() {
  return (
    <div className={styles.main}>
      <div className="container">
        <div className={styles.heading+' home_page_heading'}>Loopa allt som loopas kan</div>
        <div className="row mt-3">
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <div className={styles.heading1+' home_page_heading'}>Hämtning av grovsopor.</div>
            <div className="d-flex justify-content-center">
              <Link to="/new-shop/bulk-rubbish">
                <img src={Grosoper} alt="" className={styles.ImageClass} />
              </Link>
            </div>
            <div className="d-flex justify-content-center">
              <img
                className={styles.RectangleImageSmall+' home_rectangle_img'}
                src={Rectangle}
                alt=""
              />
            </div>
            <div className={styles.paraGraphSmall+' home_section_para'}>
              Behöver du hjälp med att slänga din gamla soffa eller annat som
              ska till tippen? Beställ hämtning av grovsopor till fasta priser.
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 border-rightt ">
            <div className={styles.heading1}>För en cirkulär ekonomi.</div>
            <div className="d-flex justify-content-center">
              <Link to="/new-shop/shop">
                <img src={WebShop} alt="" className={styles.ImageClass} />
              </Link>
            </div>
            <div className="d-flex justify-content-center">
              <img
                className={styles.RectangleImageSmall}
                src={Rectangle}
                alt=""
              />
            </div>
            <div className={styles.paraGraphSmall}>
              Dom saker vi tror kan hitta en ny ägare hittar ni i vår webshop.
              Här finns också våra egna produkter skapade av våra medlemmars
              skräp.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwoSide;
