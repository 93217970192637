import React from "react";
import "./Page.css";
import { Parent_ShopCard_Btn_Color } from "../../../Common/Color";
import Card2 from "../Card2";
import Card3 from "../Card3";

import ShopNavbar from "../../WebShop/HeaderShop/ShopNavbar";
import Footer from "../../../Common/Footer/Footer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import CartDetails from "../../CartDrawer/CartDetails";
import BulkCartDetails from "../BulkRubbishCart/BulkCartDetails";
import Tooltip from "@material-ui/core/Tooltip";
const drawerWidth = 330;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",

    padding: theme.spacing(2, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));
function Page() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ShopNavbar />
      <div className="container my-5">
        <div className="row mt-2">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Card2 openDrawer={handleDrawerOpen} />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Card2 openDrawer={handleDrawerOpen} />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Card2 openDrawer={handleDrawerOpen} />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Card2 openDrawer={handleDrawerOpen} />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Card2 openDrawer={handleDrawerOpen} />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Card2 openDrawer={handleDrawerOpen} />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Card3 openDrawer={handleDrawerOpen} />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Card3 openDrawer={handleDrawerOpen} />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Card3 openDrawer={handleDrawerOpen} />
          </div>
        </div>
      </div>
      <Footer />
      <Drawer
        className="bg-light"
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className="bg-light">
          <Tooltip title="Close">
            <CloseIcon
              onClick={handleDrawerClose}
              className="close__btn__css mt-2 "
            />
          </Tooltip>
        </div>

        <BulkCartDetails />
      </Drawer>
    </div>
  );
}

export default Page;
