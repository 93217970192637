import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useDispatch, useSelector } from "react-redux";
import { addBulkRubbishProduct } from "../../../../actions/productAction";
import {
  clearCategories,
  getAllBulkRubbishCategories,
} from "../../../../actions/categoryAction";

const Add_BulkRubbish = () => {
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.categoryReducer);

  const initialState = "";
  const [size, setsize] = useState(initialState);

  const [name, setname] = useState(initialState);
  const [image, setimage] = useState(initialState);
  const [price, setprice] = useState(initialState);
  const [productPrice, setProductPrice] = useState(initialState);
  const [status, setstatus] = useState(initialState);
  const [categoryProduct, setcategory] = useState(initialState);
  const [productDescription, setproductDescription] = useState(initialState);
    const [isFormSubmit, setIsFormSubmit] = useState(0);

  const onClickListener = () => {

      if(name!='' && price!='' && image!='' && size!='' && status!='' && productDescription!='' && categoryProduct!='') {

          const formData = new FormData();
          formData.append("brp_name", name);
          formData.append("brp_desc", productDescription);
          formData.append("brp_points", price);
          formData.append("brp_price", productPrice);
          formData.append("brp_img[]", image);
          formData.append("brp_size", size);
          formData.append("brp_active", status);
          formData.append("brp_brc_id", categoryProduct);

          console.log("Add Product Data", formData);
          dispatch(addBulkRubbishProduct(formData));
      }
  };

  useEffect(() => {
    if (Array.isArray(category) && category.length > 0) {
    } else {
      dispatch(getAllBulkRubbishCategories());
    }
  }, [dispatch, category]);

  useEffect(() => {
    return () => {
      dispatch(clearCategories());
      console.log("clearPackage");
    };
  }, [dispatch]);

  return (
    <div className="container p-2">
      <div className="container card p-5 add-products-container">
        <h2 className="text-center mb-5">Add BulkRubbish Products</h2>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 mx-auto mt-2">
            <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product Name
              </span>
              <input
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
              />
            </div>
              {isFormSubmit == 1 && name=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
            <div className="mb-3 mt-2">
              <input
                className="form-control"
                type="file"
                id="formFile"
                onChange={(e) => setimage(e.target.files[0])}
              />
            </div>
              {isFormSubmit == 1 && image=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}

            <div className="input-group mb-3 ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product Points
              </span>
              <input
                type="number"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setprice(e.target.value)}
              />

            </div>
              {isFormSubmit == 1 && price=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
              <div className="input-group mb-3 ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product Price
              </span>
                  <input
                      type="number"
                      className="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setProductPrice(e.target.value)}
                  />
              </div>
              {isFormSubmit == 1 && productPrice=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
            <lebel>Status : </lebel>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setstatus(e.target.value)}
            >
              <option disabled selected>select type</option>
              <option value="0">Not Active</option>
              <option value="1">Active</option>
            </select>

            <br />
          </div>
          <div className="col-lg-6 col-md-6 col-12 mx-auto mt-2">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setsize(e.target.value)}
            >
              <option disabled  selected>Size</option>
              <option value="small">Small</option>
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="extra-small">Extra Small</option>
              <option value="extra-large">Extra Large</option>
              {/* <option value="3">six</option> */}
            </select>

            <br />

            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setcategory(e.target.value)}
            >
              <option  disabled  selected>Select Catagory</option>

              {Array.isArray(category) && category.length > 0
                ? category.map((data, id) => (
                    <option key={id} value={data.brc_id}>
                      {data.brc_name}
                    </option>
                  ))
                : null}
            </select>

            <br />
            <div className="mb-3">
              <label for="exampleFormControlTextarea1" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                onChange={(e) => setproductDescription(e.target.value)}
              ></textarea>
            </div>
              {isFormSubmit == 1 && productDescription=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
          </div>
        </div>
        <div className=" mx-auto mt-3">
          <button
            type="button"
            class="btn btn-primary "
            onClick={()=>{
                onClickListener();

                setIsFormSubmit(1);
            }}>
            Add BulkRubbish
          </button>
        </div>
      </div>
    </div>
  );
};

export default Add_BulkRubbish;
