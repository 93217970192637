import {
    GET_ALL_PACKAGES,
    CLEAR_PACKAGES,
    GET_ALL_CATEGORIES,
    CLEAR_CATEGORIES, GET_ALL_STORE_CATEGORY,
    GET_ALL_BULKY_RUBBISH_CATEGORIES,
} from "./DispatchActions";
import Axios from "../Services/Axios";
import { LoadingState } from "./loaderActions";
import toast from "../utils/toast";

//---------------------------- Product Category Actions---------------------------
//---------------------------- Product Category Actions---------------------------
//---------------------------- Product Category Actions---------------------------
//---------------------------- Product Category Actions---------------------------

export const getAllCategories = () => (dispatch) => {
  //   dispatch(LoadingState(true));

  Axios.get("api/product-categories/all")
    .then((res) => {
      console.log("getStoreAllCategories :", res.data);
      dispatch({
        type: GET_ALL_CATEGORIES,
        payload: res.data.data,
      });

      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      console.log("Register ERR:", data);
      //   dispatch(LoadingState(false));
    });
};
export const getAllStoreProductCategories = () => (dispatch) => {
  //   dispatch(LoadingState(true));

  Axios.get("api/store-product-categories/all")
    .then((res) => {
      console.log("getAllCategories :", res.data);
      dispatch({
        type: GET_ALL_STORE_CATEGORY,
        payload: res.data.data,
      });

      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      console.log("Register ERR:", data);
      //   dispatch(LoadingState(false));
    });
};

export const getAllActiveCategories = () => (dispatch) => {
  //   dispatch(LoadingState(true));

  Axios.get("api/product-categories/active")
    .then((res) => {
      console.log("getAllActiveCategories :", res.data);
      dispatch({
        type: GET_ALL_CATEGORIES,
        payload: res.data.data,
      });

      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      console.log("Register ERR:", data);
      //   dispatch(LoadingState(false));
    });
};

export const AddCategory = (data) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("AddCategory ACTION DATA", data);
  Axios.post("/api/product-category/add", data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllCategories());
      // if (res.status === 200) {
      toast.success("Category Added successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Adding Category");
      dispatch(LoadingState(false));
    });
};

export const updateCategory = (data, id) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("updateCategory ACTION DATA", data);
  Axios.post("/api/product-category/edit/" + id, data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllCategories());
      toast.success("Category Updated Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Updating Category");
      dispatch(LoadingState(false));
    });
};

export const removeCategory = (id) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("removeCategory ACTION DATA", id);
  Axios.get("/api/product-category/delete/" + id)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllCategories());
      toast.success("Category Removed Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Removing Category");
      dispatch(LoadingState(false));
    });
};

//---------------------------- Bulky Rubbish Category Actions---------------------------
//---------------------------- Bulky Rubbish Category Actions---------------------------
//---------------------------- Bulky Rubbish Category Actions---------------------------

export const getAllBulkRubbishCategories = () => (dispatch) => {
  Axios.get("/api/bulky-rubbish-categories/active")
    .then((res) => {
      console.log("getAllActiveBulkRubbishCategories :", res.data);
      dispatch({
        type:
        GET_ALL_CATEGORIES,
        payload: res.data.data,
      });

        dispatch(LoadingState(false));
    })
    .catch((err) => {
      // const data = err.response.data.error;
      // console.log("GET_API_PRODUCT ERR:", data);
      //   dispatch(LoadingState(false));
    });
};



export const getAllActiveBulkRubbishCategories = () => (dispatch) => {
  //   dispatch(LoadingState(true));

  // Axios.get("api/bulky-rubbish-categories/active")
  //   .then((res) => {
  //     console.log("getAllActiveBulkRubbishCategories :", res.data);
  //     dispatch({
  //       type: GET_ALL_CATEGORIES,
  //       payload: res.data.data,
  //     });
  //
  //     //   dispatch(LoadingState(false));
  //   })
  //   .catch((err) => {
  //     const data = err.response.data.error;
  //     console.log("Register ERR:", data);
  //     //   dispatch(LoadingState(false));
  //   });
};

export const AddBulkRubbishCategory = (data) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("AddBulkRubbishCategory ACTION DATA", data);
  Axios.post("/api/bulky-rubbish-category/add", data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllBulkRubbishCategories());
          toast.success(res.data.message);
          console.log("DATA API HIT AddBulkRubbishCategory :", res.data.message);

      })
    .catch((err) => {
       dispatch(LoadingState(false));
    });
};

export const updateBulkRubbishCategory = (data, id) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("updateBulkRubbishCategory ACTION DATA", data);
  Axios.post("/api/bulky-rubbish-category/edit/" + id, data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllActiveBulkRubbishCategories());
      toast.success("Bulky Rubbish Category Updated Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Updating Bulky Rubbish Category");
      dispatch(LoadingState(false));
    });
};

export const removeBulkyCategory = (id) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("removeBulkyCategory ACTION DATA", id);
  Axios.get("/api/bulky-rubbish-category/delete/" + id)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllBulkRubbishCategories());
      toast.success("Bulky Rubbish Category Removed Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Removing Bulky Rubbish Category");
      dispatch(LoadingState(false));
    });
};

// --------------------------Clear State----------------------------

export const clearCategories = () => (dispatch) => {
  console.log("ACTION clearCategories ");
  dispatch({
    type: CLEAR_CATEGORIES,
  });
};
