import { GET_ALL_RUBBISH_ORDERS } from "../actions/DispatchActions";

// START_LOADING
const initialState = {
    allRubbishOrders: null,
};

export default function (state = initialState, actions) {
    switch (actions.type) {
        case GET_ALL_RUBBISH_ORDERS:
            return { ...state, allRubbishOrders: actions.payload };
        default:
            return state;
    }
}
