import React, { useState } from "react";

import Loader from "react-loader-spinner";

import { useDispatch, useSelector } from "react-redux";
import { AddPackage } from "../../../../actions/packageActions";
import { AddZipCode } from "../../../../actions/zipCodeAction";

const Add_ZipCode = () => {
  const [numm, setNumm] = useState(0);
  const [name, setname] = useState("");
  const [status, setstatus] = useState("0");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loaderReducer);
    const [isFormSubmit, setIsFormSubmit] = useState(0);

  const onClickListener = () => {
      setIsFormSubmit(1)
      if(name!='' && status!='') {

          const data = {
              zip_code: name,
              zip_active: status,
          };
          dispatch(AddZipCode(data));
      }
  };
  return (
    <div className="h-100 mt-2 p-2  mx-auto">
      <div className="container card p-5  mobile__cs">
        <h2 className="text-center my-3">Add ZipCode</h2>
        <div className="row mt-5">
          <div className="col-lg-12 col-md-12 col-12 mx-auto">
            <div className="input-group mb-3">
              <span
                className="input-group-text bg-primary text-white"
                id="inputGroup-sizing-default"
              >
                Country Code
              </span>
              <input
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
              />
            </div>
              {isFormSubmit == 1 && name=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
              <br/>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setstatus(e.target.value)}
            >
              <option value=" " disabled  selected>Status</option>
              <option value="1">active</option>
              <option value="0">not active</option>
            </select>
            <br />
          </div>
            {isFormSubmit == 1 && status==" " && <label className="row mx-auto"  style={{color:"red"}}
            >This field is required</label>}
        </div>
        <div className=" mx-auto">
          <button
            type="button"
            class="btn btn-primary "
            onClick={onClickListener}
          >
            Add ZipCode
            <Loader
              type="Puff"
              color="#FFF"
              height={20}
              width={15}
              style={{ marginLeft: 5 }}
              visible={loading}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Add_ZipCode;
