import React, { useState,useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";

import { getAllTransactions } from '../../../../actions/paymentActions';


function Transaction_List({ history }) {
    const { allTransaction } = useSelector((state) => state.transactionReducer);
    const [row, setrow] = useState([]);
    const dispatch = useDispatch();
    const [trans, setTrans] = useState(null);
console.log(allTransaction)
    const makeRow = () => {
        console.log("DATA PAXJAGEHSDBJHBDS", allTransaction);
        var data =
            Array.isArray(allTransaction) && allTransaction.length > 0 ? allTransaction.map((data, id) => ({
                    order_id: data.collection_order_id,
                    amount: data.amount + ' sek',
                    transaction_date: data.created_at,
                    package_name: (typeof data.user !="undefined" && data.user)? data.user.packageName:'',
                    user_name: (typeof data.user !="undefined" && data.user)? data.user.name:'',
                    //
                    status: data.status === "1" ? "Active" : "Not Active",
                    // action: (
                    //     <div>
                    //         <Edit color="action" onClick={() => redirectToEdit(data)} />
                    //         <Delete color="error" onClick={() => removeData(data.zip_id)} />
                    //     </div>
                    // ),
                }))
                : null;
        setrow(data);
        console.log("NEW DATA SHAPE:", data);

    };

    const columns = [
        {
            label: "Order id",
            field: "order_id",
            width: 150,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Name",
            },
        }, {
            label: "User Name",
            field: "user_name",
            width: 200,
        }, {
            label: "Package name",
            field: "package_name",
            width: 200,
        },
        {
            label: "Amount",
            field: "amount",
            width: 200,
        },
        {
            label: "Transaction date",
            field: "transaction_date",
            width: 200,
        },
        {
            label: "Status",
            field: "status",
            sort: "disabled",
            width: 100,
        },
    ];

     useEffect(() => {
          console.log(trans)

         if (Array.isArray(allTransaction) && allTransaction.length > 0) {
            makeRow();
            setTrans(allTransaction);
        } else {
            dispatch(getAllTransactions());
        }
    }, [dispatch,allTransaction]);


  return (
    <div>
        <div className="card m-2">
            <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
                Transaction Collections
            </div>
            <MDBDataTableV5
                hover
                entriesOptions={[5, 20, 25]}
                entries={5}
                pagesAmount={4}
                data={{
                    columns,
                    rows: Array.isArray(row) && row.length > 0 ? row : [],
                }}
                style={{ fontSize: 15 }}
                searchLabel="Seacrh here......"
                searchTop
                searchBottom={false}
                responsive
                barReverse
            />
        </div>


    </div>
  );
}

export default Transaction_List;
