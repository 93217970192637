import { GET_PACKAGE_BAGS } from "./DispatchActions";
import Axios from "../Services/Axios";
import { LoadingState } from "./loaderActions";
import toast from "../utils/toast";

// export const getPackageBags = () => (dispatch) => {
//   //   dispatch(LoadingState(true));

//   Axios.post("/api/packages/bags")
//     .then((res) => {
//       console.log("get All Package bags :", res.data);
//       dispatch({
//         type: GET_PACKAGE_BAGS,
//         payload: res.data.data,
//       });

//       //   dispatch(LoadingState(false));
//     })
//     .catch((err) => {
//       const data = err.response.data.error;
//       console.log("Register ERR:", data);
//       //   dispatch(LoadingState(false));
//     });
// };

export const getPackageBags = (data) => (dispatch) => {
  dispatch(LoadingState(true));
  // console.log("Package ACTION DATA", data);
  Axios.post("/api/packages/bags", data)
    .then((res) => {
        console.log('res.data',res.data.data);
        dispatch({
            type: GET_PACKAGE_BAGS,
            payload: res.data.data,
        });

        dispatch(LoadingState(false));

      // dispatch(getAllPackages());
      // if (res.status === 200) {
      // toast.success("Package Added successfully.");
      // console.log("DATA API HIT :", res.data);
      // let { id, name, avatar } = jwtDecode(res.data.token);
      // authConfig(res.data.token, res.data.user, history);
      // dispatch({
      //   type: GET_PROFILE,
      //   payload: res.data,
      // });
      // }
    })
    .catch((err) => {
      // const data = err.response.data.error;
      // var res = data.split("validation.custom.messages.");
      // res = res[1];
      // console.log("Login ERR:", res);
      // // var pk = "invalid_login";

      // console.log("Messgas", Message.validation.custom.messages[res]);
      // const Error = Message.validation.custom.messages[res];
      toast.error("There is Some Error While Adding Package");
      dispatch(LoadingState(false));
    });
};

// export const updatePackage = (data, id) => (dispatch) => {
//   dispatch(LoadingState(true));
//   console.log("Package ACTION DATA", data);
//   Axios.post("/api/package/edit/" + id, data)
//     .then((res) => {
//       dispatch(LoadingState(false));
//       dispatch(getAllPackages());
//       toast.success("Package Updated Successfully.");
//       console.log("DATA API HIT :", res.data);
//     })
//     .catch((err) => {
//       toast.error("There is Some Error While Updating Package");
//       dispatch(LoadingState(false));
//     });
// };

// export const removePackage = (id) => (dispatch) => {
//   dispatch(LoadingState(true));
//   console.log("Package ACTION DATA", id);
//   Axios.get("/api/package/delete/" + id)
//     .then((res) => {
//       dispatch(LoadingState(false));
//       dispatch(getAllPackages());
//       toast.success("Package Removed Successfully.");
//       console.log("DATA API HIT :", res.data);
//     })
//     .catch((err) => {
//       toast.error("There is Some Error While Removing Package");
//       dispatch(LoadingState(false));
//     });
// };

// // --------------------------Clear State----------------------------

// export const clearPackage = () => (dispatch) => {
//   console.log("ACTION clearPackage ");
//   dispatch({
//     type: CLEAR_PACKAGES,
//   });
// };
