import React, { useState } from "react";
import "./ShopCard.css";
import Button from "@material-ui/core/Button";
import Modal from "react-awesome-modal";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useHistory,
} from "react-router-dom";
import { NOT_FOUND_IMAGE, PRODUCT_IMAGE_URL } from "../../../Services/DataApi";

const ShopCard = ({ img, alt, title, price, id,description ,type ,point}) => {
  const [visible, setvisible] = useState(false);
  const closeModal = () => {
    setvisible(false);
  };
  const openModal = () => {
    setvisible(true);
  };
  const history = useHistory();
  return (
    <div className="CardWebShop">
      <Link to={"/shop/detail/" + id}>
        <img
          className="card-img p-1"
          src={img ? PRODUCT_IMAGE_URL + img : NOT_FOUND_IMAGE}
          alt={alt}
          classNameName="card_____img__css"
        />
      </Link>
      <div className="Card_bold mt-2">{title ? title : "Name"}</div>
      <div className="p mt-1">{description?description:''}</div>
      <div className="Card_bold my-1">

          {type== "cash" ?'Price: ' +  price +" sek" : 'Price: ' +  point +' Points' }
        {/*{ type=='cash' && price ? price : "00"}*/}
        {/*{" sek"}*/}
      </div>
      {/* <Button
          variant="contained"
          color="#000"
          onClick={() => history.push("/shop/cart")}
          style={{
            backgroundColor: "#a4a4a4",
            color: "#fff",
            width: "100%",
            height: "100%",
          }}
        >
          ADD TO CART
        </Button> */}
<Link to={"/shop/detail/" + id}>
        <div className="btn w-100" style={{ backgroundColor: "#E5EEE6" }}>
        BUY
        </div>
            </Link>
    </div>
  );
};

export default ShopCard;
