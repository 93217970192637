import toast from "../utils/toast";
import Axios from "../Services/Axios";
import Message from "../Services/messages/errorMessages.json";
import {LoadingState} from "./loaderActions";
import {GET_ALL_USER} from "./DispatchActions";

export const adminlogin = (signInData, history) => (dispatch) => {
    dispatch(LoadingState(true));
    console.log("AUTH ACTION DATA", signInData);
    Axios.post("/api/admin/login", signInData)
        .then((res) => {

            dispatch(LoadingState(false));
            // if (res.status === 200) {
            toast.success(res.data.message);
            console.log("DATA API HIT :", res.data.data);
            const {user, Token} = res.data.data;
            authConfig(Token, user, history);
            // authConfig(res.data.token, res.data.user, history);
            // dispatch({
            //   type: GET_PROFILE,
            //   payload: res.data,
            // });
            // }
        })
        .catch((err) => {
            const data = err.response.data.error;
            var res = data.split("validation.custom.messages.");
            res = res[1];
            console.log("Login ERR:", res);
            // var pk = "invalid_login";

            console.log("Messgas", Message.validation.custom.messages[res]);
            const Error = Message.validation.custom.messages[res];
            toast.error(Error);
            dispatch(LoadingState(false));
        });
};

export const register = (signInData, history) => (dispatch) => {
    console.log("AUTH ACTION REgister", signInData);
    dispatch(LoadingState(true));

    Axios.post("/api/register", signInData)
        .then((res) => {
            if (res.status === 200) {
                toast.success(res.data.message);
                console.log("DATA API HIT :", res.data);
                // authConfig(res.data.Token, res.data.user, history);
                // dispatch({
                //   type: GET_PROFILE,
                //   payload: res.data,
                // });
            }
            dispatch(LoadingState(false));
        })
        .catch((err) => {
            const data = err.response.data.error;
            console.log("Register ERR:", data);
            dispatch(LoadingState(false));
        });
};

export const getAllUsers = () => (dispatch) => {
    //   dispatch(LoadingState(true));

    Axios.get("api/users/all")
        .then((res) => {
            console.log("getAllUsers :", res.data);
            dispatch({
                type: GET_ALL_USER,
                payload: res.data.data,
            });

            //   dispatch(LoadingState(false));
        })
        .catch((err) => {
            const data = err.response.data.error;
            console.log("Register ERR:", data);
            //   dispatch(LoadingState(false));
        });
};

// export const register = (data, history) => (dispatch) => {
//   console.log(data);
//   Axios.post("/signup", JSON.stringify(data))
//     .then((res) => {
//       if (res.status === 200) {
//         toast.success("Register Successfully.");
//         console.trace("DATA API HIT :", res.data);
//         history.push("/login");
//         // let { id, name, avatar } = jwtDecode(res.data.token);
//         // authConfig(res.data.token, res.data.user, history);
//         // dispatch({
//         //   type: LOGIN,
//         //   payload: res.data,
//         // });
//       }
//     })
//     .catch((err) => {
//       console.log("ERR:", err.response.data.error);
//       toast.error(err.response.data.error);
//     });
// };
// export const signup = (signInData) => {
//   const { name, email, password } = signInData;
//   axiosIntance
//     .post("/users/signup", { name: name, email: email, password: password })
//     .then((res) => {
//       if (res.status === 200) {
//         toast.success("SignUp successfully.");
//         const { _id, name, avatar } = res.data;
//         authConfig(_id, name, avatar);
//       }
//     });
// };

const authConfig = (token, user, history) => {
    console.log("authConfig", user);
    localStorage.setItem("user", user.id);
    localStorage.setItem("user_name", user.user_name);
    localStorage.setItem("role_id", user.user_role_id);
    localStorage.setItem("role", 'admin');
    if (localStorage.getItem("role_id") == 1 ) {
         history.push("/admin/dashboard-overview");
    }
    else {
        history.push("/");
    }
};


// export const logout = (history) => {
//   localStorage.removeItem('user');
//   localStorage.removeItem('token');
//   toast.success("Logout successfully.");
//   history.replace("/login");
// };
