import React from "react";
import styles from "../../Css/HowItWorks.module.css";
import Rectangle from "../../../../assets/Rectangle.png";
import { useHistory,Link } from "react-router-dom";

import Bag from "../../../../assets/images/skrap3.png";
function HowItWorks() {
  return (
    <div className={styles.main}>
      <div className="container-fluid ">
        <div className="row d-flex justify-content-center">
            <div className={styles.heading+' home_page_heading'}>så fungerar det</div>
          <img className={styles.RectangleImage+' home_rectangle_img'} src={Rectangle} alt="" />
          <div className={styles.paraGraphSmall+' home_section_para'}>
            Slipp gegget och kletet. Släng all din regelbundna återvinning i
            samma påse. Vi hämtar och källsorterar<br /> ditt hushållsskräp. I vår
            prenumerationstjänst ingår hämtning av vardagligt hushållsskräp så
            som tidningar,<br /> plast, papper, glas och metall.
          </div>
        </div>
        <div className="row" style={{ marginTop: '6%' }}>
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <div className={styles.heading1+' home_page_heading'}>Välj medlemskap</div>

            <div className="d-flex justify-content-center my-3">
              <img
                className={styles.RectangleImageSmall+' home_rectangle_img'}
                src={Rectangle}
                alt=""
              />
            </div>
            <div className={styles.paraGraphSmall+' home_section_para'}>
              Välj hur ofta vi ska hämta och vilken storlek på återvinningspåse
              som passar bäst i ditt hem.
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className={styles.heading1}>Vi hämtar</div>

            <div className="d-flex justify-content-center my-3">
              <img
                className={styles.RectangleImageSmalll+' home_rectangle_img'}
                src={Rectangle}
                alt=""
              />
            </div>
            <div className={styles.paraGraphSmall+' home_section_para'}>
              Ställ din fulla återvinningspåse utanför dörren vid hämtning. Vi
              påminner dig via SMS eller mail om du önskar.
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className={styles.heading1}>
              Bidra till miljön och få tid till annat
            </div>

            <div className="d-flex justify-content-center my-3">
              <img
                className={styles.RectangleImageSmallll+' home_rectangle_img'}
                src={Rectangle}
                alt=""
              />
            </div>
            <div className={styles.paraGraphSmall+' home_section_para'}>
              Vi sorterar ditt skräp och tar ansvar för hela
              återvinningsprocessen. Du får en ny påse i samband med hämtningen.
            </div>
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center">
          <img src={Bag} alt="" className={styles.bagImage} />
          <Link to="/membership" style={{textAlign:'center'}}>

            <div className="MuiButtonBase-root MuiButton-root MuiButton-contained chose__btn MuiButton-containedPrimary" style={{backgroundColor:'#2e382e'}}>
              Från 79 kr. Prova två veckor gratis!
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
