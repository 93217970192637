////profile action types///
export const GET_PROFILE = "GET_PROFILE";

/////////sign_in/////
export const SIGN_IN = "SIGNIN";

/////////sign_up/////
export const SIGN_UP = "SIGN_UP";

////////error handling///////
export const SET_ERROR = "SET_ERROR";

////////Loader///////

export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";

/////////Packages/////////////////
export const GET_ALL_PACKAGES = "GET_ALL_PACKAGES";
export const CLEAR_PACKAGES = "CLEAR_PACKAGES";
export const GET_ALL_PACKAGES_ADMIN = "GET_ALL_PACKAGES_ADMIN";

/////////ZIPCODE/////////////////
export const GET_ALL_ZIPCODE = "GET_ALL_ZIPCODE";
export const CLEAR_ZIPCODE = "CLEAR_ZIPCODE";

/////////BAGS/////////////////
export const GET_ALL_BAGS = "GET_ALL_BAGS";
export const CLEAR_BAGS = "CLEAR_BAGS";

/////////Category/////////////////
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const CLEAR_CATEGORIES = "CLEAR_CATEGORIES";

/////////Product Type/////////////////
export const GET_ALL_PRODUCT_TYPE = "GET_ALL_PRODUCT_TYPE";
export const CLEAR_PRODUCT_TYPE = "CLEAR_PRODUCT_TYPE";

/////////Product /////////////////
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const GET_ALL_BULKY_RUBBISH_CATEGORIES = "GET_ALL_BULKY_RUBBISH_CATEGORIES";
export const GET_ALL_FAQ = "GET_ALL_FAQ";

/////////USERS /////////////////
export const GET_ALL_USER = "GET_ALL_USER";
export const CLEAR_USER = "CLEAR_USER";
///////////////get all users//////////
export const ALL_USERSS = "ALL_USERSS";
export const CLEAR_USERSS = "CLEAR_USERSS"; 

//////////////payment////////////
export const GET_ALL_PAYMENTS = "GET_ALL_PAYMENTS";

export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";

export const GET_ALL_RUBBISH_ORDERS = "GET_ALL_RUBBISH_ORDERS";
export const GET_ALL_STORE_CATEGORY = "GET_ALL_STORE_CATEGORY";

export const GET_ALL_COLLECTIONS = "GET_ALL_COLLECTIONS";

export const GET_PACKAGE_BAGS = "GET_PACKAGE_BAGS";

export const GET_ALL_STORE_PRODUCT = "GET_ALL_STORE_PRODUCT";