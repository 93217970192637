import React,{ useState,useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import FooterShop from '../WebShop/Common/FooterShop';
import ShopNavbar2 from '../WebShop/Common/ShopNavbar2';
import './PolicyPage.css';
import Axios from "../../Services/Axios";
import {useDispatch} from "react-redux";

const PolicyPage = () => {
    const dispatch = useDispatch();
    const [terms, setTerms] = useState([]);
    const [ispopulated, setIsPopulated] = useState(0);

    useEffect(() => {
        Axios.get("/api/terms/list?type=policy")
            .then((res) => {
                if(res.data.data.length > 0 ) {
                    setTerms(res.data.data);
                }
            })
            .catch((err) => {
                const data = err.response.data.error;
                //   dispatch(LoadingState(false));
            });
    }, []);

    // <p>Dessa allmänna villkor gäller för dina köp på, och din användning av, webbsidan www.tvattdax.se. Villkoren utgör ett avtal mellan dig och Tvättdax Sverige AB, org. nr 559110-1372. Regissörgatan 11, 215 83, Malmö. Vid frågor kontakta oss här.</p>
    // <p>Genom att göra köp ifrån och använda denna webbsida så förbinder du dig till följande villkor. Vi ber dig därför att noga läsa igenom villkoren före du använder vår sida.</p>
    // <p>1.1 Tvättdax är ett företag som bistår sina kunder med tvätteritjänster. Tjänsten består av upphämtning, hushållstvättning strykning, mangling, fläckbehandling pressning och återlämning(”Tjänsten”). Med hushållstvätt avses för närvarande vattentvätt, dock ej handtvätt.</p>
    return(
        <div className="termsPage">
            <ShopNavbar2 />
            <div className="banner">
                <h1>Integritetspolicy</h1>
            </div>
            <div className="container">
                <div className="contentWrap">
                    <ul className="list-unstyled">
                        {terms && terms.map((term) => (
                            <li className="col-sm-6 col-12">
                                <div>
                                    <h3>{term.name}</h3>
                                    <p>{term.description}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <FooterShop />
        </div>
    )
}

export default PolicyPage;