import React from "react";
import "./BulkRubbishMain/Page.css";
import { Parent_ShopCard_Btn_Color } from "../../Common/Color";
import Button from "@material-ui/core/Button";
import { useHistory,Link } from "react-router-dom";
import {NOT_FOUND_IMAGE, PRODUCT_IMAGE_URL} from "../../Services/DataApi";

function Card2(props) {
  const history = useHistory();

    const addToCart = (id,name,price,type) => {
        // if (localStorage.getItem('StoreProduct')) {
        //     cart = JSON.parse(localStorage.getItem('StoreProduct'));
        // }
        // cart[id] = (cart[id] || 0) + 1;
        // localStorage.setItem('StoreProduct', JSON.stringify(cart));
        let cart = [];
        let itemToAdd = {
            id,
            count: 1,
            name:name,
            price:price,
            type:type,

        };
        if (localStorage.getItem('StoreProduct')) {
            cart = JSON.parse(localStorage.getItem('StoreProduct'));
            let item = cart.find(el => el.id === id);
            if (!item) {
                cart.push(itemToAdd);
            } else {
                ++ item.count;
            }
        } else {
            cart = [itemToAdd];
        }
        localStorage.setItem('StoreProduct', JSON.stringify(cart))
        console.log(localStorage.getItem('StoreProduct'))
    };
  return (
    <div className="row px-2 py-3 Card_Shop2 m-1">
      <div className="col-lg-4">
        <img
            src={
                props.image
                    ? PRODUCT_IMAGE_URL +  props.image
                    : NOT_FOUND_IMAGE
            }
            alt=""
            className="img-fluid"
        />
      </div>
      <div className="col-lg-8 text-left">
          <div className="h3 font-weight-bolder">
              <Link to={"/store-product/detail/" + props.id}>

              {props.name}</Link></div>
           {props.type && props.type == 'point' ?
              <div className="h6"><span>Point</span>: {props.points?props.points:'0'} </div>
              :<div className="h6"><span>Price</span>: {props.price?props.price:'0'} {props.currency}</div>
          }

        <div className="h6">{props.description} </div>
          {props.store && props.store== 'true' ?
              <>
              {/*<Button*/}
              {/*variant="contained"*/}
              {/*color="white"*/}
              {/*style={{ backgroundColor: '#000000',color:'white' }}*/}
              {/*onClick={() => addToCart(props.id,props.name,props.type == 'point'?props.points:props.price,props.type)}*/}
          {/*>*/}
              {/*/!*<Link to={"/store-product/detail/" + props.id}>*!/*/}
                  {/*/!*Add to cart</Link>*!/*/}
                  {/*Add to cart*/}
          {/*</Button>*/}
                  <Button
                      variant="contained"
                      color="#000"
                      style={{ backgroundColor: '#000000',color:'white' ,marginLeft:"5px" }}
                      onClick={() => ''}
                  >
                      <Link to={"/store-product/detail/" + props.id}>
                          Detail</Link>

                  </Button>
              </>

              : ""}
              {/*<Button*/}
              {/*variant="contained"*/}
              {/*color="#000"*/}
              {/*style={{ backgroundColor: Parent_ShopCard_Btn_Color }}*/}
              {/*onClick={() => history.push("/shop/cart")}*/}
          {/*>*/}
              {/*Add to Cart*/}
          {/*</Button>*/}

          {props.store && props.store!='true' &&
        <div className="d-flex justify-content-end mt-2">
          <div
            className="iconQuestionMark"
            style={{ backgroundColor: Parent_ShopCard_Btn_Color }}
            onClick={() => props.openDrawer()}
          >
            <i class="fas fa-question"></i>
          </div>
        </div>
          }
      </div>
    </div>
  );
}

export default Card2;
