import React from "react";
import "./navbar.css";
import logo from "../../assets/logo2.png";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useHistory,
} from "react-router-dom";
import { Parent_Color } from "../Color";

const Navbar = ({ history }) => {

  const user_name = localStorage.getItem("user_name");
  const user_role_id = localStorage.getItem("role_id");

  const admin = <li>
    <Link className="dropdown-item" to="/admin">
      <i class="fas fa-user"></i> Admin
</Link>
  </li>

  const user = <li>
    <Link className="dropdown-item" to="/membership">
      <i class="fas fa-user"></i> Welcome {user_name}
    </Link>
  </li>

  return (
    <div className="container_navbar sticky-top navbarShadow">
      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={{ backgroundColor: Parent_Color }}
      >
        {/* <div className="container"> */}
        <Link className="navbar-brand" to="/home">
          <img src={logo} alt="" className="main-logo-css" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="fas fa-align-justify icone_css"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className="nav-link centerText active"
                aria-current="page"
                to="/"
              >
                <i class="fas fa-home"></i> HOME
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link centerText" to="/membership">
                <i class="far fa-credit-card"></i> MEMBERSHIP
              </Link>
            </li>
            <li className="nav-item dropdown ">
              <a
                className="nav-link centerText dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="far fa-address-card"></i> ABOUT
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/collection">
                    <i class="far fa-arrow-alt-circle-right"></i> COLLECTION
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/invite">
                    <i className="fas fa-user-friends"></i> INVITE FRIENDS AND
                    <br />
                    NEIGBHORS
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown  navbarProfile">
              <a
                className="nav-link centerText dropdown-toggle"
                to="/myaccount"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="far fa-user"></i> MY PROFILE
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/my-account">
                    <i className="far fa-user"></i> Account
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/do-you-know">
                    <i class="fas fa-people-carry"></i> Do You Know
                  </Link>
                </li>
                {/*<li>*/}
                  {/*<Link className="dropdown-item" to="/admin">*/}
                    {/*<i class="fas fa-user"></i> Admin*/}
                  {/*</Link>*/}

                {/*</li>*/}
                <li>
                  <Link className="dropdown-item" to="/login">
                    <i class="fas fa-sign-out-alt"></i> LOGOUT
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {/* </div> */}
      </nav>
    </div>
  );
};

export default Navbar;
