import React, { useState,useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";

import { getAllRubbishProduct } from '../../../../actions/productAction';
import Axios from "../../../../Services/Axios";
import {GET_ALL_PRODUCT} from "../../../../actions/DispatchActions";


function UserRequest({ history }) {

    const [row, setrow] = useState([]);
    const dispatch = useDispatch();
    const [UserRequets, setUserRequets] = useState(null);
    const [ispopulated, setIsPopulated] = useState(0);

    const makeRow = () => {
        var data =
            UserRequets.length > 0 ? UserRequets.map((data, id) => ({
                    Email: data.email,
                    date_time: data.created_at?data.created_at:'',


                }))
                : null;
        setrow(data);

    };
    useEffect(() => {
        Axios.get("/api/user/request/query")
            .then((res) => {
                if(res.data.data.length > 0 && ispopulated == 0) {
                    setUserRequets(res.data.data);
                    setIsPopulated(1)
                }
            })
            .catch((err) => {
                const data = err.response.data.error;
                console.log("GET_API_PRODUCT ERR:", data);
                //   dispatch(LoadingState(false));
            });

        if (Array.isArray(UserRequets) && UserRequets.length > 0) {
            makeRow();
        } else {
        }
    }, [dispatch,UserRequets]);

    const columns = [
        {
            label: "Email",
            field: "Email",
            width: 150,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Name",
            },
        },
        {
            label: "Requested Date",
            field: "date_time",
            width: 200,
        },
    ];

    return (
        <div>
            <div className="card m-2">
                <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
                    User Requests
                </div>
                <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={{
                        columns,
                        rows: Array.isArray(row) && row.length > 0 ? row : [],
                    }}
                    style={{ fontSize: 15 }}
                    searchLabel="Seacrh here......"
                    searchTop
                    searchBottom={false}
                    responsive
                    barReverse
                />
            </div>


        </div>
    );
}

export default UserRequest;
