import React from "react";
import styles from "../../Css/BulkRubbish.module.css";
import HistoryIcon from "@material-ui/icons/History";
import { Parent_Color } from "../../../../Common/Color";
import {BULKRUBBISH_PRODUCT_IMAGE_URL, NOT_FOUND_IMAGE} from "../../../../Services/DataApi";
function BookCard(props) {
    console.log(props)
  return (
    <div className={styles.BookCard}>
      {/*<div className="d-flex justify-content-end mt-2">*/}
        {/*<div*/}
          {/*className={styles.iconQuestionMark}*/}
          {/*// onClick={() => props.openDrawer()}*/}
        {/*>*/}
          {/*<i class="fas fa-question"></i>*/}
        {/*</div>*/}
      {/*</div>*/}
      <h3>{props.name}</h3>
        <img
            src={
                props.image
                    ? BULKRUBBISH_PRODUCT_IMAGE_URL + props.image
                    : NOT_FOUND_IMAGE
            }
            alt=""
            className="img-fluid img-responsive"
            style={{maxWidth:"100%",height: "100px"}}

        />
      {/*<HistoryIcon*/}
        {/*style={{*/}
          {/*fontSize: "5rem",*/}
          {/*color: "#000",*/}
          {/*marginTop: 10,*/}
          {/*marginBottom: 10,*/}
        {/*}}*/}
      {/*/>*/}
        <h5 style={{marginTop:"10px"}}>{props.brp_desc}</h5>

        {/*<h5>{props.price} SEk</h5>*/}

      <div
        className="btn btn-lg mt-2"
        style={{ backgroundColor: Parent_Color }}
      >
          {props.price} SEk
      </div>
    </div>
  );
}

export default BookCard;
