import React from "react";
import { Nav } from "shards-react";

import Notifications from "./Notifications";
import UserActions from "./UserActions";

export default () => (
  <Nav navbar className="border-left flex-row  man__nav_dash  ">
    <span className="border-right p-2"></span>
    <p className="display-none-cs ">--</p>
    <UserActions className="border-right-cs" />
  </Nav>
);
