import React, { useState } from "react";
import Button from "@material-ui/core/Button";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
const Edit_order_list = () => {

    return (
        <div className="p-2 mt-2  mx-auto">

            <div className="container card p-5 mobile__cs">
                <h2 className="text-center my-1">Edit Order list</h2>
                <div className="row mt-4">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">
                                customer  name
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </div>


                        <div className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">
                                address
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">
                                phone number
                        </span>
                            <input
                                type="number"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">
                                order date
                        </span>
                            <input
                                type="number"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </div>
                        <br />


                    </div>
                </div>
                <div className=" mx-auto">
                    <button type="button" class="btn btn-primary ">Edit order list</button>
                </div>
            </div>
        </div>
    );
};

export default Edit_order_list;

