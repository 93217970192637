import React, { useState,useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";

import Axios from "../../../../Services/Axios";
import Delete from "@material-ui/icons/Delete";
import Modal from "react-awesome-modal";
import {getAllFaqList, removeFaqById} from "../../../../actions/zipCodeAction";
import {removeTermById} from "../../../../actions/zipCodeAction";


function Terms_list() {

    const [row, setrow] = useState([]);
    const dispatch = useDispatch();
    const [UserRequets, setUserRequets] = useState(null);
    const [removeID, setRemoveID] = useState(0);
    const [visible, setVisible] = useState(false);
    const [ispopulated, setIsPopulated] = useState(0);

    const removeData = (id) => {
        setRemoveID(id);
        openModal();
    };

    const closeModal = () => {
        setVisible(false);
    };

    const openModal = () => {
        setVisible(true);
    };

    const removeFaq = () => {
        dispatch(removeTermById(removeID));
        closeModal();
    };

    const makeRow = () => {
        var data =
            UserRequets.length > 0 ? UserRequets.map((data, id) => ({
                    id: data.id,
                    name: data.name,
                    description: data.description,
                    type: data.type,
                    action: (
                                <div>
                                {/*<Edit color="action" onClick={() => redirectToEdit(data)} />*/}
                                <Delete color="error" onClick={() => removeData(data.id)} />
                        </div>
                    ),
                }))
                : null;
        setrow(data);
    };

    useEffect(() => {
        Axios.get("/api/terms/list")
            .then((res) => {
                if(res.data.data.length > 0 && ispopulated == 0) {
                     setUserRequets(res.data.data);
                    setIsPopulated(1)
                }
            })
            .catch((err) => {
                const data = err.response.data.error;
                //   dispatch(LoadingState(false));
            });


    }, []);

    useEffect(()=>{
        if (Array.isArray(UserRequets) && UserRequets.length > 0) {
            makeRow();
        }
    },[UserRequets]);

    const columns = [
        {
            label: "Id",
            field: "id",
            width: 100,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Id",
            },
        },{
            label: "Name",
            field: "name",
            width: 100,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Name",
            },
        },{
            label: "Description",
            field: "description",
            width: 100,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Description",
            },
        },{
            label: "Type",
            field: "type",
            width: 100,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Type",
            },
        },
        {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 100,
        },
    ];

    return (
        <div>
            <div className="card m-2">
                <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
                    Terms
                </div>
                <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={{
                        columns,
                        rows: Array.isArray(row) && row.length > 0 ? row : [],
                    }}
                    style={{ fontSize: 15 }}
                    searchLabel="Seacrh here......"
                    searchTop
                    searchBottom={false}
                    responsive
                    barReverse
                />
            </div>

            <Modal
                visible={visible}
                effect="fadeInRight"
                onClickAway={() => closeModal()}
                className="modalPop"
                    >
                    <div className="modalPop1">
                    {/* <h1>Title</h1> */}
                    <div className="text-danger">
                    Are you Sure? You want to delete this Record.
                </div>
                <div className="d-flex justify-content-around">
                    <div
                className="btn btn-primary btn-sm"
                onClick={() => closeModal()}
            >
                Cancel
                </div>
                <div className="btn btn-danger btn-sm" onClick={removeFaq}>
                    Delete
                    </div>
                    </div>
                    </div>
        </Modal>
        </div>
    );
}

export default Terms_list;
