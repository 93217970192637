import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePackage } from "../../../../actions/packageActions";
import Loader from "react-loader-spinner";
import { updateProductType } from "../../../../actions/productTypeAction";

const Edit_ProductType = ({ history }) => {
    const data = history.location.state.data;
console.log(data)
  const [name, setname] = useState(data.pt_name);
  const [status, setstatus] = useState(data.pt_active);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loaderReducer);

  // history.location.state.id

  const onClickListener = () => {
    const data2 = {
      pt_name: name,
      pt_active: status,
    };
    const id = history.location.state.id;
    console.log("SEND DATA", data2, data.pt_id);
    dispatch(updateProductType(data2, data.pt_id,history));
  };
  return (
    <div className="p-2 mt-2  mx-auto">
      <div className="container card p-5 mobile__cs">
        <h2 className="text-center my-1">Edit Product Type</h2>
        <div className="row mt-4">
          <div className="col-lg-12 col-md-12 col-12 mx-auto">
            <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product Type
              </span>
              <input
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
                value={name}
              />
            </div>
            <br />

            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setstatus(e.target.value)}
              value={status}
            >
              <option selected>Status type</option>
              <option value="1">active</option>
              <option value="0">not active</option>
            </select>
            <br />
          </div>
        </div>
        <div className=" mx-auto">
          <button
            type="button"
            class="btn btn-primary "
            onClick={onClickListener}
          >
            Update
            <Loader
              type="Puff"
              color="#FFF"
              height={20}
              width={15}
              style={{ marginLeft: 5 }}
              visible={loading}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit_ProductType;
