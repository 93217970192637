import { useHistory } from "react-router-dom";
import "./CartDetails.css";
import {getStoreProductByID} from "../../actions/productAction";
import React, { useEffect, useState } from "react";
import {NOT_FOUND_IMAGE, BULKRUBBISH_PRODUCT_IMAGE_URL} from "../../Services/DataApi";
import Button from "@material-ui/core/Button";

const RubbishCardDetail = (props) => {
    const [productDetails, setproductDetails] = useState("");
    const [data, setData] = useState(null);
    const [totalCartPrice, setTotalCartPrice] = useState('');
    const [totalCartPoint, setTotalCartPoint] = useState('');
const StoreBulkyRubbishProduct =localStorage.getItem('StoreBulkyRubbishProduct');
    const history = useHistory();
    const toCart = () => {
        props.setOpenDrawer(false)
        props.setOpenDetailForm(true)
        history.push("/cart/rubbish");

    };




    const totalPrice = () => {
        var totalPrice=0;
        var totalPoints=0;

        if(data){
            for (let i=0; i < data.length; i++){
                totalPoints += data[i].type =="point" ?(data[i].point * data[i].count):0;
                totalPrice += data[i].type !="point" ?(data[i].price * data[i].count):0;
            }

        }
        return {totalPoints:totalPoints,totalPrice:totalPrice};
    };

    const addRemoveFromCart = (id,type) => {
        let cart = [];
        let newcart = [];

        if (localStorage.getItem('StoreBulkyRubbishProduct')) {
            cart = JSON.parse(localStorage.getItem('StoreBulkyRubbishProduct'));

            for(let i = 0;i < cart.length; i++) {
                if(cart[i].id == id) {
                    if(type == 'add'){
                        cart[i].count = cart[i].count + 1;
                        newcart.push(cart[i]);
                    } else if(cart[i].count > 0) {
                        cart[i].count = cart[i].count - 1;
                        if(cart[i].count > 0) {
                            newcart.push(cart[i]);
                        }
                    }
                } else {

                    newcart.push(cart[i]);
                }
            }
        }

        setData(newcart);
        localStorage.setItem('StoreBulkyRubbishProduct', JSON.stringify(newcart))
    };
    const toShop = () => {
        history.push("/new-shop/bulk-rubbish");
    };
    const ProductId= localStorage.getItem("StoreBulkyRubbishProduct", productDetails.id);

    useEffect(() => {

        setData(props.cartItems)

        getStoreProductByID(ProductId).then((res) => {
            console.log("StoreBulkyRubbishProduct :", res.data.data);
            setproductDetails(res.data.data);

        });
    }, [props.cartItems]);
    return (
        <>
            <div className="col-12 mx-auto bg-light">
                <div className="p-2">
                    <h5 className="text-center">Cart</h5>
                    <hr className="w-100 mx-auto" />
                    {data && data.map((data) => (
                        <>
                            <div className=" figure__img__css d-flex justify-content-center">
                                <img
                                    src={
                                        data.image
                                            ? BULKRUBBISH_PRODUCT_IMAGE_URL + data.image
                                            : NOT_FOUND_IMAGE
                                    }
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>
                            <h6 style={{marginTop:"15px"}}>{data.count} {data.name} added in your cart</h6>
                            <Button variant="contained"
                                    color="#000"  size='small' onClick={() => addRemoveFromCart(data.id,'minus')}  >-</Button>


                            <Button  variant="contained"
                                     color="#000" size='small' style={{marginLeft:"10px"}} onClick={() => addRemoveFromCart(data.id,'add')}>+</Button>
                        </>
                    ))}
                    <hr className="w-100 mx-auto" />
                    <br />

                    {/*<div className=" figure__img__css d-flex justify-content-center">*/}
                    {/*<img*/}
                    {/*src={*/}
                    {/*productDetails.img*/}
                    {/*? PRODUCT_IMAGE_URL + productDetails.img*/}
                    {/*: NOT_FOUND_IMAGE*/}
                    {/*}*/}
                    {/*alt=""*/}
                    {/*className="img-fluid"*/}
                    {/*/>*/}
                    {/*</div>*/}
                    <br />
                    {/*<div className="mt-1 text-center">*/}
                    {/*<h4>{productDetails.product_name}</h4>*/}
                    {/*</div>*/}

                    {/*<div class="mb-3">*/}
                    {/*<label for="exampleFormControlTextarea1" class="form-label">*/}
                    {/*Instructions*/}
                    {/*</label>*/}
                    {/*<textarea*/}
                    {/*class="form-control "*/}
                    {/*id="exampleFormControlTextarea1"*/}
                    {/*rows="3"*/}
                    {/*></textarea>*/}
                    {/*</div>*/}
                    {totalPrice().totalPrice != 0 && <h5>
                        Total Price:<span className="total__">
              {totalPrice().totalPrice} {"sek"}
              </span>
                    </h5>}
                    {totalPrice().totalPoints != 0 &&
                    <h5>
                        Total points:<span class="total__">
              {totalPrice().totalPoints}
              </span>
                    </h5>
                    }
                    <div className="my_btnnn">
                        <button className="" onClick={() => toShop()}>
                            Shop
                        </button>

                         <button className="" onClick={() => toCart()}>
                            Checkout
                        </button>
                     </div>
                    {/*<p className="pl-3 pt-3">NOT Shipping to Stockholm County?</p>*/}
                    {/*<div className="">*/}
                    {/*<select className="options w-100">*/}
                    {/*<option>England</option>*/}
                    {/*<option>Sweden</option>*/}
                    {/*<option>Germany</option>*/}
                    {/*</select>*/}
                    {/*</div>*/}
                    {/*<br />*/}
                    {/*<div className="d-flex justify-content-center">*/}
                    {/*<button className="my_btnnn2">Gather shoping rate</button>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
};

export default RubbishCardDetail;
