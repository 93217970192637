import { GET_ALL_TRANSACTIONS } from "../actions/DispatchActions";

// START_LOADING
const initialState = {
    allTransaction: null,
};

export default function (state = initialState, actions) {
    switch (actions.type) {
        case GET_ALL_TRANSACTIONS:
            return { ...state, allTransaction: actions.payload };
        default:
            return state;
    }
}
