import React from "react";
import PropTypes from "prop-types";
import './MainNav.css';
import classNames from "classnames";
import { Container, Navbar } from "shards-react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";
import { Dispatcher, Constants } from "../../../flux";

const MainNavbar = ({ layout, stickyTop }) => {
  const handleClick = () => {
    console.log("New NAVBAR");
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    });
  };

  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );

  return (
    <div className={classes}>

      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-3">
                <NavbarToggle />
              </div>
              <div className="col-9 main_____nav__dash"> <NavbarSearch className="" /></div>
            </div>

          </div>

        </div>
      </div>

      <div className="d-flex justify-content-end gcgcgcm">
        <NavbarNav className="main---css" />
      </div>


    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

export default MainNavbar;
