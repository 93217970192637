import {
  CLEAR_PRODUCT,
  CLEAR_PRODUCT_TYPE,
  CLEAR_ZIPCODE,
  GET_ALL_PRODUCT,
  GET_ALL_PRODUCT_TYPE,
  GET_ALL_ZIPCODE,
} from "../actions/DispatchActions";

// START_LOADING
const initialState = {
  product: null,
};

export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_ALL_PRODUCT:
      return { ...state, product: actions.payload };
    case CLEAR_PRODUCT:
      return { product: null };
    default:
      return state;
  }
}
