// import React, { useState, useEffect } from "react";
// import TableList2 from "../../components/TableListing/TableList2";
// import TableListing from "../../components/TableListing/TableListing";
// import Edit from "@material-ui/icons/Edit";
// import Delete from "@material-ui/icons/Delete";
// import Modal from "react-awesome-modal";
// import axios from "axios";
// function User_List({ history }) {

//   const [users, setUser] = useState([]);

//   useEffect(() => {
//     loadUsers();
//   }, []);

//   const loadUsers = async () => {
//     const result = await axios.get("https://loopabackend.p2gmanagement.xyz/api/users/all");
//     setUser(result.data.data);
//     console.log(result.data.data);
//   };


//   const [visible, setvisible] = useState(false);
//   const closeModal = () => {
//     setvisible(false);
//   };
//   const openModal = () => {
//     setvisible(true);
//   };
//   const redirectToEdit = () => {
//     history.push("/edit-user-list");
//   };
//   const data = users.map(user => ({
//     rows: [
//       {
//         name: user.user_name,
//         role: user.user_role_id === "1" ? "Admin" : "Customer",
//         active: user.is_active ? "Active" : "Not active",
//         action: (
//           <div>
//             <Edit color="action" onClick={redirectToEdit} />
//             <Delete color="error" onClick={openModal} />
//           </div>
//         ),
//       }
//     ]
//   }));
//   const columns = [
//     {
//       label: "Name",
//       field: "name",
//       width: 150,
//       attributes: {
//         "aria-controls": "DataTable",
//         "aria-label": "Name",
//       },
//     },
//     {
//       label: "Role",
//       field: "role",
//       width: 200,
//     },
//     {
//       label: "Active",
//       field: "active",
//       width: 200,
//     },

//     {
//       label: "Action",
//       field: "action",
//       sort: "disabled",
//       width: 100,
//     },
//   ];

//   return (
//     <div>
//       <TableList2 heading="User " columns={columns}

//         rows={data}
//       />
//       <Modal
//         visible={visible}
//         effect="fadeInRight"
//         onClickAway={() => closeModal()}
//         className="modalPop"
//       >
//         <div className="modalPop1">
//           {/* <h1>Title</h1> */}
//           <div className="text-danger">
//             Are you Sure? You want to delete this Record.
//           </div>
//           <div className="d-flex justify-content-around">
//             <div
//               className="btn btn-primary btn-sm"
//               onClick={() => closeModal()}
//             >
//               Cancel
//             </div>
//             <div className="btn btn-danger btn-sm" onClick={() => closeModal()}>
//               Delete
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </div>
//   );
// }

// // export default User_List;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Modal from "react-awesome-modal";

const User_List = ({ history }) => {

  const [users, setUser] = useState([]);
    const role =localStorage.getItem('role_id');
  useEffect(() => {
    loadUsers();
  }, []);

  const redirectToEdit = (data) => {
      history.push({ pathname: "/admin/edit-user-list", state: { data: data } });

      // history.push("/admin/edit-user-list", state: { data: data });
  };

  const loadUsers = async () => {
    const result = await axios.get("https://loopabackend.p2gmanagement.xyz/api/users/all");
    setUser(result.data.data);
    console.log(result.data.data);
  };

  const deleteUser = async id => {
    await axios.get("https://loopabackend.p2gmanagement.xyz/api/user/ban/" + id);


    loadUsers();
  };

  return (
    <div className="container">
      <div className="py-4">
        <h1 className="my-2 text-center">Users List</h1>

        <table className="table border shadow bg-white">
          <thead className="thead-white">
            <tr>

              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Address</th>
              <th scope="col">Phone</th>
              <th scope="col">Status</th>
              <th >Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr>

                <td>{user.user_name }</td>
                <td>{user.email}</td>
                <td>{user.user_role_id === "1" ? "Admin" : "Customer"}</td>
                <td>{user.address? user.address : " "}</td>
                <td>{user.phone? user.phone : " "}</td>
                <td>{user.is_active == 1? "Active" : "Not active"}</td>

                <td>
                  <div className="d-flex" style={{ margin: '8px' }}>
                      <Edit color="action" onClick={() => redirectToEdit(user)} />
                    {/*<div className="" onClick={redirectToEdit(user)}><Edit style={{ color: '#757575' }} /></div>*/}
                    <Link
                      className=" ml-2"

                    >
                        {role === "1" &&
                        <Delete onClick={() => deleteUser(user.id)} style={{color: '#f44336', cursor: 'pointer'}}/>
                        }
                    </Link>

                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default User_List;

// import React, { useEffect, useState } from "react";
// import Edit from "@material-ui/icons/Edit";
// import Delete from "@material-ui/icons/Delete";
// import Modal from "react-awesome-modal";
// import { MDBDataTableV5 } from "mdbreact";

// import { useDispatch, useSelector } from "react-redux";
// import {
//   getAllUserss,
//   updateUser, removeUsers, clearUserss

// } from "../../../../actions/AllUsersList";
// import { ALL_USERSS } from "../../../../actions/DispatchActions";
// function User_List({ history }) {
//   const [visible, setvisible] = useState(false);
//   const userlist = useSelector((state) => state.userlistReducer)
//   console.log("atta" + userlist);

//   const dispatch = useDispatch();
//   const [row, setrow] = useState([]);
//   const [removeID, setremoveID] = useState("");

//   useEffect(() => {

//     if (Array.isArray(userlist) && userlist.length > 0) {
//       makeRow();
//     } else {
//       dispatch(getAllUserss());
//     }
//   }, []);


//   // useEffect(() => {
//   //   return () => {
//   //     dispatch(clearUserss());
//   //     console.log("clearUsers");
//   //   };
//   // }, [dispatch]);

//   const redirectToEdit = (id) => {
//     history.push({ pathname: "/edit-user-list", state: { id: id } });
//   };

//   const removeData = (id) => {
//     setremoveID(id);
//     openModal();

//     console.log("ID", id);
//   };

//   const packageRemove = () => {
//     console.log("ID DELETED", removeID);
//     dispatch(removeUsers(removeID));
//     closeModal();
//     dispatch(getAllUserss());
//   };

//   const makeRow = () => {
//     console.log("DATA PAXJAGEHSDBJHBDS", userlist);
//     var data =
//       Array.isArray(userlist) && userlist.length > 0
//         ? userlist.map((data, id) => ({
//           user_name: data.user_name,
//           email: data.email,

//           active: data.is_active === "1" ? "Active" : "Not Active",
//           action: (
//             <div>
//               <Edit color="action" onClick={() => redirectToEdit(data)} />
//               <Delete color="error" onClick={() => removeData(data.id)} />
//             </div>
//           ),
//         }))
//         : null;
//     console.log("NEW DATA SHAPE:", data);
//     setrow(data);
//   };
//   const closeModal = () => {
//     setvisible(false);
//   };
//   const openModal = () => {
//     setvisible(true);
//   };


//   const columns = [
//     {
//       label: "Name",
//       field: "user_name",
//       width: 150,
//       attributes: {
//         "aria-controls": "DataTable",
//         "aria-label": "Name",
//       },
//     },
//     {
//       label: "Email",
//       field: "email",
//       width: 200,
//     },
//     {
//       label: "Active",
//       field: "is_active",
//       width: 200,
//     },

//     {
//       label: "Action",
//       field: "action",
//       sort: "disabled",
//       width: 100,
//     },
//   ];

//   return (
//     <div>
//       {/* <TableList2 heading="Bag" columns={columns} rows={rows} /> */}

//       <div className="card m-2">
//         <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
//           Users List
//         </div>
//         <MDBDataTableV5
//           hover
//           entriesOptions={[5, 20, 25]}
//           entries={5}
//           pagesAmount={4}
//           data={{
//             columns,
//             rows: Array.isArray(row) && row.length > 0 ? row : [],

//           }}
//           style={{ fontSize: 15 }}
//           searchLabel="Search here......"
//           searchTop
//           searchBottom={false}
//           responsive
//           barReverse
//         />
//       </div>
//       <Modal
//         visible={visible}
//         effect="fadeInRight"
//         onClickAway={() => closeModal()}
//         className="modalPop"
//       >
//         <div className="modalPop1">
//           {/* <h1>Title</h1> */}
//           <div className="text-danger">
//             Are you Sure? You want to delete this Record.
//           </div>
//           <div className="d-flex justify-content-around">
//             <div
//               className="btn btn-primary btn-sm"
//               onClick={() => closeModal()}
//             >
//               Cancel
//             </div>
//             <div className="btn btn-danger btn-sm" onClick={packageRemove}>
//               Delete
//             </div>
//           </div>
//         </div>
//       </Modal>

//     </div>
//   );
// }

// export default User_List;

// import React, { useState, useEffect } from 'react';
// import MaterialTable from 'material-table'
// import axios from 'axios';
// import Table from '@material-ui/core/Table';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

// function User_list() {
//   const [data, setData] = useState([])
//   var columns = [
//     { title: "id", field: "id", hidden: true },
//     { title: "First name", field: "first_name" },
//     { title: "Last name", field: "last_name" },
//     { title: "email", field: "email" },

//   ]
//   useEffect(() => {
//     loadUsers();
//   }, [])
//   const loadUsers = async () => {
//     const result = await axios.get("https://loopabackend.p2gmanagement.xyz/api/users/all");
//     setData(result.data.data);
//     console.log(result.data.data);
//   };
//   const deleteUser = async id => {
//     await axios.get("https://loopabackend.p2gmanagement.xyz/api/user/ban/" + id);

//   };
//   return (
//     <div className="d-flex justify-center">
//       <div className="my___tabel__user__list">
//         <MaterialTable
//           title="User list"
//           columns={columns}
//           data={data}
//           actions={[
//             {
//               icon: () => (
//                 <DeleteOutlineIcon fontSize="small"

//                 />
//               ),
//               tooltip: 'Edit Item',

//             },
//             {
//               icon: () => (
//                 <DeleteOutlineIcon fontSize="large"
//                   onClick={() => deleteUser(setData.id)}

//                 />
//               ),
//               tooltip: 'Delete Item',

//             },
//           ]}

//         />
//       </div>
//     </div>
//   );
// }

// export default User_list;
