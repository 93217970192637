import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../Modules/Home/Home";
import Register from "../Modules/User/Register/Register";
// import Modal from "../Modules/Modelss/Modal";

import Login from "../Modules/User/Login/Login";
import AdminLogin from "../Modules/User/AdminLogin/AdminLogin";
import MyAccount from "../Modules/User/Profile/MyAccount";
import InviteFriendz from "../Modules/Invite/InviteFriendz";
import ResetPassword from "../Modules/User/ChangePassword/ResetPassword";
import ChangePassword from "../Modules/User/ChangePassword/ChangePassword";
import ChangePasswordRequest from "../Modules/User/ChangePasswordRequest/ChangePasswordRequest";
import ChoseMemberShip from "../Modules/MemberShip/Chose";
import ChosePlan from "../Modules/ChosePlan/ChosePlan";
import DoYouKnow from "../Modules/DoYouKnow/DoYouKnow";
import Collection from "../Modules/Collection/Collection";
import Collectionss from "../Modules/collectionss/Collectionss";
import ChoseLocation from "../Modules/ChoseLocation/ChoseLocation";
import MemberShip2 from "../Modules/MemberShip2/MemberShip2";
import RecycleBag from "../Modules/RecycleBag/RecycleBag";
import ProductDetail from "../Modules/WebShop/ProductDetail/ProductDetail";
import Store from "../Modules/WebShop/Store/Store";
import Page from "../Modules/BulkyRubbish/BulkRubbishMain/Page";
import HomeWebShop from "../Modules/WebShop/HomeWebShop/HomeWebShop";
import Cart from "../Modules/WebShop/Cart/Cart";
import ShopPage from "../Modules/WebShop/ShopPage/ShopPage";
import StoreProductDetail from "../Modules/WebShop/Store/StoreProductDetail";
import PageNotFound from "../Modules/PageNotFound/PageNotFound";
// import indexRoutes from "../Modules/AdminDashboard/routes/index.jsx";
// import AdminLayout  "../Modules/AdminDashboard/layouts/Admin";
import routes from "../Modules/Admin_Dashboard/routes";
import withTracker from "../Modules/Admin_Dashboard/withTracker";
import "../Modules/Admin_Dashboard/shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import HomeShop from "../Modules/WebShop/HomeShop/HomeShop";
import Shop from "../Modules/WebShop/Shop/Shop";
import BulkRubbish from "../Modules/WebShop/BulkRubbish/BulkRubbish";
import Business from "../Modules/WebShop/Business/Business";

import MemberShipSingle from "../Modules/MemberShip2/MembershipSingle";
import MemberShipRelax from "../Modules/MemberShip2/MembershipRelax";
import CollectionPayment from "../Modules/CollectionPayment/CollectionPayment";
import UserSubscriptions from "../Modules/UserSubscriptions/UserSubscriptions";
import UserOrderLists from "../Modules/UserSubscriptions/UserOrderLists";
import RubbishSubscription from "../Modules/UserSubscriptions/RubbishSubscription";
import {PrivateRoute} from "./PrivateRoute";
import Transaction_List from "../Modules/Admin_Dashboard/views/Listing/Transaction_List";
import BlogOverview from "../Modules/Admin_Dashboard/views/BlogOverview";
import RubbishProductDetail from "../Modules/WebShop/BulkRubbish/RubbishProductDetail";
import ThankYou from "../Modules/UserSubscriptions/ThankYou";
import BulkiRubbishCheckOut from "../Modules/WebShop/ShopCard/BulkiRubbishCheckOut";
import FaqPage from "../Modules/Faq/FaqPage";
import FaqDetailPage from "../Modules/FaqDetail/FaqDetailPage";
import TermsPage from "../Modules/terms/TermsPage";
import ContactPage from "../Modules/contact/Contact";
import AboutPage from "../Modules/about-us/AboutPage";
import PolicyPage from "../Modules/policy/PolicyPage";

// Containers
// const TheLayout = React.lazy(() => import("../Modules/AdminPannel/containers/TheLayout"));
// TheLayout
const  admin =localStorage.getItem("role_id");
console.log(admin)
 export default function MainRouter() {
  return (
    <div>
      <Switch>
         {/* <Route exact path="/" component={Home} /> */}
        <Route exact path="/" component={HomeShop} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/admin/login" component={AdminLogin}/>
        <Route exact path="/home" component={Home} />

        <Route exact path="/my-account" component={MyAccount} />
        <Route exact path="/invite" component={InviteFriendz} />

        {/* -------------22 Dec/2020------------- */}
        <Route exact path="/membership" component={ChoseMemberShip} />
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/forgot-password" component={ChangePasswordRequest} />
         <Route exact path="/plan" component={ChosePlan} />
        <Route exact path="/do-you-know" component={DoYouKnow} />
        <Route exact path="/collection" component={Collection} />
        <Route exact path="/collections" component={Collectionss} />
        <Route exact path="/chose-location" component={ChoseLocation} />
        <Route exact path="/membership/details/:id" component={MemberShip2} />
        <Route exact path="/membership/single" component={MemberShipSingle} />
        <Route exact path="/membership/payment" component={CollectionPayment} />
        <Route exact path="/user/subscriptions" component={UserSubscriptions} />
        <Route exact path="/user/store-product" component={UserOrderLists} />
        <Route exact path="/user/rubbishSubscriptions" component={RubbishSubscription} />
        <Route exact path="/user/thank-you" component={ThankYou} />
        <Route exact path="/membership/:id" component={MemberShipSingle} />
        <Route exact path="/membership/relax" component={MemberShipRelax} />
        <Route exact path="/recycle-bag" component={RecycleBag} />
        <Route exact path="/shop/detail/:id" component={ProductDetail} />
        <Route exact path="/store-product/detail/:id" component={StoreProductDetail} />
        <Route exact path="/rubbish/detail/:id" component={RubbishProductDetail} />
        <Route exact path="/bulk-rubbish" component={Page} />
        <Route exact path="/shop/main" component={HomeWebShop} />
        <Route exact path="/shop/cart" component={Cart} />
        <Route exact path="/cart/rubbish" component={BulkiRubbishCheckOut} />
        <Route exact path="/shop/shop" component={ShopPage} />
        <Route exact path="/new-shop/home" component={HomeShop} />
        <Route exact path="/new-shop/shop" component={Shop} />
        <Route exact path="/new-shop/bulk-rubbish" component={BulkRubbish} />
        <Route exact path="/store/shop" component={Store} />
        <Route exact path="/faq" component={FaqPage} />
        <Route exact path="/faq/detail/:id" component={FaqDetailPage} />
        <Route exact path="/terms" component={TermsPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/policy" component={PolicyPage} />
        <Route exact path="/new-shop/business" component={Business} />
          {admin && admin == 1  && admin != null &&
          <Route exact path="/dashboard-overview" component={BlogOverview} />
          }
        {/* <Route exact path="/admin" component={Layout} /> */}
        {/* <Route exact path="/add-product" component={Add_Product} />
        <Route exact path="/add-bulk-rubbish" component={Add_BulkRubbish} />
        <Route exact path="/add-category" component={Add_Category} />
        <Route exact path="/add-packages" component={Add_Packages} /> */}

        {/* {indexRoutes.map((prop, key) => {
          return (
            <Route path={prop.path} key={key} component={prop.component} />
          );
        })} */}

        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker((props) => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              })}
            />
          );
        })}

        <Route path="*" component={PageNotFound} />
      </Switch>
    </div>
  );
}
