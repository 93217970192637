import React, { useEffect, useState } from "react";
import "../ProductDetail/ProductDetail.css";
import { Parent_Strip_Color } from "../../../Common/Color";
import ShopNavbar from "../HeaderShop/ShopNavbar";
import Footer from "../../../Common/Footer/Footer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import CartDetails from "../../CartDrawer/CartDetails";
import Footer2 from "../../../Common/Footer/Footer2";
import ShopNavbar2 from "../Common/ShopNavbar2";
import FooterShop from "../Common/FooterShop";
import {getProductByID, getStoreProductByID} from "../../../actions/productAction";
import { getRubbishProductByID } from "../../../actions/productAction";
import { NOT_FOUND_IMAGE, PRODUCT_IMAGE_URL } from "../../../Services/DataApi";

const drawerWidth = 330;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    title: {
        flexGrow: 1,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: "flex",

        padding: theme.spacing(2, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
}));

function StoreProductDetail(props) {
    const classes = useStyles();
    const { id } = props.match.params;
    const [productDetails, setproductDetails] = useState("");
    const [open, setOpen] = React.useState(false);
    const [cartItems, setCartItems] = React.useState([]);

    const handleDrawerOpen = () => {

        localStorage.setItem("StoreProductID", productDetails.id);
         addToCart(productDetails.id,productDetails.product_name,productDetails.point,productDetails.price,productDetails.type,productDetails.img)
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const addToCart = (id,name,point,price,type,image) => {
        // if (localStorage.getItem('StoreProduct')) {
        //     cart = JSON.parse(localStorage.getItem('StoreProduct'));
        // }
        // cart[id] = (cart[id] || 0) + 1;
        // localStorage.setItem('StoreProduct', JSON.stringify(cart));
        let cart = [];
        let itemToAdd = {
            id,
            count: 1,
            name:name,
            price:price,
            point:point,
            type:type,
            image:image,

        };
        if (localStorage.getItem('StoreProduct')) {
            cart = JSON.parse(localStorage.getItem('StoreProduct'));
            let item = cart.find(el => el.id === id);
            if (!item) {
                cart.push(itemToAdd);
            } else {
                ++ item.count;
            }
        } else {
            cart = [itemToAdd];
        }
        setCartItems(cart)
        localStorage.setItem('StoreProduct', JSON.stringify(cart))
        console.log(localStorage.getItem('StoreProduct'))
    };

    useEffect(() => {
        getStoreProductByID(id).then((res) => {
             setproductDetails(res.data.data);
        });
    }, []);



    return (
        <div className=" bg-white">
            <ShopNavbar2 />
            <div className="container">
                <div className="Section_ProductDetail my-2">
                    <div className="row">
                        <div className="col-lg-6 d-flex justify-content-center align-content-center ">
                            <img
                                src={
                                    productDetails.img
                                        ? PRODUCT_IMAGE_URL + productDetails.img
                                        : NOT_FOUND_IMAGE
                                }
                                alt=""
                                className="img-fluid"
                                style={{maxWidth:"400px"}}
                            />
                        </div>
                        <div className="col-lg-6 p-5">

                            <div className="h2">{productDetails?.product_name}</div>
                            <div className="h4  mt-3">
                                {productDetails.type== 'point' ? productDetails.point: productDetails.price}
                            </div>
                            <div className="h6 textGrey mt-3"><span>description</span>: {productDetails?.description}</div>

                            {/*<div className="h5  mt-3">SIZE </div>*/}
                            <div className="row ">
                                {/*<div className="col-2 ">*/}
                                    {/*<div className="h5 m-0 border text-center py-2"> {productDetails?.price}</div>*/}
                                {/*</div>*/}
                                <div className="col-4 ">



                                    <Button
                                        variant="contained"
                                        color="#000"
                                        onClick={handleDrawerOpen}
                                         style={{
                                            backgroundColor: "#000",
                                            color: "#fff",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        ADD TO CART
                                    </Button>
                                </div>
                            </div>

                            {/*<div className="h6 textGrey mt-5">{productDetails?.p_name}</div>*/}

                            {/*<div className="h6 textGrey mt-4">*/}
                                {/*Upper - Cow Leather /Cow Suede/Kid Fur*/}
                            {/*</div>*/}

                            {/*<div className="h6 textGrey mt-3">*/}
                                {/*Insock/Lining - Kid Fur (Warm Lining)*/}
                            {/*</div>*/}
                            {/*<div className="h6 textGrey mt-3">Outsole - Rubber </div>*/}

                            {/*<div className="h6 mt-5">*/}
                                {/*Share : &nbsp;&nbsp; <i class="fab fa-facebook fa-2x"></i>{" "}*/}
                                {/*&nbsp;&nbsp;*/}
                                {/*<i class="fab fa-twitter-square fa-2x"></i>&nbsp;&nbsp;*/}
                                {/*<i class="fab fa-pinterest-square fa-2x"></i>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <FooterShop />
            <Drawer
                className="bg-light"
                variant="persistent"
                anchor="right"
                open={open}

                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className="bg-light">
                    <CloseIcon
                        onClick={handleDrawerClose}
                        className="close__btn__css mt-2 "
                    />
                </div>

                <CartDetails cartItems={cartItems}/>
            </Drawer>
        </div>
    );
}

export default StoreProductDetail;
