import {React,useState,useEffect} from "react";
import { NavLink } from 'react-router-dom';
import FooterShop from '../WebShop/Common/FooterShop';
import ShopNavbar2 from '../WebShop/Common/ShopNavbar2';
import './FaqPage.css';
import Axios from "../../Services/Axios";
import {LoadingState} from "../../actions/loaderActions";
import toast from "../../utils/toast";
import { useDispatch } from "react-redux";

const FaqPage = () => {

    const [faqCat, setFaqCat] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {


        Axios.get("/api/faq/categories")
            .then((res) => {
                if(res.data.data.length > 0) {
                    setFaqCat(res.data.data)
                    }

                dispatch(LoadingState(false));
            })
            .catch((err) => {

                // dispatch(LoadingState(false));
            });

    },[]);
    return(
        <div className="faqPage">
            <ShopNavbar2 />
            <div className="banner">
                <h1>FAQ</h1>
            </div>
            <div className="container">
                <div className="contentWrap">
                    <h2 className="listHeading">Vanliga frågor och svar</h2>
                    <ul className="list-unstyled">
                        {faqCat.map((data, id) => (
                            <li>

                                <NavLink to={"/faq/detail/" + data.id}>
                                    <div className="iconWrap">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" ariaHidden="true" className="v-icon__svg" style={{fontSize:36,height:36,width:36}}><path d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z"></path></svg>
                                        </span>
                                    </div>
                                    <div>
                                        <h3>{data.category_name}</h3>
                                     </div>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <FooterShop />
        </div>
    )
}

export default FaqPage;