import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBag } from "../../../../actions/recyclebagActions";
import Loader from "react-loader-spinner";
import { updateZipCode } from "../../../../actions/zipCodeAction";

const Edit_ZipCode = ({ history }) => {
  const data = history.location.state.data;

  const [name, setname] = useState(data.zip_code);
  const [status, setstatus] = useState(data.zip_active);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loaderReducer);

  // history.location.state.id

  const onClickListener = () => {
    const data2 = {
      zip_code: name,
      zip_active: status,
    };
    // const formData = new FormData();
    // formData.append("zip_code", name);
    // formData.append("zip_active", status);
    console.log("SEND DATA", data2, data.zip_id);
    dispatch(updateZipCode(data2, data.zip_id));
  };
  return (
    <div className="h-100 mt-2 p-2  mx-auto">
      <div className="container card p-5  mobile__cs">
        <h2 className="text-center my-3">Edit ZipCode</h2>
        <div className="row mt-5">
          <div className="col-lg-12 col-md-12 col-12 mx-auto">
            <br />
            <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Zip Code
              </span>
              <input
                type="number"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
                value={name}
              />
            </div>

            <br />
          </div>
        </div>
        <select
          className="form-select w-100"
          aria-label="Default select example"
          onChange={(e) => setstatus(e.target.value)}
          value={status}
        >
          <option selected>Status type</option>
          <option value="1">active</option>
          <option value="2">not active</option>
        </select>
        <br />

        <div className=" mx-auto mt-3">
          <div className="btn btn-primary mt-5" onClick={onClickListener}>
            Edit ZipCode
            <Loader
              type="Puff"
              color="#FFF"
              height={20}
              width={15}
              style={{ marginLeft: 5 }}
              visible={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit_ZipCode;
