const initialState = {
  data: [],
  auth: false,
  open: false,
  error: "",
  item: {},
  post: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "REG_USER":
      let newData = [...state.data, action.payload];
      return { ...state, auth: false, data: newData };

    case "LOG_USER":
      return {
        ...state,
        open: false,
        error: "",
        data: action.payload,
        auth: true,
      };
    case "ERROR_LOGIN":
      return {
        open: true,
        auth: false,
        error: action.payload.response.data.error,
      };
    case "LOG_OUT":
      return {
        data: [],
        auth: false,
      };
    case "ADD_POST":
      let postData = action.payload;
      return { ...state, item: postData };
    case "GET_POST":
      let postAll = [action.payload];
      return { ...state, post: postAll };
    case "CLOSE_MODAL":
      return {
        ...state,
        open: false,
      };
    //   case "ADD_USER":
    //     let newData = [...state.data, action.payload];
    //     return { ...state, data: newData };
    default:
      return state;
  }
};
