import React, {useEffect, useState} from "react";
import "./App.css";
import {NavLink, withRouter} from "react-router-dom";
import MainRouter from "./App-Router/MainRouter";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import createBrowserHistory from "history/createBrowserHistory";
import cookieIcon from './assets/images/cookie.png';

export const history = createBrowserHistory({
    forceRefresh: true
})

function App(props) {

    const url = window.location.href;
    const [cookies, setCookies] = useState(false);
    const [cookieAgreement, setCookieAgreement] = useState(0);


    function setCookie(cname, cvalue, exdays) {
        // setCookies(true)
        // if (cookies != false) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        // }
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(()=>{
        let cookieValue = getCookie('cookie_agreement');
        setCookieAgreement(cookieValue?1:0);
    },[]);

    useEffect(() => {
        if(window.location.hash != ""){
            let section = document.getElementById(window.location.hash.replace('#',''));
            section.scrollIntoView();
        }else{
            window.scrollTo(0, 0)
        }
    }, [window.location.href])

    //  if (url.indexOf('/admin/') != -1) {
    //
    //     if (window.localStorage.getItem('role_id') != 1 ||  window.localStorage.getItem('role_id') != null ) {
    //          history.push("/admin/login");
    //
    //     }
    // }

    return (
        <div>
            <MainRouter/>
        {!cookieAgreement && <>

             <div className="cookieBox">
                <img src={cookieIcon} width={20} height={20} style={{marginRight: 10}}/>We use cookies.
                <NavLink to="/terms">Read more </NavLink>
                <a className="okBtn" href="javascript:void(0)"
                   onClick={() => {
                       setCookie('cookie_agreement', 1, 365);
                       setCookieAgreement(1);
                   }}
                >Ok!</a>
            </div>
        </>}
         </div>
    );
}

export default withRouter(App);
