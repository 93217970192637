import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBag } from "../../../../actions/recyclebagActions";
import Loader from "react-loader-spinner";
import {getAllPackages} from "../../../../actions/packageActions";

const Edit_Bag = ({ history }) => {
  const data = history.location.state.data;
    console.log("SEND DATA", data);
    const [selectedPkg, setSelectedPkg] = useState(null);
    const [price, setPrice] = useState(data.price);
    const [isFormSubmit, setIsFormSubmit] = useState(0);

  const [name, setname] = useState(data.bag_name);
   const [status, setstatus] = useState(data.bag_active);
  const dispatch = useDispatch();
    const [selectedPackages, setSelectedPackages] = useState(data.package_id);
    const { packages } = useSelector((state) => state.packageReducer);

    const { loading } = useSelector((state) => state.loaderReducer);

  // history.location.state.id

  const onClickListener = () => {
      setIsFormSubmit(1)

      if(name!='' &&  status!='' && selectedPackages!='' && price!='' ) {
          const data2 = {
              bag_name: name,
              bag_active: status,
              price: price,
              package_id: selectedPackages,
          };
          dispatch(updateBag(data2, data.bag_id,history));
          history.push("/admin/bag-list");
      }
  };
    useEffect(() => {
        if (Array.isArray(packages) && packages.length > 0) {
            setSelectedPkg(packages);
        } else {
            dispatch(getAllPackages());
        }
    }, [dispatch,packages])
  return (
    <div className="mt-2 p-2 mx-auto">
      <div className="container card p-5  mobile__cs">
        <h1 className="text-center my-3">Edit Bags</h1>
        <div className="row mt-5">
          <div className="col-lg-12 col-md-12 col-12 mx-auto">
            <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Bag Name
              </span>
              <input
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
                value={name}
              />

            </div>
              {isFormSubmit == 1 && name=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
              <br />
              <select
                  style={{ marginTop: '12px'}}
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setSelectedPackages(e.target.value)}
                  value={selectedPackages}
              >
                  <option value="" disabled selected>Packages</option>
                  {selectedPkg && selectedPkg.map(data => (
                      <option value={data.pkg_id}>{data.pkg_name}</option>
                  ))}
              </select>
              {isFormSubmit == 1 && selectedPackages=="" && <label className="row mx-auto"  style={{color:"red"}}
              >select at least one package</label>}
              <br />
              <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Price
              </span>
                  <input
                      type="text"
                      className="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setPrice(e.target.value)}
                      value={price}

                  />

              </div>
              {isFormSubmit == 1 && price=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
            {/* <br />
            <div className="">
              <input className="form-control" type="file" id="formFile" />
            </div> */}
            <br />
            {/* <div className="input-group ">
                            <span className="input-group-text" id="inputGroup-sizing-default">
                                Bag Quantity
              </span>
                            <input
                                type="number"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </div><br /> */}
            <select
              className="form-select mt-1"
              aria-label="Default select example"
              onChange={(e) => setstatus(e.target.value)}
              value={status}
            >
              <option  disabled>Status Type</option>
              <option value="1" selected>active</option>
              <option value="0">not active</option>
            </select>
            <div className="d-flex justify-content-center">
              <div className="btn btn-primary mt-5" onClick={onClickListener}>
                Edit Bag
                <Loader
                  type="Puff"
                  color="#FFF"
                  height={20}
                  width={15}
                  style={{ marginLeft: 5 }}
                  visible={loading}
                />
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit_Bag;
