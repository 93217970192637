import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useDispatch, useSelector } from "react-redux";
import {
  addBulkRubbishProduct,
  updateBulkRubbishProduct,
} from "../../../../actions/productAction";
import {
    clearCategories,
    getAllBulkRubbishCategories, getAllCategories,
} from "../../../../actions/categoryAction";
import {
  BULKRUBBISH_PRODUCT_IMAGE_URL,
  NOT_FOUND_IMAGE,
  PRODUCT_IMAGE_URL,
} from "../../../../Services/DataApi";


const Edit_BulkRubbish = ({ history }) => {
  const ProductData = history.location.state.data;
   const dispatch = useDispatch();
  const initialState = "";
  const [size, setsize] = useState(ProductData.brp_size);

  const [name, setname] = useState(ProductData.brp_name);
  const [image, setimage] = useState(ProductData.brp_img);
  const [price, setprice] = useState(ProductData.brp_points);
  const [status, setstatus] = useState(ProductData.brp_active);
  const [categoryProduct, setcategory] = useState(ProductData.brp_brc_id);
  const [productDescription, setproductDescription] = useState(
    ProductData.brp_desc
  );
    const { bulkiRubbish } = useSelector((state) => state.bulkiRubbish);

    console.log(bulkiRubbish)
  const onClickListener = () => {
    const data = {
      brp_name: name,
      brp_desc: productDescription,
      brp_points: price,
      // brp_img: image,
      brp_size: size,
      brp_active: status,
      brp_brc_id: categoryProduct,
    };
    const formData = new FormData();
    formData.append("brp_name", name);
    formData.append("brp_desc", productDescription);
    formData.append("brp_points", price);
    formData.append("brp_img[]", image);
    formData.append("brp_size", size);
    formData.append("brp_active", status);
    formData.append("brp_brc_id", categoryProduct);

     dispatch(updateBulkRubbishProduct(formData, ProductData.brp_id,history));
  };

  useEffect(() => {
    if (Array.isArray(bulkiRubbish) && bulkiRubbish.length > 0) {
    } else {
      dispatch(getAllBulkRubbishCategories());
    }
      if (Array.isArray(bulkiRubbish) && bulkiRubbish.length > 0) {
      } else {
          dispatch(getAllCategories());
      }
  }, [dispatch, bulkiRubbish]);

  useEffect(() => {
    return () => {
      dispatch(clearCategories());
      console.log("clearPackage");
    };
  }, [dispatch]);

  return (
    <div className="container p-2">
      <div className="container card p-5 add-products-container">
        <h2 className="text-center mb-5">Edit BulkRubbish Products</h2>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 mx-auto mt-2">
            <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product Name
              </span>
              <input
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
                value={name}
              />
            </div>

            <div className="mb-3 mt-2">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-6">
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={(e) =>

                        setimage(e.target.files[0])
                    }
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 d-flex justify-content-center">
                  <img
                    src={
                      image
                        ? BULKRUBBISH_PRODUCT_IMAGE_URL + image
                        : NOT_FOUND_IMAGE
                    }
                    alt=""
                    style={{
                      height: 70,
                      width: 70,
                      objectFit: "contain",
                      borderRadius: 50,
                    }}
                  />
                </div>
              </div>{" "}
            </div>

            <div className="input-group mb-3 ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product Points
              </span>
              <input
                type="number"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setprice(e.target.value)}
                value={price}
              />
            </div>

            <lebel>Status : </lebel>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setstatus(e.target.value)}
              value={status}
            >
              <option selected>select type</option>
              <option value="0">Not Active</option>
              <option value="1">Active</option>
            </select>

            <br />
          </div>
          <div className="col-lg-6 col-md-6 col-12 mx-auto mt-2">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setsize(e.target.value)}
              value={size}
            >
              <option selected>Size</option>
              <option value="small">Small</option>
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="extra-small">Extra Small</option>
              <option value="extra-large">Extra Large</option>
              {/* <option value="3">six</option> */}
            </select>
            <br />
              {/*{JSON.stringify(bulkiRubbish)}*/}

            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setcategory(e.target.value)

              }
              value={categoryProduct}
            >
              <option selected>Select Catagory</option>
              {Array.isArray(bulkiRubbish) && bulkiRubbish.length > 0
                ? bulkiRubbish.map((data, id) => (
                    <option key={id} value={data.brc_id}>
                      {data.brc_name}
                    </option>
                  ))
                : null}
            </select>

            <br />
            <div className="mb-3">
              <label for="exampleFormControlTextarea1" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                onChange={(e) => setproductDescription(e.target.value)}
                value={productDescription}
              ></textarea>
            </div>
          </div>
        </div>
        <div className=" mx-auto mt-3">
          <button
            type="button"
            class="btn btn-primary "
            onClick={onClickListener}
          >
            Add BulkRubbish
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit_BulkRubbish;
