import React, { useEffect, useState } from "react";
import TableList2 from "../../components/TableListing/TableList2";
import TableListing from "../../components/TableListing/TableListing";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Modal from "react-awesome-modal";
import { MDBDataTableV5 } from "mdbreact";

import { useDispatch, useSelector } from "react-redux";
import {
  clearPackage,
  getAllPackages,
    getAllPackagesForAdmin,
  removePackage,
} from "../../../../actions/packageActions";
import packageAdminReducer from "../../../../reducers/allPackagesAdmin";
const useForceUpdate = () => useState()[1];

function Packages_List({ history }) {
  const [visible, setvisible] = useState(false);
  const { packages } = useSelector((state) => state.packageAdminReducer);
  const dispatch = useDispatch();
  const [row, setrow] = useState([]);
  const [removeID, setremoveID] = useState("");

  const closeModal = () => {
    setvisible(false);
  };
  const openModal = () => {
    setvisible(true);
  };

  useEffect(() => {
    console.log("Package VALUE");
    if (Array.isArray(packages) && packages.length > 0) {
      makeRow();
    } else {
      dispatch(getAllPackagesForAdmin());
    }
  }, [dispatch, packages]);

  useEffect(() => {
    return () => {
      dispatch(clearPackage());
      dispatch(getAllPackagesForAdmin())
      console.log("clearPackage",packages);
    };
  }, [dispatch]);

  const redirectToEdit = (id) => {
     history.push({ pathname: "/admin/edit-packages", state: { id: id } });
  };

  const removeData = (id) => {
    setremoveID(id);
    openModal();
  };

  const packageRemove = () => {
    console.log("ID DELETED", removeID);
    dispatch(removePackage(removeID));
    closeModal();
    if (dispatch(getAllPackagesForAdmin())) {
      forceUpdate();
    }
  };
  const forceUpdate = useForceUpdate();

  const makeRow = () => {
    console.log("DATA PAXJAGEHSDBJHBDS", packages);
    var data =
      Array.isArray(packages) && packages.length > 0
        ? packages.map((data, id) => ({
            pkg_name: data.pkg_name,
              pkg_description: data.pkg_description,
              no_of_days: data.no_of_days,
              frequency: data.frequency,
              pkg_active: data.pkg_active === "1" ? "Active" : "Not Active",

            active: data.pkg_active === "1" ? "Active" : "Not Active",
            action: (
              <div>
                <Edit color="action" onClick={() => redirectToEdit(data)} />
                <Delete color="error" onClick={() => removeData(data.pkg_id)} />
              </div>
            ),
          }))
        : null;
    console.log("NEW DATA SHAPE:", data);
    setrow(data);
    forceUpdate();
  };
  const columns = [
    {
      label: "Package Name",
      field: "pkg_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Status",
      field: "pkg_active",
      width: 200,
    },
    {
      label: "Description",
      field: "pkg_description",
      width: 200,
    },
    {
      label: "No Of Days",
      field: "no_of_days",
      width: 200,
    },
    {
      label: "Frequency",
      field: "frequency",
      width: 200,
    },

    {
      label: "Action",
      field: "action",
      sort: "disabled",
      width: 100,
    },
  ];

  return (
    <div>
      {/* <TableList2 heading="Package" columns={columns} rows={row} /> */}
      {/* {Array.isArray(row) && row.length > 0 ? "Pakistan" : "ROW"} */}
      <div className="card m-2">
        <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
          Package List
        </div>
        <MDBDataTableV5
          hover
          entriesOptions={[5, 20, 25]}
          entries={5}
          pagesAmount={4}
          data={{
            columns,
            rows: Array.isArray(row) && row.length > 0 ? row : [],
          }}
          style={{ fontSize: 15 }}
          searchLabel="Seacrh here......"
          searchTop
          searchBottom={false}
          responsive
          barReverse
        />
      </div>
      <Modal
        visible={visible}
        effect="fadeInRight"
        onClickAway={() => closeModal()}
        className="modalPop"
      >
        <div className="modalPop1">
          {/* <h1>Title</h1> */}
          <div className="text-danger">
            Are you Sure? You want to delete this Record.
          </div>
          <div className="d-flex justify-content-around">
            <div
              className="btn btn-primary btn-sm"
              onClick={() => closeModal()}
            >
              Cancel
            </div>
            <div className="btn btn-danger btn-sm" onClick={packageRemove}>
              Delete
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Packages_List;
