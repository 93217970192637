import React, { useState } from "react";
import ShopNavbar from "../HeaderShop/ShopNavbar";
import HoverShopCard from "../ShopCard/HoverShopCard";
import ShopCard from "../ShopCard/ShopCard";
import ShopCategoryCard from "../ShopCard/ShopCategoryCard";
import Catagories from "./SideBar/Catagories";
import SidebarAccordian from "./SideBar/SidebarAccordian";
import "./HomeWebShop.css";
import Footer from "../../../Common/Footer/Footer";
import InfiniteCarousel from "react-leaf-carousel";
import HorizontalScroller from "./HorizontalScroller/HorizontalScroller";

function HomeWebShop() {
  return (
    <div>
      <ShopNavbar />
      <div className="row">
        <div class="section__one ">
          <div class="section__one__text">
            <h1>Future-proof</h1>
            <h1>Christmas</h1>
            <p>Second hand some haller dig varm i winter</p>
            <button>Shop Deal</button>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center my-5">
        <div className="col-lg-2 col-md-3 col-sm-12">
          <ShopCard
            title="Shoew1"
            price="$10"
            img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzU60aFM0x5onJ6YyWnghUVZhG_jlPqX7cXg&usqp=CAU"
          />
        </div>
        <div className="col-lg-2 col-md-3 col-sm-12">
          <ShopCard
            title="Shoew2"
            price="$13"
            img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuEuBg_R5o6RBx1s8wJ-kB6ld34V_TINmyqw&usqp=CAU"
          />
        </div>
        <div className="col-lg-2 col-md-3 col-sm-12">
          <ShopCard
            title="Shoew3"
            price="$16"
            img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCiZ2AyS9UvZgwotyOSyT74XQDcKZ6xdI4UQ&usqp=CAU"
          />
        </div>
        <div className="col-lg-2 col-md-3 col-sm-12">
          <ShopCard
            title="Shoew342"
            price="$19"
            img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoBEtzWsiI6dpoM6uxx9Q0j_LFDlMacmhoMA&usqp=CAU"
          />
        </div>
        <div className="col-lg-2 col-md-3 col-sm-12">
          <ShopCard
            title="Shoew342"
            price="$19"
            img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoBEtzWsiI6dpoM6uxx9Q0j_LFDlMacmhoMA&usqp=CAU"
          />
        </div>
      </div>
      {/* <div className="row mt-5">
        <div className="col-lg-3 col-md-3 sidebarHome">
          <Catagories />
          <SidebarAccordian />
        </div>
        <div className="col-lg-9 col-md-9 col-sm-12 mt-5">
          <div className="row mt-1">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <ShopCard
                title="Shoew1"
                price="$10"
                img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzU60aFM0x5onJ6YyWnghUVZhG_jlPqX7cXg&usqp=CAU"
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <ShopCard
                title="Shoew2"
                price="$13"
                img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuEuBg_R5o6RBx1s8wJ-kB6ld34V_TINmyqw&usqp=CAU"
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <ShopCard
                title="Shoew3"
                price="$16"
                img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCiZ2AyS9UvZgwotyOSyT74XQDcKZ6xdI4UQ&usqp=CAU"
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <ShopCard
                title="Shoew342"
                price="$19"
                img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoBEtzWsiI6dpoM6uxx9Q0j_LFDlMacmhoMA&usqp=CAU"
              />
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <ShopCard
                title="Shoew1"
                price="$10"
                img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzU60aFM0x5onJ6YyWnghUVZhG_jlPqX7cXg&usqp=CAU"
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <ShopCard
                title="Shoew2"
                price="$13"
                img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuEuBg_R5o6RBx1s8wJ-kB6ld34V_TINmyqw&usqp=CAU"
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <ShopCard
                title="Shoew3"
                price="$16"
                img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCiZ2AyS9UvZgwotyOSyT74XQDcKZ6xdI4UQ&usqp=CAU"
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <ShopCard
                title="Shoew342"
                price="$19"
                img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoBEtzWsiI6dpoM6uxx9Q0j_LFDlMacmhoMA&usqp=CAU"
              />
            </div>
          </div>
        </div>
      </div> */}

      <div className="row my-3">
        <div className="h3">Andras favoriter</div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <HoverShopCard />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <HoverShopCard />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <HoverShopCard />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <HoverShopCard />
        </div>
      </div>
      <div className="row my-3">
        <div className="h3">Populära kategorier</div>
        <HorizontalScroller />
      </div>

      <Footer />
    </div>
  );
}

export default HomeWebShop;
