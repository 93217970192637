import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBag } from "../../../../actions/recyclebagActions";
import Loader from "react-loader-spinner";
import {
  updateBulkRubbishCategory,
  updateCategory,
} from "../../../../actions/categoryAction";

const Edit_BulkCategory = ({ history }) => {
  const data = history.location.state.id;

  const [name, setname] = useState(data.brc_name);
  const [status, setstatus] = useState(data.brc_active);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loaderReducer);
    const [isFormSubmit, setIsFormSubmit] = useState(0);

  // history.location.state.id

  const onClickListener = () => {
      if(name!='' && status!='') {

          const data2 = {
              brc_id: data.brc_id,
              brc_name: name,
              brc_active: status,
          };

          console.log("SEND DATA", data2, data.brc_id);
          dispatch(updateBulkRubbishCategory(data2, data.brc_id));
          history.push('/admin/bulk-category-list');
      }
  };
  return (
    <div className=" mt-3">
      <div className="container card p-5 mobile__cs">
        <h2 className="text-center my-3">Edit Bulky Catagory</h2>
        <div className="row mt-4">
          <div className="col-lg-12 col-md-12 col-12 mx-auto">
            <div className="input-group mb-3  ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                catagory name
              </span>
              <input
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
                value={name}
              />
                {isFormSubmit == 1 && name=="" && <label className="row mx-auto"  style={{color:"red"}}
                >This field is required</label>}
            </div>

            <select
              className="form-select mt-1"
              aria-label="Default select example"
              onChange={(e) => setstatus(e.target.value)}
              value={status}
            >
               <option value="1" selected>active</option>
              <option value="2">not active</option>
            </select>

            <br />
          </div>
        </div>
        <div className=" mx-auto mt-3">
          <div className="btn btn-primary mt-5" onClick={onClickListener}>
            Edit Category
            <Loader
              type="Puff"
              color="#FFF"
              height={20}
              width={15}
              style={{ marginLeft: 5 }}
              visible={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit_BulkCategory;
