import React, { useState } from "react";
import "./BulkCartDetails.css";
import tie from "../../../assets/images/m.png";
import tie1 from "../../../assets/images/t.jpg";
import ti2 from "../../../assets/images/t2.png";
import v from "../../../assets/images/v.jpg";
import ti4 from "../../../assets/images/t3.png";
import ti5 from "../../../assets/images/t6.jpg";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import Tooltip from "@material-ui/core/Tooltip";
import Slider from "infinite-react-carousel";

const BulkCartDetails = () => {
  const [num, setNum] = useState(1);

  const incNum = () => {
    setNum(num + 1);
  };
  const decNum = () => {
    if (num > 0) {
      setNum(num - 1);
    } else {
      alert("Sorry ! Zero Limit Reached");
      setNum(0);
    }
  };
  return (
    <div className="bg-light pl-1">
      <h5 className="text-center bg-light">Din varrukuge</h5>
      <hr className="w-75 mx-auto" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-12 mx-auto">
            <figure>
              <img src={tie} alt="" className="figure___img__css bg-light" />
            </figure>
          </div>
          <div className="col-lg-5 col-md-5 col-12 mx-auto d-flex justify-content-center align-items-center flex-column mb-2">
            <h6>Step</h6>
            <p>Price:90kr</p>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Tooltip title="decreament" aria-label="add">
                <Button onClick={decNum}>-</Button>
              </Tooltip>
              <Button>{num}</Button>
              <Tooltip title="increament" aria-label="add">
                <Button onClick={incNum}>+</Button>
              </Tooltip>
            </ButtonGroup>
          </div>
          <div className="col-lg-2 col-md-2 col-6 mx-auto d-flex justify-content-center align-items-center flex-column mt-5">
            <CreditCardIcon className="text-primary" />
          </div>
        </div>
      </div>
      <hr className="w-75 mx-auto" />
      <h6 className="text-center my-2">Villa du tavatta nutt</h6>

      <Slider dots arrows={true} Height="20px" autoplay>
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6">
              <img src={tie} alt="" className="slider__imggg" />
            </div>
            <div className="col-lg-6 col-md-6 col-6 d-flex justify-content-center align-items-center flex-column ">
              <h6 className="text-center">2 per itemss</h6>
              <p className="text-center">loreem is a collection</p>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6">
              <img src={tie1} alt="" className="slider__imggg" />
            </div>
            <div className="col-lg-6 col-md-6 col-6 d-flex justify-content-center align-items-center flex-column ">
              <h6 className="text-center">2 per itemss</h6>
              <p className="text-center">loreem is a collection</p>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6">
              <img src={ti2} alt="" className="slider__imggg" />
            </div>
            <div className="col-lg-6 col-md-6 col-6 d-flex justify-content-center align-items-center flex-column ">
              <h6 className="text-center">2 per itemss</h6>
              <p className="text-center">loreem is a collection</p>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6">
              <img src={ti5} alt="" className="slider__imggg" />
            </div>
            <div className="col-lg-6 col-md-6 col-6 d-flex justify-content-center align-items-center flex-column ">
              <h6 className="text-center">2 per itemss</h6>
              <p className="text-center">loreem is a collection</p>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6">
              <img src={ti4} alt="" className="slider__imggg" />
            </div>
            <div className="col-lg-6 col-md-6 col-6 d-flex justify-content-center align-items-center flex-column ">
              <h6 className="text-center">2 per itemss</h6>
              <p className="text-center">loreem is a collection</p>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6">
              <img src={v} alt="" className="slider__imggg" />
            </div>
            <div className="col-lg-6 col-md-6 col-6 d-flex justify-content-center align-items-center flex-column ">
              <h6 className="text-center">2 per itemss</h6>
              <p className="text-center">loreem is a collection</p>
            </div>
          </div>
        </div>
      </Slider>
      <hr className="w-75 mx-auto" />
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-6 mx-auto">
            <h6>Delsuma</h6>
            <h6>Moms</h6>
            <h6>Transport</h6>
            <h6>Total</h6>
          </div>
          <div className="col-lg-6 col-md-6 col-6 mx-auto">
            <h6>9000kr</h6>
            <h6>1800kr</h6>
            <h6>Grastil</h6>
            <h6>808080</h6>
          </div>
        </div>
      </div>
      <h5 className="text-center my-1">Loss in en rabattkod</h5>
      <p className="text-center">
        lorem in en rabattkod is just lorem in en rabattkod is
      </p>
      <div className="d-flex justify-content-center">
        <button className="btn___csss___till">Till Betaling</button>
      </div>
      <br />
      <div className="d-flex justify-content-center">
        <button className="btn___csss___till">For hondan</button>
      </div>
    </div>
  );
};

export default BulkCartDetails;
