import Button from "@material-ui/core/Button";
import Footer from "../../../Common/Footer/Footer";
import Navbar from "../../../Common/Header/Navbar";
import Axios from "../../../Services/Axios";
import React, { useEffect, useState } from "react";
import {LoadingState} from "../../../actions/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import toast from "../../../utils/toast";
import ShopNavbar2 from "../../WebShop/Common/ShopNavbar2";
import FooterShop from "../../WebShop/Common/FooterShop";

const AdminChangePassword = () => {
    const history = useHistory();

    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const dispatch = useDispatch();
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");


    const onClickListener = () => {
        const sendingData = {
            user: user,
            password: password,
            password_confirmation: passwordConfirmation,
            token: token,
            email: email,

        }
        console.log(sendingData)
        Axios.post("/api/user/reset-password", sendingData)
            .then((res) => {
                // console.log('bhhhghg1',res.data.data);
                if (res.data.length > 0) {
                    history.push("/login");

                }
                dispatch(LoadingState(false));

            })
            .catch((err) => {
                const data = err.response.data.errors.password;
                if(data=='The password confirmation does not match.')
                {
                    toast.error("The password confirmation does not match");

                }else{
                    toast.error("  The password must be at least 8 characters");

                }
                console.log('bhhhghg1',data);

                // dispatch(LoadingState(false));
            });
    };

    console.log((user))

    return (
        <div>
             <h1 className="text-center my-5">Change password</h1>
            <div className="container">
                <div className="col-lg-4 col-md-4 col-12 mx-auto">
                    <form>
                        <div className="wrapper">
                            <div className="input-data">
                                <input type="password" required onChange={(e) => {
                                    setPassword(e.target.value);
                                }} />
                                <div className="underline"></div>
                                <label>PASSWORD</label>
                            </div>
                            <br />
                            <div className="input-data">
                                <input type="password" required  onChange={(e) => {
                                    setPasswordConfirmation(e.target.value);
                                }}/>
                                <div className="underline"></div>
                                <label>Confirm PASSWORD</label>
                            </div>
                            <br />
                            <br />
                            <div className="d-flex justify-content-center">
                                <Button variant="contained" color="primary"
                                        onClick={onClickListener}>
                                    change password
                                </Button>
                            </div>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
         </div>
    );
};

export default AdminChangePassword;
