import React, { useState,useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";

import { getAllCollection } from '../../../../actions/paymentActions';
import collectionReducer from "../../../../reducers/collectionReducer";


function CollectionOrder({ history }) {
    const { allCollection } = useSelector((state) => state.collectionReducer);
    const [row, setrow] = useState([]);
    const dispatch = useDispatch();
    const [trans, setTrans] = useState(null);

    const makeRow = () => {
        console.log("DATA PAXJAGEHSDBJHBDS", allCollection);
        var data =
            Array.isArray(allCollection) && allCollection.length > 0 ? allCollection.map((data, id) => ({
                    pickup_address: data.pickup_address,
                    pickup_zip: data.pickup_zip,
                    instructions_for_driver: data.instructions_for_driver,
                    next_payment_date: data.next_payment_date,
                    last_pickedup_date: data.next_pickedup_date,
                    pickup_date_from: data.pickup_date_from,
                    pickup_date_to: data.pickup_date_to,
                    pickup_district: data.pickup_district,
                    status: data.status,
                    cell_phone: data.cell_phone,
                    bag_name: data.bag_name,
                    user_id: data.user_id,
                    pkg_name: data.pkg_name,
                    //
                    // status: data.status === "1" ? "Active" : "Not Active",
                    // action: (
                    //     <div>
                    //         <Edit color="action" onClick={() => redirectToEdit(data)} />
                    //         <Delete color="error" onClick={() => removeData(data.zip_id)} />
                    //     </div>
                    // ),
                }))
                : null;
        setrow(data);
        console.log("NEW DATA SHAPE:", data);

    };

    const columns = [
        {
            label: "User Id",
            field: "user_id",
            width: 200,
        },{
            label: "Bag Name",
            field: "bag_name",
            width: 200,
        },{
            label: "Package Name",
            field: "pkg_name",
            width: 200,
        },{
            label: "Pickup Address",
            field: "pickup_address",
            width: 200,
        }, {
            label: "Pickup Zip",
            field: "pickup_zip",
            width: 200,
        }, {
            label: "Pickup District",
            field: "pickup_district",
            width: 200,
        }, {
            label: "Cell Phone",
            field: "cell_phone",
            width: 200,
        }, {
            label: "Instructions For Driver",
            field: "instructions_for_driver",
            width: 200,
        }, {
            label: "Pickup Date From",
            field: "pickup_date_from",
            width: 200,
        }, {
            label: "Pickup Date To",
            field: "pickup_date_to",
            width: 200,
        },{
            label: "Next Payment Date",
            field: "next_payment_date",
            width: 200,
        }, {
            label: "Last Pickedup Date",
            field: "last_pickedup_date",
            width: 200,
        }, {
        label: "Status",
            field: "status",
            width: 200,
        },
    ];

    useEffect(() => {
        console.log(trans)

        if (Array.isArray(allCollection) && allCollection.length > 0) {
            makeRow();
            setTrans(allCollection);
        } else {
            dispatch(getAllCollection());
        }
    }, [dispatch,allCollection]);


    return (
        <div>
            <div className="card m-2">
                <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
                      Collections Order
                </div>
                <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={{
                        columns,
                        rows: Array.isArray(row) && row.length > 0 ? row : [],
                    }}
                    style={{ fontSize: 15 }}
                    searchLabel="Seacrh here......"
                    searchTop
                    searchBottom={false}
                    responsive
                    barReverse
                />
            </div>


        </div>
    );
}

export default CollectionOrder;
