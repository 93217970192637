import React from "react";
import "./footer.css";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer className="site-footer text-center">
        {/* <div className="container"> */}
        <div className="row m-0">
          <div className="col-lg-4 col-md-4 col-sm-12 p-0">
            <h6>SÄLJ MED SELLPY</h6>
            <ul className="footer-links">
              <li>
                <a href="">Beställ Sellpy-påsar</a>
              </li>
              <li>
                <a href="">Så funkar det</a>
              </li>
              <li>
                <a href="">Vad kostar det?</a>
              </li>
              <li>
                <a href="">Företagsförsäljning</a>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 p-0">
            <h6>UPPTÄCK & HANDLA</h6>
            <ul className="footer-links">
              <li>
                <a href="">Retur</a>
              </li>
              <li>
                <a href="">Reklamationsrätt</a>
              </li>
              <li>
                <a href="">Leveransinformation</a>
              </li>
              <li>
                <Link to="/">WebShop</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <ul className="footer-links">
              <div className="contact___icones">
                <FacebookIcon className="fb__icon___cs" />
                <TwitterIcon className="fb__icon___cs" />
                <InstagramIcon className="fb__icon___cs" />
              </div>
              <li>
                <a href="">2020 Abundo</a>
              </li>
              <li>
                <a href="">supprt@abundolive</a>
              </li>
              <li>
                <a href="">0222-28110</a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        {/* </div> */}
        {/* <div className="container"> */}
        {/* <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text text-white">
              Copyright &copy; 2017 All Rights Reserved by
              <a href="#"></a>.
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <div class="rounded-social-buttons mt-3">
              <a
                class="social-button facebook"
                href="https://www.facebook.com/"
                target="_blank"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                class="social-button twitter"
                href="https://www.twitter.com/"
                target="_blank"
              >
                <i class="fab fa-twitter"></i>
              </a>

              <a
                class="social-button youtube"
                href="https://www.youtube.com/"
                target="_blank"
              >
                <i class="fab fa-youtube"></i>
              </a>
              <a
                class="social-button instagram"
                href="https://www.instagram.com/"
                target="_blank"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div> */}
        {/* </div> */}
        <p className="text-center text-white">
          Tvattdax Sveriage AB(559110-1372) Alla rattigheter reservabale
        </p>
      </footer>
    </div>
  );
};

export default Footer;
