import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link, NavLink } from "react-router-dom";
import LoopaText from "../../../assets/loopa@3x.png";
import styles from "../Css/Footer.module.css";

const FooterShop = () => {
  return (
    <div>
      <div className={styles.site_footer+' footerWraper'}>
        {/* <div className="container"> */}
        <div className="row m-0">
          <div className="col-lg-3 pt-5 col-md-3 col-sm-12 d-flex justify-content-center footer_img_wrap">
            <div>
              <Link to="/">
                <img src={LoopaText} alt="" className={styles.loopaText} style={{margin:0,marginTop:-18}} />
              </Link>
              <h6 className={styles.Address}>
                Lokomobilvägen 3<br /> 131 52 Nacka Strand
              </h6>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12 mx-auto ">
            <div className="row mt-5">
              <div className="col-lg-3 col-md-3 col-sm-12 p-0 col-6  mx-auto">
                <h6 className={styles.headingLinks}>Support</h6>
                <ul className={styles.footerLinks}>
                  <li>
                    <NavLink to="/faq">Vanliga frågor</NavLink>
                  </li>
                  <li>
                    <NavLink to="/terms">Villkor</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Kontakt</NavLink>
                  </li>
                  <li>
                    <NavLink to="/policy">Integritetspolicy</NavLink>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 p-0 col-6  mx-auto">
                <h6 className={styles.headingLinks}>Övrigt</h6>
                <ul className={styles.footerLinks}>
                  <li>
                    <Link to="/new-shop/business">Företag</Link>
                  </li>
                  <li>
                    <Link to="/new-shop/business#foreningar">Föreningar</Link>
                  </li>
                  <li>
                    <Link to="/login">Mina sidor</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 p-0 col-6  mx-auto">
                <h6 className={styles.headingLinks}>Loopa</h6>
                <ul className={styles.footerLinks}>
                  <li>
                    <Link to="/about">Om oss</Link>
                  </li>
                  <li>
                    <Link to="/about#process">Process</Link>
                  </li>
                  <li>
                    <a href="mailto:jobba@loopa.se">Jobba hos oss</a>
                  </li>
                  {/* <li>
                    <Link to="/shop/shop">Press</Link>
                  </li> */}
                </ul>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 col-6 mx-auto">
                <h6 className={styles.headingLinks}>Social</h6>
                <ul className={styles.footerLinks}>
                  {/* <div className="contact___icones">
                    <FacebookIcon color="#000" />
                    <TwitterIcon color="#000" />
                    <InstagramIcon color="#000" />
                  </div> */}
                  <li>
                    <a href="">Facebook</a>
                  </li>
                  <li>
                    <a href="">Instagram</a>
                  </li>
                  <li>
                    <a href="">Linkedin</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* <div className="container"> */}
        {/* <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text text-white">
              Copyright &copy; 2017 All Rights Reserved by
              <a href="#"></a>.
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <div class="rounded-social-buttons mt-3">
              <a
                class="social-button facebook"
                href="https://www.facebook.com/"
                target="_blank"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                class="social-button twitter"
                href="https://www.twitter.com/"
                target="_blank"
              >
                <i class="fab fa-twitter"></i>
              </a>

              <a
                class="social-button youtube"
                href="https://www.youtube.com/"
                target="_blank"
              >
                <i class="fab fa-youtube"></i>
              </a>
              <a
                class="social-button instagram"
                href="https://www.instagram.com/"
                target="_blank"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div> */}
        {/* </div> */}
        <div className="text-center mt-5 pt-5 pb-5 home_footer_wrap">
          <div className={styles.copyrightText+' home_section_para'}>
            © 2021 Loopa AB. Lokomobilvägen 3, 131 52 Nacka Strand. Org. nr.
            -----------{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterShop;
