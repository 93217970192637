export default function () {
  return [
    {
      title: "Blog Dashboard",
      to: "/admin/dashboard-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: "",
    },
    {
      title: "Add Product",
      to: "/admin/add-product",
      htmlBefore: '<i class="material-icons">inventory_2</i>',
      htmlAfter: "",
      menu: "Product",
    },
    {
      title: "Add BulkRubbish Product",
      to: "/admin/add-bulk-rubbish",
      htmlBefore: '<i class="material-icons">widgets</i>',
      htmlAfter: "",
      menu: "Add_BulkRubbish_Product",
    },
    {
      title: "Add Categry",
      to: "/admin/add-category",
      htmlBefore: '<i class="material-icons">widgets</i>',
      htmlAfter: "",
      menu: "Category",
    },
    {
      title: "Add Bulky Category",
      to: "/admin/add-bulk-rubbish-category",
      htmlBefore: '<i class="material-icons">widgets</i>',
      htmlAfter: "",
      menu: "Add_BulkRubbish_Product",
    },
    {
      title: "Add Packages",
      to: "/admin/add-packages",
      htmlBefore: '<i class="material-icons">card_membership</i>',
      htmlAfter: "",
      menu: "Packages",
    },
    {
      title: "Add Bags",
      to: "/admin/add-bags",
      htmlBefore: '<i class="material-icons">luggage</i>',
      htmlAfter: "",
      menu: "Bags",
    },
    {
      title: "Add ZipCode",
      to: "/admin/add-zip-code",
      htmlBefore: '<i class="material-icons">language</i>',
      htmlAfter: "",
      menu: "Zip",
    },
    {
      title: "Add Product Type",
      to: "/admin/add-product-type",
      htmlBefore: '<i class="material-icons">inventory_2</i>',
      htmlAfter: "",
      menu: "ProductType",
    },
    {
      title: "Product Type List",
      to: "/admin/product-type-list",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
      menu: "ProductType",
    },
    {
      title: "Bag List",
      to: "/admin/bag-list",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
      menu: "Bags",
    },
    {
      title: "Product List",
      to: "/admin/product-list",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
      menu: "Product",
    },
    {
      title: "Bulk Rubbish Product List",
      to: "/admin/bulk-rubbish-list",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
      menu: "Add_BulkRubbish_Product",
    },
    {
      title: "Category List",
      to: "/admin/category-list",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
      menu: "Category",
    },
    {
      title: "Bulky Category List",
      to: "/admin/bulk-category-list",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
      menu: "Add_BulkRubbish_Product",
    },
    {
      title: "Packages List",
      to: "/admin/packages-list",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
      menu: "Packages",
    },
    {
      title: "ZipCode List",
      to: "/admin/zip-list",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
      menu: "Zip",
    },
    // {
    //   title: "Order List",
    //   to: "/admin/order-list",
    //   htmlBefore: '<i class="material-icons">list</i>',
    //   htmlAfter: "",
    //   menu: "Order",
    // },
    {
      title: "User List",
      to: "/admin/user-list",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
      menu: "User",
    },
      {
          title: "Contact Requests",
          to: "/admin/user-contact",
          htmlBefore: '<i class="material-icons">list</i>',
          htmlAfter: "",
          menu: "User",
      },
      {
          title: "User Request",
          to: "/admin/user-request",
          htmlBefore: '<i class="material-icons">list</i>',
          htmlAfter: "",
          menu: "User",
      },
    {
      title: "Transaction List",
      to: "/admin/transaction-list",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
      menu: "Transaction",
    }, {
      title: "Rubbish order list",
      to: "/admin/rubbish-order",
      htmlBefore: '<i class="material-icons">list</i>',
      htmlAfter: "",
      menu: "Rubbish_order",
    },
      {
          title: "Collection Order",
          to: "/admin/collection-order",
          htmlBefore: '<i class="material-icons">list</i>',
          htmlAfter: "",
          menu: "CollectionOrder",
      },
      {
          title: "Order List",
          to: "/admin/web-store-order",
          htmlBefore: '<i class="material-icons">list</i>',
          htmlAfter: "",
          menu: "Order",
      },
      {
          title: "Add Terms",
          to: "/admin/add-terms",
          htmlBefore: '<i class="material-icons">widgets</i>',
          htmlAfter: "",
          menu: "Terms",
      },{
          title: "Terms List",
          to: "/admin/terms/list",
          htmlBefore: '<i class="material-icons">widgets</i>',
          htmlAfter: "",
          menu: "Terms",
      },
      {
          title: "Add Faq Category",
          to: "/admin/add-faq-category",
          htmlBefore: '<i class="material-icons">widgets</i>',
          htmlAfter: "",
          menu: "Faq",
      },
      {
          title: "Faq Category List",
          to: "/admin/faq-category",
          htmlBefore: '<i class="material-icons">list</i>',
          htmlAfter: "",
          menu: "Faq",
      }, {
          title: "Add Faq",
          to: "/admin/add-faq",
          htmlBefore: '<i class="material-icons">widgets</i>',
          htmlAfter: "",
          menu: "Faq",
      },{
          title: "Faq List",
          to: "/admin/faq-list",
          htmlBefore: '<i class="material-icons">widgets</i>',
          htmlAfter: "",
          menu: "Faq",
      },
      // ,{
      //     title: "Add Store category",
      //     to: "/admin/add_store_category",
      //     htmlBefore: '<i class="material-icons">widgets</i>',
      //     htmlAfter: "",
      //     menu: "Store",
      // }, {
      //     title: "Add Store Product",
      //     to: "/admin/add_store_product",
      //     htmlBefore: '<i class="material-icons">list</i>',
      //     htmlAfter: "",
      //     menu: "Store",
      // }

  ];
}
