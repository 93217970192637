import { GET_PACKAGE_BAGS } from "../actions/DispatchActions";

// START_LOADING
const initialState = {
  package_bags: null,
};

export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_PACKAGE_BAGS:
      return { packages: actions.payload };
    default:
      return state;
  }
}
