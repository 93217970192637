import { combineReducers } from "redux";
import userReducer from "./userReducers";
import postReducer from "./postReducers";
import profileReducer from "./profileReducer";
import loaderReducer from "./loaderReducer";
import packageReducer from "./packageReducer";
import packageAdminReducer from "./allPackagesAdmin";
import recycleReducer from "./recycleReducer";
import zipCodeReducer from "./zipCodeReducer";
import userlistReducer from "./zipCodeReducer";
import categoryReducer from "./categoryReducer";
import productTypeReducer from "./productTypeReducer";
import productReducer from "./productReducer";
import packageBagsReducer from './packageBagsReducer';
import transactionReducer from './transactionReducer';
import collectionReducer from './collectionReducer';
import rubbishOrderReducer from './rubbishOrderReducer';
import storeCategoryReducer from './storeCategoryReducer';
import allProductsReducer from './allProductsReducer';
import bulkiRubbish from './bulkiRubbishCat';
import AllFaqReducer from './allFaqReducer';

export default combineReducers({
  profileReducer,
  userReducer,
  loaderReducer,
  packageReducer,
  packageAdminReducer,
  recycleReducer,
  zipCodeReducer,
  userlistReducer,
  transactionReducer,
  rubbishOrderReducer,
  collectionReducer,
  categoryReducer,
  AllFaqReducer,
  bulkiRubbish,
  storeCategoryReducer,
  productTypeReducer,
  productReducer,
  allProductsReducer,
  packageBagsReducer
});
