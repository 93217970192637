import React, { useEffect, useState } from "react";
import TableList2 from "../../components/TableListing/TableList2";
import TableListing from "../../components/TableListing/TableListing";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Modal from "react-awesome-modal";
import { MDBDataTableV5 } from "mdbreact";

import { useDispatch, useSelector } from "react-redux";
import {
  clearPackage,
  getAllPackages,
  removePackage,
} from "../../../../actions/packageActions";
import {
  clearProductType,
  getAllProductType,
  removeProductType,
} from "../../../../actions/productTypeAction";
const useForceUpdate = () => useState()[1];

function ProductType_List({ history }) {
  const [visible, setvisible] = useState(false);
  const { productType } = useSelector((state) => state.productTypeReducer);
  const dispatch = useDispatch();
  const [row, setrow] = useState([]);
  const [removeID, setremoveID] = useState("");

  const closeModal = () => {
    setvisible(false);
  };
  const openModal = () => {
    setvisible(true);
  };

  useEffect(() => {
    console.log("Package VALUE", productType);
    if (Array.isArray(productType) && productType.length > 0) {
      makeRow();
    } else {
      dispatch(getAllProductType());
    }
  }, [dispatch, productType]);

  useEffect(() => {
    return () => {
      dispatch(clearProductType());
     };
  }, [dispatch]);

  const redirectToEdit = (data) => {

    history.push({ pathname: "/admin/edit-product-type", state: { data: data } });
  };

  const removeData = (id) => {
    setremoveID(id);
    openModal();
  };

  const packageRemove = () => {
    console.log("ID DELETED", removeID);
    dispatch(removeProductType(removeID));
    closeModal();
    dispatch(getAllProductType());
    //   forceUpdate();
    // }
  };
  const forceUpdate = useForceUpdate();

  const makeRow = () => {
    console.log("DATA PAXJAGEHSDBJHBDS", productType);
    var data =
      Array.isArray(productType) && productType.length > 0
        ? productType.map((data, id) => ({
          name: data.pt_name,

          active: data.pt_active === "1" ? "Active" : "Not Active",
          action: (
            <div>
              <Edit color="action" onClick={() => redirectToEdit(data)} />
              <Delete color="error" onClick={() => removeData(data.pt_id)} />
            </div>
          ),
        }))
        : null;
    console.log("NEW DATA SHAPE:", data);
    setrow(data);
    forceUpdate();
  };
  const columns = [
    {
      label: "Product Type",
      field: "name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "active",
      field: "active",
      width: 200,
    },

    {
      label: "Action",
      field: "action",
      sort: "disabled",
      width: 100,
    },
  ];

  return (
    <div>
      {/* <TableList2 heading="Package" columns={columns} rows={row} /> */}
      {/* {Array.isArray(row) && row.length > 0 ? "Pakistan" : "ROW"} */}
      <div className="card m-2">
        <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
          Product Type List
        </div>
        <MDBDataTableV5
          hover
          entriesOptions={[5, 20, 25]}
          entries={5}
          pagesAmount={4}
          data={{
            columns,
            rows: Array.isArray(row) && row.length > 0 ? row : [],

          }}
          style={{ fontSize: 15 }}
          searchLabel="Seacrh here......"
          searchTop
          searchBottom={false}
          responsive
          barReverse
        />
      </div>
      <Modal
        visible={visible}
        effect="fadeInRight"
        onClickAway={() => closeModal()}
        className="modalPop"
      >
        <div className="modalPop1">
          {/* <h1>Title</h1> */}
          <div className="text-danger">
            Are you Sure? You want to delete this Record.
          </div>
          <div className="d-flex justify-content-around">
            <div
              className="btn btn-primary btn-sm"
              onClick={() => closeModal()}
            >
              Cancel
            </div>
            <div className="btn btn-danger btn-sm" onClick={packageRemove}>
              Delete
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ProductType_List;
