import { GET_ALL_STORE_CATEGORY } from "../actions/DispatchActions";

// START_LOADING
const initialState = {
    storeCategories: null,
};

export default function (state = initialState, actions) {
    switch (actions.type) {
        case GET_ALL_STORE_CATEGORY:
            return { ...state, storeCategories: actions.payload };
        default:
            return state;
    }
}
