import React, { useState } from "react";
import "./add_catagory.css";
import Loader from "react-loader-spinner";

import { useDispatch, useSelector } from "react-redux";
import { AddCategory } from "../../../../actions/categoryAction";

const Add_Category =  ({ history}) => {
  const [numm, setNumm] = useState(0);
  const [name, setname] = useState("");
  const [status, setstatus] = useState("0");
    const [isFormSubmit, setIsFormSubmit] = useState(0);

    const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loaderReducer);

  const onClickListener = () => {
      if(name!='' && status!='') {
          const data = {
              pc_name: name,
              pc_active: status,
          };
        history.push('/admin/category-list');
          dispatch(AddCategory(data));

      }
  };
  return (
    <div className=" mt-3">
      <div className="container card p-5 mobile__cs">
        <h2 className="text-center my-3">Add Catagory</h2>
        <div className="row mt-4">
          <div className="col-lg-12 col-md-12 col-12 mx-auto">
            <div className="input-group mb-3  ">
              <span
                className="input-group-text bg-primary text-white"
                id="inputGroup-sizing-default"
              >
                Category name
              </span>

              <input
                  required
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
              />
                {isFormSubmit == 1 && name=="" && <label className="row mx-auto red" style={{color:"red"}}>This field is required</label>}

            </div>

            {/* <div className="mb-3 mt-2">
              <input className="form-control" type="file" id="formFile" />
            </div> */}

            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setstatus(e.target.value)}
            >
               <option value="1 selected">active</option>
              <option value="0">not active</option>
            </select>
              {isFormSubmit == 1 && status=="" && <label className="row mx-auto red" style={{color:"red"}}>This field is required</label>}

            <br />
          </div>
        </div>
        <div className=" mx-auto mt-3">
          <button
            type="button"
            class="btn btn-primary "
            onClick={()=>{
                onClickListener();

                setIsFormSubmit(1);
            }}
           >
            Add Category
            <Loader
              type="Puff"
              color="#FFF"
              height={20}
              width={15}
              style={{ marginLeft: 5 }}
              visible={loading}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Add_Category;
