import {React,useState,useEffect} from "react";
import "./CollectionPayment.css";
import Button from "@material-ui/core/Button";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Common/Header/Navbar";
import PaymentForm from "../MemberShip2/SingleForm";
import ShopNavbar2 from "../WebShop/Common/ShopNavbar2";
import Axios from "../../Services/Axios";
import toast from "../../utils/toast";
import {LoadingState} from "../../actions/loaderActions";
import {useDispatch} from "react-redux";
import FooterShop from "../WebShop/Common/FooterShop";
const CollectionPayment = ({ history }) => {

    const user = localStorage.getItem("user");

    if(!user || user === "") {

        history.push("/login");
    }

    let dispatch = useDispatch();
    const [price, setPrice] = useState(0);
    const [currency, setCurrency] = useState('sek');
    const [data, setData] = useState(null);

    useEffect(() => {

        let cartValues = JSON.parse(localStorage.getItem('cart'));

        setData(cartValues);

        const sendingData = {
            package_id: cartValues.packageId,
        };

        console.log('sendingData',cartValues);
        Axios.post("/api/packages/bags", sendingData)
            .then((res) => {
                if(res.data.data.bag.length > 0) {

                    for(var i = 0;i < res.data.data.bag.length;i++) {
                        if(cartValues.bagId == res.data.data.bag[i].bag_id) {
                            setPrice(res.data.data.bag[i].price);
                            setCurrency(res.data.data.bag[i].currency?res.data.data.bag[i].currency:'sek');
                        }
                    }
                }
                dispatch(LoadingState(false));
            })
            .catch((err) => {
                toast.error("There is Some Error While Adding Package");
                // dispatch(LoadingState(false));
            });

    },[]);

    return (
    <div className="payment_page_wrap">
        <ShopNavbar2 history={history}/>
        <h1 className="text-center my-4">Collection Payment</h1>

        <div className="container wrapper mb-5">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-6 mx-auto">
                <PaymentForm price={price} currency={currency} data={data} history={history}/>

              </div>
            </div>
        </div>
    <FooterShop />
    </div>
  );
};

export default CollectionPayment;
