import axios from "axios";

// let loginData = localStorage.getItem("token");
const instance = axios.create({
  // baseURL: "http://localhost:8000",
  baseURL: "https://loopabackend.p2gmanagement.xyz",  
});

// res.setHeader("Access-Control-Allow-Origin", "*");
// res.setHeader("Access-Control-Allow-Headers", "*");

export default instance;
