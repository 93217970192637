import React from "react";
import "./ShopCard.css";

function HoverShopCard() {
  return (
    <div class="main_shoe d-flex align-items-center">
      <img
        src="https://dgbt0njcf3x4q.cloudfront.net/fit-in/248x4096/sellpy-parse-prod-files/7988c229ec9e15fbc5d5a4b5d3fc22e5_influencerTile.jpg"
        alt="Avatar"
        class="shoe_image"
      />
      <div class="shoe_middle ">
        <div class="">
          <h4 className="text-dark h5">John Doe</h4>
          <p className="text-dark h5">1455 kr</p>
        </div>
      </div>
    </div>
  );
}

export default HoverShopCard;
