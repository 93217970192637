import React, { useState,useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";

import Axios from "../../../../Services/Axios";


function Faq_Cat_List() {

    const [row, setrow] = useState([]);
    const dispatch = useDispatch();
    const [UserRequets, setUserRequets] = useState(null);
    const [ispopulated, setIsPopulated] = useState(0);

    const makeRow = () => {
        var data =
            UserRequets.length > 0 ? UserRequets.map((data, id) => ({
                    Name: data.category_name,

                    date_time: data.created_at?data.created_at:'',


                }))
                : null;
        setrow(data);

    };
    useEffect(() => {
        Axios.get("/api/faq/categories")
            .then((res) => {
                if(res.data.data.length > 0 && ispopulated == 0) {
                     setUserRequets(res.data.data);
                    setIsPopulated(1)
                }
            })
            .catch((err) => {
                const data = err.response.data.error;
                //   dispatch(LoadingState(false));
            });

        if (Array.isArray(UserRequets) && UserRequets.length > 0) {
            makeRow();
        } else {
        }
    }, [dispatch,UserRequets]);

    const columns = [
        {
            label: "Category Name",
            field: "Name",
            width: 100,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Name",
            },
        }, {
            label: "Date Added",
            field: "date_time",
            width: 100,
        },
    ];

    return (
        <div>
            <div className="card m-2">
                <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
                    Contact Requests
                </div>
                <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={{
                        columns,
                        rows: Array.isArray(row) && row.length > 0 ? row : [],
                    }}
                    style={{ fontSize: 15 }}
                    searchLabel="Seacrh here......"
                    searchTop
                    searchBottom={false}
                    responsive
                    barReverse
                />
            </div>


        </div>
    );
}

export default Faq_Cat_List;
