import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Icon from "@material-ui/core/Icon";

import { useDispatch, useSelector } from "react-redux";
import {AddPayment, AddStorePayment} from "../../actions/paymentActions";
import toast from '../../utils/toast';
import { getAllPackages } from '../../actions/packageActions';
import { getPackageBags } from '../../actions/packageBagActions';


const PaymentFormSingle = ({price,currency,data,type,history,setOpenDetailForm,setOpenPayment}) => {

    const user = localStorage.getItem("user");
    // const { packages } = useSelector((state) => state.packageReducer);
    // const { packages } = useSelector((state) => state.packageReducer);
    // const { package_bags } = useSelector((state) => state.packageBagsReducer);

    const [name, setName] = useState("");
    const [cardNo, setCardNo] = useState("");
    const [expMonth, setExpMonth] = useState("01");
    const [expYear, setExpYear] = useState("");
    const [cvc, setCvc] = useState("");
    const [amount, setAmount] = useState("");
    const [errrorMessage, setErrorMessage] = useState("");
    const [prodid, setProdid] = useState("");
    let id = window.location.href.split("/").pop();
    const [isFormSubmit, setIsFormSubmit] = useState(0);
    const [isRecurringToggleNeeded, setIsRecurringToggleNeeded] = useState(0);
    const [isRecurringToggle, setIsRecurringToggle] = useState(0);

    const dispatch = useDispatch();

    // useEffect(() => {
    //     console.log("Package VALUE");
    //     if (Array.isArray(packages) && packages.length > 0) {
    //         packages.map(data => {
    //             if (data.pkg_name === 'Single') {
    //                 id = data.pkg_id;
    //                 return setProdid(data.pkg_id);
    //             }
    //         })
    //     } else {
    //         dispatch(getAllPackages());
    //     }
    // }, [dispatch, packages]);
    //
    // useEffect(() => {
    //     const sendingData = {
    //         package_id: id
    //     };
    //     console.log("Payment data", sendingData);
    //     dispatch(getPackageBags(sendingData));
    // }, []);

    useEffect(()=> {
        if(type != 'isBulkRubbishOrder' && type != 'webStore') {
            setIsRecurringToggleNeeded(1);
        }
    },[]);
    const onClickListener = async (e) => {
        e.preventDefault();
        setIsFormSubmit(1)
        if (user === null && type!='webStore') {
            toast.error("You are not logged in");
        } else if(cardNo!='' && name!='' && expMonth!="" && cvc!="" ) {
            let sendingData = {
                name: name,
                product_id: prodid,
                cardNo: cardNo,
                expMonth: expMonth,
                expYear: expYear,
                cvc: cvc,
                amount: price,
                currency: currency,
                user_id: user,
                type: type?type:'package',
                data: data,
                isRecurring: isRecurringToggle
            };

            if(type =='isBulkRubbishOrder') {
                sendingData.items=JSON.parse(localStorage.getItem('StoreBulkyRubbishProduct'));
            }
            if(type != 'webStore'){
                dispatch(AddPayment(sendingData,history));
            } else {
                sendingData.items=data.items;
                dispatch(AddStorePayment(sendingData,history));
            }
            // console.log("Payment data", sendingData);
        }

        // const card = elements.getElement(CardElement);
        // const result = await stripe.createToken(card);

        // if (result.error) {
        //     console.log(result.error.message);
        // } else {
        //     stripeTokenHandler(result.token);
        // }


    };

    // function stripeTokenHandler(token) {
    //     setToken(token);

    //     const sendingData = {
    //         name: name,
    //         identifier: 'single',
    //         // cardNo: cardNo,
    //         // expMonth: expMonth,
    //         // expYear: expYear,
    //         // cvc: cvc,
    //         // amount: amount,
    //         stripe_token: token.id,
    //         user_id: user
    //     };
    //     console.log("Payment data", sendingData);
    //     dispatch(AddPayment(sendingData));
    // }

    const style = {
        marginTop: '20px'
    }


    return (
        <form class="form" role="form" autocomplete="off" onSubmit={onClickListener}>
            <div class="form-group">
                <label for="cc_name">Card Holder's Name</label>
                <input
                    type="text"
                    class="form-control"
                    id="cc_name"
                    pattern="\w+ \w+.*"
                    title="First and last name"
                    required="required"
                    onChange={e => setName(e.target.value)}
                />
            </div>
            {isFormSubmit == 1 && name=="" && <label className="row mx-auto"  style={{color:"red"}}
            >This field is required</label>}
            <div class="form-group">
                <label>Card Number</label>
                <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    maxlength="20"
                    pattern="\d{16}"
                    title="Credit card number"
                    required=""
                    maxLength={16}
                    onChange={e =>{
                        setCardNo(e.target.value);
                    }}
                />
            </div>
            {isFormSubmit == 1 && cardNo=="" && <label className="row mx-auto"  style={{color:"red"}}
            >This field is required</label>}
            <div class="form-group row" >
                <label class="col-md-12 ">Card Exp. Date</label>
                <div class="col-md-4 mt-2">
                    <select class="form-control" name="cc_exp_mo" size="0" onChange={e => setExpMonth(e.target.value)}>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </select>
                </div>
                <div class="col-md-4 mt-2">
                    <select class="form-control" name="cc_exp_yr" size="0" onChange={e => setExpYear(e.target.value)}>
                        <option>2018</option>
                        <option>2019</option>
                        <option>2020</option>
                        <option selected>{(new Date().getFullYear())}</option>
                        <option>2022</option>
                    </select>
                </div>
                <div class="col-md-4 mt-2">
                    <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        maxlength="3"
                        pattern="\d{3}"
                        title="Three digits at back of your card"
                        required=""
                        placeholder="CVC"
                        onChange={e => setCvc(e.target.value)}
                    />
                    {isFormSubmit == 1 && cvc=="" && <label className="row mx-auto"  style={{color:"red"}}
                    >This field is required</label>}
                </div>

            </div>
            <div class="row">
                <label class="col-md-12">Amount</label>
            </div>
            <div class="form-inline">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{currency}</span>
                    </div>
                    <div class="input-group-prepend">
                        <span class="input-group-text">{price}</span>
                    </div>
                    <div class="input-group-append">
                        <span class="input-group-text">.00</span>
                    </div>
                </div>
            </div>
            <hr />
            {isRecurringToggleNeeded && <div class="form-group row">
                        <div class="input-group">
                        <div class="input-group-prepend">
                        <span class="input-group-text">
                        <input type="checkbox" name="isRecurring" onChange={(e)=>{
                            if(isRecurringToggle == 0){
                            setIsRecurringToggle(1);
                            }else{setIsRecurringToggle(0);}
                        }} value="1"/> I agree for recurring payment</span>
                        </div>
                        </div>

            </div>}
            <div class="form-group row">
                <div class="col-md-6 mt-2">
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() =>{
                            setOpenDetailForm(false);
                            setOpenPayment(false);
                        }}
                    >
                        cancel
                </Button>
                </div>
                <div class="col-md-6 mt-2">
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Icon>send</Icon>}
                        onClick={onClickListener}
                    >
                        Submit
                </Button>
                </div>
            </div>
        </form>
    )
}

export default PaymentFormSingle;