import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {AddBags, getAllBags} from "../../../../actions/recyclebagActions";
import Loader from "react-loader-spinner";
import {getAllPackages} from "../../../../actions/packageActions";


const Add_Bag = () => {
  const [name, setname] = useState("");
  const [price, setPrice] = useState("");
  const [status, setstatus] = useState("");
  const dispatch = useDispatch();
  const [selectedPkg, setSelectedPkg] = useState(null);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const { loading } = useSelector((state) => state.loaderReducer);
    const { packages } = useSelector((state) => state.packageReducer);
    const [isFormSubmit, setIsFormSubmit] = useState(0);

   const onClickListener = () => {
       setIsFormSubmit(1)
       if(name!='' && status!='' && selectedPackages!=''&& price!='') {

           const data = {
               bag_name: name,
               bag_active: status,
               price: price,
               packages: selectedPackages
           };
           dispatch(AddBags(data));
       }
  };
     useEffect(() => {
         if (Array.isArray(packages) && packages.length > 0) {
             setSelectedPkg(packages);
         } else {
             dispatch(getAllPackages());
        }
    }, [dispatch,packages])
  return (
    <div className="mt-2 p-2 mx-auto">
      <div className="container card p-5  mobile__cs">
        <h1 className="text-center my-3">Add Bags</h1>
        <div className="row mt-5">
          <div className="col-lg-12 col-md-12 col-12 mx-auto">
            <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Bag Name
              </span>
              <input
                type="text"
                required={true}

                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
              />
            </div>
              {isFormSubmit == 1 && name=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
            {/* <br />
            <div className="">
              <input className="form-control" type="file" id="formFile" />
            </div> */}
              <br />

              <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Price
              </span>
                  <input
                      type="text"
                       className="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setPrice(e.target.value)}
                  />

              </div>
              {isFormSubmit == 1 && price=="" && <label className="row mx-auto"  style={{color:"red"}}
              >This field is required</label>}
              <br />
              <select
                  style={{ marginTop: '12px'}}
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setSelectedPackages(e.target.value)}
              >
                  <option value="" disabled selected>Packages</option>
                  {selectedPkg && selectedPkg.map(data => (
                       <option value={data.pkg_id}>{data.pkg_name}</option>
                  ))}
              </select>
              {isFormSubmit == 1 && selectedPackages=="" && <label className="row mx-auto"  style={{color:"red"}}
              >select at least one package</label>}
              <br />

              <select style={{ marginTop: '12px'}}
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setstatus(e.target.value)}
            >
                  <option  disabled selected>Status</option>
                  <option value="1">active</option>
                  <option value="0">not active</option>
            </select>
            <div className="d-flex justify-content-center">
              <div
                className="btn btn-primary mt-5 flex-row"
                onClick={onClickListener}
              >
                Add Bags
                <Loader
                  type="Puff"
                  color="#FFF"
                  height={20}
                  width={15}
                  style={{ marginLeft: 5 }}
                  visible={loading}
                />
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add_Bag;
