import React, { useEffect, useState } from "react";
import TableList2 from "../../components/TableListing/TableList2";
import TableListing from "../../components/TableListing/TableListing";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Modal from "react-awesome-modal";
import { MDBDataTableV5 } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProduct,
  getAllBulkRubbishProduct,
  getAllProduct,
  removeBulkRubbishProduct,
  removeProduct,
} from "../../../../actions/productAction";
import {
  BULKRUBBISH_PRODUCT_IMAGE_URL,
  NOT_FOUND_IMAGE,
  PRODUCT_IMAGE_URL,
} from "../../../../Services/DataApi";

function BulkRubbish_List({ history }) {
  const [visible, setvisible] = useState(false);
  const { product } = useSelector((state) => state.productReducer);
  const dispatch = useDispatch();
  const [row, setrow] = useState([]);
  const [removeID, setremoveID] = useState("");

  useEffect(() => {
    console.log("bags VALUE");
    if (Array.isArray(product) && product.length > 0) {
      makeRow();
    } else {
      dispatch(getAllBulkRubbishProduct());
    }
  }, [dispatch, product]);

  useEffect(() => {
    return () => {
      dispatch(clearProduct());
      console.log("clearProduct");
    };
  }, [dispatch]);

  const redirectToEdit = (data) => {
     history.push({ pathname: "/admin/edit-bulk-rubbish/", state: { data: data } });
  };

  const removeData = (id) => {
    setremoveID(id);
    openModal();
    console.log("ID", id);
  };

  const packageRemove = () => {
    console.log("ID DELETED", removeID);
    dispatch(removeBulkRubbishProduct(removeID));
    closeModal();
    dispatch(getAllBulkRubbishProduct());
  };

  const makeRow = () => {
    console.log("DATA PAXJAGEHSDBJHBDS", product);
    var data =
      Array.isArray(product) && product.length > 0
        ? product.map((data, id) => ({
            name: data.brp_name,
            description: data.brp_desc,
            points: data.brp_points,
              productPrice: data.price,
            picture: (
              <img
                src={
                  data.brp_img
                    ? BULKRUBBISH_PRODUCT_IMAGE_URL + data.brp_img
                    : NOT_FOUND_IMAGE
                }
                style={{
                  height: 50,
                  width: 50,
                  objectFit: "contain",
                  borderRadius: 50,
                }}
              />
            ),
            size: data.brp_size,
            catagory: data.brp_brc_id,
            active: data.brp_active === "1" ? "Active" : "Not Active",
            action: (
              <div>
                <Edit color="action" onClick={() => redirectToEdit(data)} />
                <Delete color="error" onClick={() => removeData(data.brp_id)} />
              </div>
            ),
          }))
        : null;
    console.log("NEW DATA SHAPE:", data);
    setrow(data);
  };

  const closeModal = () => {
    setvisible(false);
  };
  const openModal = () => {
    setvisible(true);
  };

  const columns = [
    {
      label: "Name",
      field: "name",
      width: 100,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Price",
      field: "productPrice",
      width: 100,
    },
    {
      label: "Description",
      field: "description",
      width: 100,
    },
    // {
    //   label: "Start date",
    //   field: "date",
    //   sort: "disabled",
    //   width: 150,
    // },
    {
      label: "Points",
      field: "points",
      sort: "disabled",
      width: 100,
    },
    {
      label: "picture",
      field: "picture",
      sort: "disabled",
      width: 100,
    },
    {
      label: "Size",
      field: "size",
      sort: "disabled",
      width: 100,
    },
    {
      label: "Catagory",
      field: "catagory",
      sort: "disabled",
      width: 100,
    },
    {
      label: "Action",
      field: "action",
      sort: "disabled",
      width: 200,
    },
  ];
  return (
    <div>
      <div className="card m-2">
        <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
          BulkRubbish Product List
        </div>
        <MDBDataTableV5
          hover
          entriesOptions={[5, 20, 25]}
          entries={5}
          pagesAmount={4}
          data={{
            columns,
            rows: Array.isArray(row) && row.length > 0 ? row : [],
          }}
          style={{ fontSize: 15 }}
          searchLabel="Seacrh here......"
          searchTop
          searchBottom={false}
          responsive
          barReverse
        />
      </div>
      <Modal
        visible={visible}
        effect="fadeInRight"
        onClickAway={() => closeModal()}
        className="modalPop"
      >
        <div className="modalPop1">
          {/* <h1>Title</h1> */}
          <div className="text-danger">
            Are you Sure? You want to delete this Record.
          </div>
          <div className="d-flex justify-content-around">
            <div
              className="btn btn-primary btn-sm"
              onClick={() => closeModal()}
            >
              Cancel
            </div>
            <div className="btn btn-danger btn-sm" onClick={packageRemove}>
              Delete
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default BulkRubbish_List;
