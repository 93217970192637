import React, { useState,useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";

 import Axios from "../../Services/Axios";
import ShopNavbar2 from "../WebShop/Common/ShopNavbar2";
import FooterShop from "../WebShop/Common/FooterShop";

function UserOrderLists({ history }) {
    const user = localStorage.getItem("user");

     const [row, setrow] = useState([]);
    const dispatch = useDispatch();
    const [rubbishOrders, setRubbishOrder] = useState(null);
     const makeRow = () => {
        var data =
            rubbishOrders.length > 0 ? rubbishOrders.map((data, id) => ({
                    order_id: data.id,
                    productName: (typeof data.productName !="undefined" && data.productName)? data.productName.p_name:'',

                    total_point_qty: data.total_point_qty,
                    price: data.price > 0 ? data.price:data.points,
                    total_cash_qty: data.total_cash_qty,
                    type: data.type,
                    status: data.status,

                }))
                : null;
        setrow(data);

    };
    const columns = [
        {
            label: "Order id",
            field: "order_id",
            width: 150,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Name",
            },
        },
        {
            label: "Product Name",
            field: "productName",
            width: 200,
        },
        {
            label: "Point Quantity",
            field: "total_point_qty",
            width: 200,
        },{
            label: "Cash Quantity",
            field: "total_cash_qty",
            width: 200,
        }, {
            label: "Price",
            field: "price",
            sort: "disabled",
            width: 100,
        }, {
            label: "Type ",
            field: "type",
            sort: "disabled",
            width: 100,
        }, {
            label: "Status",
            field: "status",
            sort: "disabled",
            width: 100,
        },
    ];

    useEffect(() => {

        Axios.get("/api/web-store-product/" +user)
            .then((res) => {
                if(res.data.data.length > 0) {
                    setRubbishOrder(res.data.data);
                }
             })
            .catch((err) => {
                const data = err.response.data.error;
                console.log("GET_API_PRODUCT ERR:", data);
                //   dispatch(LoadingState(false));
            });
    }, [dispatch]);

    useEffect(() => {

        if (Array.isArray(rubbishOrders) && rubbishOrders.length > 0) {
            makeRow();
            // setRubbishOrder(allRubbishOrders);
        } else {
            // dispatch(getAllRubbishProduct(user));
        }
    }, [rubbishOrders]);


    return (
        <div>
            <ShopNavbar2 history={history}/>
         <div style={{marginTop:90}}>
            <div className="card m-2" >
                <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
                   Your Orders
                </div>
                <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={{
                        columns,
                        rows: Array.isArray(row) && row.length > 0 ? row : [],
                    }}
                    style={{ fontSize: 15 }}
                    searchLabel="Seacrh here......"
                    searchBottom={false}
                    responsive
                    barReverse
                />
            </div>
          </div>
            <FooterShop />

        </div>
    );
}

export default UserOrderLists;
