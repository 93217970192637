import {React,useState,useEffect} from "react";
import "./UserSubscriptions.css";
import ShopNavbar2 from "../WebShop/Common/ShopNavbar2";

import FooterShop from "../WebShop/Common/FooterShop";
const ThankYou = ({ history }) => {
    return (
        <div>

            <ShopNavbar2 history={history}/>
            <h1 className="text-center my-4">Web Store</h1>
            <div className="jumbotron text-center">
                <h1 className="display-3">Thank You!</h1>
                {/*<p className="lead"><strong>Please check your email</strong> for further instructions on how to complete*/}
                    {/*your account setup.</p>*/}
                {/*<p>*/}
                    {/*Having trouble? <a href="">Contact us</a>*/}
                {/*</p>*/}
                <p className="lead">
                    <a className="btn btn-primary btn-lg" href="/" role="button">Continue
                        to homepage</a>
                </p>
            </div>
            <FooterShop />
        </div>
    );
};

export default ThankYou;
