import React, {useEffect, useState} from "react";
import FooterShop from "../Common/FooterShop";
import ShopNavbar2 from "../Common/ShopNavbar2";
import styles from "../Css/Home.module.css";
import style from '../../WebShop/Css/Header.module.css';
import Chosebag from "../Shop/Part_/Chosebag";
import HowItWorks from "../Shop/Part_/HowItWorks";
import TwoSide from "../Shop/Part_/TwoSide";
import {useDispatch, useSelector} from 'react-redux';
import {getAllZipCode} from '../../../actions/zipCodeAction';
import toast from '../../../utils/toast';
import {Link} from 'react-router-dom';
import {LoadingState} from "../../../actions/loaderActions";
import Axios from "../../../Services/Axios";
// import axios from "axios/index";
import axios from "axios";
import {getAllUserss} from "../../../actions/AllUsersList";

const HomeShop = ({history}) => {
    const {zipCode} = useSelector((state) => state.zipCodeReducer);
    const [code, setCode] = useState([]);
    const [EmailSection, setEmailSection] = useState(0);
    const [input, setInput] = useState('');
    const dispatch = useDispatch();
    const [banner, setBanner] = useState('');
    const [codes, setCodes] = useState([]);
    const [open, setOpen] = useState(false);

    console.log(EmailSection)
    useEffect(() => {
        if (Array.isArray(zipCode) && zipCode.length > 0) {
            setCode(zipCode);
        } else {
            dispatch(getAllZipCode());
        }

        var cds = [];
        if (zipCode) {
            for (var i = 0; i < zipCode.length; i++) {
                cds.push(zipCode[i].zip_code);
            }
        }
        setCodes(cds);
    }, [dispatch, zipCode]);


    function registerEmail() {
        if (email1.length !== 0) {
            const Data = {
                email: email1
            };
            Axios.post("api/user/query", Data)
                .then((res) => {
                    console.log(res)
                    // dispatch(LoadingState(false));
                    // dispatch(getAllUserss());
                    setEmailSection(0)

                    toast.success("Thank you for you interest");

                    // console.log("DATA API HIT :", res.data);
                })
                .catch((err) => {
                    toast.error("There is Some Error");
                    // dispatch(LoadingState(false));
                });
            // console.log("Email Registration", email1);
            // toast.success("Email registered successfully!");
        }

    }

    const [email1, setEmail1] = useState('');

    const checkZipCode = () => {

        if (codes.includes(input)) {
            const el = document.getElementById('hero_section');
            el.classList.add('hero_found');
            setBanner(<>
                <div className="row mt-5 p-5" style={{backgroundColor: "purple", borderRadius: "20px"}}>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h2 style={{color: "white", fontWeight: "bold"}}>MOT OANDLIGHETEN OCH VIDARE!</h2>
                        <p style={{fontSize: "20px", fontWeight: "600"}}>Vi finns i Enskededalen</p>
                        <p style={{fontSize: "20px", fontWeight: "500"}}>Med nagra fa tryck kan du da din atervinning
                            hamtad och sorterad redan imorgon</p>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                            <Link to="/membership">
                                <span className={style.shop_zip_button}>BOKA UPPHAMTNING</span>
                            </Link>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                            <a href="#chosebag">
                                <span className={style.shop_zip_button}>HUR FUNGERAR DET?</span>
                            </a>
                        </div>
                    </div>
                </div>
            </>);
            return toast.success("Zip code found");
        }
        else {
            setEmailSection(1)
            const el = document.getElementById('hero_section');
            el.classList.add('hero_found');
            setBanner(<>

            </>)
            setOpen(true)

            return toast.error("Zip code not found");
        }

        // if (Array.isArray(zipCode) && zipCode.length > 0) {
        //   // return console.log('Checking status', zipCode);
        //   zipCode.map(data => {
        //     // return console.log("checking value", data.zip_code, input);
        //     if(data.zip_code !== input){
        //       // return console.log(data.zip_code);
        //       return toast.error("Zip code not found");
        //     }
        //     else{
        //       return toast.success("Zip code found");
        //     }
        //   });
        // }
    }


    return (
        <div className={styles.main}>
            <ShopNavbar2/>
            <div className="row">
                <div className="section__one home_page_section">
                    <div className="section__one__text">

                        <Link to="/membership" style={{textDecoration: "none", color: "white"}}
                              className={styles.webshop__home_button}>
                            Prova två veckor gratis!
                        </Link>

                    </div>
                </div>
            </div>

            <div id="hero_section" className="hero-image1020 pt-5">
                <div className="hero-text1020">
                    {/* <h1 style={{ color: "##FAF0E6" }}>Hämtar vi hos dig?</h1> */}
                    <div className={styles.chooseLocationHeading + ' hero_section_heading'}>HAMTAR VI HOS<br/> DIG?
                    </div>
                    <div className="main___location">
                        <div className="form-group has-search d-flex justify-content-center">
                            {/* <input
                type="text"
                className="form-control"
                placeholder="Skriv in ditt postnummer"
                style={{ height: 40, fontSize: 18, width: "70%" }}
              /> */}
                            <div className="form-group has-search searchhh">


                                {open == false &&
                                <div>
                                    <span className="fa fa-search form-control-feedback search______ico"></span>
                                    <input type="text" className="form-control" placeholder="Skriv in ditt postnummer"
                                           onChange={(e) => setInput(e.target.value)}/>
                                    <button type="button" className="btn btn-primary mt-3"
                                            onClick={checkZipCode}>Search
                                    </button>
                                </div>
                                }
                                {banner}
                                {EmailSection == 1 &&
                                <div className="row mt-5 p-5" style={{backgroundColor: "purple", borderRadius: "20px"}}>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <h2 style={{color: "white", fontWeight: "bold"}}>FORTSATT SORTERA FOR
                                            JORDEN!</h2>
                                        <p style={{fontSize: "20px", fontWeight: "600"}}>Tyvarr har vi inte hunnit till
                                            dig annu.</p>
                                        <p style={{fontSize: "20px", fontWeight: "500"}}>Fortsatt ta ansvar for miljon
                                            och framtiden.
                                            Registrera din mail nedan sa hor vi av oss nar vi kan komma.</p>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Email</span>
                                            </div>
                                            <input type="text" required="required" className="form-control"
                                                   onChange={(e) => {
                                                       setEmail1(e.target.value)
                                                   }}/>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                            {/*<Link to="/">*/}
                                            <Link to="/" className={style.shop_zip_button} onClick={(e) => {
                                                e.persist();
                                                registerEmail();
                                                // e.stopPropagation();
                                                // various callbacks
                                            }}>
                                                HOR AV ER!
                                            </Link>
                                            {/*</Link>*/}
                                        </div>
                                    </div>
                                </div>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HowItWorks/>


            <TwoSide/>
            <div id="chosebag">
                <Chosebag/>
            </div>

            <FooterShop/>
        </div>
    );
}

export default HomeShop;
