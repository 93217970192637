import React, { useState ,useEffect} from "react";
import PropTypes from "prop-types";
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Container,
    Row,
     Col,
    Form,
    FormGroup,
    FormInput,
    FormSelect,
    FormTextarea,
    Button
} from "shards-react";
import {useDispatch} from "react-redux";
import { Link } from "react-router-dom";

import axios from "axios/index";
import toast from "../../../utils/toast";

import {LoadingState} from "../../../actions/loaderActions";
import Axios from "../../../Services/Axios";
import {register} from "../../../actions/authAction";
import {getAllUserss} from "../../../actions/AllUsersList";
import PageTitle from "../components/common/PageTitle";

const AdminUserProfileEdit  =  ({ history }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName,setlastName] = useState('');
    const [isFormSubmit, setIsFormSubmit] = useState(0);

    const [phoneNumber,setPhoneNumber] = useState('');
    const [Email,setEmail] = useState('');
    const [userData,setUserData] = useState('');
    const user = localStorage.getItem("user");
    const dispatch= useDispatch();

    useEffect(() => {

        // dispatch = useDispatch();
        Axios.get("/api/user/" +user)
            .then((res) => {
                setUserData(res.data.data);
                setFirstName(res.data.data.first_name);
                setlastName(res.data.data.last_name);
                setEmail(res.data.data.email);
                setPhoneNumber(res.data.data.phone);

                //   dispatch(LoadingState(false));
            })
            .catch((err) => {
                const data = err.response.data.error;
                console.log("Payment ERR:", data);
                //   dispatch(LoadingState(false));
            });




    },[]);console.log(userData)

    const onClickListener = (e) => {
        setIsFormSubmit(1)
        if(firstName!='' &&  lastName!='' && phoneNumber!='') {

            e.preventDefault();
            const sendingData = {
                 lname: lastName,
                fname: firstName,
                phone: phoneNumber,
                type: 'updateProfile',

            };

        console.log(sendingData)
        axios.post("https://loopabackend.p2gmanagement.xyz/api/user/updateuser/" +user ,sendingData)
            .then((res) => {
                dispatch(LoadingState(false));
                // dispatch(getAllUserss());
                toast.success("Users Updated Successfully.");
                history.push("/admin/dashboard-overview");

                console.log("DATA API HIT :", res.data);
            })
            .catch((err) => {
                toast.error("Already Updated!");
                dispatch(LoadingState(false));
            });
        }
    }

    useEffect(() => {
        Axios.get("/api/user/" +user)
            .then((res) => {
                setUserData(res.data.data);
                setFirstName(res.data.data.first_name);
                setlastName(res.data.data.last_name);
                setEmail(res.data.data.email);
                setPhoneNumber(res.data.data.phone);

                //   dispatch(LoadingState(false));
            })
            .catch((err) => {
                const data = err.response.data.error;
                console.log("Payment ERR:", data);
                //   dispatch(LoadingState(false));
            });




    },[]);

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="User Profile" subtitle="Edit" md="11" className="ml-sm-auto mr-sm-auto" />
            </Row>
            <Row>
                <Col lg="3">
                 </Col>
                <Col lg="5">
                    <Card small className="mb-4">

                        <ListGroup flush>
                            <ListGroupItem  style={{ paddingRight: '400px' }}>
                                <Row>
                                    <Col>
                                        <Form>
                                            <Row form>
                                                {/* First Name */}
                                                <Col md="12" className="form-group" style={{width: "400px"}}>
                                                    <label htmlFor="feFirstName">First Name</label>
                                                    <FormInput
                                                        id="feFirstName"
                                                        placeholder="First Name"
                                                        value={firstName}
                                                        onChange={(e) => {
                                                            setFirstName(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                {isFormSubmit == 1 && firstName=="" && <label className="row mx-auto"  style={{color:"red"}}
                                                >This field is required</label>}
                                                {/* Last Name */}
                                                <Col md="12" className="form-group" style={{width: "400px"}}>
                                                    <label htmlFor="feLastName">Last Name</label>
                                                    <FormInput
                                                        id="feLastName"
                                                        placeholder="Last Name"
                                                        value={lastName}
                                                        onChange={(e) => {
                                                            setlastName(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                {isFormSubmit == 1 && lastName=="" && <label className="row mx-auto"  style={{color:"red"}}
                                                >This field is required</label>}
                                            </Row>
                                            <Row form>
                                                {/* Email */}
                                                <Col md="12" className="form-group" style={{width: "400px"}}>
                                                    <label htmlFor="feEmail">Email</label>
                                                    <FormInput
                                                        type="email"
                                                        id="feEmail"
                                                        placeholder="Email Address"
                                                        value={Email}
                                                        // onChange={(e) => {
                                                        //     setEmail(e.target.value);
                                                        // }}
                                                        disabled
                                                        // autoComplete="email"
                                                    />
                                                </Col>
                                                {/* Password */}
                                                <Col md="12" className="form-group" style={{width: "400px"}}>
                                                    <label htmlFor="fePassword">Phone</label>
                                                    <FormInput
                                                        type="phone"
                                                        id="phone"
                                                        placeholder="Phone"
                                                        value={phoneNumber}
                                                        onChange={(e) => {
                                                            setPhoneNumber(e.target.value);
                                                        }}
                                                        autoComplete="phone"
                                                    />
                                                </Col>
                                                {isFormSubmit == 1 && phoneNumber=="" && <label className="row mx-auto"  style={{color:"red"}}
                                                >This field is required</label>}
                                            </Row>

                                            <Button theme="accent" style={{width: "150px"}}  onClick={onClickListener}>Update Account</Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                 </Col>
            </Row>
        </Container>

    );
};

export default AdminUserProfileEdit;
