import React from "react";
import "./catagories.css";

const Catagories = () => {
  return (
    <div>
      <h5 className="mt-2 text-center">Catagory</h5>
      <hr className="w-75 mx-auto" />
      <div className="scroll__css">
        <div className="form-check my-3">
          <a href="#">
            <span>Show All(8687)</span>
          </a>
        </div>

        <div className="form-check  my-3">
          <a href="#">
            <span>Sources(5848)</span>
          </a>
        </div>
        <div className="form-check  my-3">
          <a href="#">
            <span>children's clothes (98076)</span>
          </a>
        </div>
        <div className="form-check  my-3">
          <a href="#">
            <span>Shoes (686858)</span>
          </a>
        </div>
        <div className="form-check  my-3">
          <a href="#">
            <span>Acessories(80765)</span>
          </a>
        </div>
        <div className="form-check  my-3">
          <a href="#">
            <span>Coconut shop</span>
          </a>
        </div>
        <div className="form-check  my-3">
          <a href="#">
            <span>Overight</span>
          </a>
        </div>
        <div className="form-check  my-3">
          <a href="#">
            <span>Porcelain</span>
          </a>
        </div>
        <div className="form-check  my-3">
          <a href="#">
            <span>Porcelain</span>
          </a>
        </div>
      </div>
      <h5 className="my-3 text-center">FILTER</h5>
      <hr className="w-50 mx-auto" />
    </div>
  );
};

export default Catagories;
