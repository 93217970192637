import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Loader from "react-loader-spinner";

import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch,
    useHistory,
} from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
import toast from "../../../utils/toast";
import { adminlogin } from "../../../actions/adminAuthAction";

const AdminLogin = ({ history }) => {
    const initialState = "";

    const [email, setemail] = useState(initialState);
    const [password, setpassword] = useState(initialState);
    const dispatch = useDispatch();


    const { loading } = useSelector((state) => state.loaderReducer);
    const onClickListener = () => {
        if (email != "" && password != "") {
            const Data = {
                email,
                password,
            };

            dispatch(adminlogin(Data, history));

        } else {
            toast.error("Please Fill Out the Required Fields.Thanks!");
        }
    };
    return (
        <div>
            <h1 className="text-center my-5">Sign In</h1>
            <div className="container">
                <div className="col-lg-7 col-md-7 col-12 mx-auto">
                    <form>
                        <div className="wrapper">
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-primary">
                                    Sign In with Facebook <i className="fab fa-facebook"></i>{" "}
                                </button>
                            </div>
                            <br />
                            <div className="line mt-3 text-center">
                                <span className="line-bar"></span>
                                <span className="line-text">Or</span>
                                <span className="line-bar"></span>
                            </div>
                            <p className="text-center my-3">SignIn with your Email</p>

                            <div className="input-data">
                                <input
                                    type="mail"
                                    required
                                    onChange={(e) => setemail(e.target.value)}
                                />
                                <div className="underline"></div>
                                <label>MAIL</label>
                            </div>
                            <br />
                            <div className="input-data">
                                <input
                                    type="password"
                                    required
                                    onChange={(e) => setpassword(e.target.value)}
                                />
                                <div className="underline"></div>
                                <label>PASSWORD</label>
                            </div>
                            <br />
                            <div className="forgot___password text-center">
                                <Link to="/change-password" class="linktext">
                                    Forgot Password
                                </Link>
                            </div>
                            <br />

                            <div className="d-flex justify-content-center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onClickListener}
                                    disabled={loading}
                                >
                                    sign in
                                    <Loader
                                        type="Puff"
                                        color="#FFF"
                                        height={20}
                                        width={15}
                                        style={{ marginLeft: 5 }}
                                        visible={loading}
                                    />
                                </Button>
                            </div>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
