import React, { useEffect, useState } from "react";
import { Parent_Color } from "../../Common/Color";
import "./chose.css";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ShopNavbar2 from "../../Modules/WebShop/Common/ShopNavbar2";
import FooterShop from "../../Modules/WebShop/Common/FooterShop";

import { getAllPackages } from '../../actions/packageActions';
import { useDispatch, useSelector } from "react-redux";

const ChoseMemberShip = ({ history }) => {
  const { packages } = useSelector((state) => state.packageReducer);
  const dispatch = useDispatch();
  const [bags, setBags] = useState([]);
  const [selectedBag, setSelectedBag] = useState(null);
  const [selectedPkg, setSelectedPkg] = useState(null);


  useEffect(() => {
    console.log("Package VALUE");
    if (Array.isArray(packages) && packages.length > 0) {
      // setBags(packages)
      // console.log("Bag name", packages);
      packages.map(data => {
        // console.log("Bag", data)
        return setBags([...bags, data]);
      });
      console.log("All bags", bags);
    } else {
      dispatch(getAllPackages());
    }

    console.log('packages',packages);
  }, [dispatch, packages]);

  return (
    <div>
      <ShopNavbar2 />
      <h1 className="text-center my-3 mb-2 chose_____cs marginTop home_page_heading">Choose membership</h1>
      <h2 className="text-center my-5 chose_bag_desc">How often do you want a collection?</h2>
      <div className="container mb-5">
        <div className="row d-flex justify-content-center align-items-center">
        {packages && packages.map((data, id) => (

        <div className="col-lg-3 col-md-3 col-sm-12 col-12 mt-3 mb-3">

              <div class="card relax d-flex justify-content-center align-items-center">
                <div class="card-body justify-content-center align-items-center">
                    <div class="col-xs-12">
                        <Link to={"/membership/"+data.pkg_id}>
                          <h4 class="card-title">{data.pkg_name}</h4>
                        </Link>
                    </div>

                    <div class="col-xs-12 text-center">
                        <Link onClick={()=> {
                            setSelectedBag(data.bag);
                            setSelectedPkg(data);
                        }}>
                        <Button variant="contained" color="primary" className="chose__btn" >Read More</Button>
                        </Link>
                    </div>

                </div>
              </div>
          </div>
        ))}
          {/*{(Array.isArray(packages) && packages.length > 0) ?
            packages.map(data => (
              <div className="col-lg-3 col-md-3 col-sm-12 col-12 mt-3 mb-3">
                <div class="card relax d-flex justify-content-center align-items-center">
                  <div class="card-body d-flex justify-content-center align-items-center">
                    <h4 class="card-title">{data.pkg_name}</h4>
                    {console.log(data.pkg_name)}
                  </div>
                </div>
              </div>
            )) : dispatch(getAllPackages())}*/}
        </div>

    {selectedBag && <>
        <h3 className="text-center mt-5">
          Pick up every {selectedPkg && selectedPkg.frequency }.
          <br /> {selectedBag && selectedBag.price} {selectedBag && selectedBag.currency} per collection.
          <br /> {selectedPkg && selectedPkg.pkg_description}
        </h3>
        <div className="text-center h5 mt-5">
          <Link className="text-center" to="/membership2">
            Read more about our memberships here
          </Link>
        </div>
        <div className="text-center h5 mt-2">
          <Link className="text-center" to="/recycle-bag">
            How does it work?
          </Link>
        </div>
        <br />
        <div className=" d-flex justify-content-center chose___btnn">
          <Button
            variant="contained"
            color="primary"
            className="chose__btn"
            onClick={() => history.push("/membership2")}
          >
            Choose Memberships
          </Button>
        </div>
        </>}
      </div>
      <FooterShop />
    </div>
  );
};

export default ChoseMemberShip;
