import React from "react";
import { Parent_Color } from "../../Common/Color";
function PageNotFound() {
  return (
    <div className="container">
      <div className="row text-center" style={{ paddingTop: "10%" }}>
        <div className="col-12 d-flex justify-content-center align-content-center">
          <div className="error-template">
            <h1 className="error_page_css">Oops!</h1>
            <h2 className="error_page_css my-5">404 Not Found</h2>
            <div className="error-details">
              Sorry, an error has occured, Requested page not found!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
