import React, { useState,useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";

import { getAllRubbishProduct } from '../../../../actions/productAction';
import Axios from "../../../../Services/Axios";
import {GET_ALL_PRODUCT} from "../../../../actions/DispatchActions";
import axios from "axios/index";


function WebSoreOrders({ history }) {
    const user = localStorage.getItem("user");

    // const { allRubbishOrders } = useSelector((state) => state.rubbishOrderReducer);
    const [row, setrow] = useState([]);
    const dispatch = useDispatch();
    const [rubbishOrders, setRubbishOrder] = useState(null);
console.log(rubbishOrders)
    const makeRow = () => {
        var data =
            rubbishOrders.length > 0 ? rubbishOrders.map((data, id) => ({
                    order_id: data.id,
                    total_point_qty: data.total_point_qty,
                    price: data.price > 0 ? data.price:data.points,
                    productName: (typeof data.productName !="undefined" && data.productName)? data.productName.p_name:'',

                    total_cash_qty: data.total_cash_qty,
                    updated_at: data.updated_at,
                    type: data.type,
                    status: data.status,
                    // amount: data.amount,
                    //
                    // status: data.status === "1" ? "Active" : "Not Active",
                    // action: (
                    //     <div>
                    //         <Edit color="action" onClick={() => redirectToEdit(data)} />
                    //         <Delete color="error" onClick={() => removeData(data.zip_id)} />
                    //     </div>
                    // ),
                }))
                : null;
        setrow(data);

    };
    const columns = [
        {
            label: "Order id",
            field: "order_id",
            width: 150,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Name",
            },
        },
        {
            label: "Product Name",
            field: "productName",
            width: 200,
        },
        {
            label: "Point Quantity",
            field: "total_point_qty",
            width: 200,
        },{
            label: "Cash Quantity",
            field: "total_cash_qty",
            width: 200,
        }, {
            label: "Price",
            field: "price",
            sort: "disabled",
            width: 100,
        }, {
            label: "Type ",
            field: "type",
            sort: "disabled",
            width: 100,
        }, {
            label: "Status",
            field: "status",
            sort: "disabled",
            width: 100,
        }, {
            label: "Updated At",
            field: "updated_at",
            sort: "disabled",
            width: 100,
        },
    ];

    useEffect(() => {

        Axios.get("/api/store-order-products/active")
            .then((res) => {
                if(res.data.data.length > 0) {
                    setRubbishOrder(res.data.data);
                }
                //   dispatch(LoadingState(false));
            })
            .catch((err) => {
                const data = err.response.data.error;
                console.log("GET_API_PRODUCT ERR:", data);
                //   dispatch(LoadingState(false));
            });
        }, [dispatch]);

    useEffect(() => {

        if (Array.isArray(rubbishOrders) && rubbishOrders.length > 0) {
            makeRow();
            // setRubbishOrder(allRubbishOrders);
        } else {
            // dispatch(getAllRubbishProduct(user));
        }
        }, [rubbishOrders]);


    return (
        <div>
            <div className="card m-2">
                <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
                    Web Store Order
                </div>
                <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={{
                        columns,
                        rows: Array.isArray(row) && row.length > 0 ? row : [],
                    }}
                    style={{ fontSize: 15 }}
                    searchLabel="Seacrh here......"
                    searchTop
                    searchBottom={false}
                    responsive
                    barReverse
                />
            </div>


        </div>
    );
}

export default WebSoreOrders;
