import {
  GET_ALL_PACKAGES,
  CLEAR_PACKAGES,
  GET_ALL_PRODUCT,
    GET_ALL_STORE_PRODUCT,
    GET_ALL_RUBBISH_ORDERS,
  CLEAR_PRODUCT,
} from "./DispatchActions";
import {useHistory} from "react-router-dom";
import Axios from "../Services/Axios";
import { LoadingState } from "./loaderActions";
import toast from "../utils/toast";

const config = {
  headers: {
    "content-type": "multipart/form-data",
  },
};

// -------------------------Product Actions-----------------------------------
// -------------------------Product Actions-----------------------------------
// -------------------------Product Actions-----------------------------------
// -------------------------Product Actions-----------------------------------

export const getAllProduct = () => (dispatch) => {
  Axios.get("/api/products/all")
    .then((res) => {
      console.log("getAllProduct :", res.data);
      dispatch({
        type: GET_ALL_PRODUCT,
        payload: res.data.data,
      });

      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      console.log("GET_API_PRODUCT ERR:", data);
      //   dispatch(LoadingState(false));
    });
};


export const getStoreAllProduct = () => (dispatch) => {
    Axios.get("/api/all-store-products/active")
        .then((res) => {
            console.log("getAllProduct :", res.data);
            dispatch({
                type: GET_ALL_STORE_PRODUCT,
                payload: res.data.data,
            });

            //   dispatch(LoadingState(false));
        })
        .catch((err) => {
            const data = err.response.data.error;
             //   dispatch(LoadingState(false));
        });
};


export const getAllRubbishProduct = (id) => (dispatch) => {
    Axios.get("/api/user/rubbishSubscriptions/all?user_id=" +id)
        .then((res) => {
            console.log("getAllRubish :", res.data);
            dispatch({
                type: GET_ALL_RUBBISH_ORDERS,
                payload: res.data.data,
            });

            //   dispatch(LoadingState(false));
        })
        .catch((err) => {
            const data = err.response.data.error;
            console.log("GET_API_PRODUCT ERR:", data);
            //   dispatch(LoadingState(false));
        });
};
export const getProductByID = (id) => {
  return Axios.get("/api/product/" + id);
};

export const getRubbishProductByID = (id) => {
    return Axios.get("/api/rubbish/product/" +id);

};


export const getStoreProductByID = (id) => {
    return Axios.get("/api/store-product/" + id);
};
export const getAllActiveProduct = () => (dispatch) => {
  Axios.get("/api/products/active")
    .then((res) => {
      console.log("getAllActiveProduct :", res.data);
      dispatch({
        type: GET_ALL_PRODUCT,
        payload: res.data.data,
      });

      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      console.log("GET_API_PRODUCT ERR:", data);
      //   dispatch(LoadingState(false));
    });
};

export const getAllProductByCategoryID = (id) => (dispatch) => {
  Axios.get(`/api/products/cat/${id}`)
    .then((res) => {
      console.log("getAllProductByCategoryID :", res.data);
      dispatch({
        type: GET_ALL_PRODUCT,
        payload: res.data.data,
      });
      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      console.log("GET_API_PRODUCT ERR:", data);
      //   dispatch(LoadingState(false));
    });
};

export const addProduct = (data) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("Product ACTION DATA", data, config);
  Axios.post("/api/product/add", data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllProduct());
      if (res.status === 200) {
        toast.success("Product Added successfully.");
        console.log("DATA API HIT :", res.data);
      }
    })
    .catch((err) => {
      toast.error("There is Some Error While Adding Product");
      dispatch(LoadingState(false));
    });
};

export const updateProduct = (data, id,history) => (dispatch) => {

    dispatch(LoadingState(true));
  console.log("Product ACTION DATA", data);
  Axios.post("/api/product/edit/" + id, data)
    .then((res) => {

        history.push("/admin/product-list");

        dispatch(LoadingState(false));
      dispatch(getAllProduct());
      toast.success("Product Updated Successfully.");
      console.log("DATA API HIT updateProduct :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Updating Product");
      dispatch(LoadingState(false));
    });
};

export const removeProduct = (id) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("removeProduct ACTION DATA", id);
  Axios.get("/api/product/delete/" + id)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllProduct());
      toast.success("Product Removed Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Removing Product");
      dispatch(LoadingState(false));
    });
};

// -------------------------Bulk Rubbish Product Actions-----------------------------------
// -------------------------Bulk Rubbish Product Actions-----------------------------------
// -------------------------Bulk Rubbish Product Actions-----------------------------------
// -------------------------Bulk Rubbish Product Actions-----------------------------------

export const getAllBulkRubbishProduct = () => (dispatch) => {
  Axios.get("/api/bulky-rubbish-products/active")
    .then((res) => {
      console.log("getAllBulkRubbishProduct :", res.data);
      dispatch({
        type: GET_ALL_PRODUCT,
        payload: res.data.data,
      });

      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      console.log("GET_API_PRODUCT ERR:", data);
      //   dispatch(LoadingState(false));
    });
};



export const getAllStoreProduct = () => (dispatch) => {
    Axios.get("/api/all-store-products/active")
        .then((res) => {
            // console.log("getAllBulkRubbishProduct :", res.data);
            dispatch({
                type: GET_ALL_STORE_PRODUCT,
                payload: res.data.data,
            });

            //   dispatch(LoadingState(false));
        })
        .catch((err) => {
            const data = err.response.data.error;
            console.log("GET_API_PRODUCT ERR:", data);
            //   dispatch(LoadingState(false));
        });
};

export const getAllBulkProductByCategoryID = (id) => (dispatch) => {
  Axios.get(`/api/bulky-rubbish-products/cat/${id}`)
    .then((res) => {
      console.log("getAllBulkProductByCategoryID :", res.data);
      dispatch({
        type: GET_ALL_PRODUCT,
        payload: res.data.data,
      });
      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      console.log("GET_API_PRODUCT ERR:", data);
      //   dispatch(LoadingState(false));
    });
};

export const getAllStoreProductByCategoryID = (id) => (dispatch) => {
    Axios.get(`/api/store-products/cat/${id}`)
        .then((res) => {
            console.log("getAllStoreProductByCategoryID :", res.data);
            dispatch({
                type: GET_ALL_STORE_PRODUCT,
                payload: res.data.data,
            });
            //   dispatch(LoadingState(false));
        })
        .catch((err) => {
            const data = err.response.data.error;
            console.log("GET_API_PRODUCT ERR:", data);
            //   dispatch(LoadingState(false));
        });
};


export const addBulkRubbishProduct = (data) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("addBulkRubbishProduct ACTION DATA", data, config);
  Axios.post("/api/bulky-rubbish-product/add", data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllBulkRubbishProduct());
      if (res.status === 200) {
        toast.success("Bulk Rubbish Added successfully.");
        console.log("DATA API HITaddBulkRubbishProduct :", res.data);
      }
    })
    .catch((err) => {
      toast.error("There is Some Error While Adding Bulk Rubbish");
      dispatch(LoadingState(false));
    });
};

export const addStoreProductCategory = (data) => (dispatch) => {
    dispatch(LoadingState(true));
    console.log("addStoreProductCategory ACTION DATA", data, config);
    Axios.post("/api/store-category/add", data)
        .then((res) => {
            dispatch(LoadingState(false));
            console.log(res)
            // dispatch(getAllBulkRubbishProduct());
            if (res.status === 200) {
                toast.success(res.data.message);
             }
        })
        .catch((err) => {
            toast.error("There is Some Error While Adding Store Category");
            dispatch(LoadingState(false));
        });
};

export const updateBulkRubbishProduct = (data, id,history) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("updateBulkRubbishProduct ACTION DATA", data);
  Axios.post("/api/bulky-rubbish-product/edit/" + id, data)
    .then((res) => {
        history.push('/admin/bulk-rubbish-list');

      dispatch(LoadingState(false));
      dispatch(getAllBulkRubbishProduct());
      toast.success("Bulk Rubbish Updated Successfully.");
      console.log("DATA API HIT updateBulkRubbishProduct :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Updating Bulk Rubbish");
      dispatch(LoadingState(false));
    });
};

export const removeBulkRubbishProduct = (id) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("removeBulkRubbishProduct ACTION DATA", id);
  Axios.get("/api/bulky-rubbish-product/delete/" + id)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllBulkRubbishProduct());
      toast.success("Bulk Rubbish Removed Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Removing Bulk Rubbish");
      dispatch(LoadingState(false));
    });
};

// --------------------------Clear State----------------------------
export const clearProduct = () => (dispatch) => {
  console.log("ACTION clearProduct ");
  dispatch({
    type: CLEAR_PRODUCT,
  });
};
