import React, { useState, useEffect } from 'react';
import "./member.css";
import Tabss from "./Tabss";
import PaymentForm from './SingleForm';

import Button from "@material-ui/core/Button";
import ShopNavbar2 from "../../Modules/WebShop/Common/ShopNavbar2";
import FooterShop from "../../Modules/WebShop/Common/FooterShop";

import { Elements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Icon from "@material-ui/core/Icon/index";
import toast from "../../utils/toast";
import DeleteIcon from "@material-ui/icons/Delete";
import Axios from "../../Services/Axios";
import {LoadingState} from "../../actions/loaderActions";
import {getPackageBags} from "../../actions/packageBagActions";
import {useDispatch} from "react-redux";
import ButtonGroup from "@material-ui/core/ButtonGroup/index";
import {makeStyles} from "@material-ui/core/styles/index";
import Collection from "../Collection/Collection";



const MemberShipSingle = ({ history}) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    }));

    const classes = useStyles();
    // const { payments } = useSelector((state) => state.recycleReducer);
    const [packageDetails, setPackageDetails] = useState(null);
    const [packageBagSelectedId, setPackageBagSelectedId] = useState(0);
    const [packageBagSelected, setPackageBagSelected] = useState(null);
    const [paymentOptionsShow, setPaymentOptionsShow] = useState(0);
    const [userCollectionData, setUserCollectionData] = useState('');

    const stripePromise = loadStripe('pk_test_51IXfK6HVjYUffmJdp2R3nYu2MouPuYZe3xHB1aDuBQ46v5JrrsSmySqrlaqLBD4QvTQ2qQNIzKf9gm3HjHRShPnw00ngvBIPat');
     let id = window.location.href.split("/").pop();
    let dispatch = useDispatch();

    const [timeOptions, setTimeOptions] = useState([]);
    const user = localStorage.getItem("user");
    const userSubscription = localStorage.getItem("subscription");

    useEffect(() => {
        const sendingData = {
            package_id: id,
            user: user
        };



        Axios.post("/api/packages/bags", sendingData)
        .then((res) => {
            setPackageDetails(res.data.data);
            setPackageBagSelected(res.data.data.bag[0]);
            setPackageBagSelectedId(res.data.data.bag[0].bag_id);

            if(res.data.data.bag.length > 0 && userSubscription =='subscriptionChange') {
                setPickUpAddress(res.data.data.user.pickup_address);
                setPickUpDistrict(res.data.data.user.pickup_district);
                setCollectionPhone(res.data.data.user.cell_phone);
                setCollectionPostCode(res.data.data.user.post_code);
                setCollectionInstructions(res.data.data.user.instructions_for_driver);
                setPickUpZip(res.data.data.user.pickup_zip);
                setPickupDate(res.data.data.user.pickup_date_from);
                setPickupTimeTo(res.data.data.user.pickup_date_to);
            }
            dispatch(LoadingState(false));
        })
        .catch((err) => {
            toast.error("There is Some Error While Adding Package");
            dispatch(LoadingState(false));
        });

        let options = [];
        let index = ':00';
        let indexD = 0;
        for(let i = 0;i <= 48;i++) {
            options.push(indexD + index);
            if(index == ':00') {
                index = ':30';
            } else {
                indexD++;
                index = ':00';
            }
        }
        setTimeOptions(options);
    }, [id]);

    const [isFormSubmit, setIsFormSubmit] = useState(0);
    const [pickUpAddress, setPickUpAddress] = useState('');
    const [pickUpZip, setPickUpZip] = useState('');
    const [pickUpDistrict, setPickUpDistrict] = useState('');
    const [collectionPhone, setCollectionPhone] = useState('');
    const [collectionPostCode, setCollectionPostCode] = useState('');
    const [collectionInstructions, setCollectionInstructions] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [pickupTimeFrom, setPickupTimeFrom] = useState('');
    const [pickupTimeTo, setPickupTimeTo] = useState('');
    const [pickupType, setPickupType] = useState('outside_door');

    const mySubmitHandler = (event) => {
        event.preventDefault();

        if(pickUpAddress!='' && pickUpZip!='' && pickUpDistrict!='' && collectionPhone!='' && collectionPostCode!='' && collectionInstructions!='') {
            let formValues = {
                pickUpAddress: pickUpAddress,
                pickUpZip: pickUpZip,
                pickUpDistrict: pickUpDistrict,
                collectionPhone: collectionPhone,
                collectionPostCode: collectionPostCode,
                collectionInstructions: collectionInstructions,
                pickupDate: pickupDate,
                pickupTimeFrom: pickupTimeFrom,
                pickupTimeTo: pickupTimeTo,
                bagId: packageBagSelectedId,
                packageId: packageBagSelected.package_id,
                pickUpType: pickupType
            };

            window.localStorage.setItem('cart',JSON.stringify(formValues));
            localStorage.setItem("path", '/membership/payment' );

            history.push('/membership/payment');
        }
    };

    // useEffect(() => {
    //     console.log('packageDetails',id);
    //     console.log('packageDetails',packageBagSelected);
    //     console.log('packageDetails',packageDetails);
    // }, [packageDetails,id]);

    return (
        <div>
            <ShopNavbar2 />
            <div className="container">
                <div className="row gx-5">
                    <div className="col-lg-6 col-md-6 col-12 mx-auto mt-3 first_div_a d-flex justify-content-center align-items-center flex-column package_detail_banner">
                        <h1 className="text-center my-5 h1____css">{packageDetails && packageDetails.package.pkg_name}</h1>
                        <h3 className="text-center my-4">
                            Collection every {packageDetails && packageDetails.package.frequency}
                        </h3>
                    </div>
    {/*}
                    <div className="col-lg-6 col-md-6 col-12 mx-auto mt-3 first_div1 d-flex justify-content-center align-items-center flex-column">
                        <Button variant="contained" color="primary">
                            change
                        </Button>
                        <br />
                        <div className="xyz">
                            <Button variant="contained" color="primary">
                                pause
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className="secons____btnnn"
                            >
                                end
                            </Button>
                        </div>
                    </div>
                          */}

                </div>
            </div>
            <h1 className="text-center my-3 home_page_heading">Recycling bag</h1>
            <div className="col-4 mx-auto">
                <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                        <div className={classes.root}>

                        <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                            {packageDetails && packageDetails.bag && packageDetails.bag.map((data, id) => (

                                <Button className="detail_page_package_btn" color="primary" onClick = {()=>{
                                    setPackageBagSelectedId(data.bag_id);
                                    setPackageBagSelected(data);
                                }}
                                    variant={(data.bag_id == packageBagSelectedId) ?"contained":""}>
                                    {data.bag_name}
                                </Button>
                                // <Button>120 LITER</Button>
                            ))}
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            <p className="text-center my-2" style={{maxWidth: 500,margin: 'auto'}}>
                <h3 class="home_page_heading">
                    Price: { packageBagSelected && packageBagSelected.price } { packageBagSelected && packageBagSelected.currency }
                </h3>

                {/*{ packageBagSelected && packageBagSelected.bag_description }*/}
            </p>
            {/*<h1> {JSON.stringify(pickUpAddress)}</h1>*/}

            {paymentOptionsShow == 0 && <div style={{marginTop: 50}} className="container wrapper mb-5 detail_form_wrap">
                <h1 className="text-center my-4 home_page_heading">Collection</h1>
                <div className="row">
                <div className="col-lg-6 col-md-6 col-12 mx-auto">
                <form>
                <div class="input-data mb-4">
                <input type="text" required onChange={(e) => {
                    setPickUpAddress(e.target.value);
                }}
                value={pickUpAddress}

                />

                <div class="underline"></div>
                    <label>ADDRESS</label>
                </div>
                {isFormSubmit == 1 && pickUpAddress=="" && <label className="row mx-auto">This field is required</label>}
                <div className="row detail_input_row">
                <div className="col-lg-6 col-md-6 col-12 my-4" style={{paddingLeft: 0}}>
                <div class="input-data">
                    <select required class="form-control" style={{paddingLeft:3,borderTop:0,borderLeft:0,borderRight:0,borderBottom: '1.5px solid grey',marginTop:5}} onChange={(e) => {
                            setPickUpZip(e.target.value);
                        }}value={pickUpZip}
                    >
                        <option value="">SELECT ZIP CODE</option>
                        {packageDetails && packageDetails.zipcodes.map((data)=>(
                            <option value={data.zip_id}>{data.zip_code}</option>
                        ))}
                    </select>
                </div>
                {isFormSubmit == 1 && pickUpZip=="" && <label className="row mx-auto">This field is required</label>}
                </div>

                    <div className="col-lg-6 col-md-6 col-12 my-4" style={{paddingRight: 0}}>
                        <div class="input-data">
                            <input type="text" required onChange={(e) => {
                                setPickUpDistrict(e.target.value);
                            }}                value={pickUpDistrict}
                            />
                            <div class="underline"></div>
                            <label>DISTRICT</label>
                        </div>
                        {isFormSubmit == 1 && pickUpDistrict=="" && <label className="row mx-auto">This field is required</label>}
                    </div>
                </div>

                <div class="input-data my-4">
                    <input type="text" required onChange={(e) => {
                            setCollectionPhone(e.target.value);


                    }} value={collectionPhone}/>
                    <div class="underline"></div>
                    <label>CELL PHONE</label>
                </div>
                {isFormSubmit == 1 && collectionPhone=="" && <label className="row mx-auto">This field is required</label>}
            <div class="input-data my-4">
                <input type="text" required  onChange={(e) => {
                    setCollectionPostCode(e.target.value);
                }} value={collectionPostCode}/>
            <div class="underline"></div>
                <label>POST CODE</label>
            </div>
            {isFormSubmit == 1 && collectionPostCode=="" && <label className="row mx-auto">This field is required</label>}
            <div class="input-data my-4">
                <input type="text" required  onChange={(e) => {
                    setCollectionInstructions(e.target.value);
                }} value={collectionInstructions}/>
            <div class="underline"></div>
                <label>INSTRUCTION TO DRIVER</label>
            </div>

            {isFormSubmit == 1 && collectionInstructions=="" && <label className="row mx-auto">This field is required</label>}
            <h6 className="my-2">Choose Pick-Up Day</h6>
            <div class="col-lg-4 col-sm-4">
                <div className="input-data">
                    <input type="date" name="pick" id="pick" onChange={(e) => {
                        setPickupDate(e.target.value);
                    }} className="pic____css"  value={pickupDate}/>
                </div>
            </div>

            <div class="col-lg-4  col-sm-4">
                <div className="input-data">
                    <select className="form-control" onChange={(e) => {setPickupTimeFrom(e.target.value);}}>
                        <option>Select Time From</option>
                        {timeOptions && timeOptions.map((data)=>(
                            <option value={data}>{data}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div class="col-lg-4 col-sm-4">
                <div className="input-data">
                    {/*<select className="form-control" onChange={(e) => {setPickupTimeTo(e.target.value);}} value={pickupTimeTo}>*/}
                        {/*<option>Select Time To</option>*/}
                        {/*{timeOptions && timeOptions.map((data)=>(*/}
                            {/*<option value={data}>{data}</option>*/}
                        {/*))}*/}
                    {/*</select>*/}
                    <select className="form-control" onChange={(e) => {setPickupTimeTo(e.target.value);}}>
                        <option>Select Time To</option>
                        {timeOptions && timeOptions.map((data)=>(
                            <option value={data}>{data}</option>
                        ))}
                    </select>
                </div>
            </div>
        </form>
    </div>
    <div className="col-lg-6 col-md-6 col-12 mx-auto policy_wrap"  style={{paddingLeft: 50,paddingTop:30}}>
            <input type="radio" onChange={(e) => {setPickupType(e.target.value);}} name="pickup_type" checked value="outside_door"/> Picked up outside your door
            <h6 className="text-left" style={{paddingLeft: 10}}>
                Place the bags outside the door during the collection day.
                <br /> You contribute with increased awareness of the importance
                <br /> You will also receive a message about the estimated
                <br /> time for collection.
            </h6>

            <input type="radio" name="pickup_type" value="friendly"
                onChange={(e) => {setPickupType(e.target.value);}}
                /> Environmentally friendly and sustainable all the way

            <h6 className="text-left"  style={{paddingLeft: 10}}>Carbon neutral to your doorstep</h6>
            <br />
            <div className="d-flex justify-content-left">
                <div className="col-lg-4 col-md-6 col-8">
                <Button type="submit"
                    variant="contained"
                    color="primary"
                    className="payment__btn"
                        onClick={(e)=>{
                            setIsFormSubmit(1);
                            mySubmitHandler(e);
                        }}>
                    GO TO PAYMENT
                </Button>
            </div>
            </div>
            </div>

            </div>
            </div>}
            {paymentOptionsShow == 1 &&
            <>
            <h1 className="text-center my-4">Payment option</h1>

            <div class="content py-2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <span class="anchor" id="formPayment"></span>
                            <hr class="my-5" />

                            <div class="card card-outline-secondary">
                                <div class="card-body">
                                    <h3 class="text-center">Credit Card Payment</h3>
                                    <hr />
                                    <div class="alert alert-info p-2 pb-3">
                                        <a class="close font-weight-normal initialism"
                                            data-dismiss="alert"
                                            href="#">
                                            <samp>×</samp>
                                        </a>
                                        CVC code is required.
                                    </div>
                                    <PaymentForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container mb-5">
                <h1 className="text-center my-5">Payment history</h1>
                <div className="col-12 mx-auto">
                    <table className="table table-condensed">
                        <thead className="bg-dark text-white text-center">
                            <tr>
                                <th>DATE</th>
                                <th>DESCRIPTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-center">
                                <td>15 aug 2020</td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-primary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Standard
                    </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <a className="dropdown-item" href="#">
                                                Action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Another action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Something else here
                      </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td>5 aug 2019</td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-primary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            pick-up
                    </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <a className="dropdown-item" href="#">
                                                Action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Another action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Something else here
                      </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td>18 july 2020</td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-primary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Bulke-y
                    </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <a className="dropdown-item" href="#">
                                                Action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Another action
                      </a>
                                            <a className="dropdown-item" href="#">
                                                Something else here
                      </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h1 class="text-center my-4">
                    Get a free pick-up for every recruited member!
        </h1>
                <div class="col-11 mx-auto">
                    <div class="col-lg-4 col-md-6 col-11 mx-auto">
                        <div class="d-flex justify-content-center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => history.push("/invite")}
                            >
                                Invite Friends and neighbors
              </Button>
                        </div>
                    </div>
                </div>
            </div>
    </>}
            <FooterShop />
        </div>
    );
};

export default MemberShipSingle;
