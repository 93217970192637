import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { set } from "react-ga";
import Loader from "react-loader-spinner";

import { useDispatch, useSelector } from "react-redux";
import { AddPackage } from "../../../../actions/packageActions";
import { addProductType } from "../../../../actions/productTypeAction";
const Add_ProductType = () => {
  const [numm, setNumm] = useState(0);
  const [name, setname] = useState("");
  const [status, setstatus] = useState("0");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loaderReducer);

  const onClickListener = () => {
    const data = {
      pt_name: name,
      pt_active: status,
    };
    dispatch(addProductType(data));
  };
  return (
    <div className="p-2 mt-2  mx-auto">
      <div className="container card p-5 mobile__cs">
        <h2 className="text-center my-1">Add Product Type</h2>
        <div className="row mt-4">
          <div className="col-lg-12 col-md-12 col-12 mx-auto">
            <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product Type
              </span>
              <input
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setname(e.target.value)}
              />
            </div>

            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setstatus(e.target.value)}
            >
              <option selected>Status</option>
              <option value="1">active</option>
              <option value="0">not active</option>
            </select>
            <br />
          </div>
        </div>
        <div className=" mx-auto">
          <button
            type="button"
            class="btn btn-primary "
            onClick={onClickListener}
          >
            Add Product Type
            <Loader
              type="Puff"
              color="#FFF"
              height={20}
              width={15}
              style={{ marginLeft: 5 }}
              visible={loading}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Add_ProductType;
