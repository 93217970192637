import React, { useEffect, useState } from "react";
import "./Cart.css";
import Button from "@material-ui/core/Button";
import ShopNavbar from "../HeaderShop/ShopNavbar";
import Footer from "../../../Common/Footer/Footer";
import CheckOutCard1 from "../ShopCard/CheckOutCard1";
import NotLoginForm from "./Parts/NotLoginForm/NotLoginForm";
import Checkout from "./Parts/Checkout/Checkout";
import ShopNavbar2 from "../Common/ShopNavbar2";
import FooterShop from "../Common/FooterShop";
import {getStoreProductByID} from "../../../actions/productAction";

function Cart() {
    const [numm, setNumm] = useState(0);

    return (
    <div>
      <ShopNavbar2 />

      <div className="row d-flex justify-content-center my-2">
        <div className="col-10 my-3">
          <CheckOutCard1 />
        </div>
      </div>
        {numm != 0 &&
        {/*<NotLoginForm />*/}
        }

       {/*<Checkout />*/}

      <FooterShop />
    </div>
  );
}

export default Cart;
