import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {useDispatch, useSelector} from "react-redux";
import {addProduct, updateProduct} from "../../../../actions/productAction";
import {
    clearProductType,
    getAllProductType,
} from "../../../../actions/productTypeAction";
import {
    clearCategories,
    getAllCategories,
} from "../../../../actions/categoryAction";
import {
    NOT_FOUND_IMAGE,
    PRODUCT_IMAGE_URL,
} from "../../../../Services/DataApi";

const Edit_Product = ({history}) => {
    const ProductData = history.location.state.data;
    console.log('talha', ProductData)
    const dispatch = useDispatch();
    const initialState = "";
    const [numm, setNumm] = useState(parseInt(ProductData.p_qty));
    const [name, setname] = useState(ProductData.p_name);
    const [image, setimage] = useState(ProductData.p_img);
    const [price, setprice] = useState(ProductData.p_price ? ProductData.p_price : ProductData.p_point);
    const [size, setsize] = useState(ProductData.p_size);
    const [status, setstatus] = useState(ProductData.p_active);
    const [categoryProduct, setcategory] = useState(ProductData.p_pc_id);
    const [type, settype] = useState(ProductData.p_pt_id);
    const [color, setcolor] = useState(ProductData.p_color);
    const [description, setDescription] = useState(ProductData.p_description);

    const {productType} = useSelector((state) => state.productTypeReducer);
    const {category} = useSelector((state) => state.categoryReducer);
    const incNumm = () => {
        setNumm(numm + 1);
    };
    const decNumm = () => {
        if (numm > 0) {
            setNumm(numm - 1);
        } else {
            alert("Sorry ! Zero Limit Reached");
            setNumm(0);
        }
    };

    useEffect(() => {
        console.log("productType VALUE", productType);
        if (Array.isArray(productType) && productType.length > 0) {
        } else {
            dispatch(getAllProductType());
        }

        if (Array.isArray(category) && category.length > 0) {
        } else {
            dispatch(getAllCategories());
        }
    }, [dispatch, productType, category]);

    useEffect(() => {
        return () => {
            dispatch(clearProductType());
            dispatch(clearCategories());
            console.log("clearPackage");
        };
    }, [dispatch]);
    const onClickListener = () => {
        console.log(image)
        // const data = {
        //     p_name: name,
        //     p_qty: numm,
        //     p_price: price,
        //     "p_img[]": image,
        //     p_size: size,
        //     p_active: status,
        //     p_pc_id: categoryProduct,
        //     p_pt_id: type,
        //     p_color: color,
        //     p_description: description,
        // };

        const formData = new FormData();
        formData.append("p_name", name);
        formData.append("p_qty", numm);
        formData.append("p_price", price);
        formData.append("p_img[]", image);
        formData.append("p_size", size);
        formData.append("p_active", status);
        formData.append("p_pc_id", categoryProduct);
        formData.append("p_pt_id", type);
        formData.append("p_description", description);

        formData.append("p_color", color);

        // console.log("Add Product Data", formData);
        dispatch(updateProduct(formData, ProductData.p_id,history));
    };
    return (
        <div className="container p-2">
            <div className="container card p-4  add-products-container">
                <h2 className="text-center my-4">Edit Products</h2>
                {console.log("GETPROODUCT", ProductData)}
                <div className="row ">
                    <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product name
              </span>
                            <input
                                required="required"

                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                onChange={(e) => setname(e.target.value)}
                                value={name}
                            />
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-6">
                                <input
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    onChange={(e) => setimage(e.target.files[0])}

                                />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 d-flex justify-content-center">
                                <img
                                    src={image ? PRODUCT_IMAGE_URL + image : NOT_FOUND_IMAGE}
                                    alt=""
                                    style={{
                                        height: 70,
                                        width: 70,
                                        objectFit: "contain",
                                        borderRadius: 50,
                                    }}
                                />
                            </div>
                        </div>
                        <br/>
                        <div className="input-group ">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Product Price
              </span>
                            <input required="required"

                                   type="number"
                                   className="form-control"
                                   aria-label="Sizing example input"
                                   aria-describedby="inputGroup-sizing-default"
                                   onChange={(e) => setprice(e.target.value)}
                                   value={price}
                            />
                        </div>
                        <select required
                                className="form-select mt-4"
                                aria-label="Default select example"
                                onChange={(e) => setsize(e.target.value)}
                                value={size}
                        >
                            <option selected>Size</option>
                            <option value="small">Small</option>
                            <option value="large">Large</option>
                            <option value="medium">Medium</option>
                            <option value="extra-small">Extra Small</option>
                            <option value="extra-large">Extra Large</option>
                            {/* <option value="3">six</option> */}
                        </select>
                        <br/>

                        <select
                            required="required"

                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setstatus(e.target.value)}
                            value={status}
                        >
                            <option selected>Status</option>
                            <option value="1">active</option>
                            <option value="0">not active</option>
                        </select>
                        <br/>

                        <lebel>Quantity :</lebel>
                        <ButtonGroup
                            size="small"
                            color="primary"
                            aria-label="large outlined primary button group"
                        >
                            <Button onClick={decNumm}>
                                <RemoveIcon/>
                            </Button>
                            <Button>
                                <h5>{numm}</h5>
                            </Button>
                            <Button onClick={incNumm}>
                                <AddIcon/>
                            </Button>
                        </ButtonGroup>
                        <br/>
                        <br/>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <select
                            required="required"

                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setcategory(e.target.value)}
                            value={categoryProduct}
                        >
                            <option selected>Product Catagory</option>
                            {/* <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option> */}
                            {Array.isArray(category) && category.length > 0
                                ? category.map((data, id) => (
                                    <option key={id} value={data.pc_id}>
                                        {data.pc_name}
                                    </option>
                                ))
                                : null}
                        </select>

                        <select
                            required="required"

                            className="form-select mt-4"
                            aria-label="Default select example"
                            onChange={(e) => settype(e.target.value)}
                            value={type}
                        >
                            {Array.isArray(productType) && productType.length > 0
                                ? productType.map((data, id) => (
                                    <option value={data.pt_name}>
                                        {data.pt_name}
                                    </option>
                                ))
                                : null}
                        </select>
                        <br/>

                        <select
                            required="required"

                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setcolor(e.target.value)}
                            value={color}
                        >
                            <option selected>Product Color</option>
                            <option value="green">Green</option>
                            <option value="yellow">Yellow</option>
                            <option value="red">Red</option>
                            <option value="pink">Pink</option>
                            <option value="black">Black</option>
                            <option value="white">White</option>
                        </select>
                        <br/>

                        {/* <select
              className="form-select"
              aria-label="Default select example"
              // onChange={(e) => setstatus(e.target.value)}
            >
              <option selected>Status</option>
              <option value="1">active</option>
              <option value="0">not active</option>
            </select>
            <br /> */}

                        <div className="mb-3 ">
                            <div className="">
                                <input
                                    required="required"

                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Type product Brand here"
                                />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label for="exampleFormControlTextarea1" className="form-label">
                                Short description
                            </label>
                            <textarea
                                required="required"

                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto">
                    <button
                        type="button"
                        class="btn btn-primary "
                        onClick={onClickListener}
                    >
                        Update
                    </button>
                </div>
                <br/>
            </div>
        </div>
    );
};

export default Edit_Product;
