import React, { useState, useEffect } from 'react';
import style from "../../Css/choseBag.module.css";
import homelast1 from "../../../../assets/homelast1.png";
import homelast2 from "../../../../assets/homelast2.png";
import ChoseBagSelectButton from "./ChoseBagSelectButton";
import Axios from "../../../../Services/Axios";
import { Link } from "react-router-dom";
import "../../../MemberShip2/member.css";

import {LoadingState} from "../../../../actions/loaderActions";
import toast from "../../../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import "../../../MemberShip/chose.css";

import ButtonGroup from "@material-ui/core/ButtonGroup/index";
import Button from "@material-ui/core/Button";
import {getAllPackages} from "../../../../actions/packageActions";
import {makeStyles} from "@material-ui/core/styles/index";
import axios from "axios/index";

const Chosebag = () => {

    const [packageDetails, setPackageDetails] = useState(null);
    const [packageBagSelectedId, setPackageBagSelectedId] = useState(0);
    const [packageBagSelected, setPackageBagSelected] = useState(null);
    const [defualtPkgId, setDefualtPkgId] = useState('');
     const { packages } = useSelector((state) => state.packageReducer);
    const dispatch = useDispatch();
    const [bags, setBags] = useState([]);
    const [selectedBag, setSelectedBag] = useState(null);
    const [selectedPkg, setSelectedPkg] = useState(null);



     const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    }));
    const classes = useStyles();

    const [timeOptions, setTimeOptions] = useState([]);
    const onClickListener = (id) => {
        const sendingData = {

            package_id: id
        };
        Axios.post("/api/packages/bags", sendingData)
            .then((res) => {
                setPackageDetails(res.data.data);
                if(res.data.data.bag.length > 0) {
                    setPackageBagSelectedId(res.data.data.bag[0].bag_id);
                    setPackageBagSelected(res.data.data.bag[0]);
                }
                dispatch(LoadingState(false));
            })
            .catch((err) => {
                // toast.error("There is Some Error While Adding Package");
                dispatch(LoadingState(false));
            });

    }
    useEffect(() => {



         let options = [];
        let index = ':00';
        let indexD = 0;
        for(let i = 0;i <= 48;i++) {
            options.push(indexD + index);
            if(index == ':00') {
                index = ':30';
            } else {
                indexD++;
                index = ':00';
            }
        }
        setTimeOptions(options);
    }, []);
    useEffect(() => {
        const sendingData = {

            package_id: packages?packages[0].pkg_id:''
        };
        Axios.post("/api/packages/bags", sendingData)
            .then((res) => {
                setPackageDetails(res.data.data);
                if(res.data.data.bag.length > 0) {
                    setPackageBagSelectedId(res.data.data.bag[0].bag_id);
                    setPackageBagSelected(res.data.data.bag[0]);
                }
                dispatch(LoadingState(false));
            })
            .catch((err) => {
                // toast.error("There is Some Error While Adding Package");
                dispatch(LoadingState(false));
            });
         if (Array.isArray(packages) && packages.length > 0) {

            packages.map(data => {

                return setBags([...bags, data]);
            });
            console.log("All bags", bags);
        } else {
            dispatch(getAllPackages());
        }

     }, [dispatch, packages]);
  return (
    <div>
      <div className={style.background__img_chosBag}>
        <div className={style.center__div_cs}>
          <div className={style.Chosebag_center+' home_page_product_list'}>
            <h1 className={style.Heading__chosebage+' home_page_heading'}>RÄKNA UT DITT PRIS</h1>
            <p className={style.chosebag___para+' home_section_para'}>
              Vilken storlek på loopa-påsen passar bäst i ditt hem?
            </p>
            <div className="container mt-2">
              <div className={style.Chosebag_images_res}>

                  <div className="container mb-5">
                      <div className="row d-flex justify-content-center align-items-center">
                        {/* <p className={style.chosebag___para} style={{marginTop:40}}>
                          Hur ofta vill du att vi hämtar och sorterar din<br />återvinning?
                        </p> */}
                        <div className="packages_list">
                            {packages && packages.map((data, id) => (
                                <div className={packageDetails && packageDetails.bag[0].package_id == data.pkg_id ? "package_box blue package_box_selected":"package_box blue"}>
                                <Link  key={id} onClick={() => onClickListener(data.pkg_id)}>
                                        <h3>{data.pkg_name}</h3>
                                    </Link>
                                </div>
                            ))}
                        </div>
                          {/* {packages && packages.map((data, id) => (

                              <div className="col-lg-3 col-md-3 col-sm-12 col-12 mt-3 mb-3">

                                  <div className="card relax d-flex justify-content-center align-items-center" style={{"height": "auto"}}>
                                      <div className="card-body justify-content-center align-items-center" style={{padding: "3px"}}>
                                          <div className="col-xs-12">
                                              <Link  key={id} onClick={() => onClickListener(data.pkg_id)}>
                                                  <h4 className="card-title">{data.pkg_name}</h4>
                                              </Link>
                                          </div>
                                          <div class="col-xs-12">
                                              <Link  to={"/membership/"+data.pkg_id} onClick={()=> {
                                                  setSelectedBag(data.bag);
                                                  setSelectedPkg(data);
                                              }}>
                                                  <Button variant="contained" color="primary" className="chose__btn" >Read More</Button>
                                              </Link>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          ))} */}
                          {/*{(Array.isArray(packages) && packages.length > 0) ?
            packages.map(data => (
              <div className="col-lg-3 col-md-3 col-sm-12 col-12 mt-3 mb-3">
                <div class="card relax d-flex justify-content-center align-items-center">
                  <div class="card-body d-flex justify-content-center align-items-center">
                    <h4 class="card-title">{data.pkg_name}</h4>
                    {console.log(data.pkg_name)}
                  </div>
                </div>
              </div>
            )) : dispatch(getAllPackages())}*/}
                      </div>
                      <div >
                          <div>
                              <div className={classes.root}>
                                <div className="custom_tabs">
                                    {packageDetails && packageDetails.bag && packageDetails.bag.map((data, id) => (
                                        <div
                                            className={(data.bag_id == packageBagSelectedId?'active':'')}
                                            onClick = {()=>{
                                                setPackageBagSelectedId(data.bag_id);
                                                setPackageBagSelected(data);
                                            }}
                                        >{data.bag_name}</div>
                                    ))}
                                </div>
                                  {/* <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                                      {packageDetails && packageDetails.bag && packageDetails.bag.map((data, id) => (

                                          <Button color="primary" onClick = {()=>{
                                              setPackageBagSelectedId(data.bag_id);
                                              setPackageBagSelected(data);
                                          }}
                                                  variant={(data.bag_id == packageBagSelectedId) ?"contained":""}>
                                              {data.bag_name}
                                          </Button>
                                          // <Button>120 LITER</Button>
                                      ))}
                                  </ButtonGroup> */}
                              </div>
                          </div>
                      </div>
                      {packageBagSelected && packageBagSelected.price != null  &&
                      <p className="text-center my-2" style={{maxWidth: 500,margin: 'auto'}}>
                          <h3 className="home_page_heading">
                              Price: { packageBagSelected && packageBagSelected.price } { packageBagSelected && packageBagSelected.currency }
                          </h3>
                          <Link  to={"/membership/"+packageBagSelected.package_id} onClick={()=> {
                                // setSelectedBag(data.bag);
                                // setSelectedPkg(data);
                            }}>
                                <Button style={{background:"black"}} variant="contained" color="primary" className="chose__btn" >Read More</Button>
                            </Link>
                      </p>
                      }
                      {selectedBag && <>
                          <h3 className="text-center mt-5">
                              Pick up every {selectedPkg && selectedPkg.frequency}.
                              <br/> {selectedBag && selectedBag.price} {selectedBag && selectedBag.currency} per
                              collection.
                              <br/> {selectedPkg && selectedPkg.pkg_description}
                          </h3>
                          <div className="text-center h5 mt-5">
                              <Link className="text-center" to="/membership2">
                                  Read more about our memberships here
                              </Link>
                          </div>
                          <div className="text-center h5 mt-2">
                              <Link className="text-center" to="/recycle-bag">
                                  How does it work?
                              </Link>
                          </div>
                          <br/>
                          <div className=" d-flex justify-content-center chose___btnn">
                              {/*<Button*/}
                                  {/*variant="contained"*/}
                                  {/*color="primary"*/}
                                  {/*className="chose__btn"*/}
                                  {/*onClick={() => history.push("/membership2")}*/}
                              {/*>*/}
                                  {/*Choose Memberships*/}
                              {/*</Button>*/}
                          </div>
                      </>}
                  </div>

              </div>
              {/*<p className="text-center my-4" style={{ fontWeight: 'normal', fontSize: '16px', color: 'black' }}>*/}
                {/*Hur ofta vill du att vi hämtar och sorterar din återvinning?*/}
              {/*</p>*/}
                {/*<p className="text-center my-2" style={{maxWidth: 500,margin: 'auto'}}>*/}
                    {/*<h3>*/}
                        {/*Price: { packageBagSelected && packageBagSelected.price } { packageBagSelected && packageBagSelected.currency }*/}
                    {/*</h3>*/}

                    {/*/!*{ packageBagSelected && packageBagSelected.bag_description }*!/*/}
                {/*</p>*/}
              {/*<div className="d-flex justify-content-center pb-5">*/}
                {/*<ChoseBagSelectButton />*/}
              {/*</div>*/}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chosebag;
