import React from "react";
import Footer from "../../../Common/Footer/Footer";
import ShopNavbar from "../HeaderShop/ShopNavbar";
import "../HomeWebShop/HomeWebShop.css";
import Catagories from "../HomeWebShop/SideBar/Catagories";
import SidebarAccordian from "../HomeWebShop/SideBar/SidebarAccordian";
import ShopCard from "../ShopCard/ShopCard";

function ShopPage() {
  return (
    <div>
      <ShopNavbar />
      <div className="h1 text-center mt-5">Shop</div>
      <div className="row my-5">
        <div className="col-lg-3 col-md-3 sidebarHome">
          <Catagories />
          <SidebarAccordian />
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default ShopPage;
