import React, { useState } from "react";

const Edit_Transaction = () => {

    return (
        <div className="p-2 mt-2  mx-auto">

            <div className="container card p-5 mobile__cs">
                <h2 className="text-center my-1">Edit Transaction</h2>
                <div className="row mt-4">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">
                                name
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </div>


                        <div className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">
                                role
              </span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                            />
                        </div>

                        <select
                            className="form-select mt-4"
                            aria-label="Default select example"
                        >
                            <option selected>status type</option>
                            <option value="1">active</option>
                            <option value="2">not active</option>

                        </select>

                        <br />


                    </div>
                </div>
                <div className=" mx-auto">
                    <button type="button" class="btn btn-primary ">Edit Transaction</button>
                </div>
            </div>
        </div>
    );
};

export default Edit_Transaction;

