import { GET_ALL_PACKAGES, CLEAR_PACKAGES,GET_ALL_PACKAGES_ADMIN } from "./DispatchActions";
import Axios from "../Services/Axios";
import { LoadingState } from "./loaderActions";
import toast from "../utils/toast";

export const getAllPackages = () => (dispatch) => {
  //   dispatch(LoadingState(true));

  // Axios.get("/api/packages/all")
  Axios.get("/api/packages/active")
    .then((res) => {
      // console.log("getAllPackages :", res.data);
      dispatch({
        type: GET_ALL_PACKAGES,
        payload: res.data.data,
      });

      //   dispatch(LoadingState(false));
    })
    .catch((err) => {
      const data = err.response.data.error;
      // console.log("Register ERR:", data);
      //   dispatch(LoadingState(false));
    });
};


export const getAllPackagesForAdmin = () => (dispatch) => {
    //   dispatch(LoadingState(true));

    // Axios.get("/api/packages/all")
    Axios.get("/api/packages/all")
        .then((res) => {
            // console.log("getAllPackagesAdmin :", res.data);
            dispatch({
                type: GET_ALL_PACKAGES_ADMIN,
                payload: res.data.data,
            });

            //   dispatch(LoadingState(false));
        })
        .catch((err) => {
            const data = err.response.data.error;
            // console.log("Register ERR:", data);
            //   dispatch(LoadingState(false));
        });
};

export const AddPackage = (data,history) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("Package ACTION DATA", data);
  Axios.post("/api/package/add", data)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllPackages());
      // if (res.status === 200) {
        history.push("/admin/packages-list");

        toast.success("Package Added successfully.");

      console.log("DATA API HIT :", res.data);
      // let { id, name, avatar } = jwtDecode(res.data.token);
      // authConfig(res.data.token, res.data.user, history);
      // dispatch({
      //   type: GET_PROFILE,
      //   payload: res.data,
      // });
      // }
    })
    .catch((err) => {
      // const data = err.response.data.error;
      // var res = data.split("validation.custom.messages.");
      // res = res[1];
      // console.log("Login ERR:", res);
      // // var pk = "invalid_login";

      // console.log("Messgas", Message.validation.custom.messages[res]);
      // const Error = Message.validation.custom.messages[res];
      toast.error("There is Some Error While Adding Package");
      dispatch(LoadingState(false));
    });
};

export const updatePackage = (data, id,history) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("Package ACTION DATA", data);
  Axios.post("/api/package/edit/" + id, data)
    .then((res) => {
        // getAllPackagesForAdmin()
      dispatch(LoadingState(false));
      dispatch(getAllPackagesForAdmin());
      toast.success(res.data.message);
      history.push("/admin/packages-list");

      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Updating Package");
      dispatch(LoadingState(false));
    });
};

export const removePackage = (id) => (dispatch) => {
  dispatch(LoadingState(true));
  console.log("Package ACTION DATA", id);
  Axios.get("/api/package/delete/" + id)
    .then((res) => {
      dispatch(LoadingState(false));
      dispatch(getAllPackages());
      toast.success("Package Removed Successfully.");
      console.log("DATA API HIT :", res.data);
    })
    .catch((err) => {
      toast.error("There is Some Error While Removing Package");
      dispatch(LoadingState(false));
    });
};

// --------------------------Clear State----------------------------

export const clearPackage = () => (dispatch) => {
  console.log("ACTION clearPackage ");
  dispatch({
    type: CLEAR_PACKAGES,
  });
};
