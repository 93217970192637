import React from "react";
import "./ShopCard.css";
import Button from "@material-ui/core/Button";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useHistory,
} from "react-router-dom";
function ShopCategoryCard(props) {
  return (
    <Link to="/shop/shop">
      <div className="CardCategoryWebShop">
        <img
          className="card-category-img p-1"
          src={props.img}
          alt={props.alt}
          classNameName="card_____img__css"
        />
        <div className="details">
          <div className="h6">{props.title}</div>
        </div>
      </div>
    </Link>
  );
}

export default ShopCategoryCard;
