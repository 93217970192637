import React from "react";
import "./home.css";
import Navbar from "../../Common/Header/Navbar";
import Footer from "../../Common/Footer/Footer";
import { Parent_Strip_Color } from "../../Common/Color";
import Button from "@material-ui/core/Button";

const Home = () => {
  return (
    <div className="container-fluid p-0">
      <Navbar />
      <div className="hero-image_home">
        <div className="d-flex justify-content-end main__page__search">
          <form class="d-flex w-25 ">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Enter your zip code here"
              aria-label="Search"
            />
            <button className="search__btnz_home" type="submit">
              Search
            </button>
          </form>
        </div>

        <div className="hero-text2">
          <h1>
            A More Relaxed Presence And
            <br />
            sustainable future.
          </h1>
          <p>And I'm a Photographer</p>
        </div>
      </div>
      <div
        className="home_strip text-center p-3"
        style={{ backgroundColor: Parent_Strip_Color }}
      >
        <h5>Try out two weeks for free, from 89 SEK</h5>
      </div>

      <div className="Section_Memberhip  bg-white ">
        <br />
        <div className="h2 text-center pb-1 ">Choose Membership</div>
        <div className="row d-flex justify-content-center mx-5">
          <div className="text-center my-3">
            <p>How often do you want a collection? “Memberships” collect your bag<br />(based on pick up
            frequency needed) with description and from prices.</p>
          </div>
        </div>

        <div className="row d-flex justify-content-center mx-5">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div class="card relax"></div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div class="card single"></div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div class="card standard"></div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div class="card standard"></div>
          </div>
        </div>
      </div>
      <div className="Section_aboutUs my-3">
        <div className="row py-3">
          <div className="text-center my-5"><h1>About Us</h1></div>

          <div className="col-lg-6">
            <img
              src="https://cdn.wm.com/content/dam/wm/assets/getstarted/woman-waste-recycling-toters.jpg/jcr:content/renditions/rendition.xl.jpeg"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-lg-6 text-center">
            <div className="h2 text-center my-3">It's Our Job</div>

            <div className="h5 p-1 my-3">
              It’s our job to think about what’s thrown out, and your job to
              keep your household, small business, and neighborhoods moving
              forward. We’re a family of 45,000 that strives to be your partner,
              not just a provider.
            </div>
          </div>
        </div>
      </div>

      <div className="Section_contactUs bg-light">
        <div className="row py-5 ">
          <div className="h2 font-weight-bold text-center">Get in touch</div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-10 mx-auto">
                <form class="form-horizontal" action="" method="post">
                  <fieldset>
                    <div class="form-group">
                      <label class="control-label" for="name">
                        Name
                      </label>
                      <div class="col-12">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Your name"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="control-label" for="email">
                        Your E-mail
                      </label>
                      <div class="col-12">
                        <input
                          id="email"
                          name="email"
                          type="text"
                          placeholder="Your email"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="control-label" for="message">
                        Your message
                      </label>
                      <div class="col-12">
                        <textarea
                          class="form-control"
                          id="message"
                          name="message"
                          placeholder="Please enter your message here..."
                          rows="5"
                        ></textarea>
                      </div>
                    </div>

                    <div class="form-group d-flex justify-content-center mt-3">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => alert("Contact Us.")}
                      >
                        Contact Us
                      </Button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="p h5 mt-5">
              Want to get in touch? We'd love to hear from you. Here's how you
              can reach us...
            </div>
            <div className="mt-4">
              <div className="p h5">
                <i className="fas fa-envelope home_page_icon_cs"></i>
                {"  "}Company@gmail.com
              </div>
              <div classNameName="  mt-3 h5">
                <i
                  className="fas fa-phone-square home_page_icon_cs"
                  style={{ fontSize: "22px" }}
                ></i>
                {"  "}+92-123-12231
              </div>
              <div className="mt-3 h5">
                <i
                  class="fas fa-map-marker-alt  home_page_icon_cs"
                  style={{ fontSize: "22px" }}
                ></i>
                {"  "} Ground Floor, Two Dockland Central Guild Street, Dublin 1
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
