import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useDispatch, useSelector } from "react-redux";
import {addProduct, getAllProduct} from "../../../../actions/productAction";
import {
    clearProductType,
    getAllProductType,
} from "../../../../actions/productTypeAction";
import {
    clearCategories,
    getAllStoreProductCategories,
} from "../../../../actions/categoryAction";
import storeCategoryReducer from "../../../../reducers/storeCategoryReducer";
import toast from "../../../../utils/toast";
import {LoadingState} from "../../../../actions/loaderActions";
import Axios from "../../../../Services/Axios";

const Add_Faq = () => {
    const dispatch = useDispatch();
    const initialState = "";

    const [categoryId, setcategory] = useState(initialState);
    const [name, setName] = useState(initialState);
    const [description, setDescription] = useState(initialState);
    const [faqCat, setFaqCat] = useState(initialState);

    const onClickListener = () => {
        const formData = new FormData();
        formData.append("cat_id", categoryId);
        formData.append("faq_title", name);
        formData.append("faq_description", description);

        Axios.post("/api/add/faq", formData)
            .then((res) => {
                dispatch(LoadingState(false));
                // dispatch(getAllProduct());
                if (res.status === 200) {
                    toast.success(res.data.message);
                    console.log("DATA API HIT :", res.data);
                }
            })
            .catch((err) => {
                toast.error("There is Some Error While Adding Product");
                dispatch(LoadingState(false));
            });
    };

    useEffect(() => {

        Axios.get("/api/faq/categories")
            .then((res) => {
                if(res.data.data.length > 0 ) {
                    setFaqCat(res.data.data);
                 }
            })
            .catch((err) => {
                 //   dispatch(LoadingState(false));
            });
    }, [dispatch]);



    return (
        <div className="container p-2" style={{marginTop: 70}}>
            <div className="container card p-4  add-products-container" >
                <h2 className="text-center my-4">Add Faq</h2>
                <div className="row ">

                    <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setcategory(e.target.value)}
                        >
                            <option key={0} value="">
                                Select Category
                            </option>
                            {/*<option selected>Product Catagory</option>*/}
                            {Array.isArray(faqCat) && faqCat.length > 0
                                ? faqCat.map((data, id) => (
                                    <option key={id} value={data.id}>
                                        {data.category_name}
                                    </option>
                                ))
                                : null}

                            {/* <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option> */}
                        </select>
                        <br />


                        <div className="mb-3">
                            <label for="exampleFormControlTextarea1" className="form-label">
                                Faq Title
                            </label>
                            <input
                                className="form-control"
                                onChange={(e) => setName(e.target.value)}/>
                        </div>


                        <div className="mb-3">
                            <label for="exampleFormControlTextarea1" className="form-label">
                                Faq description
                            </label>
                            <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto">
                    <button
                        type="button"
                        class="btn btn-primary "
                        onClick={onClickListener}
                    >
                        Add Faq
                    </button>
                </div>
                <br />
            </div>
        </div>
    );
};

export default Add_Faq;
