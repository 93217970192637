import {React,useState,useEffect} from "react";
import "./UserSubscriptions.css";
import Button from "@material-ui/core/Button";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Common/Header/Navbar";
import PaymentForm from "../MemberShip2/SingleForm";
import ShopNavbar2 from "../WebShop/Common/ShopNavbar2";
import Axios from "../../Services/Axios";
import toast from "../../utils/toast";
import {LoadingState} from "../../actions/loaderActions";
import {useDispatch} from "react-redux";
import FooterShop from "../WebShop/Common/FooterShop";
const RubbishSubscription = ({ history }) => {

    const user = localStorage.getItem("user");

    if(!user || user === "") {
        history.push("/login");
    }

    let dispatch = useDispatch();
    const [price, setPrice] = useState(0);
    const [currency, setCurency] = useState('$');
    const [subscriptions, setSubscriptions] = useState(null);
    const [points, setPoints] = useState(null);
     useEffect(() => {

        const sendingData = {
            user_id: user
        };

        Axios.get("/api/user/rubbishSubscriptions/all?user_id="+user, sendingData)
            .then((res) => {
                if(res.data.data.length > 0) {

                    setSubscriptions(res.data.data);
                    setPoints(res.data.data[0].user.point);


                }


                dispatch(LoadingState(false));
            })
            .catch((err) => {

                toast.error("Sorry No record found");
                // dispatch(LoadingState(false));
            });

    },[]);

    return (
        <div>
            <ShopNavbar2 history={history}/>
            <div style={{marginTop:130}}>
            <h1 className="text-center my-4">Rubbish product</h1>

            <div className="container wrapper mb-5">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                        {points &&
                       <span> <h6>Current points : {points && points?points:0}</h6></span>
                        }
                        <table className="table table-bordered">
                            <tr>
                                {/*<th>Product Id</th>*/}
                                <th>Instructions for driver</th>
                                <th>Pickup address</th>
                                <th>Pickup date from</th>
                                <th>Pickup date to</th>
                                <th>District</th>
                                <th>Post code</th>
                                 {/*<th>Status</th>*/}
                             </tr>
                            { subscriptions && subscriptions.map((data) => (
                                <tr>
                                    {/*<td>{data.product_id}</td>*/}
                                    <td>{data.instructions_for_driver}</td>
                                    <td>{data.pickup_address}</td>
                                    <td>{data.pickup_date_from}</td>
                                    <td>{data.pickup_date_to}</td>
                                    <td>{data.pickup_district}</td>
                                    <td>{data.post_code}</td>
                                     {/*<td>{data.status}</td>*/}
                                    {/*<td>*/}
                                        {/*<button onClick={()=>{*/}
                                            {/*history.push('/membership/details/'+data.id)*/}
                                        {/*}}>View Detail</button>*/}
                                    {/*</td>*/}
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
            </div>
            <FooterShop />
        </div>
    );
};

export default RubbishSubscription;
