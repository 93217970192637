import React, { useEffect,useState } from "react";
import { Parent_Strip_Color } from "../../../Common/Color";
import FooterShop from "../Common/FooterShop";
import ShopNavbar2 from "../Common/ShopNavbar2";
import styles from "../Css/Shop.module.css";
import HoverShopCard from "../ShopCard/HoverShopCard";
import ShopCard from "../ShopCard/ShopCard";
import "../ShopCard/ShopCard.css";
import { Link } from "react-router-dom";
import { NOT_FOUND_IMAGE, PRODUCT_IMAGE_URL } from "../../../Services/DataApi";

import Product1 from "../../../assets/product/prod-1.png";
import Product2 from "../../../assets/product/prod-2.png";
import Product3 from "../../../assets/product/prod-3.png";
import Product4 from "../../../assets/product/prod-4.png";
import Product5 from "../../../assets/product/prod-5.png";
import Product6 from "../../../assets/product/prod-6.png";
import {
  clearProduct,
  getAllActiveProduct,
  getAllProduct,
  getAllProductByCategoryID,
} from "../../../actions/productAction";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCategories,
  getAllActiveCategories,
} from "../../../actions/categoryAction";
import {GET_ALL_PRODUCT} from "../../../actions/DispatchActions";
import Axios from "../../../Services/Axios";

function HomeShop() {
  const { product } = useSelector((state) => state.productReducer);
  const { category } = useSelector((state) => state.categoryReducer);
  const [catName, setcatName] = useState('');
  const [latestProducts, setLatestProducts] = useState([]);

  const dispatch = useDispatch();
    console.log(product);

  useEffect(() => {
    if (Array.isArray(product) && product.length > 0) {

    }

    if (Array.isArray(category) && category.length > 0) {
    } else {
        dispatch(getAllActiveProduct());

        dispatch(getAllActiveCategories());
    }
  }, [dispatch, product, category]);

  useEffect(() => {
    return () => {
      dispatch(clearProduct());
      dispatch(clearCategories());

      };
  }, [dispatch]);

  useEffect(()=>{
      if(latestProducts.length == 0 && category){
          console.log('latestProdu',category[0].pc_id);
          getLatestProductByCatId(category[0].pc_id);
      }
  },[category]);

  const getProductsByID = (id) => {
    dispatch(getAllProductByCategoryID(id));
  };

  const getLatestProductByCatId = (id) => {
      Axios.get(`/api/products/cat/${id}`)
          .then((res) => {
              // console.log("getAllProductByCategoryID :", res.data);
              setLatestProducts(res.data.data);
          })
          .catch((err) => {
              const data = err.response.data.error;
          });
  };

  return (
    <div className={styles.main}>
      <ShopNavbar2 />
      <div className={styles.bulkRubbishImageOverText+' web_shop_banner'}>
        <div>
          <h1 className="shop_page_heading">Bra deals - För både människor och planet</h1>
          <h4>För medlemmar av medlemmar</h4>
        </div>
      </div>

      <div
        className={styles.stripeSearch}
        style={{ backgroundColor: "#E9F0EA" }}
      >
        <div className="row">
          <h5>
            Prenumerationskund? Fri frakt av det som får plats i din
            återvinningspåse. Se övriga fraktalternativ i nästa steg.
          </h5>
        </div>
      </div>

      <div className="row">
        <div className="d-flex flex-wrap my-2 justify-content-center">
        {category && category.map((cat, id) => (
          <div onClick={() => {
                getLatestProductByCatId(cat.pc_id);
            }} className="btn btn-lg border-dark mx-1 mt-2 web_shop_page_buttons">{cat.pc_name}</div>
        ))}

        </div>
      </div>

      <div className="row my-2">
            {Array.isArray(category) && category.length > 0 ? (
            latestProducts.slice(0, 4).map((prod, id) => (
                  <div className="col-lg-2 col-md-3 col-sm-6"  key={id}>
                    <div className="main_shoe d-flex align-items-center">
                        <img src={prod.p_img ? PRODUCT_IMAGE_URL + prod.p_img : NOT_FOUND_IMAGE}
                            alt="Avatar" className="shoe_image"/>

                        <div className="shoe_middle">
                            <div className="">
                                <Link to={"/shop/detail/" + prod.p_id}>
                                    <h4>
                                        {prod ? prod.p_name : "Product Name"}
                                    </h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                  </div>
                ))
            ) : (
                <p>No kategori Found</p>
            )}


      </div>
      <div className="row mt-5">
        <div className="col-lg-4 col-md-4 col-12 mx-auto">
          <div className={styles.sidebar}>
            <h5>Välj kategori</h5>
            <hr />
            <ul className="bulk_page_list">
              {Array.isArray(category) && category.length > 0 ? (
                category.map((cat, id) => (
                  <li key={id} onClick={() => {
                    getProductsByID(cat.pc_id);
                    setcatName(cat.pc_name)
                  }}>
                    {cat ? cat.pc_name : "Category Name"}
                  </li>
                ))
              ) : (
                <p>No kategori Found</p>
              )}
              {/* <li>
                <a href="">Möbler</a>
              </li>
              <li>
                <a href="">Elektronik</a>
              </li>

              <li>
                <a href="">Vitvaror</a>
              </li>
              <li>
                <a href="">Byggavfall</a>
              </li>
              <li>
                <a href="">Övrigt</a>
              </li> */}
            </ul>

            {/* <h5 className="mt-5">Alternativ</h5>
            <hr />
            <BookCard />
            <BookCard /> */}
          </div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12">
          <div className={styles.bulkProducts}>
            <h1 className="mb-4">{catName?catName:''}</h1>
          </div>
          <div className="row">
            {Array.isArray(product) && product.length > 0 ? (
              product.map((data, id) => (
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <ShopCard
                    title={data.p_name}
                    price={data.p_price}
                    img={data.p_img}
                    description={data.p_description}
                    point={data.p_point}
                    type={data.p_pt_id}
                    id={data.p_id}
                  />
                </div>
              ))
            ) : (
              <p>No Product Found</p>
            )}

            {/* <div className="col-lg-3 col-md-4 col-sm-6">
              <ShopCard title="Shoew1" price="$10" img={Product1} />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <ShopCard title="Shoew1" price="$10" img={Product2} />
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <ShopCard title="Shoew1" price="$10" img={Product3} />
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <ShopCard title="Shoew1" price="$10" img={Product4} />
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <ShopCard title="Shoew1" price="$10" img={Product5} />
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
              <ShopCard title="Shoew1" price="$10" img={Product6} />
            </div> */}

            {/* <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>
            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>

            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>

            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div>

            <div className="col-lg-6 div col-md-6 col-sm-12">
              <Card3 />
            </div> */}
          </div>
        </div>
      </div>

      <FooterShop />
    </div>
  );
}

export default HomeShop;
