import React, { useEffect, useState } from "react";
import TableList2 from "../../components/TableListing/TableList2";
import TableListing from "../../components/TableListing/TableListing";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Modal from "react-awesome-modal";
import { MDBDataTableV5 } from "mdbreact";

import { useDispatch, useSelector } from "react-redux";
import {
    clearCategories,
    getAllCategories,
    removeCategory,
} from "../../../../actions/categoryAction";
import Axios from "../../../../Services/Axios";
import {getAllFaqList, removeFaqById} from "../../../../actions/zipCodeAction";
import AllFaqReducer from "../../../../reducers/allFaqReducer";
import {getAllProductType} from "../../../../actions/productTypeAction";

function Faq_list({ history }) {
    const [visible, setvisible] = useState(false);
      const dispatch = useDispatch();
    const [row, setrow] = useState([]);
    const [removeID, setremoveID] = useState("");
    const [UserRequets, setUserRequest] = useState(null);
    const [ispopulated, setIsPopulated] = useState(0);
    const { allFaq } = useSelector((state) => state.AllFaqReducer);

    useEffect(() => {
        Axios.get("/api/faq-list")
            .then((res) => {

                if(res.data.data.length > 0 && ispopulated == 0) {
                    setUserRequest(res.data.data);
                    setIsPopulated(1)
                }
            })
            .catch((err) => {
                 //   dispatch(LoadingState(false));
            });

        if (Array.isArray(UserRequets) && UserRequets.length > 0) {
            makeRow();
        } else {
            dispatch(getAllFaqList());
        }
    }, [dispatch,UserRequets,allFaq]);



    const redirectToEdit = (data) => {
        history.push({ pathname: "/admin/edit-catagory", state: { id:data } });
    };

    const removeData = (id) => {
        setremoveID(id);
        openModal();
     };

    const removeFaq = () => {

         dispatch(removeFaqById(removeID));
        closeModal();
         dispatch(getAllFaqList());

    };


    const makeRow = () => {
         var data =
            Array.isArray(UserRequets) && UserRequets.length > 0
                ? UserRequets.map((data, id) => ({
                    name: data.name,
                    description: data.description,
                    dateCreated: data.created_at,
                    catName: data.category_id.category_name,

                     action: (
                        <div>
                            {/*<Edit color="action" onClick={() => redirectToEdit(data)} />*/}
                            <Delete color="error" onClick={() => removeData(data.id)} />
                        </div>
                    ),
                }))
                : null;
         setrow(data);
    };

    const closeModal = () => {
        setvisible(false);
    };
    const openModal = () => {
        setvisible(true);
    };

    const columns = [
        {
            label: "Name",
            field: "name",
            width: 100,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Name",
            },
        }, {
            label: "Description",
            field: "description",
            width: 100,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Description",
            },
        }, {
            label: "Category Name",
            field: "catName",
            width: 100,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Name",
            },
        },
        {
            label: "Created At",
            field: "dateCreated",
            width: 200,
        },

        {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 100,
        },
    ];
    return (
        <div>
            <div className="card m-2" style={{paddingTop: 50}}>
                <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
                    Faq List
                </div>
                <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={{
                        columns,
                        rows: Array.isArray(row) && row.length > 0 ? row : [],
                    }}
                    style={{ fontSize: 15 }}
                    searchLabel="Seacrh here......"
                    searchTop
                    searchBottom={false}
                    responsive
                    barReverse
                />
            </div>
            <Modal
                visible={visible}
                effect="fadeInRight"
                onClickAway={() => closeModal()}
                className="modalPop"
            >
                <div className="modalPop1">
                    {/* <h1>Title</h1> */}
                    <div className="text-danger">
                        Are you Sure? You want to delete this Record.
                    </div>
                    <div className="d-flex justify-content-around">
                        <div
                            className="btn btn-primary btn-sm"
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </div>
                        <div className="btn btn-danger btn-sm" onClick={removeFaq}>
                            Delete
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Faq_list;
