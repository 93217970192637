import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import SidebarNavItem from "./SidebarNavItem";
import { Nav } from "shards-react";
import Icon from "@material-ui/core/Icon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const Accordion = withStyles({
  root: {
    borderBottom: "0.5px solid rgba(0, 0, 0, .05)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .005)",
    borderBottom: 0.5,
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function SidebarMenu({ items }) {
  const [expanded, setExpanded] = React.useState("");
  const color = "#007bff";
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    localStorage.setItem("pannel", panel);
  };

  useEffect(() => {
    console.log("LOCAL STOARGE", localStorage.getItem("pannel"));
    if (localStorage.getItem("pannel")) {
      setExpanded(localStorage.getItem("pannel"));
    }
  });
  return (
    <div>
      <Accordion
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className="border-bottom"
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div className="d-flex flex-row w-100">
            <span
              style={{
                color: expanded === "panel1" ? color : "#000",
                width: "80%",
                fontSize: 15,
              }}
            >
              Product
            </span>
            <span style={{ width: "20%" }}>
              {expanded === "panel1" ? (
                <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                <ExpandLessIcon className="" />
              )}
            </span>
            {/* <Icon
              className={
                expanded === "panel1"
                  ? "fas fa-caret-down "
                  : "fas fa-sort-up "
              }
            /> */}
            {/* ) : (
              <Icon className="fas fa-sort-up" />
            )} */}
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <Nav className="nav--no-borders flex-column w-100">
            {items.map((item, idx) =>
              item.menu === "Product" ? (
                <SidebarNavItem key={idx} item={item} />
              ) : (
                <null></null>
              )
            )}
          </Nav>
        </AccordionDetails>
      </Accordion>
        <Accordion
            square
            expanded={expanded === "Rubbish_Product"}
            onChange={handleChange("Rubbish_Product")}
            className="border-bottom"
        >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <div className="d-flex flex-row w-100">
            <span
                style={{
                    color: expanded === "Rubbish_Product" ? color : "#000",
                    width: "80%",
                    fontSize: 15,
                }}
            >
              Rubbish Product
            </span>
                    <span style={{ width: "20%" }}>
              {expanded === "Rubbish_Product" ? (
                  <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                  <ExpandLessIcon className="" />
              )}
            </span>
                    {/* <Icon
              className={
                expanded === "panel1"
                  ? "fas fa-caret-down "
                  : "fas fa-sort-up "
              }
            /> */}
                    {/* ) : (
              <Icon className="fas fa-sort-up" />
            )} */}
                </div>
            </AccordionSummary>
            <AccordionDetails className="p-0">
                <Nav className="nav--no-borders flex-column w-100">
                    {items.map((item, idx) =>
                        item.menu === "Add_BulkRubbish_Product" ? (
                            <SidebarNavItem key={idx} item={item} />
                        ) : (
                            <null></null>
                        )
                    )}
                </Nav>
            </AccordionDetails>
        </Accordion>
  {/*      <Accordion
            square
            expanded={expanded === "panel12"}
            onChange={handleChange("panel12")}
            className="border-bottom"
        >
            <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                <div className="d-flex flex-row w-100">
            <span
                style={{
                    color: expanded === "panel12" ? color : "#000",
                    width: "80%",
                    fontSize: 15,
                }}
            >
             Manage Orders
            </span>
                    <span style={{ width: "20%" }}>
              {expanded === "panel12" ? (
                  <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                  <ExpandLessIcon className="" />
              )}
            </span>
                </div>
            </AccordionSummary>
            <AccordionDetails className="p-0">
                <Nav className="nav--no-borders flex-column w-100">
                    {items.map((item, idx) =>
                        item.menu === "Order" ? (
                            <SidebarNavItem key={idx} item={item} />
                        ) : (
                            <null></null>
                        )
                    )}
                </Nav>
            </AccordionDetails>
        </Accordion>*/}
        <Accordion
            square
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
            className="border-bottom"
        >
            <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                <div className="d-flex flex-row w-100">
            <span
                style={{
                    color: expanded === "panel8" ? color : "#000",
                    width: "80%",
                    fontSize: 15,
                }}
            >
              Rubbish Orders
            </span>
                    <span style={{ width: "20%" }}>
              {expanded === "panel11" ? (
                  <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                  <ExpandLessIcon className="" />
              )}
            </span>
                </div>
            </AccordionSummary>
            <AccordionDetails className="p-0">
                <Nav className="nav--no-borders flex-column w-100">
                    {items.map((item, idx) =>
                        item.menu === "Rubbish_order" ? (
                            <SidebarNavItem key={idx} item={item} />
                        ) : (
                            <null></null>
                        )
                    )}
                </Nav>
            </AccordionDetails>
        </Accordion>
      <Accordion
        square
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        className="border-bottom"
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <div className="d-flex flex-row w-100">
            <span
              style={{
                color: expanded === "panel2" ? color : "#000",
                width: "80%",
                fontSize: 15,
              }}
            >
              Category
            </span>
            <span style={{ width: "20%" }}>
              {expanded === "panel2" ? (
                <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                <ExpandLessIcon className="" />
              )}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <Nav className="nav--no-borders flex-column w-100">
            {items.map((item, idx) =>
              item.menu === "Category" ? (
                <SidebarNavItem key={idx} item={item} />
              ) : (
                <null></null>
              )
            )}
          </Nav>
        </AccordionDetails>
      </Accordion>



      <Accordion
        square
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        className="border-bottom"
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <div className="d-flex flex-row w-100">
            <span
              style={{
                color: expanded === "panel3" ? color : "#000",
                width: "80%",
                fontSize: 15,
              }}
            >
              Packages
            </span>
            <span style={{ width: "20%" }}>
              {expanded === "panel3" ? (
                <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                <ExpandLessIcon className="" />
              )}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <Nav className="nav--no-borders flex-column w-100">
            {items.map((item, idx) =>
              item.menu === "Packages" ? (
                <SidebarNavItem key={idx} item={item} />
              ) : (
                <null></null>
              )
            )}
          </Nav>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        className="border-bottom"
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <div className="d-flex flex-row w-100">
            <span
              style={{
                color: expanded === "panel4" ? color : "#000",
                width: "80%",
                fontSize: 15,
              }}
            >
              Recycle Bags
            </span>
            <span style={{ width: "20%" }}>
              {expanded === "panel4" ? (
                <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                <ExpandLessIcon className="" />
              )}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <Nav className="nav--no-borders flex-column w-100">
            {items.map((item, idx) =>
              item.menu === "Bags" ? (
                <SidebarNavItem key={idx} item={item} />
              ) : (
                <null></null>
              )
            )}
          </Nav>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
        className="border-bottom"
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <div className="d-flex flex-row w-100">
            <span
              style={{
                color: expanded === "panel5" ? color : "#000",
                width: "80%",
                fontSize: 15,
              }}
            >
              Zip Code
            </span>
            <span style={{ width: "20%" }}>
              {expanded === "panel5" ? (
                <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                <ExpandLessIcon className="" />
              )}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <Nav className="nav--no-borders flex-column w-100">
            {items.map((item, idx) =>
              item.menu === "Zip" ? (
                <SidebarNavItem key={idx} item={item} />
              ) : (
                <null></null>
              )
            )}
          </Nav>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
        className="border-bottom"
      >
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <div className="d-flex flex-row w-100">
            <span
              style={{
                color: expanded === "panel6" ? color : "#000",
                width: "80%",
                fontSize: 15,
              }}
            >
              Manage Orders
            </span>
            <span style={{ width: "20%" }}>
              {expanded === "panel6" ? (
                <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                <ExpandLessIcon className="" />
              )}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <Nav className="nav--no-borders flex-column w-100">
            {items.map((item, idx) =>
              item.menu === "Order" ? (
                <SidebarNavItem key={idx} item={item} />
              ) : (
                <null></null>
              )
            )}
          </Nav>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
        className="border-bottom"
      >
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <div className="d-flex flex-row w-100">
            <span
              style={{
                color: expanded === "panel7" ? color : "#000",
                width: "80%",
                fontSize: 15,
              }}
            >
              User
            </span>
            <span style={{ width: "20%" }}>
              {expanded === "panel7" ? (
                <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                <ExpandLessIcon className="" />
              )}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <Nav className="nav--no-borders flex-column w-100">
            {items.map((item, idx) =>
              item.menu === "User" ? (
                <SidebarNavItem key={idx} item={item} />
              ) : (
                <null></null>
              )
            )}
          </Nav>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
        className="border-bottom"
      >
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <div className="d-flex flex-row w-100">
            <span
              style={{
                color: expanded === "panel8" ? color : "#000",
                width: "80%",
                fontSize: 15,
              }}
            >
              Transaction Collections
            </span>
            <span style={{ width: "20%" }}>
              {expanded === "panel8" ? (
                <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                <ExpandLessIcon className="" />
              )}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <Nav className="nav--no-borders flex-column w-100">
            {items.map((item, idx) =>
              item.menu === "Transaction" ? (
                <SidebarNavItem key={idx} item={item} />
              ) : (
                <null></null>
              )
            )}
          </Nav>
        </AccordionDetails>
      </Accordion>
        <Accordion
            square
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
            className="border-bottom"
        >
            <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                <div className="d-flex flex-row w-100">
            <span
                style={{
                    color: expanded === "panel9" ? color : "#000",
                    width: "80%",
                    fontSize: 15,
                }}
            >
              Collections  Order
            </span>
                    <span style={{ width: "20%" }}>
              {expanded === "panel9" ? (
                  <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                  <ExpandLessIcon className="" />
              )}
            </span>
                </div>
            </AccordionSummary>
            <AccordionDetails className="p-0">
                <Nav className="nav--no-borders flex-column w-100">
                    {items.map((item, idx) =>
                        item.menu === "CollectionOrder" ? (
                            <SidebarNavItem key={idx} item={item} />
                        ) : (
                            <null></null>
                        )
                    )}
                </Nav>
            </AccordionDetails>
        </Accordion>
      <Accordion
        square
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
        className="border-bottom"
      >
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <div className="d-flex flex-row w-100">
            <span
              style={{
                color: expanded === "panel10" ? color : "#000",
                width: "80%",
                fontSize: 15,
              }}
            >
              Product Type
            </span>
            <span style={{ width: "20%" }}>
              {expanded === "panel10" ? (
                <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                <ExpandLessIcon className="" />
              )}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <Nav className="nav--no-borders flex-column w-100">
            {items.map((item, idx) =>
              item.menu === "ProductType" ? (
                <SidebarNavItem key={idx} item={item} />
              ) : (
                <null></null>
              )
            )}
          </Nav>
        </AccordionDetails>
      </Accordion>
        <Accordion
            square
            expanded={expanded === "panelFaq"}
            onChange={handleChange("panelFaq")}
            className="border-bottom"
        >
            <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                <div className="d-flex flex-row w-100">
            <span
                style={{
                    color: expanded === "panelFaq" ? color : "#000",
                    width: "80%",
                    fontSize: 15,
                }}
            >
             Faq
            </span>
                    <span style={{ width: "20%" }}>
              {expanded === "panelFaq" ? (
                  <ExpandMoreIcon className="" style={{ color: color }} />
              ) : (
                  <ExpandLessIcon className="" />
              )}
            </span>
                </div>
            </AccordionSummary>
            <AccordionDetails className="p-0">
                <Nav className="nav--no-borders flex-column w-100">
                    {items.map((item, idx) =>
                        item.menu === "Faq" ? (
                            <SidebarNavItem key={idx} item={item} />
                        ) : (
                            <null></null>
                        )
                    )}
                </Nav>
            </AccordionDetails>
        </Accordion>

    <Accordion square expanded={expanded === "panelTerms"} onChange={handleChange("panelTerms")} className="border-bottom" >
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
            <div className="d-flex flex-row w-100">
                <span style={{
                        color: expanded === "panelTerms" ? color : "#000",
                            width: "80%",
                            fontSize: 15,
                    }}>
                    Terms
                </span>
                <span style={{ width: "20%" }}>
                    {expanded === "panelTerms" ? (
                        <ExpandMoreIcon className="" style={{ color: color }} />
                    ) : (
                    <ExpandLessIcon className="" />
                    )}
                </span>
            </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
        <Nav className="nav--no-borders flex-column w-100">
        {items.map((item, idx) =>
            item.menu === "Terms" ? (
                <SidebarNavItem key={idx} item={item} />
) : (
    <null></null>
)
)}
</Nav>
    </AccordionDetails>
    </Accordion>



    </div>
  );
}
