import React, { useEffect, useState } from "react";
import TableList2 from "../../components/TableListing/TableList2";
import TableListing from "../../components/TableListing/TableListing";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Modal from "react-awesome-modal";
import { MDBDataTableV5 } from "mdbreact";

import { useDispatch, useSelector } from "react-redux";
import {
  clearCategories,
  getAllBulkRubbishCategories,
  getAllCategories,
  removeBulkyCategory,
  removeCategory,
} from "../../../../actions/categoryAction";

function BulkCategory_List({ history }) {
  const [visible, setvisible] = useState(false);

  const { category } = useSelector((state) => state.categoryReducer);
  const dispatch = useDispatch();
  const [row, setrow] = useState([]);
  const [removeID, setremoveID] = useState("");

  useEffect(() => {
    console.log("category VALUE",category);
    if (Array.isArray(category) && category.length > 0) {
      makeRow();
    } else {
      dispatch(getAllBulkRubbishCategories());
    }
  }, [dispatch, category]);

  useEffect(() => {
    return () => {
      dispatch(clearCategories());
      console.log("clearcategory");
    };
  }, [dispatch]);

  const redirectToEdit = (id) => {
    history.push({
      pathname: "/admin/edit-bulk-rubbish-category",
      state: { id: id },
    });
  };

  const removeData = (id) => {
    setremoveID(id);
    openModal();
    console.log("ID", id);
  };

  const categoryRemove = () => {
    console.log("ID DELETED", removeID);
    dispatch(removeBulkyCategory(removeID));
    closeModal();
    dispatch(getAllBulkRubbishCategories());
  };

  const makeRow = () => {
    console.log("DATA PAXJAGEHSDBJHBDS", category);
    var data =
      Array.isArray(category) && category.length > 0
        ? category.map((data, id) => ({
            name: data.brc_name,

            active: data.brc_active === "1" ? "Active" : "Not Active",
            action: (
              <div>
                <Edit color="action" onClick={() => redirectToEdit(data)} />
                <Delete color="error" onClick={() => removeData(data.brc_id)} />
              </div>
            ),
          }))
        : null;
    console.log("NEW DATA SHAPE:", data);
    setrow(data);
  };

  const closeModal = () => {
    setvisible(false);
  };
  const openModal = () => {
    setvisible(true);
  };

  const columns = [
    {
      label: "Name",
      field: "name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "active",
      field: "active",
      width: 200,
    },

    {
      label: "Action",
      field: "action",
      sort: "disabled",
      width: 100,
    },
  ];
  return (
    <div>
      <div className="card m-2">
        <div className="h5 ml-5 py-3 font-weight-normal text-center bg-light">
          Bulky Rubbish Category List
        </div>
        <MDBDataTableV5
          hover
          entriesOptions={[5, 20, 25]}
          entries={5}
          pagesAmount={4}
          data={{
            columns,
            rows: Array.isArray(row) && row.length > 0 ? row : [],
          }}
          style={{ fontSize: 15 }}
          searchLabel="Seacrh here......"
          searchTop
          searchBottom={false}
          responsive
          barReverse
        />
      </div>
      <Modal
        visible={visible}
        effect="fadeInRight"
        onClickAway={() => closeModal()}
        className="modalPop"
      >
        <div className="modalPop1">
          {/* <h1>Title</h1> */}
          <div className="text-danger">
            Are you Sure? You want to delete this Record.
          </div>
          <div className="d-flex justify-content-around">
            <div
              className="btn btn-primary btn-sm"
              onClick={() => closeModal()}
            >
              Cancel
            </div>
            <div className="btn btn-danger btn-sm" onClick={categoryRemove}>
              Delete
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default BulkCategory_List;
